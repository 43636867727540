export enum Locale {
  ja_JP = 'ja_JP', // 初期値これ
  en_US = 'en_US',
  // en_AU = 'en_AU', // 代替言語指定のサンプルコード用に残すけど表示させないようにしてあるので使うときは探して戻すこと
}

/**
 * アプリのロケールと dayjs のロケールの対応
 * 新しいロケールに対応するときは @/common/useDayjs でインポートの追加が必要
 */
export const DayjsLocale: {[L in Locale]: string} = {
  [Locale.ja_JP]: 'ja',
  [Locale.en_US]: 'en',
  // [Locale.en_AU]: 'en-au',
};

export type LocaleState = Locale;
