import {V2CommonTypesBleDeviceType} from '@bitkey-service/v2_core-types/lib/common/v2_commonTypesThings';
import {createSlice} from '@reduxjs/toolkit';

import {SecurityDeviceScreenState} from '@/common/redux/screens/state/securityDeviceScreenState';
import {LatestFirmwareMap} from '@/wscreens/security-devices/WControlPanelDeviceTable';

const initialState: SecurityDeviceScreenState = {
  latestFirmwares: {},
};

const securityDeviceScreenSlice = createSlice({
  name: 'securityDeviceScreenSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    updateLatestFirmware: (state, action: {payload: LatestFirmwareMap<V2CommonTypesBleDeviceType>}) => {
      return {
        ...state,
        latestFirmwares: {
          ...state.latestFirmwares,
          ...action.payload,
        },
      };
    },
  },
});
export default securityDeviceScreenSlice;
