/**
 * @deprecated
 * ※ このファイルは削除予定です。増やすの厳禁。
 * figmaでも定義できていないカラーの定義群
 * ここに定義されているカラーをWHColorに移行していく
 */
export const DeprecatedColor = {
  F6F6F6: '#F6F6F6',
  F9F9F9: '#F9F9F9',
  ffffff: '#ffffff',
  f3f3f3: '#f3f3f3',
  c0c0c0: '#c0c0c0',
  '000000': '#000000',
  '083450': '#083450',
  '1dc1ed': '#1dc1ed',
  '333333': '#333333',
  f00: '#f00',
  '777': '#777',
  '001313': '#001313',
  E4E5E6: '#E4E5E6',
  '3949AB': '#3949AB',
  e4141b: '#e4141b',
  '3388aa': '#3388aa',
  '067091': '#067091',
  e24454: '#e24454',
  fafafa: '#fafafa',
  ACACAC: '#ACACAC',
  F5F6F7: '#F5F6F7',
  FFEB3B: '#FFEB3B',
  D1D1D1: '#D1D1D1',
  '19e': '#19e',
  '7D7D7E': '#7D7D7E',
  DFE1E6: '#DFE1E6',
  F44336: '#F44336',
} as const;

/**
 * @deprecated
 * ※ このファイルは削除予定です。増やすの厳禁。
 * figmaでも定義できていないカラーの定義群
 * ここに定義されているカラーをWHColorに移行していく
 */
export type DeprecatedColor = (typeof DeprecatedColor)[keyof typeof DeprecatedColor];

/**
 * @deprecated
 * ※ このファイルは削除予定です。増やすの厳禁。
 * figmaでも定義できていないカラーの定義群
 * ここに定義されているカラーをWHColorに移行していく
 *
 * MUIv4 => MUIv5に切り替わり、snackbarのデフォルトの色が変更されたため
 */
export const snackbarColor = {
  warning: '#ff9800',
  info: '#2196f3',
  error: '#f44336',
  success: '#4caf50',
};
