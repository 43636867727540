/**
 * @see {@link @see https://www.notion.so/workhub-Admin-f3435c4f0f4c4adc8328f9a6b216a0e8#0b599db657bf418d9548772c375f4750}
 * @deprecated CSS Variables を使ってください.
 */
export const WHSpacing = {
  spacing4: '4px',
  spacing8: '8px',
  spacing12: '12px',
  spacing16: '16px',
  spacing20: '20px',
  spacing24: '24px',
  spacing40: '40px',
};
