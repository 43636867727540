import {MutableRefObject, useCallback, useEffect, useState} from 'react';

// スクロールでフォーカスが外れたときに検知できるようにしたいだけ
export const useScrollFocusOnVisibleToolTip = ({
  scrollContainerRef,
}: {
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onMouseLeave = useCallback(() => setIsFocused(false), []);
  const onMouseEnter = useCallback(() => setIsFocused(true), []);

  const handleScroll = useCallback(() => {
    if (isFocused) {
      // スクロール時にフォーカスがあれば、フォーカスを失ったと判断
      setIsFocused(false);
    }
  }, [isFocused]);

  useEffect(() => {
    const container = scrollContainerRef?.current;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, isFocused, scrollContainerRef]); // 依存関係にフォーカス状態を含む

  return {
    isFocused,
    handleFocus: onMouseEnter,
    handleBlur: onMouseLeave,
  };
};
