import {Locale} from '@/common/redux/state-types/localeStateType';

export const spaceAndDeviceSectionMenuDef = {
  wOfficeBaseManagement: {
    default: {
      default: 'スペース管理',
      [Locale.en_US]: 'Space Management',
    },
  },
  deviceAndEquipmentManagement: {
    default: {
      default: 'デバイス・備品管理',
      [Locale.en_US]: 'Device and equipment management',
    },
  },
  reception: {default: {default: '受付管理', [Locale.en_US]: 'Reception Management'}},
  wRegionInformation: {default: {default: '拠点', [Locale.en_US]: 'Regions'}},
  wBuildingInformation: {
    default: {
      default: 'ビル',
      [Locale.en_US]: 'Buildings',
    },
  },
  wFloorInformation: {
    default: {
      default: 'フロア',
      [Locale.en_US]: 'Floors',
    },
  },
  wArea: {
    default: {
      default: 'エリア',
      [Locale.en_US]: 'Areas',
    },
  },
  wWorkBooth: {
    default: {
      default: 'ワークブース',
      [Locale.en_US]: 'Work Booths',
    },
  },
  wWorkspot: {
    default: {
      default: 'ワークスポット',
      [Locale.en_US]: 'Workspots',
    },
  },
  antiPassBack: {
    default: {
      default: 'アンチパスバックエリア',
      [Locale.en_US]: 'Anti Pass Back Area',
    },
  },
  layout: {
    default: {
      default: 'レイアウト',
      [Locale.en_US]: 'Layouts',
    },
  },
  securityDevice: {
    default: {
      default: 'デバイス',
      [Locale.en_US]: 'Security Devices',
    },
  },
  wEquipment: {
    default: {
      default: '備品',
      [Locale.en_US]: 'Equipments',
    },
  },
  equipments: {default: {default: '設備・備品', [Locale.en_US]: 'Equipments'}},
  wLockerInfo: {
    default: {
      default: 'ロッカー',
      [Locale.en_US]: 'Lockers',
    },
  },
  wLocker: {
    default: {
      default: 'ロッカー管理',
      [Locale.en_US]: 'Locker Management',
    },
  },
  wShelf: {
    default: {
      default: '収納棚',
      [Locale.en_US]: 'Shelves',
    },
  },
  receptionData: {default: {default: '受付データ', [Locale.en_US]: 'Reception Data'}},
  receptionDetail: {default: {default: '受付詳細', [Locale.en_US]: 'Reception Detail'}},
  wreceptionGuests: {default: {default: '受付ゲスト', [Locale.en_US]: 'Reception Guests'}},
};
