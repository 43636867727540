import {
  ApiTypesAddActivationsPostRequest,
  ApiTypesDeleteActivationsPostRequest,
} from '@bitkey-service/v2_core-types/lib/api/organization/activations/apiTypesActivations';
import {AxiosRequestConfig} from 'axios';

import {catchStack} from '@/common/error/stacktrace';
import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiWorkhubCore} from '../../apiWorkhubCore';

const basePath = () => {
  return `organizations/activations`;
};

export class ApiActivations {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.post<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );

  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.patch<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );

  public static add = (data: ApiTypesAddActivationsPostRequest) =>
    ApiActivations.post<undefined, ApiTypesAddActivationsPostRequest>(`/`, data);

  public static delete = (data: ApiTypesDeleteActivationsPostRequest) =>
    ApiActivations.patch<undefined, ApiTypesDeleteActivationsPostRequest>(`/delete`, data);
}
