import styled from '@emotion/styled';
import {createFileRoute} from '@tanstack/react-router';
import {useEffect, useState} from 'react';

import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import WHeaderNavigation from '@/components/header/WHeaderNavigation';
import LayoutBuildings from '@/features/layout/buildings/LayoutBuildings';
import LayoutFloors from '@/features/layout/buildings/LayoutFloors';

export const Route = createFileRoute('/_authorized/layout/')({
  component: RouteComponent,
});

const dictDef = {
  layout: {
    default: {
      default: 'レイアウト',
      [Locale.en_US]: 'Layout',
    },
  },
  navigation: {
    default: {
      default: 'スペース管理 /',
      [Locale.en_US]: 'Office Space Management',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
`;

function RouteComponent() {
  const [buildingId, setBuildingId] = useState('');
  const dict = useDict(dictDef);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const buildingId = params.get('buildingId');
    if (!buildingId) {
      return;
    }
    setBuildingId(buildingId);
  }, []);

  return (
    <Root>
      <WHeaderNavigation title={dict.layout} navigation={dict.navigation} />
      <Content>
        <LayoutBuildings buildingId={buildingId} setBuildingId={setBuildingId} />
        {!!buildingId && <LayoutFloors buildingId={buildingId} />}
      </Content>
    </Root>
  );
}
