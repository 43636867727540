import {createFileRoute, Outlet} from '@tanstack/react-router';

import {Authorize} from '@/routing/Authorize';

export const Route = createFileRoute('/_authorized/layout')({
  component: () => (
    <Authorize featureGroup='V2Layouts' feature='v2Layouts'>
      <Outlet />
    </Authorize>
  ),
});
