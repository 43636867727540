import styled from '@emotion/styled';
import ButtonBase from '@mui/material/ButtonBase';
import {useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {Link, OpenExternal} from '@workhub/icons';
import {Loading} from '@workhub/ui';

import {
  GetPublishedBuildingTenantServicesResponse,
  getPublishedBuildingTenantServicesApi,
} from '@/api-call/workhub-core/getPublishedBuildingTenantServicesApi';
import StorageBuildingTenantService from '@/common/firebase/storage/reference/organizations/building-tenant-service/storage-building-tenant-service';
import useDict from '@/common/hooks/useDict';
import {useLocale} from '@/common/hooks/useLocale';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHFont} from '@/common/styles/whFont';
import WHeaderNavigation from '@/components/header/WHeaderNavigation';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  title: {
    default: {
      default: 'ビルテナントサービス',
      [Locale.en_US]: 'Building Tenant Service',
    },
  },
  navigation: {
    default: {
      default: 'ビルテナントサービス/',
      [Locale.en_US]: 'Building Tenant Service/',
    },
  },
  noExistingBuildingTenantService: {
    default: {
      default: '公開されているビルテナントサービスがありません',
      [Locale.en_US]: 'There are no building tenant services available',
    },
  },
  moveTo: {
    default: {
      default: 'へ移動',
      [Locale.en_US]: 'Move to',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BuildingTenantServiceScreen = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  padding: var(--spacing-24);
`;

const FullSizeFlexMiddleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-40) 0;
  ${WHFont.labelLargeStrong}
`;

const ListItem = styled.div`
  border-radius: var(--radius-m);
  background-color: var(--surface-neutral-low);
`;

// ボタンに波紋を表示させるために、@mui/material/ButtonBase を直接利用しています。
// FIXME: 本来は workhub/ui 上に List > ListItem のようなコンポーネントを定義して利用したい
const ListItemButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
  padding: var(--spacing-16) var(--spacing-24);
  text-align: left;
  transition: background-color 300ms ease;
  background-color: var(--surface-neutral-low);
  border: none;
  border-color: var(--surface-neutral-low);
  border-radius: var(--radius-l);
  cursor: pointer;

  &:hover {
    background-color: var(--surface-accent-light);

    svg {
      visibility: visible;
    }
  }

  > span {
    width: 100%;
  }

  &:focus-visible {
    outline: 2px solid var(--border-brand-accent);
    svg {
      visibility: visible;
    }
  }
`;

const IMAGE_SIZE = '56px';
const Image = styled.div`
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Icon = styled.div`
  display: flex;
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-full);

  background-color: var(--surface-neutral-high);
  color: var(--object-brand-secondary);
`;

const ExternalIconSize = '18px';
const ListItemTexts = styled.div`
  ${WHFont.bodyMedium}
  max-width: calc(100% - ${IMAGE_SIZE} - var(--spacing-24) * 2 - ${ExternalIconSize});
  margin-right: var(--spacing-24);
  margin-left: var(--spacing-16);
`;

const ListItemName = styled.div`
  ${WHFont.titleMedium}
  margin-bottom: var(--spacing-4);
`;

const OpenExternalWrapper = styled.div`
  visibility: hidden;
  width: ${ExternalIconSize};
  height: ${ExternalIconSize};
  margin-left: auto;
  color: var(--text-neutral-secondary);
`;

export const Route = createFileRoute('/_authorized/building-tenant-service')({
  component: () => (
    <Authorize featureGroup='BuildingTenantService' feature='BuildingTenantService'>
      <RouteComponent />
    </Authorize>
  ),
});

const BuildingTenantServicesList = ({
  buildingTenantServices,
}: {
  buildingTenantServices: (GetPublishedBuildingTenantServicesResponse['items'][0] & {logoUrl?: string})[];
}) => {
  const dict = useDict(dictDef);
  const locale = useLocale();
  return (
    <>
      {buildingTenantServices.map(s => (
        <ListItem key={s.id}>
          <ListItemButton
            type='button'
            aria-label={locale === Locale.ja_JP ? `${s.name}${dict.moveTo}` : `${dict.moveTo}${s.name}`}
            onClick={() => window.open(s.siteUrl, '_blank')}
          >
            {s.logoUrl ? (
              <Image>
                <img src={s.logoUrl} alt={s.name} title={s.name} />
              </Image>
            ) : (
              <Icon>
                <Link color={'inherit'} />
              </Icon>
            )}
            <ListItemTexts>
              <ListItemName>{s.name}</ListItemName>
              {s.description}
            </ListItemTexts>
            <OpenExternalWrapper>
              <OpenExternal />
            </OpenExternalWrapper>
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
};

function RouteComponent() {
  const dict = useDict(dictDef);
  const {organizationId} = useLoginUser();
  const {data: buildingTenantServices, isLoading} = useQuery({
    queryKey: ['publishedBuildingTenantServices', organizationId],
    queryFn: async () => {
      // 将来、ビルごとに出し分ける構想があるためbuildingIdを渡す仕様になっているが、
      // 2025/01/06ではスコープ外で未対応で値が使われないため、暫定的に組織IDを渡しています
      const {data} = await getPublishedBuildingTenantServicesApi({paths: {buildingId: organizationId}});
      return await Promise.all(
        data.items.map(async item => {
          if (!item.logoImage) return item;
          const url = await StorageBuildingTenantService.loadLogo(item.organizationId, item.id, item.logoImage);
          return {...item, logoUrl: url};
        })
      );
    },
  });

  return (
    <Root>
      <WHeaderNavigation title={dict.title} navigation={dict.navigation} />
      {isLoading ? (
        <Loading centered={true} />
      ) : (
        <BuildingTenantServiceScreen>
          {!buildingTenantServices?.length ? (
            <FullSizeFlexMiddleWrapper>{dict.noExistingBuildingTenantService}</FullSizeFlexMiddleWrapper>
          ) : (
            <BuildingTenantServicesList buildingTenantServices={buildingTenantServices} />
          )}
        </BuildingTenantServiceScreen>
      )}
    </Root>
  );
}
