import {
  V2SpaceType,
  V2StoreTypesOrgSpace,
} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';

import {FirestoreOrgSpaces} from '@/common/firebase/firestore/references/firestoreOrgSpaces';
import {Locale} from '@/common/redux/state-types/localeStateType';
import ArrayUtil from '@/common/utils/arrayUtil';
import {EsSpace} from '@/elastic/doc-types/esSpaces';

export const SPACE_STRUCTURES_NO_SETTING_ID = 'notSettingId';

export type ConvertedFlatSpaceStructuresType = {
  area: EsSpace;
  floor?: Partial<V2StoreTypesOrgSpace>;
  building?: Partial<V2StoreTypesOrgSpace>;
  region?: Partial<V2StoreTypesOrgSpace>;
}[];

export type ConvertedSpaceStructuresType = {
  regionId: string;
  label: string;
  buildings: {
    buildingId: string;
    label: string;
    floors: {
      floorId: string;
      label: string;
    }[];
  }[];
}[];

export class SpaceStructuresConvert {
  public static init = () => new SpaceStructuresConvert();

  /**親エリアとスペースを紐付け */
  private joinAreaAndParentArea = (spaces: EsSpace[], parentAreas: V2StoreTypesOrgSpace[]) => {
    const findArea = (type: V2SpaceType, area: EsSpace) => {
      return parentAreas.find(parent => area.parentIds?.includes(parent.id) && parent.type === type);
    };
    return spaces.map(area => {
      const parentFloor = findArea('Floor', area);
      const parentBuilding = findArea('Building', area);
      const parentRegion = findArea('Region', area);
      return {
        area: area,
        floor: parentFloor,
        building: parentBuilding,
        region: parentRegion,
      };
    });
  };

  /**親エリアを取得し、スペースと紐づけたデータを返す */
  public getParentsSpaces = async (spaces: EsSpace[]): Promise<ConvertedFlatSpaceStructuresType> => {
    const parentIds = ArrayUtil.removeDuplicateBySet(ArrayUtil.removeUndefined(spaces.flatMap(v => v.parentIds)));
    const orgId = spaces[0].organization_id;
    if (!orgId) return [];
    const parentAreas = await FirestoreOrgSpaces.getByIds(orgId, parentIds);
    return this.joinAreaAndParentArea(spaces, parentAreas);
  };

  public convertSpaceStructures = (convertedSpaces: ConvertedFlatSpaceStructuresType, locale: Locale) => {
    return convertedSpaces.reduce<ConvertedSpaceStructuresType>((result, current) => {
      const addBuilding = {
        buildingId: current?.building?.id ?? '',
        label: current.building?.name ?? '',
        floors: !current.floor?.id
          ? []
          : [
              {
                floorId: current.floor.id,
                label: current.floor.name ?? '',
              },
            ],
      };
      if (current.region?.id) {
        const existRegion = result.find(v => v.regionId === current.region?.id);
        if (current.building?.id) {
          if (existRegion) {
            const existBuilding = existRegion.buildings.find(v => v.buildingId === current.building?.id);
            if (existBuilding) {
              if (current.floor?.id) {
                const existFloor = existBuilding.floors.find(v => v.floorId === current.floor?.id);
                if (!existFloor) {
                  // フロアが登録されていない
                  existBuilding.floors = [
                    ...existBuilding.floors,
                    {
                      floorId: current.floor.id,
                      label: current.floor.name ?? '',
                    },
                  ];
                }
              }
            } else {
              // ビルが登録されていない
              existRegion.buildings = [...existRegion.buildings, addBuilding];
            }
          } else {
            // 拠点が登録されていない
            result.unshift({
              regionId: current.region.id,
              label: current.region.name ?? '',
              buildings: !current.building.id ? [] : [addBuilding],
            });
          }
        }
      } else {
        // 拠点設定無し
        const addNoSettingBuildings = {
          buildingId: SPACE_STRUCTURES_NO_SETTING_ID,
          label: locale === Locale.ja_JP ? 'ビル指定無し' : 'No building setting',
          floors: !current.floor?.id
            ? []
            : [
                {
                  floorId: current.floor?.id,
                  label: current.floor?.name ?? '',
                },
              ],
        };

        const existRegion = result.find(v => v.regionId === SPACE_STRUCTURES_NO_SETTING_ID);
        if (existRegion) {
          const existBuilding = existRegion.buildings.find(v => v.buildingId === SPACE_STRUCTURES_NO_SETTING_ID);
          if (existBuilding) {
            const existFloor = existBuilding.floors.find(v => v.floorId === current.floor?.id);
            if (current.floor?.id && !existFloor) {
              // フロアが登録されていない
              existBuilding.floors = [...existBuilding.floors, addNoSettingBuildings.floors[0]];
            }
          } else {
            // フロアだけ登録されてる
            existRegion.buildings = [...existRegion.buildings, addNoSettingBuildings];
          }
        } else {
          // 拠点が登録されていない
          result.push({
            regionId: SPACE_STRUCTURES_NO_SETTING_ID,
            label: locale === Locale.ja_JP ? '拠点指定無し' : 'No region setting',
            buildings: [addNoSettingBuildings],
          });
        }
      }
      return result;
    }, []);
  };
}
