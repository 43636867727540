import * as React from 'react';
import {useEffect, useState} from 'react';

import useDict from '../../common/hooks/useDict';
import {Locale} from '../../common/redux/state-types/localeStateType';
import SimpleSnackbar, {SimpleSnackbarState, SimpleSnackbarType} from '../snackbar/SimpleSnackbar';

const dictDef = {
  networkConnectionError: {
    default: {
      default: 'ネットワークへ接続されていません。',
      [Locale.en_US]: 'Network connection error.',
    },
  },
};

const NetWorkWatcher: React.FC = () => {
  const dict = useDict(dictDef);
  const [snackbarState, setSnackbarState] = useState<SimpleSnackbarState>({
    open: false,
    message: '',
    type: SimpleSnackbarType.Success,
  });

  useEffect(() => {
    let timer: number;
    const listener = () => {
      timer = window.setTimeout(() => {
        setSnackbarState({
          open: true,
          message: dict.networkConnectionError,
          type: SimpleSnackbarType.Error,
        });
      }, 5000);
    };
    const onlineListener = () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
    window.addEventListener('offline', listener);
    window.addEventListener('online', onlineListener);
    return () => {
      window.removeEventListener('offline', listener);
      window.removeEventListener('online', onlineListener);
    };
  }, [dict.networkConnectionError]);

  return <SimpleSnackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />;
};

export default NetWorkWatcher;
