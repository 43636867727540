import {createSlice} from '@reduxjs/toolkit';

import {UserState} from '../state-types/userStateType';

export const initialState: UserState = {
  userId: '',
  personaId: '',
  peopleId: '',
  peopleType: undefined,
  people: undefined,
  code: '',
  nameJp: '',
  nameEn: '',
  firstNameJp: '',
  firstNameEn: '',
  firstNameKana: '',
  familyNameJp: '',
  familyNameEn: '',
  familyNameKana: '',
  email: '',
  organizationId: '',
  organization: undefined,
  photoUri: '',
  themeColor: '',
  accessToken: '',
  refreshToken: '',
  switchablePersonas: [],
  activations: [],
  authority: {},
  userGroupIds: [],
  userGroups: [],
  superUser: false,
  visibleSpaces: [],
  debuggable: undefined,
  experimentable: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<UserState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});

export default userSlice;
