export class StringUtils {
  public static convertDisplayJoinSlash = ({words}: {words: string[]}) => {
    const fixed = words.map(word => (!word ? '-' : word));
    return fixed.join(' / ');
  };

  /** 指定文字長以上の文字列をカットする。
   * @param label: 対象文字列
   * @param limitLength: 桁数
   * @returns limitLength以上の場合は word(指定文字数まで) + '...'
   * TODO: バイト数で区切る（forで回すとテーブルなどが重そうなのでやっていない）
   */
  public static cutOverFlowLabel = (label: string, limitLength: number) => {
    return !label || label.length <= limitLength ? label : label.slice(0, limitLength) + '...';
  };

  public static createUniqueStr = (): string => {
    return crypto.randomUUID();
  };

  /**
   * targetsの中にwordと部分一致する文字列があるか判定する。
   * targetsの中に1つでも一致するものがあればtrueを返す。
   * 複数件の一致は判定しない。
   * @param targets チェック対象
   * @param word 対象文字列
   * @returns boolean 判定結果
   */
  public static checkPartialMatch = (targets: string[], word: string) => {
    const regex = new RegExp('.*' + word + '.*');
    return targets.some(target => regex.test(target));
  };

  /**
   * 漢字、数字混じりの文字列を比較して結果を返す
   * sort関数にそのまま使う
   * @param array
   */
  public static sortJapaneseStrArrayCondition = (a: string, b: string): number => {
    const length = a.length > b.length ? a.length : b.length;
    const sa = a.replace(/(\d+)/g, m => m.padStart(length, '0'));
    const sb = b.replace(/(\d+)/g, m => m.padStart(length, '0'));
    return sa.localeCompare(sb, 'ja');
  };

  public static chunkSubstr(src: string, size: number) {
    const numChunks = Math.ceil(src.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = src.substring(o, o + size);
    }

    return chunks;
  }

  /**
   * 対象から制御文字を削除する
   * @param str
   * @returns
   */
  public static removeControlCode = (str: string) => {
    // eslint-disable-next-line no-control-regex
    return str.replace(/[\x00-\x1F\x7F-\x9F]/g, '');
  };

  public static capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
}
