import {combineReducers} from '@reduxjs/toolkit';

import contractDetailSlice from '@/common/redux/screens/slices/contractDetailSlice';
import customerDetailSlice from '@/common/redux/screens/slices/customerDetailSlice';
import {dropInPlansScreenSlice} from '@/common/redux/screens/slices/dropInPlansScreenSlice';
import {dropInPricePlansDetailScreenSlice} from '@/common/redux/screens/slices/dropInPricePlansDetailScreenSlice';
import {dropInPricePlansScreenSlice} from '@/common/redux/screens/slices/dropInPricePlansScreenSlice';
import {dropInSpaceSettingsSlice} from '@/common/redux/screens/slices/dropInSpaceSettingsSlice';
import multiTenantContractDetailScreenSlice from '@/common/redux/screens/slices/multiTenantContractDetailScreenSlice';
import multiTenantContractPlanScreenSlice from '@/common/redux/screens/slices/multiTenantContractPlanScreenSlice';
import securityDeviceScreenSlice from '@/common/redux/screens/slices/securityDeviceScreenSlice';
import spaceReportScreenSlice from '@/common/redux/screens/slices/spaceReportScreenSlice';
import specifiedCommercialTransactionSettingSlice from '@/common/redux/screens/slices/specifiedCommercialTransactionSettingSlice';
import upsertMultiTenantContractSlice from '@/common/redux/screens/slices/upsertMultiTenantContractSlice';

import contractPlanScreenSlice from './slices/contractPlanScreenSlice';
import createContractSlice from './slices/createContractSlice';
import releaseContractPlanSlice from './slices/releaseContractPlanSlice';
import renewContractSlice from './slices/renewContractSlice';

const areasDetailScreenTabsReducer = combineReducers({
  tabs: combineReducers({
    dropInSpaceSettingsSlice: dropInSpaceSettingsSlice.reducer,
  }),
});

const workhubPassSettingScreen = combineReducers({
  settings: combineReducers({
    specifiedCommercialTransactionSettingSlice: specifiedCommercialTransactionSettingSlice.reducer,
  }),
});

export const screensReducer = combineReducers({
  renewContract: renewContractSlice.reducer,
  createContract: createContractSlice.reducer,
  contractDetail: contractDetailSlice.reducer,
  customerDetail: customerDetailSlice.reducer,
  releaseContractPlan: releaseContractPlanSlice.reducer,
  contractPlanScreen: contractPlanScreenSlice.reducer,
  spaceReportScreen: spaceReportScreenSlice.reducer,
  dropInPlansScreen: dropInPlansScreenSlice.reducer,
  dropInPricePlansScreen: dropInPricePlansScreenSlice.reducer,
  dropInPricePlansDetailScreen: dropInPricePlansDetailScreenSlice.reducer,
  multiTenantContractPlanScreen: multiTenantContractPlanScreenSlice.reducer,
  multiTenantContractDetailScreen: multiTenantContractDetailScreenSlice.reducer,
  upsertMultiTenantContractSlice: upsertMultiTenantContractSlice.reducer,
  areasDetailScreen: areasDetailScreenTabsReducer,
  workhubPassSettingScreen: workhubPassSettingScreen,
  securityDeviceScreen: securityDeviceScreenSlice.reducer,
});
