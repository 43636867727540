import {Locale} from '@/common/redux/state-types/localeStateType';

export const applicationSectionMenuDef = {
  wApplication: {
    default: {
      default: '申請管理',
      [Locale.en_US]: 'Application Management',
    },
  },
  wApprovalTray: {
    default: {
      default: '承認トレイ',
      [Locale.en_US]: 'Approval Tray',
    },
  },
};
