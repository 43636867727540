import styled from '@emotion/styled';
import React, {useMemo} from 'react';

import {WHColor} from '@/common/styles/whColor';
import {WHFontCss} from '@/common/styles/whFont';

const TextAreaWrapped = styled.textarea<{
  borderColor?: string;
  width?: number;
  height?: number;
  padding?: React.CSSProperties['padding'];
}>`
  &::placeholder {
    padding: var(--spacing-8);
  }

  ${WHFontCss.bodyMedium}

  padding: ${({padding}) => padding};
  position: relative;
  background: var(--surface-neutral-middle);
  border: 2px solid ${props => (props.borderColor ? props.borderColor : WHColor.border.onLightDefault)};
  box-sizing: border-box;
  border-radius: 3px;
  resize: none;
  max-width: ${props => (props.width ? `${props.width}px` : '100%')};
  min-width: ${props => (props.width ? `${props.width}px` : '100%')};
  max-height: ${props => (props.height ? `${props.height}px` : '100%')};
  min-height: ${props => (props.height ? `${props.height}px` : '100%')};
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MaxLengthErrorMessage = styled.div`
  color: var(--text-semantic-error);
  ${WHFontCss.labelSmall}
`;

const CurrentTextCount = styled.div<{isError?: boolean}>`
  color: ${props => (props.isError ? WHColor.text.semanticError : WHColor.text.neutralDisabled)};
  ${WHFontCss.labelSmall}
`;

const TextContentsWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
`;

/** @deprecated @workhub/ui の TextArea を使ってください */
const WTextArea = React.memo<
  {
    width?: number;
    height?: number;
    placeholder?: string;
    placeholderPadding?: React.CSSProperties['padding'];
    onChange?: (...args: any[]) => any;
    maxLengthWithError?: number;
  } & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({width, height, placeholder, onChange, defaultValue, ref, ...props}) => {
  const isStringValue = useMemo(() => props.value === undefined || typeof props.value === 'string', [props.value]);
  const currentLength = useMemo(
    () => (isStringValue ? (props.value as string)?.length : 0),
    [isStringValue, props.value]
  );
  const isExceedLength = useMemo(
    () => (props.maxLengthWithError && isStringValue ? currentLength > props.maxLengthWithError : false),
    [currentLength, isStringValue, props.maxLengthWithError]
  );

  return (
    <div>
      <TextAreaWrapped
        placeholder={placeholder}
        borderColor={isExceedLength ? WHColor.border.semanticError : undefined}
        width={width}
        height={height}
        padding={props.placeholderPadding}
        onChange={onChange ? events => onChange(events) : undefined}
        defaultValue={defaultValue}
        {...props}
      />
      {isStringValue && props.maxLengthWithError && (
        <Flex>
          <MaxLengthErrorMessage>
            {isExceedLength ? `${props.maxLengthWithError}文字を超えています` : ''}
          </MaxLengthErrorMessage>

          <TextContentsWrapper>
            <TextContentsWrapper>
              <CurrentTextCount isError={isExceedLength}>{currentLength}</CurrentTextCount>
              <CurrentTextCount>{`/${props.maxLengthWithError}`}</CurrentTextCount>
            </TextContentsWrapper>
          </TextContentsWrapper>
        </Flex>
      )}
    </div>
  );
});

WTextArea.displayName = 'WTextArea';
export default WTextArea;
