import {Components, Theme, createTheme} from '@mui/material/styles';

import {DeprecatedColor} from './deprecatedColor';
import {WHColor} from './whColor';
//NOTE: https://mui.com/x/react-date-pickers/base-concepts/#typescript
import type {} from '@mui/x-date-pickers/themeAugmentation';
import {WHFont} from './whFont';

export type WDefaultThemeColor = 'primary' | 'secondary';
export type WThemeColor = 'success' | 'info' | 'warning' | 'error' | 'default';
export type WColor = WDefaultThemeColor | WThemeColor;

export const V5Overrides: Components<Omit<Theme, 'components'>> = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
      },
      input: {
        height: '1.2em',
        paddingTop: 5,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        // fontSize: '0.875rem',®
        // fontFamily: 'inherit',
      },
      body1: {
        fontSize: 'inherit',
        fontFamily: 'inherit',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
        textTransform: 'none',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      inputSizeSmall: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
      },
      body: {
        position: 'relative',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      label: {
        ...WHFont.labelLargeStrong,
      },
    },
  },
  MuiMultiSectionDigitalClock: {
    styleOverrides: {
      root: {
        //NOTE: DateTimePickerの時刻表示にスクロールバーが被る既存バグ対応
        //@see: https://github.com/mui/mui-x/issues/9311
        '&&': {
          '.MuiList-root': {
            width: '65px',
          },
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionStart: {
        color: WHColor.text.neutralPrimary,
      },
    },
  },
};

export const ThemeDefaults = createTheme({
  typography: {
    fontFamily: WHFont.bodyMedium.fontFamily,
  },
  palette: {
    primary: {
      main: WHColor.surface.brandPrimary,
      light: WHColor.surface.brandSecondary,
      dark: WHColor.surface.brandPrimary,
      contrastText: WHColor.text.neutralWhitePrimary,
    },
    secondary: {
      main: WHColor.surface.neutralHigh,
      light: WHColor.surface.neutralHigh,
      dark: WHColor.surface.neutralHigh,
      contrastText: WHColor.text.brandPrimary,
    },
    text: {
      primary: WHColor.text.neutralPrimary,
      secondary: WHColor.text.neutralSecondary,
    },
  },
  components: V5Overrides,
});

export const Themes: {[K in WThemeColor]: any} = {
  success: createTheme({
    palette: {
      primary: {
        main: WHColor.surface.semanticSuccess,
        light: WHColor.surface.semanticSuccess,
        dark: WHColor.surface.semanticSuccess,
        contrastText: WHColor.text.neutralWhitePrimary,
      },
    },
    components: V5Overrides,
  }),
  info: createTheme({
    palette: {
      primary: {
        main: WHColor.surface.expressiveLightblue,
        light: WHColor.surface.expressiveLightblue,
        dark: WHColor.surface.expressiveLightblue,
        contrastText: WHColor.text.neutralWhitePrimary,
      },
    },
    components: V5Overrides,
  }),
  warning: createTheme({
    palette: {
      primary: {
        main: WHColor.surface.semanticWarning,
        light: WHColor.surface.semanticWarning,
        dark: WHColor.surface.semanticWarning,
        contrastText: WHColor.text.neutralWhitePrimary,
      },
    },
    components: V5Overrides,
  }),
  error: createTheme({
    palette: {
      primary: {
        main: WHColor.surface.semanticError,
        light: WHColor.surface.semanticError,
        dark: WHColor.surface.semanticError,
        contrastText: WHColor.text.neutralWhitePrimary,
      },
    },
    components: V5Overrides,
  }),
  default: createTheme({
    palette: {
      primary: {
        main: DeprecatedColor.f3f3f3,
        light: DeprecatedColor.ffffff,
        dark: DeprecatedColor.c0c0c0,
      },
    },
    components: V5Overrides,
  }),
};
