// https://ja.vitejs.dev/guide/build#%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%82%A8%E3%83%A9%E3%83%BC%E3%81%AE%E3%83%8F%E3%83%B3%E3%83%88%E3%82%99%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%99
export const addPreloadErrorListener = () => {
  window.addEventListener('vite:preloadError', reload);
};

export const removePreloadErrorListener = () => {
  window.removeEventListener('vite:preloadError', reload);
};

const reload = () => {
  window.location.reload();
};
