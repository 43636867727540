import {StoreTypesOrgContract} from '@bitkey-service/v2_core-types/lib/store/organizations/contract/storeTypesOrgContract';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {MultiTenantContractDetailScreenSliceState} from '@/common/redux/screens/state/multiTenantContractDetailScreenSliceState';

const initialState: MultiTenantContractDetailScreenSliceState = {
  tab: 'overview',
  headerActionDialog: {type: 'discard', open: false},
};

const multiTenantContractDetailScreenSlice = createSlice({
  name: 'multiTenantContractDetailScreenSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    initialize: (state, {payload}: PayloadAction<MultiTenantContractDetailScreenSliceState['contract']>) => {
      state.contract = payload;
    },
    updateContract: (state, {payload}: PayloadAction<Partial<StoreTypesOrgContract>>) => {
      if (state.contract) {
        state.contract = {...state.contract, ...payload};
      }
    },
    updateTab: (state, {payload}: PayloadAction<MultiTenantContractDetailScreenSliceState['tab']>) => {
      state.tab = payload;
    },
    openHeaderActionDialog: (
      state,
      {payload}: PayloadAction<MultiTenantContractDetailScreenSliceState['headerActionDialog']['type']>
    ) => {
      state.headerActionDialog = {open: true, type: payload};
    },
    closeHeaderActionDialog: state => {
      state.headerActionDialog = {...state.headerActionDialog, open: false};
    },
  },
});
export default multiTenantContractDetailScreenSlice;
