import {ApiTypesCreateReservationSettingReq} from '@bitkey-service/v2_core-types/lib/api/organization/reservations/reservation-settings/apiTypesOrgReservationSettings';
import {V2PeopleTypes} from '@bitkey-service/v2_core-types/lib/common/v2_commonTypes';
import {DayOfWeek} from '@bitkey-service/v2_core-types/lib/enum/dayOfWeek';
import {
  BasicTypesReservationTimeUnit,
  DetectiveConditionJudgeRule,
} from '@bitkey-service/v2_core-types/lib/store/organizations/reservation-settings/storeTypesOrgReservationSettings';
import {
  AutoCancelTimeLengthInMinutes,
  AutoExtendSetting,
  AvailablePreCheckInTimeLengthInMinutes,
  ReservablePeriod,
  StoreTypesOrgReservationSettings,
  WorkspotCheckInType,
  WorkspotReservationSettings,
} from '@bitkey-service/v2_core-types/lib/store/organizations/reservation-settings/storeTypesOrgReservationSettings';
import {V2StoreTypesOrgSpace} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';
import {
  MailTemplateType,
  RepeatableType,
  SendableMailSettings,
} from '@bitkey-service/v2_core-types/lib/store/organizations/workhub-reservation-settings/v2_storeTypesWHOrgReservationSetting';

import {getCompanionPreregistrationSettingByFsReservationIdApi} from '@/api-call/workhub-core/getCompanionPreregistrationSettingByFsReservationIdApi';
import {CommonError} from '@/common/error/commonError';
import {FirestoreOrgReservationSettings} from '@/common/firebase/firestore/references/firestoreOrgReservationSettings';
import {FirestoreOrgSpaces} from '@/common/firebase/firestore/references/firestoreOrgSpaces';
import {ReservationSettingUpdate} from '@/features/others/reservation/types/ReservationSettingUpdate';
import {V2ReservationSettingService} from '@/v2_service/reservation/v2_reservaionSettingService';

export type RemindTimeSettings = {
  unit: 'minute' | 'hour' | 'day';
  count: number;
};

export type CompanionPreregistrationSettingType = {
  companionPreregistrationWebUrl?: string;
  maxCompanionCount?: number;
  hasPassword?: boolean;
  registeredCompanionCount?: number;
};

export class SpaceReservationService {
  public static loadReservationSetting = async ({
    organizationId,
    spaceId,
  }: {
    organizationId: string;
    spaceId: string;
  }): Promise<{
    space: V2StoreTypesOrgSpace;
    reservationSetting: StoreTypesOrgReservationSettings;
  }> => {
    const [space, reservationSetting] = await Promise.all([
      FirestoreOrgSpaces.getById(organizationId, spaceId),
      FirestoreOrgReservationSettings.getById(organizationId, spaceId),
    ]);
    if (!space) {
      throw new CommonError('space-not-found');
    }

    if (!reservationSetting) {
      throw new CommonError('reservation-setting-not-found');
    }
    return {
      space,
      reservationSetting,
    };
  };

  public registerReservationSetting = async ({
    spaceId,
    reservableTimeUnitInMinutes,
    reservableDayOfWeeks,
    baseReservableTimes,
    reservableTimesEachDayOfWeek,
    permitReservationDuplication,
    minReservationTimeLengthInMinutes,
    maxReservationTimeLengthInMinutes,
    workspotCheckInType,
    availablePreCheckInTimeLengthInMinutes,
    autoCancelTimeLengthInMinutes,
    useUserGroup,
    userGroupIds,
    noteJp,
    noteEn,
    cancelPolicyJp,
    cancelPolicyEn,
    phoneNumber,
    Private, // privateが予約語のため
    visibleContentFromOther,
    availableBeforeMinutes,
    availableAfterMinutes,
    availableACPatterns,
    mailTemplateIds,
    sendableMailTemplateTypes,
    sendableMailSettings,
    remindMinutes,
    finishRemindMinutes,
    reminderMinutesForCompanionRegistration,
    cancellationDeadlineUnit,
    cancellationDeadlineNumber,
    ableToInviteExternal,
    isGuestCompanionPreRegisteringEnabled,
    reservablePeriod,
    availableWebCheckIn,
    autoExtendSettings,
    sendGuestMailToEmployee,
    repeatableType,
    availableACPatternConditions,
  }: {
    organizationId: string;
    spaceId: string;
    reservable: boolean;
    reservableTimeUnitInMinutes?: number;
    reservableDayOfWeeks?: Array<DayOfWeek>;
    baseReservableTimes?: Array<BasicTypesReservationTimeUnit>;
    reservableTimesEachDayOfWeek?: {
      [key in DayOfWeek]: Array<BasicTypesReservationTimeUnit>;
    };
    permitReservationDuplication?: boolean;
    minReservationTimeLengthInMinutes?: number;
    maxReservationTimeLengthInMinutes?: number;
    workspotCheckInType?: WorkspotCheckInType;
    availablePreCheckInTimeLengthInMinutes?: AvailablePreCheckInTimeLengthInMinutes;
    autoCancelTimeLengthInMinutes?: AutoCancelTimeLengthInMinutes;
    useUserGroup?: boolean;
    userGroupIds?: string[];
    noteJp?: string;
    noteEn?: string;
    cancelPolicyJp?: string;
    cancelPolicyEn?: string;
    phoneNumber?: string;
    Private?: boolean;
    visibleContentFromOther?: NonNullable<
      NonNullable<StoreTypesOrgReservationSettings['workhubSettings']>['visibleContentFromOther']
    >;
    availableBeforeMinutes?: number;
    availableAfterMinutes?: number;
    availableACPatterns?: {
      [key in V2PeopleTypes]: string[];
    };
    mailTemplateIds?: string[];
    sendableMailTemplateTypes?: MailTemplateType[];
    sendableMailSettings?: SendableMailSettings;
    remindMinutes?: number;
    finishRemindMinutes?: number;
    reminderMinutesForCompanionRegistration?: number;
    cancellationDeadlineNumber?: number;
    cancellationDeadlineUnit?: 'minute' | 'hour';
    ableToInviteExternal?: boolean;
    isGuestCompanionPreRegisteringEnabled: boolean | undefined;
    reservablePeriod?: ReservablePeriod;
    availableWebCheckIn?: boolean;
    autoExtendSettings?: AutoExtendSetting;
    sendGuestMailToEmployee?: StoreTypesOrgReservationSettings['sendGuestMailToEmployee'];
    repeatableType?: RepeatableType;
    availableACPatternConditions?: ReservationSettingUpdate['availableACPatternConditions'];
  }) => {
    // 旧動線でカレンダーとれなくなってユーザーが詰むので、IDは詰めておかないとダメ。
    const reservationSetting: StoreTypesOrgReservationSettings = {
      id: spaceId,
      reservable: true,
      reservableTimeUnitInMinutes,
      reservableDayOfWeeks,
      baseReservableTimes,
      reservableTimesEachDayOfWeek,
      permitReservationDuplication,
      minReservationTimeLengthInMinutes,
      maxReservationTimeLengthInMinutes,
      useUserGroup,
      userGroupIds,
      // 使ってないので決め打ち
      detectableCondition: {
        conditionResultJudgeRule: DetectiveConditionJudgeRule.Every,
        conditions: [],
      },
      cancellationDeadlineUnit,
      cancellationDeadlineNumber,
      ableToInviteExternal,
      reservablePeriod,
      autoExtendSettings,
      sendGuestMailToEmployee,
      availableACPatternConditions,
    };

    const workhubSettings: StoreTypesOrgReservationSettings['workhubSettings'] = {
      id: spaceId, // ほんとはいらない
      type: 'spaces',
      targetId: spaceId,
      // いいのか悪いのか知らないけどこれSpaceID使ってる
      reservationSettingsId: spaceId,
      noteJp,
      noteEn,
      cancelPolicyJp,
      cancelPolicyEn,
      phoneNumber,
      private: Private,
      visibleContentFromOther: visibleContentFromOther || 'none',
      availableBeforeMinutes,
      sendableMailSettings,
      availableAfterMinutes,
      availableACPatterns,
      mailTemplateIds,
      sendableMailTemplateTypes,
      remindMinutes,
      reminderMinutesForCompanionRegistration,
      finishRemindMinutes,
      repeatableType,
      isGuestCompanionPreRegisteringEnabled,
    };

    const workspotSettings: WorkspotReservationSettings | undefined =
      availablePreCheckInTimeLengthInMinutes ||
      workspotCheckInType ||
      autoCancelTimeLengthInMinutes ||
      availableWebCheckIn
        ? {
            checkInType: workspotCheckInType,
            availablePreCheckInTimeLengthInMinutes,
            autoCancelTimeLengthInMinutes,
            availableWebCheckIn,
          }
        : undefined;

    const stored = await V2ReservationSettingService.create({
      ...reservationSetting,
      workhubSettings,
      workspotSettings,
    });

    if (!stored) {
      throw new CommonError('store-failed');
    }

    return stored;
  };

  /**
   * 予約できなくする
   * もともとの時間設定とかはそのまま
   */
  public static disable = async (settingId: string) => {
    return await V2ReservationSettingService.update(
      {settingId},
      {
        reservable: false,
      }
    );
  };

  public bulk = async (data: bulkArgs[]) => {
    // 旧動線でカレンダーとれなくなってユーザーが詰むので、IDは詰めておかないとダメ。
    const bulkData: ApiTypesCreateReservationSettingReq[] = data.map((d): ApiTypesCreateReservationSettingReq => {
      return {
        id: d.spaceId,
        reservable: d.reservable,
        autoExtendSettings: d.autoExtendSettings,
        reservableTimeUnitInMinutes: d.reservableTimeUnitInMinutes,
        reservableDayOfWeeks: d.reservableDayOfWeeks,
        baseReservableTimes: d.baseReservableTimes,
        reservableTimesEachDayOfWeek: d.reservableTimesEachDayOfWeek,
        permitReservationDuplication: d.permitReservationDuplication,
        minReservationTimeLengthInMinutes: d.minReservationTimeLengthInMinutes,
        maxReservationTimeLengthInMinutes: d.maxReservationTimeLengthInMinutes,
        useUserGroup: d.useUserGroup,
        userGroupIds: d.userGroupIds,
        cancellationDeadlineNumber: d.cancellationDeadlineNumber,
        cancellationDeadlineUnit: d.cancellationDeadlineUnit,
        ableToInviteExternal: d.ableToInviteExternal,
        sendGuestMailToEmployee: d.sendGuestMailToEmployee,
        // 使ってないので決め打ち
        detectableCondition: {
          conditionResultJudgeRule: DetectiveConditionJudgeRule.Every,
          conditions: [],
        },
        availableACPatternConditions: d.availableACPatternConditions,
        workhubSettings: {
          id: d.spaceId, // ほんとはいらない
          type: 'spaces',
          targetId: d.spaceId,
          // いいのか悪いのか知らないけどこれSpaceID使ってる
          reservationSettingsId: d.spaceId,
          noteJp: d.noteJp,
          noteEn: d.noteEn,
          cancelPolicyJp: d.cancelPolicyJp,
          cancelPolicyEn: d.cancelPolicyEn,
          phoneNumber: d.phoneNumber,
          private: d.Private,
          availableBeforeMinutes: d.availableBeforeMinutes,
          availableAfterMinutes: d.availableAfterMinutes,
          availableACPatterns: d.availableACPatterns,
          mailTemplateIds: d.mailTemplateIds,
          sendableMailTemplateTypes: d.sendableMailTemplateTypes,
          sendableMailSettings: d.sendableMailSettings,
          remindMinutes: d.remindMinutes,
          finishRemindMinutes: d.finishRemindMinutes,
          reminderMinutesForCompanionRegistration: d.reminderMinutesForCompanionRegistration,
          repeatableType: d.repeatableType,
          visibleContentFromOther: d.visibleContentFromOther || 'none',
          isGuestCompanionPreRegisteringEnabled: d.isGuestCompanionPreRegisteringEnabled,
        },
        workspotSettings:
          d.availablePreCheckInTimeLengthInMinutes ||
          d.workspotCheckInType ||
          d.autoCancelTimeLengthInMinutes ||
          d.availableWebCheckIn
            ? {
                checkInType: d.workspotCheckInType,
                availablePreCheckInTimeLengthInMinutes: d.availablePreCheckInTimeLengthInMinutes,
                autoCancelTimeLengthInMinutes: d.autoCancelTimeLengthInMinutes,
                availableWebCheckIn: d.availableWebCheckIn,
              }
            : undefined,
        reservablePeriod: d.reservablePeriod,
      };
    });
    const stored = await V2ReservationSettingService.bulk(bulkData);

    if (!stored) {
      throw new CommonError('store-failed');
    }

    return stored;
  };
  public static convertUnitCountToMinute = (data: {unit?: 'minute' | 'hour' | 'day'; count: number}) => {
    const baseMinute = data.unit === 'hour' ? 60 : data.unit === 'day' ? 1440 : 1;
    return baseMinute * data.count;
  };

  public static convertMinuteToCountUnit = (minute: number): {unit: 'minute' | 'hour' | 'day'; count: number} => {
    if (minute % 1440 === 0) {
      return {
        unit: 'day',
        count: minute / 1440,
      };
    }
    if (minute % 60 === 0) {
      return {
        unit: 'hour',
        count: minute / 60,
      };
    }
    return {
      unit: 'minute',
      count: minute,
    };
  };

  public static getCompanionPreregistrationSetting = async (
    reservationId: string
  ): Promise<CompanionPreregistrationSettingType | null> => {
    const {data} = await getCompanionPreregistrationSettingByFsReservationIdApi({
      paths: {
        reservationId,
      },
    });

    return data.companionPreregistrationSetting
      ? {
          companionPreregistrationWebUrl: data.companionPreregistrationSetting?.companionPreregistrationWebUrl,
          maxCompanionCount: data.companionPreregistrationSetting?.maxCompanionCount,
          hasPassword: data.companionPreregistrationSetting?.hasPassword,
          registeredCompanionCount: data.registeredCompanionCount,
        }
      : null;
  };
}

export interface bulkArgs {
  organizationId: string;
  spaceId: string;
  reservable: boolean;
  autoExtendSettings?: AutoExtendSetting;
  reservableTimeUnitInMinutes?: number;
  reservableDayOfWeeks?: Array<DayOfWeek>;
  baseReservableTimes?: Array<BasicTypesReservationTimeUnit>;
  reservableTimesEachDayOfWeek?: {
    [key in DayOfWeek]: Array<BasicTypesReservationTimeUnit>;
  };
  permitReservationDuplication?: boolean;
  minReservationTimeLengthInMinutes?: number;
  maxReservationTimeLengthInMinutes?: number;
  workspotCheckInType?: WorkspotCheckInType;
  availablePreCheckInTimeLengthInMinutes?: AvailablePreCheckInTimeLengthInMinutes;
  autoCancelTimeLengthInMinutes?: AutoCancelTimeLengthInMinutes;
  useUserGroup?: boolean;
  userGroupIds?: string[];
  noteJp?: string;
  noteEn?: string;
  cancelPolicyJp?: string;
  cancelPolicyEn?: string;
  phoneNumber?: string;
  Private?: boolean;
  availableBeforeMinutes?: number;
  availableAfterMinutes?: number;
  availableACPatterns?: {
    [key in V2PeopleTypes]: string[];
  };
  availableACPatternConditions?: ReservationSettingUpdate['availableACPatternConditions'];
  mailTemplateIds?: string[];
  sendableMailTemplateTypes?: MailTemplateType[];
  sendableMailSettings?: SendableMailSettings;
  remindMinutes?: number;
  finishRemindMinutes?: number;
  reminderMinutesForCompanionRegistration?: number;
  cancellationDeadlineNumber?: number;
  cancellationDeadlineUnit?: 'minute' | 'hour';
  ableToInviteExternal?: boolean;
  isGuestCompanionPreRegisteringEnabled: boolean | undefined;
  reservablePeriod?: ReservablePeriod;
  availableWebCheckIn?: boolean;
  sendGuestMailToEmployee?: StoreTypesOrgReservationSettings['sendGuestMailToEmployee'];
  repeatableType: RepeatableType;
  visibleContentFromOther?: NonNullable<
    NonNullable<StoreTypesOrgReservationSettings['workhubSettings']>['visibleContentFromOther']
  >;
}
