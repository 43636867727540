import React from 'react';

import {WHColor} from '@/common/styles/whColor';

/** @deprecated components/icon へ移行 */
const WorkhubLogoText = React.memo<{width?: number; height?: number; color?: string}>(function WorkhubLogoText({
  width,
  height,
  color,
}) {
  return (
    <svg
      width={width ? width : '540'}
      height={height ? height : '32'}
      viewBox='0 0 530 123'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M72.6359 122.153C71.2708 122.153 70.0574 121.496 69.7034 120.585L51.2491 80.4914C50.7435 79.4297 49.6817 78.7218 48.4683 78.7218C47.3054 78.7218 46.1931 79.4297 45.6875 80.4914L27.3848 120.585C27.0309 121.394 26.0197 122.153 24.6545 122.153H23.795C22.4299 122.153 21.2165 121.445 20.8625 120.434L0.385769 55.5654C-0.11983 54.3519 -0.11983 53.3913 0.335209 52.734C0.790249 52.0262 1.70033 51.6217 2.81265 51.6217H12.1157C13.2786 51.6217 14.8965 52.4812 15.1998 53.6946L24.4523 84.2329C24.8062 85.4463 25.9185 86.3058 27.132 86.4069C27.1825 86.4069 27.2837 86.4069 27.3342 86.4069C28.4971 86.4069 29.6094 85.6991 30.115 84.6373L45.283 51.6217C45.6369 50.7116 46.9009 49.9026 48.2155 49.9026H49.2267C50.5918 49.9026 51.7547 50.5599 52.1591 51.6217L67.1249 84.6879C67.6305 85.8002 68.6922 86.4575 69.9057 86.4575C69.9562 86.4575 70.0574 86.4575 70.1079 86.4575C71.3719 86.3564 72.4842 85.4968 72.8382 84.2834L81.939 53.6946C82.6468 52.0262 84.4669 51.6217 87.2477 51.6217H93.9722C95.1351 51.6217 96.0957 52.0262 96.5508 52.7846C97.0058 53.543 97.0058 54.5542 96.5508 55.5654L76.4279 120.434C76.074 121.445 74.9111 122.153 73.3438 122.153H72.6359Z'
        fill={color ? color : WHColor.object.brandPrimary}
      />
      <path
        d='M135.077 122.153C115.915 122.153 100.343 105.873 100.343 85.8508C100.343 66.0313 115.915 49.9026 135.077 49.9026C154.24 49.9026 169.812 66.0313 169.812 85.8508C169.812 105.873 154.24 122.153 135.077 122.153ZM135.077 64.8684C124.359 64.8684 115.612 74.2725 115.612 85.8508C115.612 97.6818 124.359 107.339 135.077 107.339C145.796 107.339 154.543 97.6818 154.543 85.8508C154.492 74.2725 145.796 64.8684 135.077 64.8684Z'
        fill={color ? color : WHColor.object.brandPrimary}
      />
      <path
        d='M183.413 120.434C181.694 120.434 180.126 118.866 180.126 117.147V54.9081C180.126 53.1891 181.694 51.6217 183.413 51.6217H188.418C189.43 51.6217 190.491 52.3296 191.047 53.3408C191.705 54.5542 192.918 55.4643 194.283 55.7676C194.637 55.8182 194.941 55.8688 195.294 55.8688C196.356 55.8688 197.367 55.5148 198.227 54.9081C202.626 51.6217 207.682 49.9532 213.344 49.9532C219.513 49.9532 224.265 51.2678 225.732 53.4419C226.288 54.3014 226.389 55.262 225.934 56.3238L221.788 64.7673C221.232 66.0313 220.271 66.638 219.108 66.638C218.603 66.638 218.148 66.5369 217.642 66.3347C216.631 65.8796 214.204 65.1212 211.777 65.1212C203.687 65.1212 198.985 69.975 197.317 72.0479C196.66 72.9075 196.255 73.9692 196.255 75.0815V117.249C196.255 118.614 196.255 120.535 191.604 120.535H183.413V120.434Z'
        fill={color ? color : WHColor.object.brandPrimary}
      />
      <path
        d='M285.24 120.434C284.027 120.434 282.611 119.726 282.156 119.069L259.404 95.6594C258.444 94.6988 257.18 94.1932 255.916 94.1932C255.309 94.1932 254.652 94.2943 254.096 94.5471C252.275 95.3055 251.062 97.0751 251.062 99.0469V117.147C251.062 118.866 249.495 120.434 247.776 120.434H237.815C236.096 120.434 234.529 118.866 234.529 117.147V3.2864C234.529 1.56736 236.096 0 237.815 0H247.725C249.444 0 251.011 1.56736 251.011 3.2864V66.8402C251.011 68.7615 252.124 70.5311 253.893 71.2895C254.551 71.5929 255.208 71.694 255.865 71.694C257.028 71.694 258.191 71.2895 259.101 70.43L277.808 53.6441C278.668 52.8857 280.184 51.5711 282.106 51.5711H294.139C294.847 51.5711 296.161 51.7734 296.718 53.1891C297.122 54.2508 296.869 55.5148 296.06 56.2226L271.286 78.7724C270.325 79.6825 269.719 80.9465 269.719 82.261C269.668 83.5756 270.174 84.8901 271.134 85.8508L301.217 115.934C302.026 116.743 302.279 117.956 301.875 118.917C301.47 119.878 300.56 120.383 299.296 120.383H285.24V120.434Z'
        fill={color ? color : WHColor.object.brandPrimary}
      />
      <path
        d='M364.367 120.434C362.547 120.434 361.08 118.968 361.08 117.147V82.2105C361.08 71.3401 355.62 64.8178 346.468 64.8178C337.722 64.8178 330.997 70.3794 328.671 74.1209C328.166 74.8793 327.913 75.7893 327.913 76.75V117.147C327.913 119.17 326.598 120.434 324.475 120.434H314.666C312.947 120.434 311.38 118.866 311.38 117.147V3.2864C311.38 1.56736 312.947 0 314.666 0H324.677C326.396 0 327.964 1.56736 327.964 3.2864V48.7398C327.964 50.4083 328.823 51.9756 330.239 52.8857C331.048 53.3913 331.958 53.6441 332.817 53.6441C333.525 53.6441 334.233 53.4924 334.941 53.1891C339.491 51.015 343.94 49.9532 348.592 49.9532C366.591 49.9532 377.31 62.1887 377.31 82.6655V117.248C377.31 118.968 375.743 120.535 374.024 120.535H364.367V120.434Z'
        fill={color ? color : WHColor.object.brandPrimary}
      />
      <path
        d='M415.281 122.153C397.281 122.153 387.422 110.625 387.422 89.6428V54.9081C387.422 53.1891 388.989 51.6217 390.709 51.6217H400.517C402.236 51.6217 403.804 53.1891 403.804 54.9081V90.199C403.804 101.12 408.961 107.389 417.91 107.389C427.061 107.389 433.027 100.614 434.493 97.3279C434.797 96.7212 434.948 96.0134 434.948 95.3055V54.8576C434.948 52.4307 435.909 51.5712 438.74 51.5712H447.69C449.409 51.5712 450.976 53.1385 450.976 54.8576V117.147C450.976 118.866 449.409 120.434 447.69 120.434H442.886C441.066 120.434 440.409 119.069 440.156 118.614C439.499 117.35 438.387 116.44 437.021 116.086C436.617 115.985 436.212 115.934 435.808 115.934C434.847 115.934 433.887 116.237 433.078 116.793C427.668 120.383 421.853 122.153 415.281 122.153Z'
        fill={color ? color : WHColor.object.brandPrimary}
      />
      <path
        d='M497.693 122.153C490.362 122.153 484.598 119.321 481.009 116.895C480.2 116.338 479.239 116.035 478.278 116.035C477.874 116.035 477.52 116.086 477.116 116.187C475.801 116.49 474.638 117.4 473.981 118.614C473.324 119.827 472.413 120.434 471.352 120.434H466.195C464.476 120.434 462.908 118.866 462.908 117.147V3.2864C462.908 1.56736 464.476 0 466.195 0H476.205C477.925 0 479.492 1.56736 479.492 3.2864V46.7174C479.492 48.2342 480.2 49.7004 481.464 50.6105C482.323 51.2678 483.334 51.5711 484.396 51.5711C484.851 51.5711 485.306 51.5206 485.761 51.3689C489.149 50.3577 492.637 49.8521 496.025 49.8521C515.035 49.8521 529.9 65.7279 529.9 85.9519C529.85 106.277 515.693 122.153 497.693 122.153ZM494.205 64.8684C488.997 64.8684 484.801 66.183 482.222 67.2953C480.402 68.0537 479.239 69.8233 479.239 71.7951V95.8111C479.239 96.7212 479.492 97.5807 479.947 98.3391C481.211 100.463 486.166 107.339 495.57 107.339C507.553 107.339 513.822 96.62 513.822 86.0025C513.873 73.9692 505.429 64.8684 494.205 64.8684Z'
        fill={color ? color : WHColor.object.brandPrimary}
      />
    </svg>
  );
});

export default WorkhubLogoText;
