declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export const downloadCsv = (csvData: string[], fileName: string): void => {
  downloadUnParsedCsv(csvData.join('\n'), fileName);
};

/**
 * 改行コードで改行されたテキストをそのままダウンロードする
 * 主にpapaparseのunparse結果をそのまま使うときはこちら
 * @param csvData
 * @param fileName
 */
export const downloadUnParsedCsv = (csvData: string, fileName: string): void => {
  const bom = '\uFEFF';
  const blob = new Blob([bom, csvData], {type: 'text/csv'});
  const anchor = document.createElement('a');
  // ie
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, fileName);
    // chrome, firefox, etc.
  } else if (window.URL && anchor.download !== undefined) {
    anchor.download = fileName;
    const url = window.URL.createObjectURL(blob);
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url); // メモリリークしてたため追加
    anchor.remove();
  } else {
    window.location.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(bom + csvData);
  }
};

export const dataURLtoBlob = (dataurl: string) => {
  const arr = dataurl.split(',');
  const fmt = arr[0];
  const b64 = arr[1];
  if (!fmt || !b64) throw new Error('invalid dataurl');

  const m = fmt.match(/:(.*?);/);
  if (!m) throw new Error('invalid dataurl');

  const mime = m[1];
  if (!mime) throw new Error('invalid dataurl');

  const bstr = atob(b64);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type: mime});
};

export const downloadDataURL = (uri: string, fileName: string) => {
  const anchor = document.createElement('a');
  // ie
  if (window.navigator.msSaveBlob) {
    const blob = dataURLtoBlob(uri);
    window.navigator.msSaveBlob(blob, fileName);
    // chrome, firefox, etc.
  } else if (window.URL && anchor.download !== undefined) {
    anchor.download = fileName;
    anchor.href = uri;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } else {
    window.location.href = uri;
  }
};
