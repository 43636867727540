import {useCallback, useState} from 'react';

const isHtmlElement = (elm: any): elm is HTMLElement => {
  return (
    typeof elm.offsetWidth === 'number' &&
    typeof elm.scrollWidth === 'number' &&
    typeof elm.offsetHeight === 'number' &&
    typeof elm.scrollHeight === 'number'
  );
};

type Option = {
  depth?: number;
};

/**
 * 「text-overflow: ellipsisつけて省略されたときだけtooltipを表示したい」というよくあるケースに使用
 */
export const useEnableTooltipOnlyEllipsis = (opt?: Option) => {
  const [disableTooltip, setDisableTooltip] = useState(false);
  // 表示タイミングの都合でuseRefだとnullになることがあるので、callback refを使用
  const ref = useCallback(
    (elm: HTMLParagraphElement | null) => {
      if (!elm) return;

      let target: HTMLElement = elm;

      // 対象の子要素をさかのぼる
      if (opt?.depth) {
        for (let i = 0; i < opt.depth; i++) {
          const e = target.firstElementChild;
          if (!e) {
            return;
          }
          // Element型にoffsetWidthがないのでtype guardを入れる
          if (!isHtmlElement(e)) {
            return;
          }
          target = e;
        }
      }

      // 要素幅と scrollWidth（テキスト幅）が同一、あるいは前者のほうが大きいときは
      // text-overflow: ellipsis が適用されない（省略表示されない）状態と判断する
      setDisableTooltip(target.offsetWidth >= target.scrollWidth && target.offsetHeight >= target.scrollHeight);
    },
    [opt]
  );

  return {ref, disableTooltip};
};
