// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import Logger from '@/common/logger/logger';

const logger = Logger.create('getCompanionPreregistrationSettingByFsReservationIdApi');

export const getCompanionPreregistrationSettingByFsReservationIdApi = async (
  arg: {paths: GetCompanionPreregistrationSettingByFsReservationIdPathParam},
  opts?: {region?: 'default' | 'asia'}
) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .get<GetCompanionPreregistrationSettingByFsReservationIdResponse>(
      `/organizations/reservations/${arg.paths.reservationId}/companion-preregistration-setting`,
      {
        headers: {
          authorization: `bearer ${idToken}`,
        },
      }
    )
    .catch(axiosCatch(getStackTrace()));
};

export type GetCompanionPreregistrationSettingByFsReservationIdResponse = {
  companionPreregistrationSetting?: {
    /**
     * 同行者登録用WEBのURL
     */
    companionPreregistrationWebUrl: string;
    /**
     * 同行者登録の最大数
     */
    maxCompanionCount?: Double;
    /**
     * 同行者登録用WEBのパスワードが存在するかどうか
     */
    hasPassword: boolean;
  };
  /**
   * 登録済み同行者数
   */
  registeredCompanionCount?: Double;
};
export type GetCompanionPreregistrationSettingByFsReservationIdPathParam = {
  reservationId: string;
};
