import {Locale} from '@/common/redux/state-types/localeStateType';
import {accessSecuritySectionMenuDef} from '@/wscreens/routing/def/menuDictDef/accessSecuritySectionMenuDef';
import {accountSectionMenuDef} from '@/wscreens/routing/def/menuDictDef/accountSectionMenuDef';
import {applicationSectionMenuDef} from '@/wscreens/routing/def/menuDictDef/applicationSectionMenuDef';
import {buildingOwnerSectionMenuDef} from '@/wscreens/routing/def/menuDictDef/buildingOwnerSectionMenuDef';
import {monitoringSectionMenuDef} from '@/wscreens/routing/def/menuDictDef/monitoringSectionMenuDef';
import {otherSectionMenuDef} from '@/wscreens/routing/def/menuDictDef/otherSectionMenuDef';
import {spaceAndDeviceSectionMenuDef} from '@/wscreens/routing/def/menuDictDef/spaceAndDeviceSectionMenuDef';
import {thirdPlaceSectionMenuDef} from '@/wscreens/routing/def/menuDictDef/thirdPlaceSectionMenuDef';
import {userSectionMenuDef} from '@/wscreens/routing/def/menuDictDef/userSectionMenuDef';

export type MainMenuDictKey = keyof typeof MainMenuDictDef;
export const MainMenuDictDef = {
  ...accountSectionMenuDef,
  ...thirdPlaceSectionMenuDef,
  ...buildingOwnerSectionMenuDef,
  ...applicationSectionMenuDef,
  ...monitoringSectionMenuDef,
  ...userSectionMenuDef,
  ...spaceAndDeviceSectionMenuDef,
  ...accessSecuritySectionMenuDef,
  ...otherSectionMenuDef,
  bitlockApp: {
    default: {
      default: 'bitlockアプリ',
      [Locale.en_US]: 'bitlock App',
    },
  },
  receptionApp: {
    default: {
      default: 'workhubReceptionアプリ',
      [Locale.en_US]: 'workhub reception App',
    },
  },
  workhubApp: {
    default: {
      default: 'workhubアプリ',
      [Locale.en_US]: 'workhub App',
    },
  },
  remoteOperation: {
    default: {
      default: 'デバイス',
      [Locale.en_US]: 'Devices',
    },
  },
  onlyBitkey: {
    default: {
      default: 'bitkey専用',
      [Locale.en_US]: 'Only bitkey',
    },
  },
};
