import {createSlice} from '@reduxjs/toolkit';

import {
  SpaceReportScreenState,
  UsageStatusLineGraphDataType,
} from '@/common/redux/screens/state/spaceReportScreenState';

const initialState: SpaceReportScreenState = {
  displayGraphs: {},
  usageGraphState: {},
};

const spaceReportScreenSlice = createSlice({
  name: 'spaceReportScreenSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    updateSpaceReportState: (state, action: {payload: Record<string, UsageStatusLineGraphDataType>}) => {
      return {
        ...state,
        displayGraphs: {
          ...state.displayGraphs,
          ...action.payload,
        },
      };
    },
    updateUsageGraphState: (
      state,
      action: {payload: Record<string, Record<'departments' | 'employee', {graphData: number; label: string}[]>>}
    ) => {
      return {
        ...state,
        usageGraphState: {
          ...state.usageGraphState,
          ...action.payload,
        },
      };
    },
  },
});
export default spaceReportScreenSlice;
