import React from 'react';

export function contextContainer<Props, Value>(useX: (props: Props) => Value) {
  const context = React.createContext<Value>(null as any);

  const Provider: React.FC<React.PropsWithChildren<Props>> = ({children, ...props}) => {
    const value = useX(props as Props);
    return <context.Provider value={value}>{children}</context.Provider>;
  };

  const useValue = (): Value => React.useContext(context);

  return {Provider, useValue};
}
