import {createListenerMiddleware, createSlice} from '@reduxjs/toolkit';

import {ObjectUtil} from '@/common/utils/objectUtil';
import {Datadog} from '@/monitoring/datadog';

import {ExperimentalFeaturesState} from '../state-types/experimentalFeaturesState';

const initialState: ExperimentalFeaturesState = {
  lockUnlockLogV2: false,
  reservationV3: false,
  floorMapV2: false,
  accessControlCriteria: false,
};

export const experimentalFeaturesSlice = createSlice({
  name: 'experimentalFeatures',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<ExperimentalFeaturesState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});

const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  actionCreator: experimentalFeaturesSlice.actions.set,
  effect: (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    for (const key of ObjectUtil.keys(action.payload)) {
      const val = action.payload[key];
      Datadog.addFeatureFlagEvaluation(key, val);
    }
  },
});

export const experimentalFeatureMiddleware = listenerMiddleware.middleware;
