import {
  ApiTypesApplicationDraftGetRes,
  DraftPersonalIdentificationStatus,
} from '@bitkey-service/v2_core-types/lib/api/application-draft/apiTypesApplicationDrafts';
import {createFileRoute} from '@tanstack/react-router';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import useDict from '@/common/hooks/useDict';
import Logger from '@/common/logger/logger';
import localeSlice from '@/common/redux/slices/localeSlice';
import {Locale} from '@/common/redux/state-types/localeStateType';
import LocalStorage, {LocalStorageKey} from '@/common/storage/localStorage';
import {UrlParamUtil} from '@/common/utils/urlParamUtil';
import WLoadingComponent from '@/components/figma/others/stepper/WLoadingComponent';
import PersonalIdentificationClosure from '@/features/application-draft/components/PersonalIdentificationClosure';
import PersonalIdentificationExpired from '@/features/application-draft/components/PersonalIdentificationExpired';
import PersonalIdentificationOld from '@/features/application-draft/components/PersonalIdentificationOld';
import PersonalIdentificationRejected from '@/features/application-draft/components/PersonalIdentificationRejected';
import FaceRegistrationStepper from '@/features/application-draft/FaceRegistrationStepper';
import {ApplicationDraftService} from '@/v2_service/application-drafts/applicationDraftService';

export const Route = createFileRoute('/application-draft')({
  component: RouteComponent,
});

const dictDef = {
  loading: {
    default: {
      default: '申請情報を取得中です...',
      [Locale.en_US]: 'Application information is being obtained.',
    },
  },
};

const logger = Logger.create('PersonalIdentificationScreen');

function RouteComponent() {
  const dict = useDict(dictDef);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  const draftId = useMemo(() => UrlParamUtil.getByKey('id'), []);
  const [draft, setDraft] = useState<ApiTypesApplicationDraftGetRes>();

  const loadDraft = useCallback(async () => {
    setLoading(true);
    const draftData = await ApplicationDraftService.getDraft(draftId);
    setDraft(draftData);
  }, [draftId]);

  const setHeight = useCallback(
    () => document.documentElement.style.setProperty('--100vh', `${window.innerHeight}px`),
    []
  );

  useEffect(() => {
    const selectedLanguage = LocalStorage.get(LocalStorageKey.SelectedLanguageForNoAuth);
    if (selectedLanguage) {
      dispatch(localeSlice.actions.set(selectedLanguage as Locale));
    }
    if (draftId) {
      loadDraft()
        .catch(e => logger.error('failed to load draft data', e))
        .finally(() => setLoading(false));
      //スマートフォンからだと100vhがアドレスバー無視するので、改めて高さを取得し直してセットする
      setHeight();
      window.addEventListener('resize', () => {
        // iOSChromeでresizeイベントの発火が遅延することがあるので
        setTimeout(setHeight, 500);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId]);

  const component = useMemo(() => {
    if (loading) {
      return <WLoadingComponent message={dict.loading} notTransparent={true} />;
    }

    if (!draft) {
      return <div />; //404ページ？
    }
    switch (draft.status) {
      case DraftPersonalIdentificationStatus.WaitingForRegister:
        return <FaceRegistrationStepper draft={draft} draftId={draftId} />;
      case DraftPersonalIdentificationStatus.InReview:
      case DraftPersonalIdentificationStatus.Completed:
      case DraftPersonalIdentificationStatus.Deleted:
        return <PersonalIdentificationClosure status={draft.status} />;
      case DraftPersonalIdentificationStatus.Expired:
        return <PersonalIdentificationExpired />;
      case DraftPersonalIdentificationStatus.Rejected:
        return <PersonalIdentificationRejected />;
      case DraftPersonalIdentificationStatus.Old:
        return <PersonalIdentificationOld />;
    }
    return <div />;
  }, [dict.loading, draft, draftId, loading]);

  return component;
}
