import styled from '@emotion/styled';
import {useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {generateHTML} from '@tiptap/core';
import * as Icon from '@workhub/icons';
import {IconButton, Loading, TextButton} from '@workhub/ui';
import {useMemo} from 'react';

import {getAnnouncementApi, type GetAnnouncementResponse} from '@/api-call/workhub-core/getAnnouncementApi';
import {GetAnnouncementsResponse} from '@/api-call/workhub-core/getAnnouncementsApi';
import StoragePeople from '@/common/firebase/storage/reference/organizations/people/storagePeople';
import useDict, {useCommonDict} from '@/common/hooks/useDict';
import {useLocale} from '@/common/hooks/useLocale';
import {useOrganization} from '@/common/hooks/useOrganization';
import {jpDate} from '@/common/jpDate';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHFont} from '@/common/styles/whFont';
import {LocaleUtils} from '@/common/utils/localeUtils';
import {TimeUtils} from '@/common/utils/timeUtils';
import WCardTemplate from '@/components/figma/card/WCardTemplate';
import WHeaderNavigation, {BreadcrumbLink} from '@/components/header/WHeaderNavigation';
import {WAvatarRenderer} from '@/components/image/WAvatorRenderer';
import {RichTextHtmlViewer} from '@/components/rich-text-editor/RichTextHtmlViewer';
import CellStatus from '@/features/building-tenant-management/announcement/cells/CellStatus';
import {extensions} from '@/features/building-tenant-management/announcement/extentions';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  buildingTenantManagement: {
    default: {
      default: 'ビルテナント管理',
      [Locale.en_US]: 'Building Tenant Management',
    },
  },
  announcementManagement: {
    default: {
      default: 'お知らせ管理',
      [Locale.en_US]: 'Announcement Management',
    },
  },
  announcementContent: {
    default: {
      default: 'お知らせ内容',
      [Locale.en_US]: 'Announcement Content',
    },
  },
  preview: {
    default: {
      default: 'プレビュー',
      [Locale.en_US]: 'Preview',
    },
  },
  publishSetting: {
    default: {
      default: '公開設定',
      [Locale.en_US]: 'Publish Setting',
    },
  },
  proceedToPublish: {
    default: {
      default: '公開に進む',
      [Locale.en_US]: 'Proceed to publish',
    },
  },
  status: {
    default: {
      default: 'ステータス',
      [Locale.en_US]: 'Status',
    },
  },
  scheduledDate: {
    default: {
      default: '公開予約日時',
      [Locale.en_US]: 'Scheduled Date',
    },
  },
  publishTimeNotice: {
    default: {
      default: (duration: string) => `${duration}後に公開予定`,
      [Locale.en_US]: (duration: string) => `Scheduled to be published in ${duration}`,
    },
  },
  publishScope: {
    default: {
      default: '公開範囲（テナント）',
      [Locale.en_US]: 'Publish Scope',
    },
  },
  allTenants: {
    default: {
      default: 'すべてのテナント',
      [Locale.en_US]: 'All Tenants',
    },
  },
  updateInformation: {
    default: {
      default: '更新情報',
      [Locale.en_US]: 'Update information',
    },
  },
  lastModifiedDate: {
    default: {
      default: '最終編集日時',
      [Locale.en_US]: 'Last Modified Date',
    },
  },
  createdDate: {
    default: {
      default: '作成日時',
      [Locale.en_US]: 'Created Date',
    },
  },
  edit: {
    default: {
      default: '編集',
      [Locale.en_US]: 'Edit',
    },
  },
  back: {
    default: {
      default: '戻る',
      [Locale.en_US]: 'Back',
    },
  },
  publishStatus: {
    default: {
      default: (status: GetAnnouncementResponse['status']) => {
        switch (status) {
          case 'published':
            return '公開';
          case 'draft':
            return '下書き';
          case 'preparing':
            return '公開準備';
          case 'scheduled':
            return '公開予約';
          case 'canceled':
            return '公開取消';
          default: {
            const _: never = status;
            return '';
          }
        }
      },
      [Locale.en_US]: (status: GetAnnouncementResponse['status']) => {
        switch (status) {
          case 'published':
            return 'Published';
          case 'draft':
            return 'Draft';
          case 'preparing':
            return 'Preparing';
          case 'scheduled':
            return 'Scheduled';
          case 'canceled':
            return 'Canceled';
          default: {
            const _: never = status;
            return '';
          }
        }
      },
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: var(--spacing-16);
`;

const Layout = styled.div`
  display: flex;
  column-gap: var(--spacing-24);
  padding: var(--spacing-24);
  overflow: auto;
`;

const BodyWrapper = styled.div`
  padding: var(--spacing-24) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  height: calc(100% - 48px);
  box-sizing: border-box;
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  row-gap: var(--spacing-24);
  width: 320px;
`;

const AsideLabel = styled.dt`
  ${WHFont.labelMediumStrong}
  color: var(--text-neutral-secondary);
`;

const AsideDetail = styled.dd`
  margin-top: var(--spacing-8);
  margin-left: 0;

  + dt {
    margin-top: var(--spacing-16);
  }
`;

const ScheduledAtDescription = styled.p`
  display: block;
  margin-top: var(--spacing-4);
  ${WHFont.bodySmall}
  color: var(--text-neutral-secondary);
`;

export const Route = createFileRoute('/_authorized/building-tenant-management/announcement/$announcementId')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingAnnouncementManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const navigate = Route.useNavigate();
  const params = Route.useParams();
  const {id: organizationId} = useOrganization();

  const navigation = useMemo(() => {
    const breadcrumbs: BreadcrumbLink[] = [
      {
        label: dict.buildingTenantManagement,
      },
      {
        label: dict.announcementManagement,
        toPath: '/building-tenant-management/announcement',
      },
    ];
    return breadcrumbs;
  }, [dict.announcementManagement, dict.buildingTenantManagement]);

  const {data: announcement, isLoading} = useQuery({
    queryKey: ['building-tenant-management/announcement', params.announcementId],
    queryFn: async () => {
      const {data} = await getAnnouncementApi({paths: {announcementId: params.announcementId}});
      return data;
    },
  });

  const idEditable = useMemo(
    () => announcement?.status === 'draft' || announcement?.status === 'scheduled',
    [announcement?.status]
  );

  if (isLoading) {
    return <Loading centered />;
  }

  if (!announcement) {
    return null;
  }

  return (
    <Root>
      <WHeaderNavigation
        title={
          <TitleWrapper>
            <IconButton
              title={dict.back}
              onClick={() => navigate({to: '/building-tenant-management/announcement'})}
              disableTooltip
            >
              <Icon.Arrow.Back />
            </IconButton>
            <>{announcement.title}</>
          </TitleWrapper>
        }
        navigation={navigation}
      />
      <Layout>
        <WCardTemplate
          title={dict.announcementContent}
          width={'100%'}
          iconButton={
            idEditable && (
              // TODO: 編集アイコンをonClickしたときの処理の実装は別PRでS6中に実装する
              <IconButton title={dict.edit} onClick={() => {}}>
                <Icon.Edit />
              </IconButton>
            )
          }
        >
          <BodyWrapper>
            <RichTextHtmlViewer html={generateHTML(announcement.content, extensions)} />
          </BodyWrapper>
        </WCardTemplate>
        <Aside>
          <WCardTemplate
            title={dict.publishSetting}
            width={'100%'}
            iconButton={
              idEditable && (
                // TODO: 公開に進むをonClickしたときの処理の実装は別PRでS6中に実装する
                <TextButton color={'secondary'} onClick={() => {}}>
                  {dict.proceedToPublish}
                </TextButton>
              )
            }
          >
            <dl>
              <AsideLabel>{dict.status}</AsideLabel>
              <AsideDetail>
                <CellStatus status={announcement.status}>{dict.publishStatus(announcement.status)}</CellStatus>
              </AsideDetail>
              {announcement.status === 'scheduled' && (
                <>
                  <AsideLabel>{dict.scheduledDate}</AsideLabel>
                  <AsideDetail>
                    {TimeUtils.timestampToFormatString(announcement.createdAt)}
                    <ScheduledAtDescription>
                      {dict.publishTimeNotice(
                        TimeUtils.durationFromWithFormat({
                          from: jpDate().valueOf(),
                          to: jpDate(announcement.scheduledAt).valueOf(),
                        })
                      )}
                    </ScheduledAtDescription>
                  </AsideDetail>
                </>
              )}
            </dl>
          </WCardTemplate>
          <WCardTemplate title={dict.updateInformation} width={'100%'}>
            <dl>
              <AsideLabel>{dict.lastModifiedDate}</AsideLabel>
              <AsideDetail>
                {TimeUtils.timestampToFormatString(announcement.modifiedAt)}
                <PeopleCardComponent organizationId={organizationId} people={announcement.modifiedBy} />
              </AsideDetail>
              <AsideLabel>{dict.createdDate}</AsideLabel>
              <AsideDetail>
                {TimeUtils.timestampToFormatString(announcement.createdAt)}
                <PeopleCardComponent organizationId={organizationId} people={announcement.createdBy} />
              </AsideDetail>
            </dl>
          </WCardTemplate>
        </Aside>
      </Layout>
    </Root>
  );
}

const PeopleCard = styled.div`
  margin-top: var(--spacing-8);
  padding: var(--spacing-12);
  background-color: var(--surface-neutral-middle);
  border-radius: var(--radius-m);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
`;

const IconWrapper = styled.span`
  flex: 0 0 30px;
`;

const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-neutral-primary);
  ${WHFont.bodyMedium}
`;

const PeopleCardComponent = ({
  organizationId,
  people,
}: {
  organizationId: string;
  people?:
    | GetAnnouncementsResponse['items'][number]['createdBy']
    | GetAnnouncementsResponse['items'][number]['modifiedBy'];
}) => {
  const commonDict = useCommonDict();
  const locale = useLocale();

  return (
    <PeopleCard>
      <Wrapper>
        <IconWrapper>
          <WAvatarRenderer
            width={30}
            height={30}
            name={LocaleUtils.getLocaleName({nameJp: people?.nameJp, nameEn: people?.nameEn}, locale)}
            filePath={
              people?.id && people.iconUrl
                ? StoragePeople.getImagePath(organizationId, people.id, people.iconUrl)
                : undefined
            }
          />
        </IconWrapper>
        <Name>
          {people?.nameJp || people?.nameEn
            ? LocaleUtils.getLocaleName({nameJp: people.nameJp, nameEn: people.nameEn}, locale)
            : commonDict.noData}
        </Name>
      </Wrapper>
    </PeopleCard>
  );
};
