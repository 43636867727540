import {NestedPartial} from './typeUtils';

export class ObjectUtil {
  public static keys = <T extends Record<string, any>>(obj: T): (keyof typeof obj)[] => Object.keys(obj);
  public static values = <K extends string, T>(obj: Record<K, T>) => {
    return Object.values(obj) as T[];
  };
  public static entries = <K extends string, T>(obj: Record<K, T>) => {
    return Object.entries(obj) as [K, T][];
  };

  public static deepEquals = (val1: any, val2: any): boolean => {
    if (val1 === val2) {
      // なくてもいいけど単純な比較で済むものは最速にしたいため
      return true;
    }
    const val1Type = getType(val1);
    const val2Type = getType(val2);
    if (val1Type !== val2Type) {
      return false;
    }
    if (comparable.includes(val1Type)) {
      return val1 === val2;
    }
    if (val1Type === 'object' || val1Type === 'array') {
      return (
        ObjectUtil.keys(val1).every(k => ObjectUtil.deepEquals(val1[k], val2[k])) &&
        ObjectUtil.keys(val2).every(k => ObjectUtil.deepEquals(val1[k], val2[k]))
      );
    }
    // 他はequalsが実装されてる保証がなく正しく比較できないので全部false
    return false;
  };

  /**
   * オブジェクト同士をマージする
   *
   * @param a
   * @param b
   */
  public static merge = <T extends Record<string, any>>(a: T, b: NestedPartial<T>): T => {
    const result = {...a};
    for (const k of Object.keys(b)) {
      result[k as keyof T] = merge(a[k], b[k]);
    }
    return result;
  };

  /**
   * オブジェクトから不要な要素を削除する
   *
   * 引数および返り値の型指定が望ましいが、オブジェクトのマージ等により型指定にないデータが入るケースがあり、
   * そのような場合でも不要な要素を削除できるよう、敢えて型指定を緩めにしています。
   * @param source
   * @param deleteKeys
   * @returns
   *
   * @deprecated R.omit を使ってください
   */
  public static deleteProps = <T, K extends keyof T>(source: T, deleteKeys: K[] = []): Omit<T, K> => {
    const copiedObject = {...source};
    for (const key of deleteKeys) {
      delete copiedObject[key];
    }
    return copiedObject;
  };

  /**
   * オブジェクトからundefinedフィールドを除去する
   * ネストしたフィールドや配列フィールドの各要素のundefinedも消す
   * nullは消さない
   * 配列のundefined要素も消さない
   * @param val
   */
  static removeUndefined = <T extends Record<string, any>>(val: T): T => {
    return removeUndefined(val);
  };
}

type ValueType = 'undefined' | 'null' | 'boolean' | 'number' | 'string' | 'object' | 'array' | 'function' | string;
const comparable = ['undefined', 'null', 'boolean', 'number', 'string'];
// これ共通関数にできるけどobjectとは違うから欲しくなったらvalueUtil的なやつ作ってそこに移動
const getType = (val: any) => Object.prototype.toString.call(val).slice(8, -1).toLowerCase() as ValueType;
const merge = (a: any, b: any) => {
  const aType = getType(b);
  const bType = getType(b);
  if (aType === 'object' && bType === 'object') {
    const result = {...a};
    for (const k of ObjectUtil.keys(b)) {
      result[k] = merge(a[k], b[k]);
    }
  } else {
    return b;
  }
};

const removeUndefined = <T>(val: T): T => {
  if (Array.isArray(val)) {
    return val.map(v => removeUndefined(v)) as T;
  } else if (isRecord(val)) {
    return ObjectUtil.entries(val)
      .filter(([, v]) => v !== undefined)
      .reduce<T>((res, [k, v]) => {
        res[k as keyof T] = removeUndefined(v) as T[keyof T];
        return res;
      }, {} as any);
  } else {
    return val;
  }
};
const isRecord = (val: any): val is Record<string, unknown> =>
  Object.prototype.toString.call(val).slice(8, -1).toLowerCase() === 'object';
