import React from 'react';

import {WHColor} from '@/common/styles/whColor';

// 予約ダイアログに使われてましたが、共通化されていなかったので切り出し
const WIconClock: React.FC<{size?: number; color?: string}> = ({size, color}) => {
  return (
    <svg
      width={size ? size : '24'}
      height={size ? size : '24'}
      viewBox='0 0 24 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='clockIcon'
    >
      <path
        d='M11.99 6C6.47 6 2 10.48 2 16C2 21.52 6.47 26 11.99 26C17.52 26 22 21.52 22 16C22 10.48 17.52 6 11.99 6ZM12 24C7.58 24 4 20.42 4 16C4 11.58 7.58 8 12 8C16.42 8 20 11.58 20 16C20 20.42 16.42 24 12 24Z'
        fill={color ? color : WHColor.object.neutralSecondary}
      />
      <path
        d='M12.5 11H11V17L16.25 20.15L17 18.92L12.5 16.25V11Z'
        fill={color ? color : WHColor.object.neutralSecondary}
      />
    </svg>
  );
};
export default React.memo(WIconClock);
