import {ApiTypesGetOrgChargeDetailLabelResponse} from '@bitkey-service/v2_core-types/lib/api/organization/charge-detail-label/apiTypesOrgChargeDetailLabel';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {ChargeDetailLabelService} from '@/v2_service/charge-detail-label/chargeDetailLabelService';

import {LabelState} from '../state-types/labelState';

const chargeDetailLabelService = new ChargeDetailLabelService();

export const initialState: LabelState = {
  labels: [],
  status: 'initial',
};

export const fetchLabels = createAsyncThunk<ApiTypesGetOrgChargeDetailLabelResponse>(
  'label/fetch',
  async () => await chargeDetailLabelService.getAll()
);

const labelSlice = createSlice({
  name: 'label',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<LabelState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLabels.pending, state => ({
        ...state,
        status: 'pending',
      }))
      .addCase(fetchLabels.fulfilled, (state, {payload}) => ({
        ...state,
        labels: payload,
        status: 'fulfilled',
      }))
      .addCase(fetchLabels.rejected, state => ({
        ...state,
        status: 'rejected',
      }));
  },
});

export default labelSlice;
