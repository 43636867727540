import {useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {useMemo} from 'react';

import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import WLoadingComponent from '@/components/figma/others/stepper/WLoadingComponent';
import {InvitationsService} from '@/v2_service/invitations/invitationsService';
import {OidcSsoService} from '@/v2_service/oidc/sso/oidcSsoService';

import WSignupAlreadyCompleted from './-components/contents/WSignupAlreadyCompleted';
import WSignupCompletion from './-components/contents/WSignupCompletion';
import WSignupExpiredUrl from './-components/contents/WSignupExpiredUrl';
import WSignupForHHCross from './-components/contents/WSignupForHHCross';
import WSignupInvalidUrl from './-components/contents/WSignupInvalidUrl';
import WSignupTemplate from './-components/WSignupTemplate';
import {SIGNUP_QUERY_KEY} from './-constants/queryKeys';

export const Route = createFileRoute('/sso/signup')({
  component: RouteComponent,
});

const dictDef = {
  loading: {
    default: {
      default: '招待情報を確認しています...',
      [Locale.en_US]: 'Loading...',
    },
  },
};

export const ContentTypes = {
  Completion: 'completion',
  Loading: 'loading',
  InvalidUrl: 'invalidUrl',
  AlreadyCompleted: 'alreadyCompleted',
  ExpiredUrl: 'expiredUrl',
  SignupForHHCross: 'signupForHHCross',
} as const;

type ContentTypes = (typeof ContentTypes)[keyof typeof ContentTypes];

function RouteComponent() {
  const dict = useDict(dictDef);
  const search = window.location.search;
  const invitationId = new URLSearchParams(search).get('id');
  const isSuccess = new URLSearchParams(search).get('success') === 'true';

  const {data: invitation, isPending: isInvitationLoading} = useQuery({
    queryKey: [SIGNUP_QUERY_KEY.SIGNUP_INVITATION, invitationId],
    queryFn: async () => {
      if (!invitationId) return;
      return await InvitationsService.find({invitationId});
    },
    enabled: !!invitationId && !isSuccess,
  });

  const {data: startFlowResult, isPending: isStartFlowLoading} = useQuery({
    queryKey: [SIGNUP_QUERY_KEY.SIGNUP_FLOW, invitationId],
    queryFn: async () => {
      return await OidcSsoService.startSignupFlow({
        invitationId: invitationId ?? '',
        // GETリクエストのクエリパラメータなのでエンコードする
        redirectUri: encodeURIComponent(import.meta.env.VITE_SSO_REDIRECT_URL ?? ''),
      });
    },
    enabled: !!invitation && !isSuccess && !invitation?.alreadyRegistered,
  });

  const isLoading = useMemo(() => {
    return invitation?.alreadyRegistered ? isInvitationLoading : isInvitationLoading || isStartFlowLoading;
  }, [invitation?.alreadyRegistered, isInvitationLoading, isStartFlowLoading]);

  const contentType = useMemo((): ContentTypes => {
    if (isSuccess) return ContentTypes.Completion;
    if (isLoading) return ContentTypes.Loading;
    if (invitation?.alreadyRegistered && !invitation.isDeletedPerson) return ContentTypes.AlreadyCompleted;
    if (!invitation || !startFlowResult || invitation.isDeletedPerson) return ContentTypes.InvalidUrl;
    if (invitation.isExpired) return ContentTypes.ExpiredUrl;

    return ContentTypes.SignupForHHCross;
  }, [isSuccess, isLoading, invitation, startFlowResult]);

  return (
    <WSignupTemplate>
      {contentType === ContentTypes.Completion && <WSignupCompletion />}
      {contentType === ContentTypes.Loading && <WLoadingComponent message={dict.loading} notTransparent />}
      {contentType === ContentTypes.InvalidUrl && <WSignupInvalidUrl />}
      {contentType === ContentTypes.AlreadyCompleted && <WSignupAlreadyCompleted />}
      {contentType === ContentTypes.ExpiredUrl && <WSignupExpiredUrl />}
      {contentType === ContentTypes.SignupForHHCross && (
        <WSignupForHHCross invitationId={invitationId ?? ''} ssoStartFlowResult={startFlowResult} />
      )}
    </WSignupTemplate>
  );
}
