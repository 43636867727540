import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import type SvgIcon from '@mui/material/SvgIcon';
import React, {MouseEventHandler, ReactNode, useCallback, useMemo, useRef} from 'react';
import {makeStyles} from 'tss-react/mui';

import {useCommonDict} from '@/common/hooks/useDict';
import {isNonNullableReactNode} from '@/common/react/nonNullableReactNodeUtils';
import {WHColor} from '@/common/styles/whColor';
import {WHFont} from '@/common/styles/whFont';
import CircularIndicator from '@/components/progress/CircularIndicator';
import Tooltip from '@/components/tooltip/Tooltip';

import WButton, {WButtonProps} from '../button/WButton';

const AlertButton = styled(Button)`
  color: ${WHColor.text.semanticError};
  margin-right: 8px;

  :hover {
    background-color: ${WHColor.surface.semanticErrorLight};
  }
`;

export type WTabDialogProps = {
  open: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  title: ReactNode;
  titleRightContent?: ReactNode;
  okButtonLabel?: string;
  onOk?: MouseEventHandler<HTMLButtonElement>;
  secondaryButtonLabel?: string;
  secondaryButtonIcon?: typeof SvgIcon | React.FC;
  secondaryButtonColor?: WButtonProps['color'];
  onClickSecondary?: MouseEventHandler<HTMLButtonElement>;
  secondaryButtonDisabled?: boolean;
  alertButtonLabel?: string;
  onAlert?: MouseEventHandler<HTMLButtonElement>;
  cancelButtonLabel?: string;
  children: any;
  running: boolean;
  disabled?: boolean;
  cancelHidden?: boolean;

  // ダイアログのchildren内で展開されるPopperなどの中のTextboxを有効化する際にtrueにする
  disableEnforceFocus?: boolean;

  okButtonColor?: WButtonProps['color'];
  leftSideButtonLabel?: string;
  leftSideButtonColor?: WButtonProps['color'];
  onClickLeftSideButton?: MouseEventHandler<HTMLButtonElement>;
  leftSideButtonIcon?: typeof SvgIcon | React.FC;
  leftSideButtonDisabled?: boolean;
  leftSideButtonDisableMessage?: string;

  headerHeight?: number;
  footerHeight?: number;
  titleMargin?: string;
  contentMaxHeight?: number;

  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'inherit' | 'initial' | 'unset';
  zIndex?: number;

  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  disableEscapeKeyDown?: boolean;
};
const TitleWrapper = styled.div<{headerHeight?: number; titleMargin?: string}>`
  height: ${props => (props.headerHeight ? `${props.headerHeight}px` : undefined)};
  margin: ${props => (props.titleMargin ? `${props.titleMargin}` : undefined)};
  align-items: center;
  display: flex;

  justify-content: space-between;
`;
const useStyle = makeStyles<Pick<WTabDialogProps, 'contentMaxHeight' | 'overflow' | 'zIndex'>>()(
  (_theme, {contentMaxHeight, overflow, zIndex}) => ({
    headerTitle: {
      paddingBottom: 8,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 12,
      ...WHFont.titleLarge,
    },
    dialogContent: {
      paddingTop: 0,
      paddingBottom: 24,
      maxHeight: contentMaxHeight,
    },
    dialog: {
      borderRadius: 8,
      // デフォルトの大きさを指定したい場合は
      // ここでminWidth
      // とりあえず影響範囲デカすぎるんでいったんやらない。
      overflow: overflow,
    },
    root: {
      // 外から zIndex の値を指定しない場合は、mui のデフォルト値を使用する
      // ref: https://mui.com/material-ui/customization/z-index/?srsltid=AfmBOop4RP8_73EIr2rznQYmTw1DJB1WCqthp3VuQuwwgoyv1Tow2H7z
      zIndex: zIndex ?? 1300,
    },
  })
);

const WDialog: React.FC<WTabDialogProps> = props => {
  const commonDict = useCommonDict();
  const {classes} = useStyle({
    contentMaxHeight: props.contentMaxHeight,
    overflow: props.overflow,
    zIndex: props.zIndex,
  });

  const runningForOnClickOk = useRef(false);
  const onClickOk = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (props.onOk) {
        if (runningForOnClickOk.current) return;
        runningForOnClickOk.current = true;
        props.onOk(e);
        // ダブルクリックで２回処理が実行されないようにするため、runningへの値反映を少し待つ
        setTimeout(() => (runningForOnClickOk.current = false), 1000);
      }
    },
    [props]
  );

  const noFooter = useMemo(() => {
    const isVisibleLeftSideButton = props.leftSideButtonLabel && props.onClickLeftSideButton;
    const isVisibleCancel = !props.cancelHidden;
    const isVisibleSecondary = props.secondaryButtonLabel && props.onClickSecondary;
    const isVisibleOk = props.onOk && props.okButtonLabel;
    const isVisibleAlert = props.onAlert && props.alertButtonLabel;

    return !(isVisibleLeftSideButton || isVisibleCancel || isVisibleSecondary || isVisibleOk || isVisibleAlert);
  }, [
    props.alertButtonLabel,
    props.cancelHidden,
    props.leftSideButtonLabel,
    props.okButtonLabel,
    props.onAlert,
    props.onClickLeftSideButton,
    props.onClickSecondary,
    props.onOk,
    props.secondaryButtonLabel,
  ]);

  return (
    <Dialog
      classes={{paper: classes.dialog, root: classes.root}}
      open={props.open}
      maxWidth={false}
      disableEnforceFocus={props.disableEnforceFocus}
      TransitionProps={{
        onEnter: props.onEnter,
      }}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
    >
      <TitleWrapper className={classes.headerTitle} titleMargin={props.titleMargin} headerHeight={props.headerHeight}>
        {isNonNullableReactNode(props.title) && props.title}
        {isNonNullableReactNode(props.titleRightContent) && props.titleRightContent}
      </TitleWrapper>
      <DialogContent
        className={classes.dialogContent}
        dividers={false}
        style={{marginTop: props.title ? 'var(--spacing-8)' : 0}}
      >
        {props.children}
      </DialogContent>
      <Divider />
      {/* TODO marginが狭いから調整 */}
      {!noFooter && (
        <DialogActions style={{height: props.footerHeight ? props.footerHeight : undefined}}>
          {props.leftSideButtonLabel && props.onClickLeftSideButton && (
            <span style={{position: 'absolute', left: 24}}>
              <Tooltip
                title={props.leftSideButtonDisableMessage ?? ''}
                placement={'top'}
                disableHoverListener={!props.leftSideButtonDisabled}
              >
                <WButton
                  label={props.leftSideButtonLabel}
                  StartIcon={props.leftSideButtonIcon}
                  onClick={props.onClickLeftSideButton}
                  disabled={props.leftSideButtonDisabled || props.running}
                  color={props.leftSideButtonColor || 'secondary'}
                />
              </Tooltip>
            </span>
          )}

          {!props.cancelHidden && (
            <WButton
              label={props.cancelButtonLabel || commonDict.cancel}
              variant={'text'}
              onClick={props.onClose}
              disabled={props.running}
            />
          )}
          {props.secondaryButtonLabel && props.onClickSecondary && (
            <WButton
              label={props.secondaryButtonLabel}
              StartIcon={props.secondaryButtonIcon}
              onClick={props.onClickSecondary}
              running={props.running}
              disabled={(props.secondaryButtonDisabled ?? props.disabled) || (props.running ? props.running : false)}
              color={props.secondaryButtonColor || 'primary'}
            />
          )}
          {props.onOk && props.okButtonLabel && (
            <WButton
              label={props.okButtonLabel}
              onClick={onClickOk}
              running={props.running}
              disabled={props.disabled || (props.running ? props.running : false)}
              color={props.okButtonColor || 'primary'}
            />
          )}
          {props.onAlert && props.alertButtonLabel && (
            <AlertButton onClick={props.onAlert}>
              {props.running && <CircularIndicator size={30} />}
              {props.alertButtonLabel}
            </AlertButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default React.memo(WDialog);
