import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import {createFileRoute} from '@tanstack/react-router';
import * as Icon from '@workhub/icons';
import {type FC, useState} from 'react';

import useDict from '@/common/hooks/useDict';
import {useLocale} from '@/common/hooks/useLocale';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {LocaleUtils} from '@/common/utils/localeUtils';
import WHeaderNavigation from '@/components/header/WHeaderNavigation';
import {WithWorkspotSize} from '@/features/floor-map/editor';
import {
  FloorObjectsContext,
  LayoutEditor,
  LayoutSummary,
  ObjectList,
  WithPlots,
  useLayoutActions,
} from '@/features/layout/next';
import {type Category, categories} from '@/features/layout/next/category';
import type {FsLayoutCompositions} from '@/firestore/types/fsLayoutCompositionsType';
import type {FsSpace} from '@/firestore/types/fsSpaceType';
import {useFloorMapImageUrlQuery} from '@/query/floorMap';
import {useFloorObjectsQuery} from '@/query/floorObjects';
import {useFloorQuery} from '@/query/floors';
import {useLayoutCompositionQuery} from '@/query/layoutCompositions';
import {usePlotsQuery} from '@/query/plots';

export const Route = createFileRoute('/_authorized/layout/$floorId/$layoutCompositionId/')({
  component: RouteComponent,
});

const dictDef = {
  officeSpaceManagement: {
    default: {
      default: 'スペース管理',
      [Locale.en_US]: 'Office Space Management',
    },
  },
  layout: {
    default: {
      default: 'レイアウト',
      [Locale.en_US]: 'Layout',
    },
  },
  publish: {
    default: {
      default: '公開',
      [Locale.en_US]: 'Publish',
    },
  },
  edit: {
    default: {
      default: '編集',
      [Locale.en_US]: 'Edit',
    },
  },
  changeFloorMap: {
    default: {
      default: 'フロアマップの変更',
      [Locale.en_US]: 'Change floor map',
    },
  },
  scaleSetting: {
    default: {
      default: 'スケールの設定',
      [Locale.en_US]: 'Scale setting',
    },
  },
  deleteLayout: {
    default: {
      default: 'レイアウトの削除',
      [Locale.en_US]: 'Delete layout',
    },
  },
};

const ProgressWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const Header = styled(WHeaderNavigation)`
  flex: 0;
`;

const Body = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  column-gap: var(--spacing-24);
  padding: var(--spacing-24);
`;

const Column = styled.div`
  background-color: var(--surface-neutral-low);
  border-radius: var(--radius-l);
`;

const LeftColumn = styled(Column)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: var(--spacing-16);
  padding: var(--spacing-24);
`;

const RightColumn = styled(Column)`
  flex-shrink: 0;
  width: 436px;
  display: flex;
`;

type InnerProps = {
  floor: FsSpace;
  layoutComposition: FsLayoutCompositions;
  backgroundImageUrl: string;
};

const LayoutViewerScreenInner: FC<InnerProps> = ({floor, layoutComposition, backgroundImageUrl}) => {
  const dict = useDict(dictDef);
  const locale = useLocale();
  const navigate = Route.useNavigate();

  const [selectedCategories, setSelectedCategories] = useState<ReadonlyArray<Category>>(categories);
  const [selectedItemIds, setSelectedItemIds] = useState<ReadonlyArray<string>>([]);

  const isPublished = layoutComposition.id === floor.publishedLayoutCompositionId;
  const {renderActions, dispatchAction} = useLayoutActions({layoutComposition, isPublished, isCurrent: true});

  return (
    <Wrapper>
      <Header
        title={LocaleUtils.getLocaleName(floor, locale)}
        navigation={[{label: dict.officeSpaceManagement}, {label: dict.layout, toPath: '/layout'}]}
        actions={{
          // 非公開のレイアウトにのみ公開ボタンを表示
          ...(!isPublished
            ? {
                primary: {
                  label: dict.publish,
                  action: () => dispatchAction('publish'),
                },
                secondary: {
                  label: dict.edit,
                  Icon: Icon.Edit,
                  action: () => navigate({to: './edit'}),
                },
              }
            : {
                primary: {
                  label: dict.edit,
                  Icon: Icon.Edit,
                  action: () => navigate({to: './edit'}),
                },
              }),
          others: [
            {
              label: dict.changeFloorMap,
              action: () => dispatchAction('changeImage'),
            },
            {
              label: dict.scaleSetting,
              action: () => dispatchAction('changeScale'),
            },
            {
              label: dict.deleteLayout,
              destructive: true,
              action: () => dispatchAction('delete'),
            },
          ],
        }}
      />
      <Body>
        <LeftColumn>
          <LayoutSummary
            id={layoutComposition.id}
            name={layoutComposition.layoutName}
            floorId={floor.id}
            publishedLayoutCompositionId={floor.publishedLayoutCompositionId}
          />
          <WithWorkspotSize size={layoutComposition.workspotSize}>
            <LayoutEditor
              readonly
              width={layoutComposition.coordinatesMax?.x ?? 0}
              height={layoutComposition.coordinatesMax?.y ?? 0}
              fit='contain'
              backgroundImageUrl={backgroundImageUrl}
              selectedCategories={selectedCategories}
              selectedItemIds={selectedItemIds}
            />
          </WithWorkspotSize>
        </LeftColumn>
        <RightColumn>
          <ObjectList
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedItemIds={selectedItemIds}
            setSelectedItemIds={setSelectedItemIds}
          />
        </RightColumn>
      </Body>
      {renderActions()}
    </Wrapper>
  );
};

function RouteComponent() {
  const {organizationId} = useLoginUser();
  const {floorId, layoutCompositionId} = Route.useParams();

  const {data: floor} = useFloorQuery({organizationId, floorId});
  const {data: floorObjects} = useFloorObjectsQuery({organizationId, floorId});
  const {data: layoutComposition} = useLayoutCompositionQuery(
    {organizationId, layoutCompositionId: layoutCompositionId as string},
    {enabled: !!layoutCompositionId}
  );

  const {data: plots} = usePlotsQuery(
    {organizationId, layoutCompositionId: layoutComposition?.id as string},
    {enabled: !!layoutComposition}
  );
  const {data: backgroundImageUrl} = useFloorMapImageUrlQuery(
    {organizationId, layoutCompositionId: layoutComposition?.id as string},
    {enabled: !!layoutComposition}
  );

  if (!floor || !floorObjects || !plots || !layoutComposition?.coordinatesMax || !backgroundImageUrl) {
    return (
      <ProgressWrapper>
        <CircularProgress />
      </ProgressWrapper>
    );
  }

  return (
    <FloorObjectsContext.Provider value={floorObjects}>
      <WithPlots plots={plots}>
        <LayoutViewerScreenInner
          floor={floor}
          layoutComposition={layoutComposition}
          backgroundImageUrl={backgroundImageUrl}
        />
      </WithPlots>
    </FloorObjectsContext.Provider>
  );
}
