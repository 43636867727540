// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import Logger from '@/common/logger/logger';

const logger = Logger.create('getReservableSpaceCountByAttributesApi');

export const getReservableSpaceCountByAttributesApi = async (
  arg: {body: GetReservableSpaceCountByAttributesBody},
  opts?: {region?: 'default' | 'asia'}
) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .post<GetReservableSpaceCountByAttributesResponse>(
      `/organizations/reservations/settings/reservableSpaceCountByAttributes`,
      arg.body,
      {
        headers: {
          authorization: `bearer ${idToken}`,
        },
      }
    )
    .catch(axiosCatch(getStackTrace()));
};

export type GetReservableSpaceCountByAttributesResponse = {
  [K: string]: {
    [K in
      | 'MeetingRoom'
      | 'Facility'
      | 'Parking'
      | 'Workspace'
      | 'Reception'
      | 'OpenSpace'
      | 'Other'
      | 'CommonArea'
      | 'ExclusiveArea'
      | 'StaffOnly'
      | 'WorkBooth'
      | 'PrivateRoom']?: number;
  };
};
export type GetReservableSpaceCountByAttributesBody = {
  userGroupIds?: string[];
  visibleSpaceIds?: string[];
};
