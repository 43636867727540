import type {ApiTypesOrgLayoutIconType} from '@bitkey-service/v2_core-types/lib/api/organization/layout/apiTypesOrgLayout';
import type {V2CommonThingTypes} from '@bitkey-service/v2_core-types/lib/store/organizations/things/v2_storeTypesOrgThing';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import {createFileRoute, useBlocker, useLocation} from '@tanstack/react-router';
import * as Icon from '@workhub/icons';
import {IconButton} from '@workhub/ui';
import {type ComponentRef, type FC, useEffect, useMemo, useRef, useState} from 'react';
import * as R from 'remeda';

import useDict from '@/common/hooks/useDict';
import {useLocale} from '@/common/hooks/useLocale';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHFontCss} from '@/common/styles/whFont';
import {LocaleUtils} from '@/common/utils/localeUtils';
import WHeaderNavigation from '@/components/header/WHeaderNavigation';
import {type Point, WithWorkspotSize} from '@/features/floor-map/editor';
import * as P from '@/features/floor-map/editor/path';
import {
  AddPlotDropdown,
  ExitConfirmationDialog,
  FilterDropdown,
  FloorObjectsContext,
  LayoutEditor,
  LayoutSummary,
  WithPlots,
  WorkspotSizeSlider,
  useDeletedPlotIds,
  useLayoutActions,
  usePlots,
  useUpdatedPlots,
  type Prototype,
} from '@/features/layout/next';
import {type Category, categories} from '@/features/layout/next/category';
import type {FsLayoutCompositions} from '@/firestore/types/fsLayoutCompositionsType';
import type {FsSpace} from '@/firestore/types/fsSpaceType';
import {useFloorMapImageUrlQuery} from '@/query/floorMap';
import {useFloorObjectsQuery} from '@/query/floorObjects';
import {useFloorQuery} from '@/query/floors';
import {useLayoutCompositionQuery, useLayoutCompositionMutation} from '@/query/layoutCompositions';
import {type Plot, usePlotsMutation, usePlotsQuery} from '@/query/plots';

export const Route = createFileRoute('/_authorized/layout/$floorId/$layoutCompositionId/edit')({
  component: RouteComponent,
});

const dictDef = {
  officeSpaceManagement: {
    default: {
      default: 'スペース管理',
      [Locale.en_US]: 'Office Space Management',
    },
  },
  layout: {
    default: {
      default: 'レイアウト',
      [Locale.en_US]: 'Layout',
    },
  },
  save: {
    default: {
      default: '保存',
      [Locale.en_US]: 'Save',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
  changeFloorMap: {
    default: {
      default: 'フロアマップの変更',
      [Locale.en_US]: 'Change floor map',
    },
  },
  scaleSetting: {
    default: {
      default: 'スケールの設定',
      [Locale.en_US]: 'Scale setting',
    },
  },
  deleteLayout: {
    default: {
      default: 'レイアウトの削除',
      [Locale.en_US]: 'Delete layout',
    },
  },
  resetFilters: {
    default: {
      default: '絞り込みをリセット',
      [Locale.en_US]: 'Reset filters',
    },
  },
  missingError: {
    default: {
      default: (count: number) => `紐付けなし: ${count}件`,
      [Locale.en_US]: (count: number) => `Unlinked: ${count} item${count > 1 ? 's' : ''}`,
    },
  },
  workspotSize: {
    default: {
      default: 'ワークスポットサイズ',
      [Locale.en_US]: 'Workspot size',
    },
  },
  unlinkedPlotsOnMap: {
    default: {
      default: 'マップ上に紐付けされていないプロットがあります。すべて登録してから保存してください。',
      [Locale.en_US]: 'There are some plots which are not linked on the map. Please register all before saving.',
    },
  },
  savedSuccessfully: {
    default: {
      default: 'フロアマップを保存しました',
      [Locale.en_US]: 'The floor map has been saved successfully.',
    },
  },
  saveFailed: {
    default: {
      default: 'フロアマップの保存に失敗しました',
      [Locale.en_US]: 'Failed to save the floor map.',
    },
  },
};

const ProgressWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled(WHeaderNavigation)`
  flex: 0;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  row-gap: var(--spacing-16);
  padding: var(--spacing-24);
  margin: var(--spacing-24);
  background-color: var(--surface-neutral-low);
  border-radius: var(--radius-l);
`;

const Toolbar = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Actions = styled.div`
  display: flex;
  column-gap: var(--spacing-16);
`;

const Error = styled.div`
  ${WHFontCss.labelLargeStrong};

  display: flex;
  align-items: center;
  column-gap: var(--spacing-4);
  color: var(--text-semantic-error);
`;

/**
 * ワークスポットの中心座標とサイズ (直径) から正方形の SVG パスを生成する.
 * 後方互換性を確保するために利用.
 */
const createWorkspotPath = (pos: Point, size: number): string => {
  return P.toString({
    cmds: [
      {cmd: 'M', x: pos.x - size / 2, y: pos.y - size / 2},
      {cmd: 'h', dx: size},
      {cmd: 'v', dy: size},
      {cmd: 'h', dx: -size},
      {cmd: 'z'},
    ],
  });
};

/**
 * デバイスの種類に対応するアイコンの種類を返す.
 * TODO: 後方互換性のための対応なので, フロアマップ表示側で必要なくなったら削除.
 */
const getDeviceIcon = (thingType: V2CommonThingTypes | 'equipment'): ApiTypesOrgLayoutIconType | undefined => {
  switch (thingType) {
    case 'bitlink':
      return 'bitlink';
    case 'wifiConnector':
      return 'wifi';
    case 'bitlock':
    case 'bitlockGate':
    case 'flapperGate':
    case 'entrance':
    case 'electricLock':
    case 'edgeWorkstation':
      return 'lock';
    case 'bitreader':
      return 'bitreader';
    case 'reader':
      return 'reader';
    case 'faceRecognitionTablet':
      return 'face';
    case 'bitreception':
    case 'roomSupport':
      return 'tablet';
    case 'locker':
    case 'shelf':
      return 'locker';
    case 'peopleCountCamera':
    case 'securityCamera':
    case 'equipment': // 絶対に備品は違うけど, 仮でこれが使われている: https://github.com/bitkey-service/workhub-web/blob/7f307cd14662ff248bd89a78b49ac6ac69ea3f12/src/wscreens/layout/detail/items/LayoutUpdateDialog/hooks/useSaveLayout.ts#L196
      return 'video';
    case 'baggageKeeper':
    case 'bike':
    case 'cabinet':
    case 'car':
    case 'controlPanel':
    case 'display':
    case 'esl':
    case 'lighting':
    case 'pc':
      return 'lock';
    default:
      void (thingType satisfies never);
  }
};

type InnerProps = {
  organizationId: string;
  floor: FsSpace;
  layoutComposition: FsLayoutCompositions;
  backgroundImageUrl: string;
};

const LayoutEditorScreenInner: FC<InnerProps> = ({organizationId, floor, layoutComposition, backgroundImageUrl}) => {
  const dict = useDict(dictDef);
  const locale = useLocale();
  const snackbar = useSnackbar();
  const navigate = Route.useNavigate();

  const [plots] = usePlots();
  const updatedPlots = useUpdatedPlots();
  const deletedPlotIds = useDeletedPlotIds();

  // 画像の大きさを基にワークスポットの最小値・最大値を決める
  const {x: width, y: height} = layoutComposition.coordinatesMax ?? {x: 0, y: 0};
  const minWorkspotSize = Math.min(width, height) * 0.005;
  const maxWorkspotSize = Math.max(width, height) * 0.1;

  // オブジェクトに紐づけられていないプロットの数
  const missingCount = useMemo(
    () => plots.filter(plot => plot.type !== 'noTrackingArea' && plot.target == null).length,
    [plots]
  );

  // 一つでも紐づけられていないプロットがある間は不正とし, 保存させない
  const isValid = missingCount === 0;

  const editorRef = useRef<ComponentRef<typeof LayoutEditor> | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<ReadonlyArray<Category>>([...categories]);
  const [workspotSize, setWorkspotSize] = useState<number>(layoutComposition.workspotSize ?? 24);

  const {state} = useLocation();
  const {prototype} = (state ?? {}) as {prototype?: Prototype};

  // location.state に追加するプロットの情報があれば実行する
  useEffect(() => {
    if (prototype) {
      editorRef.current?.addObject(prototype);
    }
  }, [prototype]);

  const handleAddObject = (category: Category): void => {
    editorRef.current?.addObject({type: category});
  };

  const isBlocking = useRef(true);
  const blocker = useBlocker<never, true>({
    shouldBlockFn: () => isBlocking.current,
    withResolver: true,
  });

  const {mutateAsync: updateLayout, isPending: isUpdatingLayout} = useLayoutCompositionMutation({
    organizationId,
    layoutCompositionId: layoutComposition.id,
  });

  const {mutate: updatePlots, isPending: isUpdatingPlots} = usePlotsMutation(
    {organizationId, layoutCompositionId: layoutComposition.id},
    {
      onSuccess: () => {
        snackbar.success(dict.savedSuccessfully);
        isBlocking.current = false; // 予期した遷移をブロックしないようにする
        void navigate({
          to: '/layout/$floorId/$layoutCompositionId',
          params: {floorId: floor.id, layoutCompositionId: layoutComposition.id},
        });
      },
      onError: () => {
        snackbar.fail(dict.saveFailed);
      },
    }
  );

  const isUpdating = isUpdatingLayout || isUpdatingPlots;

  const handleSave = async (): Promise<void> => {
    // ワークスポットサイズが変更されたら保存
    if (workspotSize !== layoutComposition.workspotSize) {
      await updateLayout({
        layoutCompositionId: layoutComposition.id,
        workspotSize,
        // FIXME: PATCH といいながら PUT 的な挙動をするので今の値を入れる必要がある. 消したい時に明示的に null を入れるように変えたい
        ...R.pick(layoutComposition, ['scale', 'scaleBasisLine', 'scaleMeasuredValue']),
      });
    }

    updatePlots({
      updatedPlots: updatedPlots
        .map((plot): Plot | undefined => {
          if (plot.type === 'area' && plot.target) {
            return {
              layer: 'area' as const,
              path: plot.path,
              targetId: plot.target.id,
            };
          }

          if (plot.type === 'workspot' && plot.target) {
            return {
              layer: 'workspot' as const,
              pos: plot.at,
              path: createWorkspotPath(plot.at, workspotSize),
              targetId: plot.target.id,
            };
          }

          if (plot.type === 'locker' && plot.target) {
            return {
              layer: 'things' as const,
              icon: 'locker' as const,
              pos: plot.at,
              targetId: plot.target.id,
            };
          }

          if (plot.type === 'device' && plot.target) {
            return {
              layer: 'things' as const,
              icon: getDeviceIcon(plot.target.type),
              pos: plot.at,
              targetId: plot.target.id,
            };
          }

          if (plot.type === 'noTrackingArea') {
            return {
              layer: 'noTrackingArea' as const,
              path: plot.path,
              targetId: plot.id,
            };
          }
        })
        .filter(p => p != null),
      deletedPlotIds,
    });
  };

  const handleCancel = (): void => {
    void navigate({
      to: '/layout/$floorId/$layoutCompositionId',
      params: {floorId: floor.id, layoutCompositionId: layoutComposition.id},
    });
  };

  const {renderActions, dispatchAction} = useLayoutActions({
    layoutComposition,
    isPublished: layoutComposition.id === floor.publishedLayoutCompositionId,
    isCurrent: true,
  });

  return (
    <Wrapper>
      <Header
        title={LocaleUtils.getLocaleName(floor, locale)}
        navigation={[{label: dict.officeSpaceManagement}, {label: dict.layout, toPath: '/layout'}]}
        actions={{
          primary: {
            label: dict.save,
            disabled: isUpdating || !isValid,
            running: isUpdating,
            tooltip: !isValid ? dict.unlinkedPlotsOnMap : undefined,
            action: handleSave,
          },
          secondary: {label: dict.cancel, action: handleCancel},
          others: [
            {
              label: dict.changeFloorMap,
              action: () => dispatchAction('changeImage'),
            },
            {
              label: dict.scaleSetting,
              action: () => dispatchAction('changeScale'),
            },
            {
              label: dict.deleteLayout,
              destructive: true,
              action: () => dispatchAction('delete'),
            },
          ],
        }}
      />
      <Body>
        <LayoutSummary
          editing
          id={layoutComposition.id}
          name={layoutComposition.layoutName}
          floorId={floor.id}
          publishedLayoutCompositionId={floor.publishedLayoutCompositionId}
        />
        <Toolbar>
          <Actions>
            <FilterDropdown selected={selectedCategories} onChange={setSelectedCategories} />
            <IconButton background title={dict.resetFilters} onClick={() => setSelectedCategories([...categories])}>
              <Icon.View.FilterClear />
            </IconButton>
            {missingCount > 0 && (
              <Error>
                <Icon.Error color='error' />
                {dict.missingError(missingCount)}
              </Error>
            )}
          </Actions>
          <Actions>
            <WorkspotSizeSlider
              label={dict.workspotSize}
              value={workspotSize}
              minValue={minWorkspotSize}
              maxValue={maxWorkspotSize}
              onChange={setWorkspotSize}
            />
            <AddPlotDropdown onSelect={handleAddObject} />
          </Actions>
        </Toolbar>
        <WithWorkspotSize size={workspotSize}>
          <LayoutEditor
            ref={editorRef}
            width={width}
            height={height}
            fit='contain'
            backgroundImageUrl={backgroundImageUrl}
            selectedCategories={selectedCategories}
          />
        </WithWorkspotSize>
      </Body>
      <ExitConfirmationDialog
        open={blocker.status === 'blocked'}
        onConfirm={blocker.proceed}
        onCancel={blocker.reset}
      />
      {renderActions()}
    </Wrapper>
  );
};

function RouteComponent() {
  const {organizationId} = useLoginUser();
  const {floorId, layoutCompositionId} = Route.useParams();

  const {data: floor} = useFloorQuery({organizationId, floorId});
  const {data: floorObjects} = useFloorObjectsQuery({organizationId, floorId});
  const {data: layoutComposition} = useLayoutCompositionQuery({organizationId, layoutCompositionId});
  const {data: plots} = usePlotsQuery({organizationId, layoutCompositionId});
  const {data: backgroundImageUrl} = useFloorMapImageUrlQuery({organizationId, layoutCompositionId});

  if (!floor || !floorObjects || !plots || !layoutComposition?.coordinatesMax || !backgroundImageUrl) {
    return (
      <ProgressWrapper>
        <CircularProgress />
      </ProgressWrapper>
    );
  }

  return (
    <FloorObjectsContext.Provider value={floorObjects}>
      <WithPlots plots={plots}>
        <LayoutEditorScreenInner
          organizationId={organizationId}
          floor={floor}
          layoutComposition={layoutComposition}
          backgroundImageUrl={backgroundImageUrl}
        />
      </WithPlots>
    </FloorObjectsContext.Provider>
  );
}
