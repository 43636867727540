import {StringUtils} from './stringUtils';
import {LocaleData} from '../hooks/useDict';
import {Locale} from '../redux/state-types/localeStateType';
import {NameObj} from '../types/locale-types';

export class LocaleUtils {
  private static searchWord = (locale: Locale, localeWords: {[locale in Locale]?: string}): string | undefined => {
    let word = localeWords[locale];
    if (!word) {
      const altLocale = LocaleData[locale]?.alt;
      if (altLocale) {
        word = LocaleUtils.searchWord(altLocale, localeWords);
      }
    }
    return word;
  };
  public static getWord = (
    currentLocale: Locale,
    localeWords: {
      [locale in Locale]?: string;
    }
  ): string => {
    let word = LocaleUtils.searchWord(currentLocale, localeWords);
    if (!word) {
      // altのツリーから見つからない場合はもうあるもの返す。なければ空文字
      word = Object.values(localeWords).find(x => !!x);
    }
    return word ? word : '';
  };

  public static getJpName = (obj: NameObj) =>
    obj.familyNameJp ? `${obj.familyNameJp} ${obj.firstNameJp}` : obj.nameJp ? obj.nameJp : obj.name ? obj.name : '';
  public static getEnName = (obj: NameObj) =>
    obj.familyNameEn ? `${obj.firstNameEn} ${obj.familyNameEn}` : obj.nameEn ? obj.nameEn : obj.name ? obj.name : '';
  public static getLocaleName = (obj: NameObj | undefined | null, locale: Locale): string => {
    if (!obj) {
      return '';
    }
    if (locale === Locale.ja_JP) {
      const jp = LocaleUtils.getJpName(obj);
      return jp ? jp : LocaleUtils.getEnName(obj);
    } else {
      const en = LocaleUtils.getEnName(obj);
      return en ? en : LocaleUtils.getJpName(obj);
    }
  };

  public static getJpFamilyName = (obj: NameObj) =>
    obj.familyNameJp ? obj.familyNameJp : obj.nameJp ? obj.nameJp : obj.name ? obj.name : '';
  public static getEnFamilyName = (obj: NameObj) =>
    obj.familyNameEn ? obj.familyNameEn : obj.nameEn ? obj.nameEn : obj.name ? obj.name : '';
  public static getLocaleFamilyName = (obj: NameObj | undefined | null, locale: Locale): string => {
    if (!obj) {
      return '';
    }
    if (locale === Locale.ja_JP) {
      const jp = LocaleUtils.getJpFamilyName(obj);
      return jp ? jp : LocaleUtils.getEnFamilyName(obj);
    } else {
      const en = LocaleUtils.getEnFamilyName(obj);
      return en ? en : LocaleUtils.getJpFamilyName(obj);
    }
  };

  // name以外にも色々増えたため汎用化
  public static getLocaleString = ({
    jp,
    en,
    common,
    locale,
  }: {
    jp?: string;
    en?: string;
    common?: string;
    locale?: Locale;
  }): string => {
    if (!locale || locale === Locale.ja_JP) {
      return jp ? jp : en ? en : common ? common : '';
    } else {
      return en ? en : jp ? jp : common ? common : '';
    }
  };

  /**
   * ロケールに応じて nameJp または nameEn を比較するソート用関数を返す.
   * 具体的には, ロケールが ja_JP の場合は nameJp の昇順にソートし, それ以外の場合は nameEn の昇順にソートすることを想定.
   * また ja_JP のときは StringUtils.sortJapaneseStrArrayCondition に準拠して比較する.
   */
  public static compareByLocaleName(
    locale: Locale
  ): <T extends NameObj, Nil extends null | undefined>(a: T | Nil, b: T | Nil) => number {
    if (locale === Locale.ja_JP) {
      return (a, b) =>
        StringUtils.sortJapaneseStrArrayCondition(this.getLocaleName(a, locale), this.getLocaleName(b, locale));
    }

    return (a, b) => this.getLocaleName(a, locale).localeCompare(this.getLocaleName(b, locale));
  }
}
