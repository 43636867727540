import {createSlice} from '@reduxjs/toolkit';

import {ConfirmationState} from '../state-types/confirmationState';

const initialState: ConfirmationState = {
  usedContractPlan: false,
  usedReferenceAreaPrice: false,
  usedContractOption: false,
  usedOptionService: false,
};

const confirmationSlice = createSlice({
  name: 'confirmations',
  initialState,
  reducers: {
    setUsedContractPlan: (state, action: {payload: boolean}) => ({
      ...state,
      usedContractPlan: action.payload,
    }),
    setUsedReferenceAreaPrice: (state, action: {payload: boolean}) => ({
      ...state,
      usedReferenceAreaPrice: action.payload,
    }),
    setUsedContractOption: (state, action: {payload: boolean}) => ({
      ...state,
      usedContractOption: action.payload,
    }),
    setUsedOptionService: (state, action: {payload: boolean}) => ({
      ...state,
      usedOptionService: action.payload,
    }),
    clear: () => initialState,
  },
});
export default confirmationSlice;
