import clsx from 'clsx';
import {
  type ComponentType,
  type CSSProperties,
  forwardRef,
  type ForwardRefRenderFunction,
  memo,
  type PropsWithRef,
  type RefAttributes,
} from 'react';

import styles from '../styles/index.module.css';

type Color = 'inherit' | 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'info';
type FontSize = 'inherit' | 'small' | 'medium' | 'large';

export type Props = {
  color?: Color | undefined;
  fontSize?: FontSize | undefined;
  style?: CSSProperties | undefined;
  className?: string | undefined;
};

type InnerProps = {
  className?: string | undefined;
  width?: number | undefined;
  height?: number | undefined;
};

const styleMap = {
  color: new Map<Color, string>([
    ['primary', styles.primary],
    ['secondary', styles.secondary],
    ['error', styles.error],
    ['warning', styles.warning],
    ['success', styles.success],
    ['info', styles.info],
  ]),
  fontSize: new Map<FontSize, string>([
    ['small', styles.small],
    ['medium', styles.medium],
    ['large', styles.large],
  ]),
} as const;

export type Icon = ComponentType<PropsWithRef<Props & RefAttributes<SVGSVGElement>>>;

export function createIcon(name: string, render: ForwardRefRenderFunction<SVGSVGElement, InnerProps>): Icon {
  const wrap: ForwardRefRenderFunction<SVGSVGElement, Props> = (
    {color = 'inherit', fontSize = 'medium', className, ...additionalProps},
    ref
  ) => {
    const props: InnerProps = {
      ...additionalProps,
      className: clsx(styles.icon, styleMap.color.get(color), styleMap.fontSize.get(fontSize), className),
    };

    return render(props, ref);
  };

  const Component = memo(forwardRef(wrap));

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = name;
  }

  return Component;
}
