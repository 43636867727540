import {useSelector} from 'react-redux';

import {OrganizationState} from '../redux/state-types/organizationStateType';
import {RootState} from '../redux/store/rootStateType';

const useOrganization = () => {
  return useSelector<RootState, OrganizationState>(s => s.organization);
};

export {useOrganization};
