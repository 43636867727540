import {Locale} from '@/common/redux/state-types/localeStateType';

export const userSectionMenuDef = {
  employeeManagement: {
    default: {
      default: '従業員管理',
      [Locale.en_US]: 'Employee Management',
    },
  },

  guestManagement: {
    default: {
      default: 'ゲスト管理',
      [Locale.en_US]: 'Guest Management',
    },
  },
  employees: {
    default: {
      default: '従業員',
      [Locale.en_US]: 'Employees',
    },
  },
  wDepartment: {
    default: {
      default: '事業所・部門',
      [Locale.en_US]: 'Offices/Departments',
    },
  },
  wPosition: {
    default: {
      default: '役職',
      [Locale.en_US]: 'Positions',
    },
  },
  wEmploymentStatus: {
    default: {
      default: '雇用形態',
      [Locale.en_US]: 'Employment Statuses',
    },
  },
  wUserGroup: {
    default: {
      default: '従業員グループ',
      [Locale.en_US]: 'Employee Groups',
    },
  },
  wFaceRegistration: {
    default: {
      default: '顔情報登録',
      [Locale.en_US]: 'Face registration',
    },
  },
  wGuestInformation: {
    default: {
      default: '来訪者',
      [Locale.en_US]: 'Guests',
    },
  },
  wGuestDetail: {
    default: {
      default: '来訪者一覧',
      [Locale.en_US]: 'List of guest',
    },
  },
  wGuestGroup: {
    default: {
      default: '来訪者グループ',
      [Locale.en_US]: 'Guest Groups',
    },
  },
  wCustomerInformation: {
    default: {
      default: '取引先',
      [Locale.en_US]: 'Customers',
    },
  },
  wCustomerDetail: {
    default: {
      default: '取引先一覧',
      [Locale.en_US]: 'List of Customer',
    },
  },
  wVendorInformation: {
    default: {
      default: '業者',
      [Locale.en_US]: 'Vendors',
    },
  },
  wVendorDetail: {
    default: {
      default: '業者一覧',
      [Locale.en_US]: 'List of Vendor',
    },
  },
  wVendorGroup: {
    default: {
      default: '業者グループ',
      [Locale.en_US]: 'Vendor Groups',
    },
  },
};
