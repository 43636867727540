import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, {Dispatch, SetStateAction, useCallback} from 'react';

import {snackbarColor} from '@/common/styles/deprecatedColor';

export enum SimpleSnackbarType {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

export type SimpleSnackbarState = {
  open: boolean;
  message: string;
  type: SimpleSnackbarType;
  duration?: number;
};

const SimpleSnackbar = React.memo<{
  snackbarState: SimpleSnackbarState;
  setSnackbarState: Dispatch<SetStateAction<SimpleSnackbarState>>;
}>(function SimpleSnackbar({snackbarState, setSnackbarState}) {
  const onClose = useCallback(() => {
    setSnackbarState(s => ({...s, open: false}));
  }, [setSnackbarState]);

  return (
    <Snackbar
      open={snackbarState.open}
      onClose={onClose}
      autoHideDuration={snackbarState?.duration ? snackbarState?.duration : 3000}
      data-testid='rootSimpleSnackbar'
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    >
      <Alert
        style={{
          backgroundColor: snackbarColor[snackbarState.type],
          color: 'white',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        elevation={6}
        variant={'filled'}
        onClose={onClose}
        severity={snackbarState.type}
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
});

export default SimpleSnackbar;
