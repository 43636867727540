import {ApiTypesOrgDropInPricePlanGetRes} from '@bitkey-service/v2_core-types/lib/api/organization/drop-in-price-plans/apiTypesOrgDropInPricePlans';
import {StoreTypesOrgChargeDetail} from '@bitkey-service/v2_core-types/lib/store/organizations/charge-detail/storeTypesOrgChargeDetail';

export enum DropInPricePlansDetailTabs {
  OverView = 'overView',
  DropInPlans = 'dropInPlans',
}

export type DropInPricePlansDetailScreenState = {
  dropInPricePlanId?: string;
  deleteLoading: boolean;
  displayTab: (typeof DropInPricePlansDetailTabs)[keyof typeof DropInPricePlansDetailTabs];
  editBasicInfoDialog: {
    isOpen: boolean;
  };
  editValidPeriodDialog: {
    isOpen: boolean;
  };
  editPricePlanDialog: {
    isOpen: boolean;
  };
  editChargeDetailDialog: {
    isOpen: boolean;
  };
  duplicatePricePlanDialog: {
    isOpen: boolean;
  };
  deletePricePlanDialog: {
    isOpen: boolean;
  };
  master: {
    dropInPricePlan?: ApiTypesOrgDropInPricePlanGetRes;
    chargeDetails?: StoreTypesOrgChargeDetail[];
  };
};
