import {
  ApiTypesOrgDropInPricePlanGetRes,
  ApiTypesOrgDropInPricePlansUpdateReq,
  ApiTypesOrgDropInPricePlansAddClaimCycleReq,
  ApiTypesOrgDropInPricePlansClaimCycle,
  ApiTypesOrgDropInPricePlansAddReq,
  ApiTypesOrgDropInPricePlansRes,
} from '@bitkey-service/v2_core-types/lib/api/organization/drop-in-price-plans/apiTypesOrgDropInPricePlans';
import {StoreTypesOrgDropInPricePlans} from '@bitkey-service/v2_core-types/lib/store/organizations/drop-in-price-plans/storeTypesOrgDropInPricePlans';
import {AxiosRequestConfig} from 'axios';

import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiCoreOrg} from '../apiCoreOrg';

const basePath = '/drop-in-price-plans';

export class ApiDropInPricePlans {
  static get = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiCoreOrg.get<Response>(FirebaseFunctions.makePath(basePath, path), config);
  static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiCoreOrg.delete<Response>(FirebaseFunctions.makePath(basePath, path), config);

  /** ドロップイン料金プランを全て取得する */
  public static getDropInPricePlansAll = () => {
    return ApiDropInPricePlans.get<StoreTypesOrgDropInPricePlans[]>('/');
  };

  /** codeの重複チェック */
  public static checkDuplicationCode = (arg: {organizationId: string; code: string}) => {
    return ApiDropInPricePlans.post<boolean, {organizationId: string; code: string}>(`/check-duplicate-code`, arg);
  };

  /** idを使ってドロップイン料金プランを取得する */
  public static getDropInPricePlanById = (id: string) => {
    return ApiDropInPricePlans.get<ApiTypesOrgDropInPricePlanGetRes>(`/${id}`);
  };

  public static addDropInPricePlan = (data: ApiTypesOrgDropInPricePlansAddReq) =>
    ApiDropInPricePlans.post<ApiTypesOrgDropInPricePlansRes, ApiTypesOrgDropInPricePlansAddReq>('/', data);

  /** ドロップイン料金プランの更新 */
  public static patchDropInPricePlan = (dropInPricePlanId: string, body: ApiTypesOrgDropInPricePlansUpdateReq) =>
    ApiDropInPricePlans.patch<unknown, ApiTypesOrgDropInPricePlansUpdateReq>(`/${dropInPricePlanId}`, body);

  /** ドロップイン料金プランの削除 */
  public static deletedDropInPricePlan = (id: string) => ApiDropInPricePlans.delete(`/${id}`);

  /** ドロップイン料金プラン用請求スケジュールの作成 */
  public static postDropInPricePlanClaimCycle = (data: ApiTypesOrgDropInPricePlansAddClaimCycleReq) =>
    ApiDropInPricePlans.post<{id: string}, ApiTypesOrgDropInPricePlansAddClaimCycleReq>(`/claim-cycle`, data);

  /** ドロップイン料金プラン用請求スケジュールの取得 */
  public static getDropInPricePlanClaimCycleByCode = (code: string) =>
    ApiDropInPricePlans.get<ApiTypesOrgDropInPricePlansClaimCycle[] | undefined>(`/claim-cycle/${code}`);
}
