import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {useMemo, useCallback, useState} from 'react';

import {getAnnouncementsApi} from '@/api-call/workhub-core/getAnnouncementsApi';
import {useDebounceFilter} from '@/common/hooks/useDebounceFilter';
import useDict from '@/common/hooks/useDict';
import useWPagination from '@/common/hooks/useWPagination';
import {Locale} from '@/common/redux/state-types/localeStateType';
import WHeaderNavigation, {type WHeaderActions} from '@/components/header/WHeaderNavigation';
import WHeaderSearch from '@/components/header/WHeaderSearch';
import {WFacetSearchSelectModelsProps} from '@/components/search/WFacetSearchSelectModels';
import List from '@/features/building-tenant-management/announcement/List';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  title: {
    default: {
      default: 'お知らせ管理',
      [Locale.en_US]: 'Building Announcement Management',
    },
  },
  navigation: {
    default: {
      default: 'ビルテナント管理/',
      [Locale.en_US]: 'Building Tenant Management/',
    },
  },
  add: {
    default: {
      default: '新規追加',
      [Locale.en_US]: 'Add',
    },
  },
  searchByTitle: {
    default: {
      default: 'タイトル',
      [Locale.en_US]: 'Title',
    },
  },
  author: {
    default: {
      default: '作成者',
      [Locale.en_US]: 'Author',
    },
  },
};

export const Route = createFileRoute('/_authorized/building-tenant-management/announcement/')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingAnnouncementManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const navigate = Route.useNavigate();

  const [searchWord, debouncedSearchWord, setDebouncedSearchWord] = useDebounceFilter('');
  const onChangeSearchWord = useCallback(
    (word: string) => {
      setDebouncedSearchWord(word);
    },
    [setDebouncedSearchWord]
  );
  const [selectedPersonaIds, setSelectedPersonaIds] = useState<string[]>([]);

  const {offset, perPage, onNext, onPrev, handleChangePageSize} = useWPagination();

  const {data, isFetching} = useQuery({
    queryKey: ['building-tenant-management/announcement', {debouncedSearchWord, selectedPersonaIds}, {offset, perPage}],
    queryFn: async () => {
      const {data} = await getAnnouncementsApi({
        query: {
          offset,
          limit: perPage,
          freeword: debouncedSearchWord,
          createdByPersonaIds: selectedPersonaIds.length ? selectedPersonaIds : undefined,
        },
      });
      return data;
    },
    placeholderData: keepPreviousData, // ページを変更したときに前のデータを残す
    staleTime: 1000 * 60, // 1分
  });

  const actions = useMemo<WHeaderActions>(
    () => ({
      primary: {
        label: dict.add,
        action: () => {
          navigate({to: '/building-tenant-management/announcement/create'});
        },
      },
    }),
    [dict.add, navigate]
  );

  const peopleFacet: WFacetSearchSelectModelsProps = useMemo(
    () => ({
      model: 'people',
      label: dict.author,
      searchableLabels: [dict.author],
      selectedIds: selectedPersonaIds,
      onCheckStateChanged: (selectedIds: string[]) => {
        setSelectedPersonaIds(selectedIds);
      },
      filter: {
        type: {equals: 'Member'},
      },
      option: {idColumn: 'personaId'},
      checkType: 'multi',
      disable: false,
    }),
    [dict.author, selectedPersonaIds]
  );

  const onReset = useCallback(() => {
    setSelectedPersonaIds([]);
    setDebouncedSearchWord('');
  }, [setDebouncedSearchWord]);

  return (
    <>
      <WHeaderNavigation title={dict.title} navigation={dict.navigation} actions={actions} />
      <WHeaderSearch
        searchableLabels={[dict.searchByTitle]}
        facets={[peopleFacet]}
        updateSearchWord={onChangeSearchWord}
        searchWord={searchWord}
        onReset={onReset}
      />
      <List
        list={data?.items}
        fetching={isFetching}
        totalCount={data?.totalCount}
        onPrev={onPrev}
        onNext={onNext}
        onChangePageSize={handleChangePageSize}
      />
    </>
  );
}
