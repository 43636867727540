import {StoreTypesOrgChargeDetail} from '@bitkey-service/v2_core-types/lib/store/organizations/charge-detail/storeTypesOrgChargeDetail';
import {StoreTypesOrgClaimCycle} from '@bitkey-service/v2_core-types/lib/store/organizations/claim-cycle/storeTypesOrgClaimCycle';
import {StoreTypesOrgContract} from '@bitkey-service/v2_core-types/lib/store/organizations/contract/storeTypesOrgContract';
import {StoreTypesOrgContractPlans} from '@bitkey-service/v2_core-types/lib/store/organizations/contract-plans/storeTypesOrgContractPlans';
import {
  CommonContractCorporateInfo,
  ContractCustomer,
  StoreTypesOrgCustomer,
} from '@bitkey-service/v2_core-types/lib/store/organizations/customer/storeTypesOrgCustomer';
import {StoreTypesOrgOptionServices} from '@bitkey-service/v2_core-types/lib/store/organizations/option-services/storeTypesOrgOptionServices';
import {StoreTypesOrgPayment} from '@bitkey-service/v2_core-types/lib/store/organizations/payment/storeTypesOrgPayment';
import {
  PaymentMethodType,
  StoreTypesOrgPaymentMethod,
} from '@bitkey-service/v2_core-types/lib/store/organizations/payment-method/storeTypesOrgPaymentMethod';
import {V2StoreTypesOrgPeople} from '@bitkey-service/v2_core-types/lib/store/organizations/people/v2_storeTypesOrgPeople';
import {V2StoreTypesOrgSpace} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';
import {StoreTypesOrgThirdPlaceAreaSettings} from '@bitkey-service/v2_core-types/lib/store/organizations/third-place-area-setting/storeTypesOrgThirdPlaceAreaSettings';
import {EpochMillis} from '@bitkey-service/workhub-functions-common-types/lib/common/commonTypes';

import {CONTRACT_CREATE_ERROR_TYPE} from './common/WContractCreateContext';
import {BankAccountInput, InputRepresentativeType} from './common/WContractCreateTypes';
import {ClaimCycles} from '../../coworking-claim-cycle/types/ClaimCycles';
import {CalculatedChargeDetailTypes} from '../common/interactors/calcChargeDetailInteractor';

/**
 * 契約プラン及び、オプションサービスを選択した時に持つ情報
 * 下記の情報を元に、データを取得していく。
 */
export type TPLoadedDbState = {
  // TODO これはいらないかもしれないな。
  // 選択した時点でinput情報には持っている
  contractPlan?: StoreTypesOrgContractPlans; // 選択したプラン
  selectableOptionServices: StoreTypesOrgOptionServices[]; // プラン選択時に読み込まれる選択可能なオプションサービス
  selectableSpaces: V2StoreTypesOrgSpace[]; // プラン選択時に読み込まれる選択可能な空間情報
  allRelationSpaces: V2StoreTypesOrgSpace[]; // プラン選択時に読み込まれる選択可能な空間の親空間などを持つ
  thirdPlaceAreaSettings: StoreTypesOrgThirdPlaceAreaSettings[]; // プラン選択時に読み込まれる選択可能な空間の親空間などを持つ

  // ここから先はいるかどうか微妙
  paymentMethods: StoreTypesOrgPaymentMethod[]; // プラン選択時に読み込まれるpaymentMethods
  chargeDetails: StoreTypesOrgChargeDetail[]; // プラン選択時とオプション選択時に読み込まれるchargeDetails
  claimCycles: StoreTypesOrgClaimCycle[]; // プラン選択時とオプション選択時に読み込まれるclaimCycles

  loadAllClaimCycles: StoreTypesOrgClaimCycle[]; // プラン選択と関係なく、組織の全ての請求サイクルを読み込む
  loadAllCustomer: StoreTypesOrgCustomer[]; // プラン選択と関係なく、組織の全ての顧客を読み込む
  loadAllPeople: V2StoreTypesOrgPeople[]; // プラン選択と関係なく、組織の全ての会員を読み込む
  loadAllEmployee: V2StoreTypesOrgPeople[]; // プラン選択と関係なく、組織の全ての従業員を読み込む

  // 下書きとか。
  // 下書き以外に持つことあるんだっけ、、、ロジック見てもわからない、、
  beforeContract?: StoreTypesOrgContract;
  beforeContractId?: string;
  // 契約作成時に作ったデータを埋めておく
  currentContract?: StoreTypesOrgContract;

  existingData?: TPLoadedBaseContractState;

  initialPayMethodTypes: PaymentMethodType[]; // 初期費用の選択しうる支払い方法
  basePayMethodTypes: PaymentMethodType[]; // 基本料金の選択しうる支払い方法
};

// ドラフトからの契約や、追加契約する時に元の契約の情報を保持するところ
export type TPLoadedBaseContractState = {
  id: string;
  code?: string;
  customerId?: string; // 前回契約した顧客のIDとか
  selectedContractPlans: Omit<StoreTypesOrgContractPlans, 'registeredBy' | 'lastUpdatedBy'>[]; // 契約プラン
  chargeDetails: Omit<StoreTypesOrgChargeDetail, 'registeredBy' | 'lastUpdatedBy'>[]; // プラン選択時に読み込まれるchargeDetails
  claimCycles: Omit<StoreTypesOrgClaimCycle, 'registeredBy' | 'lastUpdatedBy'>[]; // プラン選択時に読み込まれるclaimCycles

  initialClaimCycle?: ClaimCycles | undefined;
  allClaimCycles: ClaimCycles[];
  payForAgree?: boolean; // 入金待ち

  // 契約済みの顧客情報
  contractedCustomer?: {
    contractIds: string[];
    customer?: StoreTypesOrgCustomer;
    people?: V2StoreTypesOrgPeople;
    payment?: StoreTypesOrgPayment;
  };
};

/**
 * 契約作成時に入力及び自動入力された項目の全て
 * これをそのままAPIに投げれる形に変換する
 */
export type TPInputContractState = {
  // FIXME これはとても消したい。今んところ契約作成後のidしか持ってない。
  // 後続でどちらにせよこのIDを使ってデータを取りに行ってるので、契約作成時の返却でcontractそのまま返してreduxにつめたい。
  // 詰める先はcurrentContractで良いかなと。
  id?: string;
  contractCode: string;
  // FIXME これあってるか？
  customerType: 'consumer' | 'business' | 'agency' | 'member';
  conclusionDate: EpochMillis; // 締結日
  // 契約期間
  contractTerm: {
    startAt: EpochMillis;
    endAt?: EpochMillis;
  };
  selectedContractPlan?: StoreTypesOrgContractPlans; // 契約プラン
  selectedOptionServices: StoreTypesOrgOptionServices[]; // 契約プラン
  selectedSpaces: V2StoreTypesOrgSpace[]; // 選択された空間情報

  // 支払い方法系
  basePaymentMethodType?: PaymentMethodType; // 定期請求の支払い方法
  initialPaymentMethodType?: PaymentMethodType; // 初期費用の請求タイプ

  // 請求サイクル系
  initialBillSyncBaseCycle?: boolean; // 初期費用の請求を基本料金に合わせるかどうか

  // TODO 下記二つは持つ場所を分けていいかもしれない。
  // 特にselectableAllClaimCyclesについては結構微妙。
  // ただ日割りの計算とかに使う可能性があるから一旦ここに保持。
  initialClaimCycle?: StoreTypesOrgClaimCycle | undefined; // 初期費用の請求サイクル
  baseCycle?: StoreTypesOrgClaimCycle | undefined; // 定期請求の請求サイクル
  selectedAllClaimCycles: StoreTypesOrgClaimCycle[]; // 選択された全ての請求サイクル
  selectedCustomer?: StoreTypesOrgCustomer; // 選択された顧客

  // その契約の際に、入金待ちとするか否か。
  payForAgree?: boolean; // 入金待ち

  // 法人情報系
  corporateInfo: CommonContractCorporateInfo & {
    useRentalSpaceAddress?: boolean;
    isRegistered?: boolean;
    corporateRegistrationNumber?: string;
    corporateRegistrationFiles?: File[]; // これ使ってるのか
    // マルチテナント用
    organizationCode?: string;
    organizationName?: string;
  };

  // 契約担当者(従業員)
  employeeContractors: {id: string; name: string; email: string; iconImage?: string}[];

  // メモ
  memo?: string;

  // 代表者情報系
  representative: InputRepresentativeType;

  /** 契約担当者(顧客) */
  inChargeContractor: (ContractCustomer & {peopleId?: string})[];

  /** 請求額通知先 */
  billingNotifications: ContractCustomer[];

  /** 会員 */
  members: ContractCustomer[]; // これいるか？

  /** 請求先 */
  billTo: ContractCustomer;

  // 口座情報系
  /** 請求先口座 */
  billAccount?: BankAccountInput & {isBillingAddressSame?: boolean; customerNo?: string};
  /** 返金先口座 */
  refundBankAccount?: BankAccountInput & {isRefundAddressSame?: boolean};

  // 計算結果系
  // TODO ここの情報の持ち方は要検討
  calcChargeDetailAll?: CalculatedChargeDetailTypes; // 画面側で計算した料金の一覧 契約における計算結果は基本ここに持つ。

  // 選択中のプランに手動で追加した品目のオリジナル
  // 基本的にはここは使わないが、入居エリアに依存する品目はcalcChargeDetailAllとは別にオリジナルを保持しておかないと計算できない
  manualAddedChargeDetails: StoreTypesOrgChargeDetail[];
};

export type TPInputContractStateArg = TPInputContractState & {
  startAt: EpochMillis;
  endAt?: EpochMillis;
};

export type TPContractType = 'create' | 'renew' | 'draft' | 'add';

// 契約作成画面において、画面側の制御を行うための項目
export type TPContractControl = {
  type: TPContractType;
  step: number;
  // 画面の制御に必要な項目
  errorList: CONTRACT_CREATE_ERROR_TYPE[];
  isInviteMailSetting?: boolean;
  invalidPlanContractArea?: boolean;
  initialized?: boolean; // これ名前がよくない気もするな
  additionalLoaded?: boolean;
  readonly?: boolean;
  ownerSettingCompleted?: boolean;
};

export enum TPContractCreateSteps {
  SelectPlan,
  SelectOption,
  InputContractor,
  Confirm,
  Success,
}
