import {StoreTypesOrgChargeDetail} from '@bitkey-service/v2_core-types/lib/store/organizations/charge-detail/storeTypesOrgChargeDetail';
import {
  ApplicationFormSetting,
  StoreTypesOrgContractPlans,
} from '@bitkey-service/v2_core-types/lib/store/organizations/contract-plans/storeTypesOrgContractPlans';
import {StoreTypesOrgOptionServices} from '@bitkey-service/v2_core-types/lib/store/organizations/option-services/storeTypesOrgOptionServices';
import {StoreTypesOrgPaymentMethod} from '@bitkey-service/v2_core-types/lib/store/organizations/payment-method/storeTypesOrgPaymentMethod';
import {V2StoreTypesOrgSpace} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';
import {StoreTypesOrgThirdPlaceAreaSettings} from '@bitkey-service/v2_core-types/lib/store/organizations/third-place-area-setting/storeTypesOrgThirdPlaceAreaSettings';
import {createSlice} from '@reduxjs/toolkit';

import ArrayUtil from '@/common/utils/arrayUtil';
import {ImageSelectorData} from '@/components/image/types/ImageSelectorData';
import {
  CommonAvailableArea,
  RELEASE_CONTRACT_PLAN_ERROR_TYPE,
  TPInputReleaseContractPlanState,
  TPLoadedDbReleaseContractPlanState,
  TPReleaseContractPlanControl,
  TPReleaseContractPlanStep,
} from '@/wscreens/third-place/types/ContractPlanReleaseState';

import {ReleaseContractPlanState} from '../state/releaseContractPlanState';

const InitialInputReleaseContractPlanState: TPInputReleaseContractPlanState = {
  id: '',
  availablePaymentMethods: [],
};

const InitialLoadedDBState: TPLoadedDbReleaseContractPlanState = {};

const InitialControlState: TPReleaseContractPlanControl = {
  errorList: [],
  initialized: false,
  step: 0,
  isInviteMailSetting: false,
  type: 'create',
  openConfirmDialog: false,
};
const initialState: ReleaseContractPlanState = {
  db: InitialLoadedDBState,
  input: InitialInputReleaseContractPlanState,
  control: InitialControlState,
};

const releaseContractPlanSlice = createSlice({
  name: 'releaseContractPlan',
  initialState,
  reducers: {
    // 基本形
    setInput: (state, action: {payload: Partial<TPInputReleaseContractPlanState>}) => ({
      ...state,
      input: {
        ...state.input,
        ...action.payload,
      },
    }),
    setPublicDescription: (state, action: {payload: string}) => ({
      ...state,
      input: {
        ...state.input,
        public: {
          ...state.input.public,
          name: state.input.public?.name ?? '',
          description: action.payload,
        },
      },
    }),
    setPublicDescriptionEn: (state, action: {payload: string}) => ({
      ...state,
      input: {
        ...state.input,
        public: {
          ...state.input.public,
          name: state.input.public?.name ?? '',
          descriptionEn: action.payload,
        },
      },
    }),
    setPublicNotion: (state, action: {payload: string}) => ({
      ...state,
      input: {
        ...state.input,
        public: {
          ...state.input.public,
          name: state.input.public?.name ?? '',
          precautions: action.payload,
        },
      },
    }),
    setPublicNotionEn: (state, action: {payload: string}) => ({
      ...state,
      input: {
        ...state.input,
        public: {
          ...state.input.public,
          name: state.input.public?.name ?? '',
          precautionsEn: action.payload,
        },
      },
    }),
    setPublicPrivacyPolicy: (state, action: {payload: string}) => ({
      ...state,
      input: {
        ...state.input,
        public: {
          ...state.input.public,
          name: state.input.public?.name ?? '',
          privacyPolicyUrl: action.payload,
        },
      },
    }),
    setPublicTermOfService: (state, action: {payload: string}) => ({
      ...state,
      input: {
        ...state.input,
        public: {
          ...state.input.public,
          name: state.input.public?.name ?? '',
          termOfServiceUrl: action.payload,
        },
      },
    }),
    setPublicName: (state, action: {payload: string}) => ({
      ...state,
      input: {
        ...state.input,
        public: {
          ...state.input.public,
          name: action.payload ?? '',
          nameJp: action.payload ?? '',
        },
      },
    }),
    setPublicNameEn: (state, action: {payload: string}) => ({
      ...state,
      input: {
        ...state.input,
        public: {
          ...state.input.public,
          name: state.input.public?.name ?? '',
          nameEn: action.payload,
        },
      },
    }),
    setPublicArea: (state, action: {payload: string[]}) => {
      const selectSpaceIds = action.payload;
      const selectedSpaces = state.db.spaces?.filter(space => selectSpaceIds.some(spaceId => spaceId === space.id));
      return {
        ...state,
        input: {
          ...state.input,
          selectedSpaces: selectedSpaces,
        },
      };
    },
    setPublicOptionService: (state, action: {payload: string[]}) => {
      const selectIds = action.payload;
      const selected = state.db.optionServices?.filter(optionService => selectIds.some(id => id === optionService.id));
      return {
        ...state,
        input: {
          ...state.input,
          selectedOptionServices: selected,
        },
      };
    },
    setPublicApplicationFormSetting: (state, action: {payload: ApplicationFormSetting[]}) => {
      return {
        ...state,
        input: {
          ...state.input,
          public: {
            ...state.input.public,
            name: state.input.public?.name ?? '',
            applicationFormSettings: action.payload,
          },
        },
      };
    },
    setImages: (state, action: {payload: ImageSelectorData[]}) => ({
      ...state,
      input: {
        ...state.input,
        imageSelectors: action.payload ?? [],
      },
    }),
    setInitialLoaded: (
      state,
      action: {
        payload: {
          contractPlan?: StoreTypesOrgContractPlans;
          spaces?: V2StoreTypesOrgSpace[];
          optionServices?: StoreTypesOrgOptionServices[];
          availableAreas: CommonAvailableArea[];
          thirdPlaceAreaSettings: StoreTypesOrgThirdPlaceAreaSettings[];
          paymentMethods: StoreTypesOrgPaymentMethod[];
          chargeDetails: StoreTypesOrgChargeDetail[];
        };
      }
    ) => ({
      ...state,
      db: {
        ...state.db,
        contractPlan: action.payload.contractPlan,
        spaces: action.payload.spaces,
        optionServices: action.payload.optionServices,
        availableAreas: action.payload.availableAreas,
        thirdPlaceAreaSettings: action.payload.thirdPlaceAreaSettings,
        payments: action.payload.paymentMethods,
        chargeDetails: action.payload.chargeDetails,
      },
      input: {
        ...state.input,
        id: action.payload.contractPlan?.id ?? '',
        public: {
          ...action.payload.contractPlan?.public,
          name: action.payload.contractPlan?.public?.name ?? '',
          applicationFormSettings: action.payload.contractPlan?.public?.applicationFormSettings ?? [
            {
              category: 'customer',
              settingItems: [
                {
                  code: 'telephone',
                  selectValue: 'required',
                },
                {
                  code: 'address',
                  selectValue: 'disabled',
                },
              ],
            },
          ],
        },
        availablePaymentMethods: ArrayUtil.removeDuplicateBySet(
          action.payload.paymentMethods.map(payment => payment.methodType)
        ),
        selectedSpaces:
          action.payload.spaces
            ?.filter(space => action.payload.contractPlan?.publicSpaceIds?.some(spaceId => spaceId === space.id))
            .map(space => space) ?? [],
        selectedOptionServices:
          action.payload.optionServices
            ?.filter(optionService =>
              action.payload.contractPlan?.publicOptionServiceIds?.some(optionId => optionId === optionService.id)
            )
            .map(optionService => optionService) ?? [],
        images: action.payload.contractPlan?.images,
      },
      control: {
        ...state.control,
        initialized: true,
      },
    }),
    setControl: (state, action: {payload: Partial<TPReleaseContractPlanControl>}) => ({
      ...state,
      control: {
        ...state.control,
        ...action.payload,
      },
    }),
    setNext: state => {
      if (state.control.step === TPReleaseContractPlanStep.Confirm) {
        return {
          ...state,
          control: {
            ...state.control,
            openConfirmDialog: true,
          },
        };
      } else {
        return {
          ...state,
          control: {
            ...state.control,
            step: state.control.step + 1,
            readonly: state.control.step + 1 === TPReleaseContractPlanStep.Confirm,
          },
        };
      }
    },
    setBack: state => ({
      ...state,
      control: {
        ...state.control,
        step: state.control.step - 1,
        errorList: [],
        readonly: state.control.step - 1 === TPReleaseContractPlanStep.Confirm,
      },
    }),
    setCloseConfirmDialog: state => ({
      ...state,
      control: {
        ...state.control,
        openConfirmDialog: false,
      },
    }),
    setStep: (state, action: {payload: number}) => ({
      ...state,
      control: {
        ...state.control,
        step: action.payload,
        readonly: action.payload === TPReleaseContractPlanStep.Confirm,
      },
    }),
    addError: (state, action: {payload: RELEASE_CONTRACT_PLAN_ERROR_TYPE}) => {
      if (state.control.errorList.some(error => error === action.payload)) {
        return;
      }
      return {
        ...state,
        control: {
          ...state.control,
          errorList: ArrayUtil.removeDuplicateBySet([...state.control.errorList, action.payload]),
        },
      };
    },
    removeError: (state, action: {payload: RELEASE_CONTRACT_PLAN_ERROR_TYPE}) => {
      if (!state.control.errorList.some(error => error === action.payload)) {
        return;
      }
      return {
        ...state,
        control: {
          ...state.control,
          errorList: ArrayUtil.removeDuplicateBySet(state.control.errorList.filter(error => error !== action.payload)),
        },
      };
    },
    clear: () => initialState,
  },
});
export default releaseContractPlanSlice;
