import {
  ApiTypesBulkCreateReservationLockerSettingReq,
  ApiTypesUpdateReservationSettingReq,
} from '@bitkey-service/v2_core-types/lib/api/organization/reservations/reservation-settings/apiTypesOrgReservationSettings';

import {
  bulkUpsertReservationSettingApi,
  BulkUpsertReservationSettingBody,
} from '@/api-call/workhub-core/bulkUpsertReservationSettingApi';
import {
  createReservationSettingApi,
  CreateReservationSettingBody,
} from '@/api-call/workhub-core/createReservationSettingApi';
import {
  getReservableSpaceSettingSubsetsApi,
  GetReservableSpaceSettingSubsetsQueryParam,
} from '@/api-call/workhub-core/getReservableSpaceSettingSubsetsApi';
import {
  updateReservationSettingApi,
  UpdateReservationSettingBody,
} from '@/api-call/workhub-core/updateReservationSettingApi';
import {updateReservationSettingWorkhubSettingApi} from '@/api-call/workhub-core/updateReservationSettingWorkhubSettingApi';

import {
  getReservableSpaceCountByAttributesApi,
  GetReservableSpaceCountByAttributesBody,
} from '../../api-call/workhub-core/getReservableSpaceCountByAttributesApi';
import {ApiWorkhubCoreReservationSettings} from '../../v2_api/workhub-core/organizations/reservation/reservation-settings/apiWorkhubCoreReservationSettings';

export class V2ReservationSettingService {
  public static create = (data: CreateReservationSettingBody) => createReservationSettingApi({body: data});
  public static update = ({settingId}: {settingId: string}, data: UpdateReservationSettingBody) =>
    updateReservationSettingApi({paths: {settingId}, body: data});
  public static updateWorkhubSetting = (settingId: string, data: ApiTypesUpdateReservationSettingReq) =>
    updateReservationSettingWorkhubSettingApi({paths: {settingId}, body: data});
  public static bulkLocker = (createType: 'all' | 'allForce', data: ApiTypesBulkCreateReservationLockerSettingReq) =>
    ApiWorkhubCoreReservationSettings.bulkLocker(createType, data);
  public static bulk = (data: BulkUpsertReservationSettingBody) => bulkUpsertReservationSettingApi({body: data});
  public static getOwnerSetting = (settingId: string) => ApiWorkhubCoreReservationSettings.getOwnerSetting(settingId);
  public static getReservableSpaceCountByAttributes = (arg: {body: GetReservableSpaceCountByAttributesBody}) =>
    getReservableSpaceCountByAttributesApi(arg, {region: 'asia'}).then(r => r.data);
  public static getReservableSpaceSettings = (arg: {query: GetReservableSpaceSettingSubsetsQueryParam}) =>
    getReservableSpaceSettingSubsetsApi(arg, {region: 'asia'}).then(r => r.data);
}
