// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import Logger from '@/common/logger/logger';

const logger = Logger.create('updateReservationSettingWorkhubSettingApi');

export const updateReservationSettingWorkhubSettingApi = async (
  arg: {body: UpdateReservationSettingWorkhubSettingBody; paths: UpdateReservationSettingWorkhubSettingPathParam},
  opts?: {region?: 'default' | 'asia'}
) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .post<UpdateReservationSettingWorkhubSettingResponse>(
      `/organizations/reservations/settings/${arg.paths.settingId}/workhubSetting`,
      arg.body,
      {
        headers: {
          authorization: `bearer ${idToken}`,
        },
      }
    )
    .catch(axiosCatch(getStackTrace()));
};

export type UpdateReservationSettingWorkhubSettingResponse = {
  id: string;
  /**
   * 現状利用してないので合言葉的なやついれてる
   */
  detectableCondition: {
    conditionResultJudgeRule: 'Every' | 'Some';
    conditions: {
      originDataStructureType:
        | 'Order'
        | 'Contract'
        | 'Region'
        | 'OptionService'
        | 'Organization'
        | 'OrganizationSpace'
        | 'OrganizationSpaceBuilding'
        | 'OrganizationSpaceProperty'
        | 'OrganizationTreasureDataSource'
        | 'OrganizationLocker'
        | 'OrganizationShelf'
        | 'OrganizationEquipment'
        | 'OrganizationAccessControl'
        | 'OrganizationAccessControlV2'
        | 'OrganizationThingProperty'
        | 'OrganizationApplication'
        | 'OrganizationReservationUnavailableTime'
        | 'UserRegion'
        | 'UserBuilding'
        | 'UserProperty'
        | 'UserAttribute'
        | 'User'
        | 'DropInContract'
        | 'DropInPlan'
        | 'DropInPricePlan';
      filterConditions?: {
        operator: 'Equal' | 'NotEqual' | 'In' | 'NotIn' | 'Between' | 'NumberIsLarger' | 'NumberIsSmaller';
        originAttribute?: string;
        comparison:
          | {
              /**
               * 固定値か否か
               */
              isFixedValue: true;
              fixedValues: (string | number | boolean)[];
            }
          | {
              isFixedValue: false;
              fixedValue: (string | number | boolean)[];
              dataStructureType:
                | 'Order'
                | 'Contract'
                | 'Region'
                | 'OptionService'
                | 'Organization'
                | 'OrganizationSpace'
                | 'OrganizationSpaceBuilding'
                | 'OrganizationSpaceProperty'
                | 'OrganizationTreasureDataSource'
                | 'OrganizationLocker'
                | 'OrganizationShelf'
                | 'OrganizationEquipment'
                | 'OrganizationAccessControl'
                | 'OrganizationAccessControlV2'
                | 'OrganizationThingProperty'
                | 'OrganizationApplication'
                | 'OrganizationReservationUnavailableTime'
                | 'UserRegion'
                | 'UserBuilding'
                | 'UserProperty'
                | 'UserAttribute'
                | 'User'
                | 'DropInContract'
                | 'DropInPlan'
                | 'DropInPricePlan';
              attribute: string;
            };
      }[];
      checkRule: 'HasAny' | 'HasNone' | 'HasSameAs' | 'HasLargeThan' | 'HasSmallerThan';
      divideAttribute?: string;
      judgeBasisLength?: number;
    }[];
  };
  targetId?: string;
  targetType?: 'space' | 'thing';
  /**
   * 予約可能か否か
   */
  reservable?: boolean;
  /**
   * 予約可能な時間単位 (分)
   * 特にバリデーションとかしてない
   */
  reservableTimeUnitInMinutes?: number;
  /**
   * 予約可能な曜日
   */
  reservableDayOfWeeks?: ('Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday')[];
  baseReservableTimes?: {
    startTime?: string;
    endTime?: string;
    timeZone?: string;
  }[];
  reservableTimesEachDayOfWeek?: {
    [K in 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday']: {
      startTime?: string;
      endTime?: string;
      timeZone?: string;
    }[];
  };
  /**
   * 重複して予約が可能か
   */
  permitReservationDuplication?: boolean;
  /**
   * 予約可能な最小時間（分）
   * 特にバリデーションとかしてない
   */
  minReservationTimeLengthInMinutes?: number;
  /**
   * 予約可能な最大時間（分）
   * 特にバリデーションとかしてない
   */
  maxReservationTimeLengthInMinutes?: number;
  /**
   * ユーザーグループのチェックを行うか
   */
  useUserGroup?: boolean;
  /**
   * 絞込みを行うユーザーグループID
   */
  userGroupIds?: string[];
  workhubSettings?: {
    id: string;
    /**
     * 利用方法など
     */
    noteJp?: string;
    noteEn?: string;
    /**
     * 対象
     */
    type: 'spaces' | 'things';
    /**
     * 対象のリレーション
     */
    targetId?: string;
    /**
     * 予約設定へのリレーション
     */
    reservationSettingsId?: string;
    /**
     * キャンセルポリシー
     */
    cancelPolicyJp?: string;
    cancelPolicyEn?: string;
    /**
     * 連絡先
     */
    phoneNumber?: string;
    /**
     * 公開可能かどうか
     * trueであれば公開できない
     */
    private?: boolean;
    /**
     * 参加者以外から見える予約情報(デフォルト値)
     * all: 公開、 owner: 主催者のみ、 none: 非公開
     */
    visibleContentFromOther?: 'none' | 'all' | 'owner';
    /**
     * 予約の何分前から利用可能か
     * e.g. 予約開始時間の5分前から使えるカギを発行
     */
    availableBeforeMinutes?: number;
    /**
     * 予約の何分後まで利用可能か
     * e.g. 予約終了時間の5分後まで使えるカギを発行
     */
    availableAfterMinutes?: number;
    /**
     * 利用できる鍵束のID
     */
    availableACPatterns?: {[K in 'Member' | 'Cast' | 'Visitor' | 'Customer']: string[]};
    /**
     * メールテンプレートへのリレーション
     *
     * 今後sendableMailSettingsへ移行したい。
     * この設定は今は社外、という大きなくくりの設定で使用している。
     * 今後来訪者や業者など細かくメールテンプレートを持たせる時が移行タイミング
     */
    mailTemplateIds?: string[];
    /**
     * 送信可能なメールテンプレートの種別
     * invitation: 招待, acceptance: 受付, update: 更新, cancel: キャンセル, delete: 削除時, remind: リマインド
     *
     * 今後sendableMailSettingsへ移行したい。
     * この設定は今は社外、という大きなくくりの設定で使用している。
     * 今後来訪者や業者など細かくメールテンプレートを持たせる時が移行タイミング
     */
    sendableMailTemplateTypes?: (
      | 'invitation'
      | 'cancel'
      | 'update'
      | 'remind'
      | 'resend'
      | 'other'
      | 'finishRemind'
      | 'autoExtend'
      | 'remindGuestsToRegisterCompanions'
      | 'equipmentInvitation'
      | 'equipmentCancel'
      | 'equipmentUpdate'
      | 'equipmentRemind'
      | 'lockerInvitation'
      | 'lockerCancel'
      | 'lockerUpdate'
      | 'lockerRemind'
      | 'lockerFinishRemind'
    )[];
    /**
     * メールの送信の設定を種類別（PeopleTypeなど）に持つ
     */
    sendableMailSettings?: {
      [K in 'Member' | 'Cast' | 'Visitor' | 'Customer']?: {
        mailTypeSettings?: {
          [K in
            | 'invitation'
            | 'cancel'
            | 'update'
            | 'remind'
            | 'resend'
            | 'other'
            | 'finishRemind'
            | 'autoExtend'
            | 'remindGuestsToRegisterCompanions'
            | 'equipmentInvitation'
            | 'equipmentCancel'
            | 'equipmentUpdate'
            | 'equipmentRemind'
            | 'lockerInvitation'
            | 'lockerCancel'
            | 'lockerUpdate'
            | 'lockerRemind'
            | 'lockerFinishRemind']?: {
            templateId?: string;
            sendable: boolean;
          };
        };
        /**
         * 予約開始リマインド
         */
        remindMinutes?: number;
        /**
         * 予約終了リマインド
         */
        finishRemindMinutes?: number;
      };
    };
    /**
     * リマインドメールを何分前(or 何分後)に送るか
     * ここは時間だけを管理。
     * リマインドメールを送るかどうかは「リレーションにIDがあるかつsendableMailTemplateTypesにremindがあること」
     * e.g. 会議室予約開始時間の5分前にリマインドメールを送る
     */
    remindMinutes?: number;
    finishRemindMinutes?: number;
    /**
     * 繰り返し予約を許可するか
     */
    repeatableType?: 'all' | 'none';
    /**
     * 事前ゲスト同行者登録を許可する/許可しない
     */
    isGuestCompanionPreRegisteringEnabled?: boolean;
    /**
     * 事前同行者登録のリマインドメールを何分前に送るか
     */
    reminderMinutesForCompanionRegistration?: number;
  };
  /**
   * ホテリング機能で利用
   */
  workspotSettings?: {
    /**
     * 事前チェックインの時間設定（何分前からチェックインできるか。デフォルトは５分）
     */
    availablePreCheckInTimeLengthInMinutes?: 5 | 10 | 15 | 30 | 45;
    autoCancelTimeLengthInMinutes?: 5 | 10 | 15 | 30 | 60;
    /**
     * どの方法でワークスポットにチェックインを行うのかを設定
     * デフォルトはQRコード
     */
    checkInType?: 'qrCode' | 'pressAppButton';
    /**
     * webからチェックインできるかどうかワークスポット単位での設定
     */
    availableWebCheckIn?: boolean;
  };
  /**
   * 裏設定、クライアント側で過去日予約を許容するのに利用
   */
  allowPastDays?: boolean;
  /**
   * キャンセルポリシー
   */
  cancellationDeadlineUnit?: 'minute' | 'hour';
  cancellationDeadlineNumber?: number;
  ableToInviteExternal?: boolean;
  /**
   * 予約受付期間
   */
  reservablePeriod?: {
    unit: 'day' | 'week' | 'month' | 'year';
    count: number;
    additionalConditions?: {
      unit: 'day' | 'week' | 'month' | 'year';
      count: number;
      userGroupId: string;
      id: string;
    }[];
  };
  /**
   * 予約の自動延長設定
   */
  autoExtendSettings?: {
    /**
     * 自動延長可能か否か
     */
    extendable: boolean;
    /**
     * 延長時間単位
     * 1回の延長でどのくらいの時間延長するか
     * number*unitで期間を定義する
     */
    extendPeriod?: {
      unit: 'minute' | 'hour';
      count: number;
    };
    /**
     * 自動延長メール配信頻度
     * 今は初回延長時にメール配信 or 毎回メール配信の2択
     */
    sendExtendMailTiming?: 'firstExtension' | 'everyTime';
  };
  sendGuestMailToEmployee?: 'cc' | 'bcc';
  /**
   * 予約設定を同期しているオーナー組織
   */
  ownerOrganizationId?: string;
  /**
   * 予約時に入館証(アクセスコントロールのQRコード)を送付するか
   * 裏からの設定のみ
   */
  enableVisitorPassSent?: boolean;
  /**
   * オーナーの予約設定更新時、テナントの予約設定を連動して変更する処理の対象外とする項目の設定
   */
  unlinkTenantSettingTypes?: 'mailTemplates'[];
  /**
   * 状況によって切り替えられる予約時自動発行カギ
   * 日中はReceptionでQR受付、夜間はRoomSupportでQRで直接開錠したい、などの用途に利用
   * ex. conditionLabelがunavailableなら受付可能時間外におけるカギ束の設定、など
   */
  availableACPatternConditions?: {
    /**
     * 曜日と時間 -> 予約設定の持ち方と一緒
     */
    timesEachDayOfWeek: {
      [K in 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday']: {
        startTime?: string;
        endTime?: string;
        timeZone?: string;
      }[];
    };
    /**
     * 解錠対象のカギ束
     */
    availableACPatterns: {[K in 'Member' | 'Cast' | 'Visitor' | 'Customer']: string[]};
    /**
     * カギ束の有効期間前後
     */
    availableAfterMinutes?: number;
    availableBeforeMinutes?: number;
    /**
     * どの条件を利用するかを特定する際に利用する意図
     */
    conditionLabel: string;
  }[];
};
export type UpdateReservationSettingWorkhubSettingBody = {
  workhubSettings?: {
    id?: string;
    /**
     * 利用方法など
     */
    noteJp?: string;
    noteEn?: string;
    /**
     * 対象
     */
    type?: 'spaces' | 'things';
    /**
     * 対象のリレーション
     */
    targetId?: string;
    /**
     * 予約設定へのリレーション
     */
    reservationSettingsId?: string;
    /**
     * キャンセルポリシー
     */
    cancelPolicyJp?: string;
    cancelPolicyEn?: string;
    /**
     * 連絡先
     */
    phoneNumber?: string;
    /**
     * 公開可能かどうか
     * trueであれば公開できない
     */
    private?: boolean;
    /**
     * 参加者以外から見える予約情報(デフォルト値)
     * all: 公開、 owner: 主催者のみ、 none: 非公開
     */
    visibleContentFromOther?: 'none' | 'all' | 'owner';
    /**
     * 予約の何分前から利用可能か
     * e.g. 予約開始時間の5分前から使えるカギを発行
     */
    availableBeforeMinutes?: number;
    /**
     * 予約の何分後まで利用可能か
     * e.g. 予約終了時間の5分後まで使えるカギを発行
     */
    availableAfterMinutes?: number;
    /**
     * 利用できる鍵束のID
     */
    availableACPatterns?: {[K in 'Member' | 'Cast' | 'Visitor' | 'Customer']?: string[]};
    /**
     * メールテンプレートへのリレーション
     *
     * 今後sendableMailSettingsへ移行したい。
     * この設定は今は社外、という大きなくくりの設定で使用している。
     * 今後来訪者や業者など細かくメールテンプレートを持たせる時が移行タイミング
     */
    mailTemplateIds?: string[];
    /**
     * 送信可能なメールテンプレートの種別
     * invitation: 招待, acceptance: 受付, update: 更新, cancel: キャンセル, delete: 削除時, remind: リマインド
     *
     * 今後sendableMailSettingsへ移行したい。
     * この設定は今は社外、という大きなくくりの設定で使用している。
     * 今後来訪者や業者など細かくメールテンプレートを持たせる時が移行タイミング
     */
    sendableMailTemplateTypes?: (
      | 'invitation'
      | 'cancel'
      | 'update'
      | 'remind'
      | 'resend'
      | 'other'
      | 'finishRemind'
      | 'autoExtend'
      | 'remindGuestsToRegisterCompanions'
      | 'equipmentInvitation'
      | 'equipmentCancel'
      | 'equipmentUpdate'
      | 'equipmentRemind'
      | 'lockerInvitation'
      | 'lockerCancel'
      | 'lockerUpdate'
      | 'lockerRemind'
      | 'lockerFinishRemind'
    )[];
    /**
     * メールの送信の設定を種類別（PeopleTypeなど）に持つ
     */
    sendableMailSettings?: {
      [K in 'Member' | 'Cast' | 'Visitor' | 'Customer']?: {
        mailTypeSettings?: {
          [K in
            | 'invitation'
            | 'cancel'
            | 'update'
            | 'remind'
            | 'resend'
            | 'other'
            | 'finishRemind'
            | 'autoExtend'
            | 'remindGuestsToRegisterCompanions'
            | 'equipmentInvitation'
            | 'equipmentCancel'
            | 'equipmentUpdate'
            | 'equipmentRemind'
            | 'lockerInvitation'
            | 'lockerCancel'
            | 'lockerUpdate'
            | 'lockerRemind'
            | 'lockerFinishRemind']?: {
            templateId?: string;
            sendable?: boolean;
          };
        };
        /**
         * 予約開始リマインド
         */
        remindMinutes?: number;
        /**
         * 予約終了リマインド
         */
        finishRemindMinutes?: number;
      };
    };
    /**
     * リマインドメールを何分前(or 何分後)に送るか
     * ここは時間だけを管理。
     * リマインドメールを送るかどうかは「リレーションにIDがあるかつsendableMailTemplateTypesにremindがあること」
     * e.g. 会議室予約開始時間の5分前にリマインドメールを送る
     */
    remindMinutes?: number;
    finishRemindMinutes?: number;
    /**
     * 繰り返し予約を許可するか
     */
    repeatableType?: 'all' | 'none';
    /**
     * 事前ゲスト同行者登録を許可する/許可しない
     */
    isGuestCompanionPreRegisteringEnabled?: boolean;
    /**
     * 事前同行者登録のリマインドメールを何分前に送るか
     */
    reminderMinutesForCompanionRegistration?: number;
  };
  logOption?: {
    /**
     * 取り込み方法、CSV・手動のフォーム入力・API連携など
     */
    trigger?: 'Csv' | 'Manual' | 'ExternalApi';
    /**
     * 自由入力
     */
    memo?: string;
    /**
     * Trueであれば保存しない
     */
    disable?: boolean;
  };
};
export type UpdateReservationSettingWorkhubSettingPathParam = {
  settingId: string;
};
