import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Provider} from 'react-redux';

import {setupStore} from '@/common/redux/store/store';
import {ThemeDefaults} from '@/common/styles/theme';
import DeveloperTools from '@/components/developer-tools/DeveloperTools';
import {simpleSnackbarContainer} from '@/components/layouts/simpleSnackbarContainer';
import NetWorkWatcher from '@/components/network-watcher/NetworkWatcher';

import {Router} from './router';

import '@/common/useDayjs';

const queryClient = new QueryClient();
const store = setupStore();

const developerWatcherProjects = ['bitlock-dev', 'bitlock-staging', 'workhub-local'];

const App = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={ThemeDefaults}>
          <simpleSnackbarContainer.Provider>
            <Router />
            <NetWorkWatcher />
            {developerWatcherProjects.includes(import.meta.env['VITE_FIREBASE_PROJECT_ID'] as string) && (
              <DeveloperTools />
            )}
          </simpleSnackbarContainer.Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
