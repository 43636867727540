import type {AxiosError} from 'axios';

export const axiosCatch = (stackTrace: string) => (error: AxiosError) => {
  let errorMessage = error.message;

  if (error.response) {
    errorMessage = `Request failed with status code ${error.response.status}. ${JSON.stringify(error.response.data)}`;
  }
  // error.message = errorMessage する前に、stack の上書きしないと、書き換えられた message で stack が積まれてしまう
  error.message = errorMessage;
  error.stack = stackTrace + `\nCaused by ${error.stack}`;

  throw error;
};
