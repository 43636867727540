import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import {
  CreateDropInPlanStep,
  DropInPlanDetailTabs,
  DropInPlansScreenState,
} from '@/common/redux/screens/state/dropInPlansScreenState';
import {ValidationUtil} from '@/common/utils/validationUtil';
import {
  SPACE_STRUCTURES_NO_SETTING_ID,
  ConvertedFlatSpaceStructuresType,
} from '@/components/dialog/SelectAreaDialog/spaceStructuresConvert';
import {RequestTypesOrgPaymentMethod} from '@/features/paymentMethod/WSelectPaymentMethod';
import {PayeeType} from '@/wscreens/third-place/invoice-setting/edit/InvoiceSettingPayeeEdit';
import {SenderType} from '@/wscreens/third-place/invoice-setting/edit/InvoiceSettingSenderEdit';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const initialState: DropInPlansScreenState = {
  isDetailScreen: false,
  tabs: {
    createDropInPlanDialog: CreateDropInPlanStep.Start,
    dropInPlanDetailTab: DropInPlanDetailTabs.OverView,
  },
  loading: {deleteLoading: false, publishLoading: false},
  dropInPlanListUI: {
    allCheck: false,
    searchWord: '',
    checkedPlanIds: [],
    viewPlans: [],
    lastUpdateFilter: {},
    publishFilter: [],
    sortBy: {
      key: 'code',
      orderBy: 'asc',
    },
  },
  dialog: {
    bulkDeleteConfirmDialog: {
      isOpen: false,
      isProcessing: false,
    },
    editBasicInfoDialog: {
      isOpen: false,
      code: {validType: 'initial'},
      managementName: {validType: 'initial'},
      isProcessing: false,
    },
    editPricePlanDialog: {
      isOpen: false,
      isProcessing: false,
      viewPricePlans: [],
      allCheckRow: false,
      checkedPricePlanIds: [],
    },
    editPaymentMethodDialog: {
      isOpen: false,
      isProcessing: false,
      selectedPaymentMethods: ['creditCard'],
    },
    editApplicableAreaDialog: {
      isOpen: false,
      isProcessing: false,
      allCheckRow: false,
      checkRowIds: [],
      viewSpaces: [],
      displayAreaType: [],
      selectSpaceStructures: {
        regionIds: [],
        buildingIds: [],
        floorIds: [],
      },
    },
    editPublishDialog: {isOpen: false, isProcessing: false, isOpenConfirmDialog: false},
    deleteDropInPlanDialog: {isOpen: false, isProcessing: false},
    createDropInPlan: {
      registering: false,
      isOpenCreateDialog: false,
      isOpenConfirmDialog: false,
      inputBasicInfoStep: {
        code: {validType: 'initial'},
        managementName: {validType: 'initial'},
      },
      selectAreaStep: {
        allCheckRow: false,
        checkRowIds: [],
        viewSpaces: [],
        displayAreaType: [],
        selectSpaceStructures: {
          regionIds: [],
          buildingIds: [],
          floorIds: [],
        },
      },
      selectPricePlanStep: {
        viewPricePlans: [],
        allCheckRow: false,
        checkedPricePlanIds: [],
      },
      selectPaymentMethodStep: {
        selectedPaymentMethods: ['creditCard'],
      },
    },
  },
  persist: {
    loginUser: {name: '', iconUrl: ''},
    dropInPlans: {data: [], result: 'pending'},
    spaceStructures: [],
    pricePlans: {data: [], result: 'pending'},
    spaces: {data: [], result: 'pending'},
    activePaymentMethod: [],
  },
};

export const dropInPlansScreenSlice = createSlice({
  name: 'dropInPlansScreenSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    resetCreateDialog: state => {
      state.dialog.createDropInPlan = initialState.dialog.createDropInPlan;
      state.tabs.createDropInPlanDialog = initialState.tabs.createDropInPlanDialog;
    },
    updateIsDetailScreen: (state, {payload}: PayloadAction<boolean>) => {
      state.isDetailScreen = payload;
    },
    updateIsOpenCreateDialog: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['dialog']['createDropInPlan']['isOpenCreateDialog']>
    ) => {
      state.dialog.createDropInPlan.isOpenCreateDialog = payload;
      if (payload) {
        state.dialog.createDropInPlan.selectPricePlanStep.viewPricePlans = state.persist.pricePlans.data;
        state.dialog.createDropInPlan.selectAreaStep.viewSpaces = state.persist.spaces.data;
      }
    },
    toggleDeleteDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.deleteDropInPlanDialog.isOpen = payload;
    },
    updateIsOpenPublishConfirmDialog: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['dialog']['createDropInPlan']['isOpenConfirmDialog']>
    ) => {
      state.dialog.editPublishDialog.isOpenConfirmDialog = payload;
    },
    updateRegistering: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.createDropInPlan.registering = payload;
    },
    updateBulkDeleteConfirmDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.bulkDeleteConfirmDialog.isOpen = payload;
    },
    updateBulkDeleteProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.bulkDeleteConfirmDialog.isProcessing = payload;
    },

    updateCreateStep: (state, {payload}: PayloadAction<'goBack' | 'goNext'>) => {
      state.tabs.createDropInPlanDialog = state.tabs.createDropInPlanDialog + (payload === 'goNext' ? 1 : -1);
    },
    setDropInPlanSpaces: (
      state,
      {
        payload,
      }: PayloadAction<{
        result: NonNullable<DropInPlansScreenState['persist']['dropInPlan']>['spaces']['result'];
        convertedSpaces: ConvertedFlatSpaceStructuresType;
      }>
    ) => {
      if (state.persist.dropInPlan) {
        state.persist.dropInPlan.spaces.result = payload.result;
        state.persist.dropInPlan.spaces.data = payload.convertedSpaces.map(space => ({
          id: space.area.id,
          name: space.area.name ?? space.area.public_name ?? '',
          attribute: space.area
            .attributes?.[0] as DropInPlansScreenState['persist']['spaces']['data'][number]['attribute'],
          location: [space.region?.name ?? '-', space.building?.name ?? '-', space.floor?.name ?? '-'].join('/'),
          regionName: space.region?.name === '-' ? undefined : space.region?.name,
          regionId: space.region?.id,
          buildingId: space.building?.id,
          floorId: space.floor?.id,
          capacity: space.area.capacity?.toString() ?? '-',
          isPublicSubscriptionArea: !!space.area.reservable,
          isPublicDropInArea: true, //TODO
          // publishDropInArea: !!space.area.published_temporary_use,
        }));
      }
    },
    initializeDetailScreen: (state, {payload}: PayloadAction<{dropInPlanId: string}>) => {
      const plan = state.persist.dropInPlans.data.find(v => v.id === payload.dropInPlanId);
      if (plan) {
        state.tabs.dropInPlanDetailTab = DropInPlanDetailTabs.OverView;
        state.persist.dropInPlan = {
          id: plan.id,
          code: plan.code,
          name: plan.name,
          isPublished: plan.isPublished,
          paymentMethodTypes: [],
          pricePlans: [],
          spaces: {ids: [], result: 'pending'},
        };
      }
    },
    updateBasicInfoCode: (
      state,
      {
        payload,
      }: PayloadAction<Partial<DropInPlansScreenState['dialog']['createDropInPlan']['inputBasicInfoStep']['code']>>
    ) => {
      const create = state.dialog.createDropInPlan.inputBasicInfoStep;
      const edit = state.dialog.editBasicInfoDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.code = {
        value: payload?.value ?? updateTarget.code.value,
        validType: payload?.validType
          ? payload.validType
          : !payload?.value
            ? 'noInput'
            : ValidationUtil.isValidCode(payload.value)
              ? 'pending'
              : 'invalid',
      };
    },
    updateBasicInfoManagementName: (state, {payload}: PayloadAction<string>) => {
      const value = payload.trim();
      const create = state.dialog.createDropInPlan.inputBasicInfoStep;
      const edit = state.dialog.editBasicInfoDialog;
      const updateTarget = state.isDetailScreen ? edit : create;

      updateTarget.managementName = {
        value: value,
        validType: value ? 'valid' : 'noInput',
      };
    },
    updateAllCheckArea: state => {
      const selectAreaStep = state.dialog.createDropInPlan.selectAreaStep;
      const editApplicableAreaDialog = state.dialog.editApplicableAreaDialog;
      const updateTarget = state.isDetailScreen ? editApplicableAreaDialog : selectAreaStep;

      const currentCheck = updateTarget.allCheckRow;
      updateTarget.allCheckRow = !currentCheck;
      updateTarget.checkRowIds = currentCheck ? [] : state.persist.spaces.data.map(v => v.id);
    },
    updateValidPeriod: (
      state,
      {
        payload,
      }: PayloadAction<
        | {type: 'to' | 'from'; value: number}
        | {
            type: 'clear';
          }
      >
    ) => {
      const selectPricePlanStep = state.dialog.createDropInPlan.selectPricePlanStep;
      const editPricePlanDialog = state.dialog.editPricePlanDialog;
      const updateTarget = state.isDetailScreen ? editPricePlanDialog : selectPricePlanStep;
      switch (payload.type) {
        case 'clear': {
          updateTarget.validFrom = undefined;
          updateTarget.validTo = undefined;
          break;
        }
        case 'from': {
          updateTarget.validFrom = payload.value;
          break;
        }
        case 'to': {
          updateTarget.validTo = payload.value;
          break;
        }
      }
    },
    updateAllCheckPricePlan: state => {
      const selectPricePlanStep = state.dialog.createDropInPlan.selectPricePlanStep;
      const editPricePlanDialog = state.dialog.editPricePlanDialog;
      const updateTarget = state.isDetailScreen ? editPricePlanDialog : selectPricePlanStep;

      // 更新処理
      const currentCheck = updateTarget.allCheckRow;
      updateTarget.allCheckRow = !currentCheck;
      updateTarget.checkedPricePlanIds = currentCheck ? [] : state.persist.pricePlans.data.map(v => v.id);
    },

    updateCheckedArea: (state, {payload}: PayloadAction<{spaceId: string}>) => {
      const selectAreaStep = state.dialog.createDropInPlan.selectAreaStep;
      const editApplicableAreaDialog = state.dialog.editApplicableAreaDialog;
      const updateTarget = state.isDetailScreen ? editApplicableAreaDialog : selectAreaStep;

      const currentIds = updateTarget.checkRowIds;
      updateTarget.checkRowIds = currentIds.includes(payload.spaceId)
        ? currentIds.filter(id => id !== payload.spaceId)
        : [payload.spaceId, ...currentIds];
    },
    updateCheckPricePlan: (state, {payload}: PayloadAction<{id: string}>) => {
      const selectPricePlanStep = state.dialog.createDropInPlan.selectPricePlanStep;
      const editPricePlanDialog = state.dialog.editPricePlanDialog;
      const updateTarget = state.isDetailScreen ? editPricePlanDialog : selectPricePlanStep;

      const currentIds = updateTarget.checkedPricePlanIds;
      updateTarget.checkedPricePlanIds = currentIds.includes(payload.id)
        ? currentIds.filter(id => id !== payload.id)
        : [payload.id, ...currentIds];
    },
    updateSelectSpaceStructures: (
      state,
      {payload}: PayloadAction<{type: 'region' | 'building' | 'floor'; id: string} | {type: 'allSelect' | 'clear'}>
    ) => {
      const selectAreaStep = state.dialog.createDropInPlan.selectAreaStep.selectSpaceStructures;
      const editApplicableAreaDialog = state.dialog.editApplicableAreaDialog.selectSpaceStructures;
      const selectSpaceStructures = state.isDetailScreen ? editApplicableAreaDialog : selectAreaStep;
      switch (payload.type) {
        case 'region': {
          const includesId = selectSpaceStructures.regionIds.includes(payload.id);
          selectSpaceStructures.regionIds = includesId
            ? selectSpaceStructures.regionIds.filter(v => v !== payload.id)
            : [...selectSpaceStructures.regionIds, payload.id];
          break;
        }
        case 'building': {
          const includesId = selectSpaceStructures.buildingIds.includes(payload.id);
          selectSpaceStructures.buildingIds = includesId
            ? selectSpaceStructures.buildingIds.filter(v => v !== payload.id)
            : [...selectSpaceStructures.buildingIds, payload.id];
          break;
        }
        case 'floor': {
          const includesId = selectSpaceStructures.floorIds.includes(payload.id);
          selectSpaceStructures.floorIds = includesId
            ? selectSpaceStructures.floorIds.filter(v => v !== payload.id)
            : [...selectSpaceStructures.floorIds, payload.id];
          break;
        }
        case 'clear': {
          selectSpaceStructures.regionIds = [];
          selectSpaceStructures.buildingIds = [];
          selectSpaceStructures.floorIds = [];
          break;
        }
        case 'allSelect': {
          const masterSpaceStructures = state.persist.spaceStructures;
          selectSpaceStructures.regionIds = masterSpaceStructures.map(v => v.regionId);
          selectSpaceStructures.buildingIds = masterSpaceStructures.flatMap(v => v.buildings.map(b => b.buildingId));
          selectSpaceStructures.floorIds = masterSpaceStructures.flatMap(v =>
            v.buildings.flatMap(b => b.floors.map(f => f.floorId))
          );
          break;
        }
      }
    },
    updateSpaceStructures: (state, {payload}: PayloadAction<DropInPlansScreenState['persist']['spaceStructures']>) => {
      state.persist.spaceStructures = payload;
    },
    allCheckDropInPlanIds: (state, {payload}: PayloadAction<boolean>) => {
      state.dropInPlanListUI.allCheck = payload;
      if (payload) {
        state.dropInPlanListUI.checkedPlanIds = state.dropInPlanListUI.viewPlans.reduce<string[]>(
          (result, current) => (!current.isPublished ? [...result, current.id] : result),
          []
        );
      } else {
        state.dropInPlanListUI.checkedPlanIds = [];
      }
    },
    checkDropInPlanId: (state, {payload}: PayloadAction<string>) => {
      const checkedPlanIds = state.dropInPlanListUI.checkedPlanIds;
      state.dropInPlanListUI.checkedPlanIds = checkedPlanIds.includes(payload)
        ? checkedPlanIds.filter(v => v !== payload)
        : [...checkedPlanIds, payload];
    },
    updatePaymentMethod: (
      state,
      {
        payload,
      }: PayloadAction<
        | {type: 'paymentMethod'; fn: (value: RequestTypesOrgPaymentMethod[]) => RequestTypesOrgPaymentMethod[]}
        | {type: 'payee'; params: PayeeType}
        | {type: 'sender'; params: SenderType}
      >
    ) => {
      const selectPaymentMethodStep = state.dialog.createDropInPlan.selectPaymentMethodStep;
      const editPaymentMethodDialog = state.dialog.editPaymentMethodDialog;
      const updateTarget = state.isDetailScreen ? editPaymentMethodDialog : selectPaymentMethodStep;
      switch (payload.type) {
        case 'paymentMethod': {
          updateTarget.selectedPaymentMethods = payload
            .fn(updateTarget.selectedPaymentMethods.map(v => ({id: '', methodType: v, isMain: false})))
            .map(v => v.methodType);
          break;
        }
        case 'payee': {
          updateTarget.payee = payload.params;
          break;
        }
        case 'sender': {
          updateTarget.sender = payload.params;
          break;
        }
      }
    },
    updateSpaces: (
      state,
      {
        payload,
      }: PayloadAction<{
        convertedSpaces: ConvertedFlatSpaceStructuresType;
        result: DropInPlansScreenState['persist']['spaces']['result'];
      }>
    ) => {
      const data = payload.convertedSpaces.map<DropInPlansScreenState['persist']['spaces']['data'][number]>(space => {
        return {
          id: space.area.id,
          name: space.area.name ?? space.area.public_name ?? '',
          attribute: space.area
            .attributes?.[0] as DropInPlansScreenState['persist']['spaces']['data'][number]['attribute'],
          location: [space.region?.name ?? '-', space.building?.name ?? '-', space.floor?.name ?? '-'].join('/'),
          regionName: space.region?.name === '-' ? undefined : space.region?.name,
          regionId: space.region?.id,
          buildingId: space.building?.id,
          floorId: space.floor?.id,
          capacity: space.area.capacity?.toString() ?? '-',
          isPublicSubscriptionArea: !!space.area.reservable,
          isPublicDropInArea: true, //TODO
        };
      });
      state.dialog.createDropInPlan.selectAreaStep.viewSpaces = data;
      state.dialog.editApplicableAreaDialog.viewSpaces = data;
      state.persist.spaces.data = data;
      state.persist.spaces.result = payload.result;
    },
    updateDropInPlans: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: DropInPlansScreenState['persist']['dropInPlans']['data'];
        result: DropInPlansScreenState['persist']['dropInPlans']['result'];
        type: 'create' | 'initial';
      }>
    ) => {
      if (payload.type === 'initial') {
        state.persist.dropInPlans.data = payload.data;
        state.dropInPlanListUI.viewPlans = payload.data;
        state.persist.dropInPlans.result = payload.result;
      } else {
        state.persist.dropInPlans.data = [...payload.data, ...state.persist.dropInPlans.data];
        state.dropInPlanListUI.viewPlans = [...payload.data, ...state.dropInPlanListUI.viewPlans];
      }
    },
    sortViewDropInPlans: (state, {payload}: PayloadAction<{orderBy: 'asc' | 'desc'}>) => {
      const dropInPlanListUIInstance = state.dropInPlanListUI;
      const viewPlans = dropInPlanListUIInstance.viewPlans;
      const currentSortBy = dropInPlanListUIInstance.sortBy;
      const isAsc = payload.orderBy === 'asc';
      dropInPlanListUIInstance.sortBy.orderBy = payload.orderBy;
      dropInPlanListUIInstance.viewPlans = viewPlans.sort((a, b) =>
        a[currentSortBy.key] > b[currentSortBy.key] ? (isAsc ? 1 : -1) : isAsc ? -1 : 1
      );
      state.persist.dropInPlans.data = state.persist.dropInPlans.data.sort((a, b) =>
        a[currentSortBy.key] > b[currentSortBy.key] ? (isAsc ? 1 : -1) : isAsc ? -1 : 1
      );
    },
    updateLastUpdateFilter: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['dropInPlanListUI']['lastUpdateFilter']>
    ) => {
      const instance = state.dropInPlanListUI.lastUpdateFilter;
      state.dropInPlanListUI.lastUpdateFilter = {
        from: payload.from ?? instance.from,
        to: payload.to ?? instance.to,
      };
    },
    filterViewDropInPlans: (
      state,
      {
        payload,
      }: PayloadAction<{
        searchWord?: string;
        allClear?: boolean;
        lastUpdateFilter?: 'clear' | 'search';
        publishedStatus?: ('publish' | 'private')[];
      }>
    ) => {
      const dropInPlanListUIInstance = state.dropInPlanListUI;
      const persistDropInPlans = state.persist.dropInPlans.data;

      if (payload.allClear) {
        state.dropInPlanListUI = {
          ...initialState.dropInPlanListUI,
          viewPlans: persistDropInPlans,
        };
      } else {
        const targetSearchWord = payload.searchWord ?? dropInPlanListUIInstance.searchWord;
        const targetSearchPublishStatus = payload.publishedStatus ?? dropInPlanListUIInstance.publishFilter;
        const targetLastUpdatedAt =
          payload.lastUpdateFilter === 'clear'
            ? {to: undefined, from: undefined}
            : dropInPlanListUIInstance.lastUpdateFilter;

        const filteredPlans = persistDropInPlans.filter(plan => {
          // 文字列フィルター
          const isMatchSearchWord =
            !targetSearchWord ||
            plan.code.toLowerCase().includes(targetSearchWord.toLowerCase()) ||
            plan.name.toLowerCase().includes(targetSearchWord.toLowerCase());

          // 最終更新日フィルター
          const isMatchLastUpdatedAt = !targetLastUpdatedAt
            ? true
            : (!targetLastUpdatedAt.from || plan.lastUpdatedAt >= targetLastUpdatedAt.from) &&
              (!targetLastUpdatedAt.to || plan.lastUpdatedAt <= targetLastUpdatedAt.to);

          // 公開状態フィルター
          const currentPublishStatus = plan.isPublished ? 'publish' : 'private';
          const isMatchPublishStatus =
            !targetSearchPublishStatus.length || targetSearchPublishStatus.includes(currentPublishStatus);

          return isMatchSearchWord && isMatchLastUpdatedAt && isMatchPublishStatus;
        });

        dropInPlanListUIInstance.viewPlans = filteredPlans;
        dropInPlanListUIInstance.lastUpdateFilter = targetLastUpdatedAt ?? {to: undefined, from: undefined};
        dropInPlanListUIInstance.searchWord = targetSearchWord;
        dropInPlanListUIInstance.publishFilter = targetSearchPublishStatus;
      }
    },
    updateDBPricePlans: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: DropInPlansScreenState['persist']['pricePlans']['data'];
        result: DropInPlansScreenState['persist']['pricePlans']['result'];
      }>
    ) => {
      state.dialog.createDropInPlan.selectPricePlanStep.viewPricePlans = payload.data;
      state.dialog.editPricePlanDialog.viewPricePlans = payload.data;
      state.persist.pricePlans.data = payload.data;
      state.persist.pricePlans.result = payload.result;
    },
    updateDBActivePaymentMethod: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['persist']['activePaymentMethod']>
    ) => {
      state.persist.activePaymentMethod = payload;
    },
    filterViewSpaces: (
      state,
      {
        payload,
      }: PayloadAction<{
        searchWord?: string;
        filterSpaceStructures?: boolean;
        displayAreaType?: DropInPlansScreenState['dialog']['createDropInPlan']['selectAreaStep']['displayAreaType'];
      }>
    ) => {
      const selectAreaStep = state.dialog.createDropInPlan.selectAreaStep;
      const editApplicableAreaDialog = state.dialog.editApplicableAreaDialog;

      const selectAreaStepInstance = state.isDetailScreen ? editApplicableAreaDialog : selectAreaStep;

      const masterSpaceData = state.persist.spaces.data;
      const masterSpaceStructures = state.persist.spaceStructures;
      const targetSearchWord =
        typeof payload.searchWord === 'undefined' ? selectAreaStepInstance.searchWord : payload.searchWord;
      const targetDisplayAreaType = payload.displayAreaType ?? selectAreaStepInstance.displayAreaType;

      const regionIds = selectAreaStepInstance.selectSpaceStructures.regionIds;
      const buildingIds = selectAreaStepInstance.selectSpaceStructures.buildingIds;
      const floorIds = selectAreaStepInstance.selectSpaceStructures.floorIds;

      // 以下stateの更新処理
      selectAreaStepInstance.searchWord = targetSearchWord;
      selectAreaStepInstance.displayAreaType = targetDisplayAreaType;

      if (targetSearchWord || regionIds.length || targetDisplayAreaType.length) {
        selectAreaStepInstance.viewSpaces = masterSpaceData.filter(v => {
          const isHitTextSearch = !targetSearchWord || v.name.includes(targetSearchWord);
          const isHitDisplayAreaType = targetDisplayAreaType.length
            ? targetDisplayAreaType.includes(v.attribute)
            : true;

          const isHitSelectSpaceStructures = (() => {
            // 拠点選択無しの場合
            if (!regionIds.length) return true;
            if (!regionIds.includes(v.regionId ?? SPACE_STRUCTURES_NO_SETTING_ID)) return false;

            // ビル選択無しの場合
            if (!buildingIds.length || !buildingIds.includes(v.buildingId ?? SPACE_STRUCTURES_NO_SETTING_ID))
              return true;

            // フロア選択無しの場合
            if (!floorIds.length) return true;

            const currentBuilding = masterSpaceStructures
              .find(s => s.regionId === (v.regionId ?? SPACE_STRUCTURES_NO_SETTING_ID))
              ?.buildings.find(b => b.buildingId === (v.buildingId ?? SPACE_STRUCTURES_NO_SETTING_ID));

            if (currentBuilding?.floors.some(f => floorIds.includes(f.floorId))) {
              if (!v.floorId) return false;
              return floorIds.includes(v.floorId);
            } else {
              // ビルに紐づくフロアが選択されていない
              return true;
            }
          })();

          return isHitTextSearch && isHitSelectSpaceStructures && isHitDisplayAreaType;
        });
      } else {
        selectAreaStepInstance.viewSpaces = masterSpaceData;
      }
    },
    /** 料金選択時のフィルター用 */
    filterViewPricePlans: (state, {payload}: PayloadAction<{searchWord?: string; searchPeriod?: boolean}>) => {
      const selectPricePlanStep = state.dialog.createDropInPlan.selectPricePlanStep;
      const editPricePlanDialog = state.dialog.editPricePlanDialog;

      const masterPricePlan = state.persist.pricePlans.data;

      const selectPricePlanStepInstance = state.isDetailScreen ? editPricePlanDialog : selectPricePlanStep;

      const targetSearchWord =
        typeof payload.searchWord === 'undefined' ? selectPricePlanStepInstance.searchWord : payload.searchWord;

      const validFrom = selectPricePlanStepInstance.validFrom;
      const validTo = selectPricePlanStepInstance.validTo;
      if (targetSearchWord || validFrom || validTo) {
        const filteringValueFrom = dayjs(validFrom).startOf('day');
        const filteringValueTo = dayjs(validTo).endOf('day');
        selectPricePlanStepInstance.viewPricePlans = masterPricePlan.filter(v => {
          // 文言検索
          const searchWord = targetSearchWord?.trim().toLocaleLowerCase();
          const isHitTextSearch = !searchWord
            ? true
            : v.publicName.toLocaleLowerCase().includes(searchWord) ||
              v.name.toLocaleLowerCase().includes(searchWord) ||
              v.code.toLocaleLowerCase().includes(searchWord);

          // 利用可能期間で絞る
          const isBetweenFilteringPeriod: boolean = (() => {
            const availableFrom = dayjs(v.validFrom);
            const availableTo = v.validTo ? dayjs(v.validTo) : undefined;
            return (
              (!validFrom || availableFrom.isSameOrAfter(filteringValueFrom)) &&
              (!validTo || (!!availableTo && availableTo.isSameOrBefore(filteringValueTo)))
            );
          })();

          return isHitTextSearch && isBetweenFilteringPeriod;
        });
      } else {
        selectPricePlanStepInstance.viewPricePlans = masterPricePlan;
      }
      selectPricePlanStepInstance.searchWord = targetSearchWord;
    },

    updateDropInPlanDetailTab: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['tabs']['dropInPlanDetailTab']>
    ) => {
      state.tabs.dropInPlanDetailTab = payload;
    },
    updateEditBasicInfoDialog: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['dialog']['editBasicInfoDialog']['isOpen']>
    ) => {
      state.dialog.editBasicInfoDialog.isOpen = payload;
      if (payload) {
        // 初期値のセット
        state.dialog.editBasicInfoDialog.code = {value: state.persist.dropInPlan?.code, validType: 'valid'};
        state.dialog.editBasicInfoDialog.managementName = {
          value: state.persist.dropInPlan?.name,
          validType: 'valid',
        };
      } else {
        // 初期化
        state.dialog.editBasicInfoDialog = initialState.dialog.editBasicInfoDialog;
      }
    },
    updateEditPricePlanDialog: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['dialog']['editPricePlanDialog']['isOpen']>
    ) => {
      const editPricePlanDialog = state.dialog.editPricePlanDialog;
      if (payload) {
        editPricePlanDialog.searchWord = undefined;
        editPricePlanDialog.validFrom = undefined;
        editPricePlanDialog.validTo = undefined;
        editPricePlanDialog.allCheckRow = false;
        editPricePlanDialog.checkedPricePlanIds = state.persist.dropInPlan?.pricePlans.map(v => v.id) ?? [];
        editPricePlanDialog.viewPricePlans = state.persist.pricePlans.data;
      }
      editPricePlanDialog.isOpen = payload;
    },
    openCreateConfirmDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.createDropInPlan.isOpenConfirmDialog = payload;
    },
    updateEditPaymentMethodDialog: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['dialog']['editPaymentMethodDialog']['isOpen']>
    ) => {
      const editPaymentMethodDialogInstance = state.dialog.editPaymentMethodDialog;
      const dropInPlanInstance = state.persist.dropInPlan;
      editPaymentMethodDialogInstance.isOpen = payload;
      if (payload) {
        editPaymentMethodDialogInstance.selectedPaymentMethods = dropInPlanInstance?.paymentMethodTypes ?? [];
        editPaymentMethodDialogInstance.payee = dropInPlanInstance?.payee;
        editPaymentMethodDialogInstance.sender = dropInPlanInstance?.sender;
      }
    },
    updateEditApplicableAreaDialog: (
      state,
      {payload}: PayloadAction<DropInPlansScreenState['dialog']['editApplicableAreaDialog']['isOpen']>
    ) => {
      if (payload) {
        state.dialog.editApplicableAreaDialog = {
          ...initialState.dialog.editApplicableAreaDialog,
          isOpen: true,
          checkRowIds: state.persist.dropInPlan?.spaces.ids ?? [],
          viewSpaces: state.persist.spaces.data,
        };
      } else {
        state.dialog.editApplicableAreaDialog.isOpen = false;
      }
    },
    updateEditPublishDialogIsLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editPublishDialog.isProcessing = payload;
    },
    updateEditBasicInfoProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editBasicInfoDialog.isProcessing = payload;
    },
    updateEditPricePlanProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editPricePlanDialog.isProcessing = payload;
    },
    updateEditApplicableAreaProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editApplicableAreaDialog.isProcessing = payload;
    },
    updateEditPaymentMethodProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editPaymentMethodDialog.isProcessing = payload;
    },
    updateDeleteLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.loading.deleteLoading = payload;
    },
    updatePublishLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.loading.publishLoading = payload;
    },
    deleteDropInPlan: (state, {payload}: PayloadAction<{ids: string[]}>) => {
      const data = state.persist.dropInPlans.data.filter(v => !payload.ids.includes(v.id));
      state.persist.dropInPlans.data = data;
      state.dropInPlanListUI.viewPlans = data;
      state.dropInPlanListUI.allCheck = false;
      state.dropInPlanListUI.checkedPlanIds = [];
    },
    // 更新処理をした時に呼び出す
    setPersistDropInPlan: (
      state,
      {payload}: PayloadAction<Partial<DropInPlansScreenState['persist']['dropInPlan']>>
    ) => {
      const current = state.persist.dropInPlan;
      if (!current) return state;
      const setData = {
        ...current,
        code: payload?.code ?? current.code,
        name: payload?.name ?? current.name,
        isPublished: payload?.isPublished ?? current.isPublished,
        lastUpdatedBy: {
          peopleId: '',
          name: state.persist.loginUser.name,
          imageUrl: state.persist.loginUser.iconUrl,
          timestamp: dayjs().valueOf(),
        },
        paymentMethodTypes: payload?.paymentMethodTypes ?? current.paymentMethodTypes,
        sender: payload?.sender ?? current.sender,
        payee: payload?.payee ?? current.payee,
        pricePlans: payload?.pricePlans ?? current.pricePlans,
        spaces: payload?.spaces ?? current.spaces,
      };
      state.persist.dropInPlan = setData;
      state.persist.dropInPlans.data = convertDropInPlan(state.persist.dropInPlans.data, setData);
      state.dropInPlanListUI.viewPlans = convertDropInPlan(state.dropInPlanListUI.viewPlans, setData);
    },
    initializeDropInPlanDetailScreen: (
      state,
      {
        payload,
      }: PayloadAction<{
        dropInPlan: DropInPlansScreenState['persist']['dropInPlan'];
        loginUser: DropInPlansScreenState['persist']['loginUser'];
      }>
    ) => {
      state.isDetailScreen = true;
      state.persist.dropInPlan = payload.dropInPlan;
      state.persist.loginUser = payload.loginUser;
    },
  },
});

const convertDropInPlan = (
  data: DropInPlansScreenState['persist']['dropInPlans']['data'],
  setData: NonNullable<DropInPlansScreenState['persist']['dropInPlan']>
) => {
  const index = data.findIndex(v => v.id === setData.id);
  if (index === -1) return data;
  data[index] = {
    ...data[index],
    code: setData.code,
    name: setData.name,
    isPublished: setData.isPublished,
    lastUpdatedAt: setData.lastUpdatedBy?.timestamp ?? 0,
  };
  return data;
};
