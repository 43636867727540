import {
  ApiTypesCreateOrgChargeDetailLabelPostRequest,
  ApiTypesCreateOrgChargeDetailLabelPostResponse,
  ApiTypesDeleteOrgChargeDetailLabelPostResponse,
  ApiTypesGetOrgChargeDetailLabelResponse,
  ApiTypesUpdateOrgChargeDetailLabelPatchRequest,
  ApiTypesUpdateOrgChargeDetailLabelPatchResponse,
} from '@bitkey-service/v2_core-types/lib/api/organization/charge-detail-label/apiTypesOrgChargeDetailLabel';
import {AxiosRequestConfig} from 'axios';

import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiCoreOrg} from '../apiCoreOrg';

const basePath = 'charge-detail-label';

export class ApiChargeDetailLabel {
  private static get = <Response>(path: string) => ApiCoreOrg.get<Response>(FirebaseFunctions.makePath(basePath, path));
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCoreOrg.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiCoreOrg.delete<Response>(FirebaseFunctions.makePath(basePath, path), config);

  public static getAll = () => ApiChargeDetailLabel.get<ApiTypesGetOrgChargeDetailLabelResponse>('/');
  public static add = (data: ApiTypesCreateOrgChargeDetailLabelPostRequest) =>
    ApiChargeDetailLabel.post<
      ApiTypesCreateOrgChargeDetailLabelPostResponse,
      ApiTypesCreateOrgChargeDetailLabelPostRequest
    >('/', data);
  public static update = (data: ApiTypesUpdateOrgChargeDetailLabelPatchRequest) =>
    ApiChargeDetailLabel.patch<
      ApiTypesUpdateOrgChargeDetailLabelPatchResponse,
      ApiTypesUpdateOrgChargeDetailLabelPatchRequest
    >(`/${data.id}`, data);
  public static deleteById = (id: string) =>
    ApiChargeDetailLabel.delete<ApiTypesDeleteOrgChargeDetailLabelPostResponse>(`/${id}`);
}
