// Code generated by "generate-icons.mjs". DO NOT EDIT.
// noinspection JSUnusedGlobalSymbols

import {createIcon} from '../utils.js';

export const InsertImage = createIcon('InsertImage', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2M8.9 13.98l2.1 2.53 3.1-3.99c.2-.26.6-.26.8.01l3.51 4.68a.5.5 0 0 1-.4.8H6.02c-.42 0-.65-.48-.39-.81L8.12 14c.19-.26.57-.27.78-.02'
    />
  </svg>
));

export const EmojiEmotions = createIcon('EmojiEmotions', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <g fill='currentColor'>
      <path d='M10 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M17 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M12 18c1.844 0 3.465-1.085 4.43-2.738.345-.589-.135-1.262-.817-1.262H8.387c-.682 0-1.161.673-.817 1.262C8.535 16.915 10.157 18 12 18' />
      <path
        fillRule='evenodd'
        d='M2 12C2 6.48 6.47 2 11.99 2 17.52 2 22 6.48 22 12s-4.48 10-10.01 10C6.47 22 2 17.52 2 12m2 0c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8-8 3.58-8 8'
        clipRule='evenodd'
      />
    </g>
  </svg>
));

export const NoImage = createIcon('NoImage', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='m21.713 20.32-.765-.765-2.365-2.365-4.503-4.503-9.636-9.635-.765-.765a.977.977 0 0 0-1.383 0 .97.97 0 0 0-.01 1.383l1.58 1.58V18.17c0 1.08.883 1.962 1.962 1.962H18.75l1.58 1.58a.977.977 0 0 0 1.383 0 .99.99 0 0 0 0-1.393M6.829 17.19a.489.489 0 0 1-.383-.795l2.443-3.14a.49.49 0 0 1 .765-.009l2.06 2.482 1.149-1.481 2.943 2.943zm14.698.167L6.642 2.473h12.922c1.08 0 1.963.883 1.963 1.963z'
    />
  </svg>
));
