import {createFileRoute, Outlet} from '@tanstack/react-router';

import {Authorize} from '@/routing/Authorize';

export const Route = createFileRoute('/_authorized/nfc-cards')({
  component: () => (
    <Authorize featureGroup='V2SecurityCards' feature='v2SecurityCards'>
      <Outlet />
    </Authorize>
  ),
});
