import {NestedPartial} from '@bitkey-service/workhub-functions-common-types/lib/common/typeUtils';
import {createSlice} from '@reduxjs/toolkit';

import {AnnouncementState} from '../state-types/announcementState';

const initialState: AnnouncementState[] = [];

const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    set: (state, action: {payload: NestedPartial<AnnouncementState[]>}) => [...state, ...action.payload],
    clear: () => initialState,
  },
});

export default announcementSlice;
