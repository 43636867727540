/**
 * 環境情報を管理するファイル
 * サーバー側とは情報が異なるためserviceのenvを持ってくるのは禁止
 * 各クライアント間では共有してOK
 */
import Logger, {LogLevel} from '../logger/logger';

const initLogger = () => {
  if (import.meta.env.VITE_LOG_LEVEL) {
    const logLevel = LogLevel[import.meta.env.VITE_LOG_LEVEL];
    if (!logLevel) {
      throw new Error('log level must be one of LogLevel.');
    }
    Logger.setLogLevel(logLevel as unknown as LogLevel);
  } else {
    throw new Error('log level must be specified.');
  }
};

const checkEnv = () => {
  if (!Env.getApiHost()) {
    throw new Error('api host required.');
  }
  // 今これをやるとProdの環境情報がなく落ちるはずなのでコメントアウト
  // if (!Env.getApiHostSecureStorage()) {
  //   throw new Error('secure storage api host required.');
  // }
  if (import.meta.env.VITE_LOCAL_API && !Env.getApiHostLocal()) {
    throw new Error('api host for local required.');
  }
};

export default class Env {
  // TODO 環境情報全部.envに移動
  public static init = () => {
    checkEnv();
    initLogger();
  };

  public static getGCPProjectId = () => import.meta.env.VITE_FIREBASE_PROJECT_ID as string;
  public static getApiHost = () => import.meta.env.VITE_API_HOST_LOAD_BALANCER as string;
  public static getApiHostLocal = () => import.meta.env.VITE_API_HOST_LOCAL as string;

  /**
   * firebase serveしているローカル環境を使う場合。
   * npm start -- --local common
   * みたいに指定するとそこだけ選択的にローカルサーバを使用する。
   * カンマ区切りで複数指定も考えたけど、ポート指定がめんどくさいのと、そもそも各APIサーバは独立しているべきなので、
   * 複数ローカルで動作確認しないといけないシーンがよくないためこれで。
   */
  public static getLocalApi = () => import.meta.env.VITE_LOCAL_API;

  /**
   * 公開ドキュメント用バケットURL
   */
  public static getDocsStorageBucketUrl = () => 'gs://' + import.meta.env.VITE_FIREBASE_DOCS_STORAGE_BUCKET;

  /**
   * 公開ドキュメントのホスト名。バケット名と同一。
   */
  public static getDocsStorageHost = () => 'https://' + import.meta.env.VITE_FIREBASE_DOCS_STORAGE_BUCKET;
}
