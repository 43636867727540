import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import type {Unsubscribe} from 'firebase/auth';
import {getFunctions, httpsCallable, type HttpsCallableOptions} from 'firebase/functions';

import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';

import {setAccessToken} from '../../accessToken';
import Env from '../../env/env';
import Logger from '../../logger/logger';
import FirebaseAuth, {getFirebaseAuth} from '../firebase-auth';

const createAxios = (baseURL?: string) => {
  if (!baseURL) {
    throw new Error('baseURL required.');
  }
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const logger = Logger.create('functions');
const onFulfilled = (res: AxiosResponse) => {
  logger.trace(res.request?.responseURL, res);
  return res;
};
const onRejected = (e: AxiosError) => {
  const errRes = (e as AxiosError<{code: number}>)?.response;
  if (errRes?.data && errRes.data.code === 401 && FirebaseFunctions.logoutByExpired) {
    logger.info(`failed to authenticate. logouting...`, {res: errRes}, e);
    setTimeout(() => {
      // 一応次のキューに詰んで最低限state更新後に実行されるようにする
      FirebaseFunctions.logoutByExpired?.();
    });
  } else {
    logger.error(`failed to ${e.response?.config.method} ${e.response?.config.url}`, e);
  }
  throw e;
};

export class FirebaseFunctions {
  private static axios: AxiosInstance;
  private static axiosLocal: AxiosInstance;
  private static axiosLoadBalancer: AxiosInstance;

  private static init = () => {
    FirebaseFunctions.axios = createAxios(import.meta.env.VITE_API_HOST);
    FirebaseFunctions.axios.interceptors.response.use(onFulfilled, onRejected);
  };

  private static initLocal = () => {
    FirebaseFunctions.axiosLocal = createAxios(import.meta.env.VITE_API_HOST_LOCAL);
    FirebaseFunctions.axiosLocal.interceptors.response.use(onFulfilled, onRejected);
  };

  private static initLoadBalancer = () => {
    FirebaseFunctions.axiosLoadBalancer = createAxios(import.meta.env.VITE_API_HOST_LOAD_BALANCER);
    FirebaseFunctions.axiosLoadBalancer.interceptors.response.use(onFulfilled, onRejected);
  };

  public static initIfNot = () => {
    if (!FirebaseFunctions.axios) {
      FirebaseFunctions.init();
    }
    if (!FirebaseFunctions.axiosLoadBalancer) {
      FirebaseFunctions.initLoadBalancer();
    }
    if (!FirebaseFunctions.axiosLocal) {
      if (Env.getLocalApi()) {
        FirebaseFunctions.initLocal();
      }
    }
  };

  public static setAccessToken = (token: string) => {
    FirebaseFunctions.initIfNot();
    FirebaseFunctions.axios.defaults.headers['X-Api-Key'] = token;
    FirebaseFunctions.axiosLoadBalancer.defaults.headers['X-Api-Key'] = token;
    if (FirebaseFunctions.axiosLocal) {
      FirebaseFunctions.axiosLocal.defaults.headers['X-Api-Key'] = token;
    }
    setAccessToken(token);
  };

  public static makePath = (a: string, b: string) => {
    if (!a.startsWith('/')) {
      a = '/' + a;
    }
    if (!b.startsWith('/')) {
      b = '/' + b;
    }
    return a + b;
  };

  // token期限切れの場合にログイン画面に戻してsnackbar出すための関数
  public static logoutByExpired?: () => void;
  public static setLogoutByExpired = (logoutByExpired: () => void) =>
    (FirebaseFunctions.logoutByExpired = logoutByExpired);

  private static setIdToken = async () => {
    const idToken = await FirebaseAuth.createInstance().getIdToken();
    if (idToken) {
      FirebaseFunctions.axios.defaults.headers['Authorization'] = `Bearer ${idToken}`;
    } else {
      delete FirebaseFunctions.axios.defaults.headers['Authorization'];
    }
  };

  public static post = async <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    await FirebaseFunctions.setIdToken();
    const res = await FirebaseFunctions.axios.post<Response>(path, data, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
  public static patch = async <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    await FirebaseFunctions.setIdToken();
    const res = await FirebaseFunctions.axios.patch<Response>(path, data, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static patchLocal = async <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    await FirebaseFunctions.setIdToken();
    const res = await FirebaseFunctions.axiosLocal
      .patch<Response>(path, data, config)
      .catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static postLocal = async <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    await FirebaseFunctions.setIdToken();
    const res = await FirebaseFunctions.axiosLocal
      .post<Response>(path, data, config)
      .catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static patchLoadBalancer = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ) => {
    FirebaseFunctions.initIfNot();
    const res = await FirebaseFunctions.axiosLoadBalancer
      .patch<Response>(path, data, config)
      .catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static postLoadBalancer = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ) => {
    FirebaseFunctions.initIfNot();
    const res = await FirebaseFunctions.axiosLoadBalancer
      .post<Response>(path, data, config)
      .catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static get = async <Response>(path: string, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    await FirebaseFunctions.setIdToken();
    const res = await FirebaseFunctions.axios.get<Response>(path, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static getLocal = async <Response>(path: string, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    await FirebaseFunctions.setIdToken();
    const res = await FirebaseFunctions.axiosLocal.get<Response>(path, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static getLoadBalancer = async <Response>(path: string, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    const res = await FirebaseFunctions.axiosLoadBalancer
      .get<Response>(path, config)
      .catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static delete = async <Response>(path: string, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    await FirebaseFunctions.setIdToken();
    const res = await FirebaseFunctions.axios.delete<Response>(path, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static deleteLocal = async <Response>(path: string, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    await FirebaseFunctions.setIdToken();
    const res = await FirebaseFunctions.axiosLocal.delete<Response>(path, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  public static deleteLoadBalancer = async <Response>(path: string, config?: AxiosRequestConfig) => {
    FirebaseFunctions.initIfNot();
    const res = await FirebaseFunctions.axiosLoadBalancer
      .delete<Response>(path, config)
      .catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  private static defaultApiVersion = 'v1';
  private static checkLogin = () => {
    let unsubscribe: Unsubscribe;
    return new Promise<void>((resolve, reject) => {
      const waitTimer = setTimeout(() => {
        if (unsubscribe) {
          unsubscribe();
        }
        reject('timeout');
      }, 10000);
      unsubscribe = getFirebaseAuth().onAuthStateChanged(user => {
        if (user && user.uid) {
          if (unsubscribe) {
            unsubscribe();
          }
          clearTimeout(waitTimer);
          resolve();
        }
      });
    });
  };
  public static call = async <Req, Res = void>(apiName: string, data?: Req, options?: HttpsCallableOptions) => {
    await FirebaseFunctions.checkLogin().catch(e => {
      logger.warn('failed to check login', e);
      throw new Error('unauthorized.');
    });
    if (apiName.startsWith('callable-')) {
      throw new Error('apiName must not starts with "callable-"');
    }
    if (!/^v[0-9]+-/.exec(apiName)) {
      apiName = FirebaseFunctions.defaultApiVersion + '-' + apiName;
    }
    apiName = 'callable-' + apiName;
    if (!options) {
      options = {};
    }
    if (!options.timeout) {
      options.timeout = 540000;
    }
    const res = await httpsCallable<Req, Res>(
      getFunctions(),
      apiName,
      options
    )(data).catch(e => {
      throw e;
    });

    return res.data as Res;
  };
}
