import {css} from '@emotion/react';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiFormGroup from '@mui/material/FormGroup';
import {SwitchBaseProps} from '@mui/material/internal/SwitchBase';
import React, {type MouseEvent, type MutableRefObject, type ReactNode, useCallback, useMemo} from 'react';
import {withStyles} from 'tss-react/mui';

import {useEnableTooltipOnlyEllipsis} from '@/common/hooks/useEnableTooltipOnlyEllipsis';
import {useScrollFocusOnVisibleToolTip} from '@/common/hooks/useScrollFocusOnVisibleToolTip';
import {WHColor} from '@/common/styles/whColor';
import Tooltip from '@/components/tooltip/Tooltip';

const IconTextWrapper = styled.span`
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.span`
  margin: 4px 6px 0 0;
  vertical-align: middle;
`;

const FormGroup = styled(MuiFormGroup)`
  display: inherit;
  flex-wrap: inherit;
  flex-direction: row;
  align-items: center;
`;
const EllipsisLabelWrapper = styled.div<{maxWidth?: string; ellipsislineClamp?: number}>`
  overflow: hidden;
  height: 100%;
  text-align: left;
  text-overflow: ellipsis;
  ${({maxWidth}) => maxWidth && `max-width: ${maxWidth};`}
  ${({ellipsislineClamp}) =>
    ellipsislineClamp
      ? css`
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${ellipsislineClamp};
        `
      : css`
          white-space: nowrap;
        `}
`;
const SubText = styled.div`
  margin-bottom: 8px;
  line-height: 1.5;
  font-size: 12px;
  color: ${WHColor.text.neutralPrimary};
`;

const FormControlLabel = withStyles(MuiFormControlLabel, {
  root: {
    height: '100%',
  },
  label: {
    height: '100%',
  },
});

export const LabelText = styled.span<{labelWordBrake?: boolean; disabled?: boolean}>`
  word-break: ${props => (props.labelWordBrake ? 'break-all' : undefined)};
  text-align: left;
  color: ${props => (props.disabled ? WHColor.text.neutralDisabled : WHColor.text.neutralPrimary)};
  vertical-align: middle;
  display: inline-block;
  width: 100%;
`;

export type WCheckboxProps = {
  label?: string;
  subText?: string;
  icon?: ReactNode;
  checked?: boolean; // 初期値指定する場合や、確実にレンダリングと状態を連動させたい場合に使用。指定しなくてもonChangeは使えるし、そのほうが動作は軽い。
  defaultChecked?: boolean; //初期値指定する場合に利用。
  onChange?: SwitchBaseProps['onChange'];
  indeterminate?: boolean;
  disabled?: boolean;

  // WFacetSearchでチェックボックスをクリックしたときにe.stopPropagationを呼ぶと
  // facetが閉じてしまうため、回避する。
  notStopPropagation?: boolean;
  value?: string;
  checkBoxSize?: 'small' | 'medium';
  labelFontSize?: number;
  disabledCheck?: boolean;
  checkBoxIcon?: ReactNode;
  checkBoxCheckedIcon?: ReactNode;
  ellipsisWidth?: string; // labelをツールチップで省略表示するフラグ
  disableTooltip?: boolean;
  labelWordBrake?: boolean;
  ellipsislineClamp?: number;
  ellipsisRefTo?: 'wrapper' | 'label';
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
};

/**
 * @deprecated @workhub/ui の Checkbox を利用してください
 */
export const WCheckbox = React.memo(function WCheckbox(props: WCheckboxProps) {
  // 既存の動作を担保するためにデフォルトはwrapper
  // labelに対してrefを適用しないとツールチップは表示されない場面があるためellipsisRefToを追加
  // ref: https://bit-key.atlassian.net/browse/WWA-13978
  const {ellipsisRefTo = 'wrapper'} = props;
  const stopPropagation = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!props.notStopPropagation) {
        e.stopPropagation();
      }
    },
    [props.notStopPropagation]
  );

  const {ref, disableTooltip} = useEnableTooltipOnlyEllipsis();
  const {isFocused, handleFocus, handleBlur} = useScrollFocusOnVisibleToolTip({
    scrollContainerRef: props.scrollContainerRef,
  });

  const checkBoxStyle = useMemo(
    () =>
      props.subText
        ? {
            paddingTop: '0',
          }
        : {},
    [props.subText]
  );

  const currentDisableToolTip = useMemo(() => {
    return typeof props.disableTooltip === 'undefined' ? disableTooltip : props.disableTooltip;
  }, [disableTooltip, props.disableTooltip]);

  const toolTipOpen = useMemo(() => {
    return !currentDisableToolTip && isFocused;
  }, [currentDisableToolTip, isFocused]);

  return (
    <FormGroup>
      <Tooltip title={props.label ?? ''} open={toolTipOpen} disableHoverListener={currentDisableToolTip}>
        <FormControlLabel
          onMouseEnter={handleFocus}
          onMouseLeave={handleBlur}
          sx={{
            marginRight: 0,
            fontSize: props.labelFontSize ? props.labelFontSize : undefined,
          }}
          label={
            props.ellipsisWidth || props.ellipsislineClamp ? (
              <EllipsisLabelWrapper
                maxWidth={props.ellipsisWidth}
                ellipsislineClamp={props.ellipsislineClamp}
                ref={ellipsisRefTo === 'wrapper' ? ref : undefined}
              >
                <IconTextWrapper>
                  {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
                  <LabelText ref={ellipsisRefTo === 'label' ? ref : undefined} disabled={props.disabled}>
                    {props.label}
                  </LabelText>
                </IconTextWrapper>
                {props.subText && <SubText>{props.subText}</SubText>}
              </EllipsisLabelWrapper>
            ) : props.icon ? (
              <IconTextWrapper>
                <IconWrapper>{props.icon}</IconWrapper>
                <div>{props.label}</div>
              </IconTextWrapper>
            ) : (
              <LabelText labelWordBrake={props.labelWordBrake} disabled={props.disabled}>
                {props.label}
              </LabelText>
            )
          }
          disabled={props.disabled}
          data-testid='rootWCheckbox'
          control={
            <Checkbox
              size={props.checkBoxSize}
              onClick={stopPropagation}
              onChange={props.onChange}
              checked={props.checked}
              defaultChecked={props.defaultChecked}
              color={'primary'}
              indeterminate={props.indeterminate}
              value={props.value}
              disabled={props.disabledCheck}
              style={{...checkBoxStyle}}
              icon={props.checkBoxIcon}
              checkedIcon={props.checkBoxCheckedIcon}
            />
          }
        />
      </Tooltip>
    </FormGroup>
  );
});
