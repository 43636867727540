import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

import {experimentalFeaturesSlice} from '@/common/redux/slices/experimentalFeaturesSlice';
import {ExperimentalFeaturesState} from '@/common/redux/state-types/experimentalFeaturesState';
import {useAppSelector, useAsyncDispatch} from '@/common/redux/store/store';
import {ObjectUtil} from '@/common/utils/objectUtil';

function ExperimentalFeatures() {
  const state = useAppSelector(s => s.experimentalFeatures);
  const dispatch = useAsyncDispatch();

  const flags: {code: keyof ExperimentalFeaturesState; checked: boolean}[] = ObjectUtil.entries(state).map(
    ([k, v]) => ({
      code: k,
      checked: v,
    })
  );

  const handleChange = (code: keyof ExperimentalFeaturesState, checked: boolean) => {
    dispatch(experimentalFeaturesSlice.actions.set({[code]: checked}));
  };

  return (
    <div>
      <FormControl component='fieldset'>
        <FormGroup>
          {flags.map((flag, idx) => (
            <FormControlLabel
              key={idx}
              sx={{
                fontSize: 14,
              }}
              control={
                <Switch
                  color='primary'
                  checked={flag.checked}
                  onChange={e => handleChange(flag.code, e.currentTarget.checked)}
                />
              }
              label={flag.code}
              labelPlacement='start'
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default ExperimentalFeatures;
