import {createFileRoute, Navigate} from '@tanstack/react-router';

import {useLoginUser} from '@/common/hooks/useLoginUser';
import {useFloorQuery} from '@/query/floors';

export const Route = createFileRoute('/_authorized/layout/$floorId/')({
  component: RouteComponent,
});

function RouteComponent() {
  const {organizationId} = useLoginUser();
  const {floorId} = Route.useParams();

  const {data: floor} = useFloorQuery({organizationId, floorId});
  if (!floor?.publishedLayoutCompositionId) {
    // FIXME: 404 的なページができたら出す
    return <></>;
  }

  return (
    <Navigate
      replace
      to='/layout/$floorId/$layoutCompositionId'
      params={{floorId, layoutCompositionId: floor.publishedLayoutCompositionId}}
    />
  );
}
