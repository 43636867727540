import {type FC, lazy, type PropsWithChildren} from 'react';
import {Outlet} from 'react-router';

import {useFeature, type UseFeatureConfig} from '@/common/feature-control';

type Props = PropsWithChildren<UseFeatureConfig>;

const ErrorDisplayScreen = lazy(() => import('@/wscreens/no-authority/ErrorDisplayScreen'));

export const Authorize: FC<Props> = ({children, ...props}) => {
  const {enabled} = useFeature(props);
  if (!enabled) {
    return <ErrorDisplayScreen noAuthority />;
  }

  return children ?? <Outlet />;
};
