import styled from '@emotion/styled';
import {useMutation, useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import * as Icon from '@workhub/icons';
import {TextButton, IconButton, Loading} from '@workhub/ui';
import {isAxiosError} from 'axios';
import React, {useCallback, useMemo, useState} from 'react';
import {isMobile} from 'react-device-detect';

import {WORKHUB_PRIVACY_POLICY_URL, WORKHUB_TERMS_OF_USE_URL} from '@/common/constant/url';
import useDict from '@/common/hooks/useDict';
import {useLocale} from '@/common/hooks/useLocale';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHFont, WHFontCss} from '@/common/styles/whFont';
import {LocaleUtils} from '@/common/utils/localeUtils';
import WFormDialogTextField from '@/components/figma/others/dialog/form/WFormDialogTextField';
import WLabelContent from '@/components/figma/others/label/WLabelContent';
import LanguageToggler from '@/components/languageToggler/LanguageToggler';
import InvitationsService from '@/services/invitations/invitationsService';
import {BKP_MIN_PASSWORD_LENGTH, useValidatePassword} from '@/wcustomhooks/auth/useValidatePassword';

import AlreadyInvited from './-AlreadyInvited';
import Completed from './-Completed';
import {SIGNUP_QUERY_KEY} from '../sso/signup/-constants/queryKeys';

export const Route = createFileRoute('/inviteMember')({
  component: RouteComponent,
  validateSearch: search => search as {id: string}, // TODO: バリデーション
});

const Root = styled.div`
  background-color: var(--surface-brand-primary);
`;

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  * {
    font-family: ${WHFont.overline.fontFamily};
  }
`;

const Label = styled.div`
  font-size: ${WHFont.labelMedium.fontSize}px;
  transform: translate(-10%, 16%);
  position: absolute;
  bottom: 5px;
  right: 0;
  ul {
    display: flex;
    justify-content: flex-end;
    li {
      list-style: none;
    }
    li:last-child {
      margin-left: var(--spacing-16);
    }
    li a {
      ${WHFont.overline}
      text-decoration: underline;
      color: var(--text-link-primary);
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const Box = styled.div`
  position: absolute;
  width: 90%;
  max-width: 389px;
  height: 90vh;
  max-height: 607px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-shadow: ${!isMobile ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : 'none'};
  border-radius: var(--radius-l);
  background-color: var(--surface-neutral-low);
`;

const Wrapper = styled.div`
  padding: var(--spacing-40);
  box-sizing: border-box;
`;

const Email = styled.div`
  ${WHFont.labelMedium}
  color: var(--text-neutral-primary);
  word-break: break-all;
`;

const PasswordDescription = styled.p`
  ${WHFont.labelMedium}
  margin: 0 0 var(--spacing-8);
  color: var(--text-neutral-secondary);
`;

const InputFieldWrapper = styled.div`
  position: relative;
`;

const VisibilityIconButtonWrapper = styled.div`
  position: absolute;
  top: 1px;
  right: 0;
`;

const VisibilityIcon = styled(Icon.Visibility)`
  color: var(--text-neutral-secondary);
`;

const VisibilityOffIcon = styled(Icon.VisibilityOff)`
  color: var(--text-neutral-secondary);
`;

const LogoArea = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-20);
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
`;

const Description = styled.p`
  ${WHFont.labelMedium}
  color: var(--text-neutral-secondary);
  white-space: pre-line;
`;

const WhIcon = styled(Icon.WhIconDisplay)`
  font-size: 48px;
  color: var(--text-brand-primary);
`;

const JoinButton = styled(TextButton)`
  width: 100%;
  padding: var(--spacing-20) 0;
`;

const OrganizationName = styled.p`
  ${WHFont.labelSmallStrong}
  margin-top: var(--spacing-24);
  color: var(--text-brand-primary);
`;

const JoinTextSection = styled.section`
  text-align: center;
  & h2 {
    ${WHFont.headlineSmall}
    color: var(--text-brand-primary);
  }
`;

const ResetPasswordTextWrapper = styled.a`
  color: var(--text-neutral-primary);
  ${WHFontCss.linkMedium}
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-block;
  margin-top: var(--spacing-4);
`;

const OpenExternalIcon = styled(Icon.OpenExternal)`
  vertical-align: text-bottom;
`;

const dictDef = {
  joinSectionTitle: {
    default: {
      default: '組織に参加する',
      [Locale.en_US]: 'Join the organization',
    },
  },
  joinSectionText: {
    default: {
      default: 'workhub にログインする\nパスワードを設定してください',
      [Locale.en_US]: 'Set your password\nto login to workhub',
    },
  },
  emailAddress: {
    default: {
      default: 'メールアドレス',
      [Locale.en_US]: 'Email address',
    },
  },
  password: {
    default: {
      default: 'パスワード',
      [Locale.en_US]: 'Password',
    },
  },
  passwordDescription: {
    default: {
      default: (minPasswordLength: number) => `半角英数字・記号のみ、${minPasswordLength}文字以上`,
      [Locale.en_US]: (minPasswordLength: number) =>
        `Alphanumeric and symbols only, ${minPasswordLength} characters or more`,
    },
  },
  togglePasswordVisibility: {
    default: {
      default: 'パスワードの表示/非表示',
      [Locale.en_US]: 'Toggle password visibility',
    },
  },
  join: {
    default: {
      default: '参加',
      [Locale.en_US]: 'Join',
    },
  },
  privacyPolicy: {
    default: {
      default: 'プライバシーポリシー',
      [Locale.en_US]: 'Privacy Policy',
    },
  },
  termsOfUse: {
    default: {
      default: '利用規約',
      [Locale.en_US]: 'Terms of Use',
    },
  },
  noInvitationInfo: {
    default: {
      default: '招待情報がありません',
      [Locale.en_US]: 'No invitation information',
    },
  },
  notFoundInvitaionInfo: {
    default: {
      default:
        '招待情報が確認できません。新しい招待URLが発行されていないかご確認ください。発行されていない場合は管理者にお問い合わせください',
      [Locale.en_US]:
        'Invitation information not found. Please check if a new invitation URL has been issued. If not, please contact the administrator',
    },
  },
  thisPasswordIsNotSecure: {
    default: {
      default: '安全性の低いパスワードです。別のパスワードを設定してください',
      [Locale.en_US]: 'This password is not secure. Please set a different password.',
    },
  },
  deadlineExceeded: {
    default: {
      default: '招待が期限切れです。管理者に再発行を依頼してください',
      [Locale.en_US]: 'The invitation has expired. Please contact the administrator',
    },
  },
  // アカウントロック時 または 別組織に参加済みで PasswordRuleset を満たしていない場合 のメッセージ。
  // ロックされていることや、組織の PasswordRuleset を満たしていないパスワードを設定していることは伝えずにパスワード再設定を促したい
  inquiryPasswordReset: {
    default: {
      default: '同じメールアドレスが既に存在します。組織に参加するにはパスワードの再設定が必要です。',
      [Locale.en_US]: 'The same email address already exists. To join the organization, please reset your password.',
    },
  },
  invalidPassword: {
    default: {
      default: 'パスワードが違います',
      [Locale.en_US]: 'password is incorrect.',
    },
  },
  signupFailed: {
    default: {
      default: 'サインアップできませんでした',
      [Locale.en_US]: 'Signup failed',
    },
  },
  passwordRecoveryLink: {
    default: {
      default: 'パスワードの再設定はこちら',
      [Locale.en_US]: 'Reset your password here',
    },
  },
};

function RouteComponent() {
  const dict = useDict(dictDef);
  const [isAlreadyJoined, setIsAlreadyJoined] = useState(false);
  const {id: invitationId} = Route.useSearch();
  const [isLocked, setIsLocked] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const locale = useLocale();
  const [showPassword, setShowPassword] = useState(false);

  const {
    mutate: onClickCreateAccount,
    isPending: isSignupPending,
    isSuccess: isSignupSuccess,
  } = useMutation({
    mutationKey: [SIGNUP_QUERY_KEY.SIGNUP_FLOW, invitationId],
    mutationFn: async () => {
      setErrorMessage(undefined);

      if (passwordErrorMessage) {
        throw new Error('validate-password-error');
      }

      if (!invitationId) {
        throw new Error('invitation-id-not-found');
      }

      setIsLocked(false);
      return await InvitationsService.signUp(password, invitationId);
    },
    onError: e => {
      if (e instanceof Error) {
        switch (e.message) {
          case 'validate-password-error':
            setErrorMessage(passwordErrorMessage);
            return;
          case 'invitation-id-not-found':
            setErrorMessage(dict.noInvitationInfo);
            return;
        }
      }

      if (!isAxiosError(e)) {
        setErrorMessage(dict.signupFailed);
        return;
      }

      // invitationId自体が存在しない or BKPのsignUpToOrganizationが404を返す場合
      if (e.response?.data.code === 'join-invitation-not-found' || e.response?.data.code === 'not-found') {
        setErrorMessage(dict.notFoundInvitaionInfo);
      } else if (e.response?.data.code === 'deadline-exceeded') {
        setErrorMessage(dict.deadlineExceeded);
      } else if (e.response?.data.code === 'unlocked' || e.response?.data.code === 'locked') {
        setErrorMessage(dict.inquiryPasswordReset);
        setIsLocked(true);
      } else if (e.response?.data.code === 'password_ruleset_violation') {
        setErrorMessage(dict.thisPasswordIsNotSecure);
      } else if (e.response?.data.code === 'invalid-argument') {
        setErrorMessage(dict.invalidPassword);
      } else if (e.response?.data.code === 'already-exists') {
        // すでに参加済みの場合は完了画面に飛ばす
        setIsAlreadyJoined(true);
        return;
      } else {
        setErrorMessage(dict.signupFailed);
      }
    },
  });

  const onChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const password = e.target.value;
      setPassword(password || '');
    },
    [setPassword]
  );

  const onKeyDownPassword = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter') {
        onClickCreateAccount();
      }
    },
    [onClickCreateAccount]
  );

  const transitionResetPasswordScreen = useCallback(() => {
    // 【背景】招待URLからの参加がうまくできない（例: アカウントロック）場合、
    // パスワードリセット画面へ遷移する必要があるが、リセット後に再度招待URLに戻るのは手間がかかる。
    // 【目的】別タブで開くことで、ユーザーがリセット後も元の画面に戻りやすくするため。
    window.open('/sendResetPassword', '_blank');
  }, []);

  const {data: invitation, isFetching: isFetchingInvitation} = useQuery({
    queryKey: [SIGNUP_QUERY_KEY.SIGNUP_INVITATION, invitationId],
    queryFn: async () => {
      if (!invitationId) return;
      return await InvitationsService.findInvitation({invitationId});
    },
    enabled: !!invitationId,
    refetchOnReconnect: false,
  });
  const email = useMemo(() => invitation?.email || '', [invitation]);
  const {passwordErrorMessage} = useValidatePassword(password);
  const alreadyRegistered = useMemo(() => invitation?.alreadyRegistered || false, [invitation]);
  const organizationName = useMemo(() => {
    return LocaleUtils.getLocaleName(
      {
        name: invitation?.organizationName,
        nameJp: invitation?.organizationNameJp,
        nameEn: invitation?.organizationNameEn,
      },
      locale
    );
  }, [invitation, locale]);
  const isDeletedPerson = useMemo(() => !!invitation?.isDeletedPerson, [invitation]);
  const minPasswordLength = useMemo(
    () => invitation?.minPasswordLength || BKP_MIN_PASSWORD_LENGTH,
    [invitation?.minPasswordLength]
  );

  const toggleShowPassword = useCallback(() => {
    setShowPassword(value => !value);
  }, []);

  if (isFetchingInvitation) {
    return (
      <Root>
        <Frame>
          <Box>
            <Loading centered />
          </Box>
        </Frame>
      </Root>
    );
  }

  if (isSignupSuccess || isAlreadyJoined) {
    return <Completed organizationName={organizationName} />;
  }

  return (
    <Root>
      <Frame>
        <LanguageToggler />
        <Box>
          {alreadyRegistered ? (
            <AlreadyInvited identifyValue={email} deletedPerson={isDeletedPerson} />
          ) : (
            <Wrapper>
              <LogoArea>
                <WhIcon />
              </LogoArea>
              <DescriptionWrapper>
                <OrganizationName>{organizationName}</OrganizationName>
                <JoinTextSection>
                  <h2>{dict.joinSectionTitle}</h2>
                  <Description>{dict.joinSectionText}</Description>
                </JoinTextSection>
              </DescriptionWrapper>
              <WLabelContent
                label={dict.emailAddress}
                content={<Email>{email || 'workhub@bitkey.jp'}</Email>}
                width={'100%'}
                marginTop={'var(--spacing-16)'}
              />
              <WLabelContent
                label={dict.password}
                content={
                  <>
                    <PasswordDescription>{dict.passwordDescription(minPasswordLength)}</PasswordDescription>
                    <InputFieldWrapper>
                      <WFormDialogTextField
                        name={'password'}
                        type={showPassword ? 'text' : 'password'}
                        onChange={onChangePassword}
                        onKeyDown={onKeyDownPassword}
                        inValidMessage={errorMessage}
                      />
                      <VisibilityIconButtonWrapper>
                        <IconButton title={dict.togglePasswordVisibility} onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </VisibilityIconButtonWrapper>
                    </InputFieldWrapper>
                  </>
                }
                width={'100%'}
                marginTop={'var(--spacing-16)'}
              />
              {isLocked && (
                <>
                  <ResetPasswordTextWrapper onClick={transitionResetPasswordScreen}>
                    {dict.passwordRecoveryLink}
                  </ResetPasswordTextWrapper>
                  <OpenExternalIcon fontSize='small' />
                </>
              )}
              <ButtonArea>
                <JoinButton onClick={() => onClickCreateAccount()} disabled={!password} processing={isSignupPending}>
                  {dict.join}
                </JoinButton>
              </ButtonArea>
              <Label>
                <ul>
                  <li>
                    <a href={WORKHUB_PRIVACY_POLICY_URL} target={'_blank'} rel={'noreferrer noopener'}>
                      {dict.privacyPolicy}
                    </a>
                  </li>
                  <li>
                    <a href={WORKHUB_TERMS_OF_USE_URL} target={'_blank'} rel={'noreferrer noopener'}>
                      {dict.termsOfUse}
                    </a>
                  </li>
                </ul>
              </Label>
            </Wrapper>
          )}
        </Box>
      </Frame>
    </Root>
  );
}
