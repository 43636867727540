import styled from '@emotion/styled';
import FlagIcon from '@mui/icons-material/Flag';
import Badge from '@mui/material/Badge';
import React from 'react';

import {useAppSelector} from '@/common/redux/store/store';
import {WHColor} from '@/common/styles/whColor';
import {ObjectUtil} from '@/common/utils/objectUtil';

const Circle = styled.div`
  border-radius: 30px;
  width: 40px;
  height: 40px;
  background: var(--surface-brand-primary);
  color: ${WHColor.text.neutralWhitePrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExperimentalFeatureWatcherUnmemoized = () => {
  const features = useAppSelector(s => s.experimentalFeatures);
  const enabledNum = ObjectUtil.values(features).filter(enabled => enabled).length;

  return (
    <Badge color='secondary' badgeContent={enabledNum}>
      <Circle>
        <FlagIcon />
      </Circle>
    </Badge>
  );
};

export const ExperimentalFeatureWatcher = React.memo(ExperimentalFeatureWatcherUnmemoized);
