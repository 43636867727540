import React, {SVGProps} from 'react';

type Props = {size: number; color: string} & SVGProps<SVGSVGElement>;

const WIconRatingStar = React.memo<Props>(function WIconRatingStar({size, color, ...rest}) {
  return (
    <svg width={size} height={size} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M26.1872 13.9667L22.4488 1.76794C22.0928 0.589313 21.2818 0 20.0159 0C18.75 0 17.939 0.589313 17.583 1.76794L13.8447 13.9667L2.57023 13.9667C1.34389 13.9667 0.532921 14.556 0.137328 15.7346C-0.218707 16.874 0.117548 17.7972 1.14609 18.5044L10.3436 25.0458L6.72396 36.6553C6.36793 37.8339 6.68441 38.7768 7.67339 39.484C8.70193 40.1518 9.7107 40.1126 10.6997 39.3661L20.0159 32.3533L29.3321 39.425C30.3211 40.1715 31.3101 40.1911 32.2991 39.484C33.3276 38.7768 33.6639 37.8339 33.3079 36.6553L29.6882 25.1047L38.8857 18.5634C39.9143 17.8169 40.2308 16.874 39.8352 15.7346C39.4791 14.556 38.6879 13.9667 37.4616 13.9667L26.1872 13.9667Z'
        fill={color}
      />
    </svg>
  );
});
export default WIconRatingStar;
