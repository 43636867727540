import {useCallback, useMemo} from 'react';

import type {SnackbarAction} from '@/common/types/snackbarAction';
import {simpleSnackbarContainer} from '@/components/layouts/simpleSnackbarContainer';
import {SimpleSnackbarType} from '@/components/snackbar/SimpleSnackbar';

export const useSnackbar = (): SnackbarAction => {
  const {setState} = simpleSnackbarContainer.useValue();

  const open = useCallback(
    (message: string, type: SimpleSnackbarType) => {
      // キューに詰まないと開いている間に開こうとしたら閉じるので。
      setTimeout(() => {
        setState({
          message,
          type,
          open: true,
        });
      }, 0);
    },
    [setState]
  );

  return useMemo(
    () => ({
      success: (message: string) => {
        open(message, SimpleSnackbarType.Success);
      },
      fail: (message: string) => {
        open(message, SimpleSnackbarType.Error);
      },
      info: (message: string) => {
        open(message, SimpleSnackbarType.Info);
      },
      warn: (message: string) => {
        open(message, SimpleSnackbarType.Warning);
      },
    }),
    [open]
  );
};
