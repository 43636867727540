import {Locale} from '@/common/redux/state-types/localeStateType';

export const otherSectionMenuDef = {
  wAnnouncementManagement: {
    default: {
      default: 'お知らせ設定',
      [Locale.en_US]: 'Announcement information',
    },
  },
  manualManagement: {
    default: {
      default: 'マニュアル管理',
      [Locale.en_US]: 'Manual Management',
    },
  },
  eventParticipantsReservationScreen: {
    default: {
      default: 'イベント参加者予約',
      [Locale.en_US]: 'Event Participant Reservation',
    },
  },
  eventListReservationScreen: {
    default: {
      default: '招待情報一覧',
      [Locale.en_US]: 'Invitation Information List',
    },
  },
  eventDashboard: {
    default: {
      default: 'イベントダッシュボード',
      [Locale.en_US]: 'Event Dashboard',
    },
  },
  wTenant: {
    default: {
      default: 'テナント情報',
      [Locale.en_US]: 'Tenants',
    },
  },
  wTenantDetail: {
    default: {
      default: 'テナント詳細',
      [Locale.en_US]: 'Tenant Detail',
    },
  },
  events: {
    default: {
      default: 'イベント',
      [Locale.en_US]: 'Event Management',
    },
  },
  dataImport: {
    default: {
      default: 'データインポート',
      [Locale.en_US]: 'Data Import',
    },
  },
  wForBitkey: {
    default: {
      default: 'For Bitkey',
      [Locale.en_US]: 'For Bitkey',
    },
  },
  noAuthority: {
    default: {
      default: '権限なし',
      [Locale.en_US]: 'No Authority',
    },
  },
};
