export const getStackTrace = () => {
  const {stack} = new Error();
  if (!stack) {
    return '';
  }
  let split = stack.split('\n');

  // Remove the above "new Error" and "getStackTrace" line from the stack trace
  if (split[0]) {
    split = [split[0], ...split.splice(3)];
  }

  return split.join('\n');
};

export const catchStack =
  (stackTrace: string = getStackTrace()) =>
  (error: Error) => {
    error.stack = stackTrace + `\nCaused by ${error.stack}`;
    throw error;
  };
