import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as React from 'react';
import {useCallback} from 'react';

import {WHColor} from '@/common/styles/whColor';

const Wrapper = styled.div`
  padding: 16px;
  background-color: ${WHColor.surface.neutralMiddle};
  border-radius: 8px;
  gap: 8px;
`;

export const QuestionnaireItemEdit = React.memo(function QuestionnaireItemView({
  choices,
  questionnaireName,
  onChange,
  selectId,
}: {
  questionnaireName: string;
  choices: {id: string; name: string}[];
  onChange: (radioId: string) => any;
  selectId: string;
}) {
  const onChangeRadioId = useCallback(
    (id: string) => () => {
      onChange(id);
    },
    [onChange]
  );
  return (
    <Wrapper>
      <div css={{marginTop: 8}}>
        <div>{questionnaireName}</div>
        <RadioGroup value={selectId}>
          {choices.map(choice => {
            return (
              <div css={{display: 'flex', alignItems: 'center'}} key={choice.id}>
                <FormControlLabel
                  value={choice.id}
                  control={<Radio key={choice.id} onChange={onChangeRadioId(choice.id)} />}
                  label={choice.name}
                />
              </div>
            );
          })}
        </RadioGroup>
      </div>
    </Wrapper>
  );
});
