/* eslint-disable no-console */
import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import {decycle} from 'cycle';

export enum LogLevel {
  Trace = 1,
  Log = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
  None = 6,
}

export interface LoggerInterface {
  trace: (...data: any) => void;
  log: (message: string, data?: any) => void;
  info: (message: string, data?: Record<string, unknown>, err?: Error) => void;
  warn: (message: string, data?: Record<string, unknown>, err?: Error) => void;
  error: (message: string, err: Error, data?: Record<string, unknown>) => void;
}

export default class Logger implements LoggerInterface {
  private static logLevel: LogLevel = LogLevel.Log;

  public static create = (name: string) => new Logger(name);

  public static setLogLevel = (level: LogLevel) => {
    Logger.logLevel = level;
    if (level === LogLevel.None) {
      console.log = () => {};
      console.info = () => {};
      console.warn = () => {};
      console.error = () => {};
    }
    // MUI関連のエラーが異様にうるさいので、それによって開発効率下がる場合はこれを有効にする
    // const origError = console.error;
    // console.error = (...data: any[]) => {
    //   if (data?.[0]?.startsWith) {
    //     const first = data[0];
    //     if (first.startsWith('Warning: %s is deprecated in StrictMode.')) {
    //       return;
    //     } else if (first.startsWith('Warning: Invalid DOM property')) {
    //       return;
    //     } else if (first.startsWith('Warning: React does not recognize the')) {
    //       return;
    //     }
    //   }
    //   origError(...data);
    // };
  };

  private readonly name: string;
  constructor(name: string) {
    this.name = name;
  }

  /**
   * TRACE
   *
   * - console.trace を利用して、stacktrace も一緒に出力するような箇所に追加すると良い。
   * - 原則、ローカル等の開発環境でのみ利用する。
   *
   * @param data - 任意のなにか
   */
  public trace = (...data: any) => {
    if (Logger.logLevel <= LogLevel.Trace) {
      // ログあんまり追えなくて効率悪そうだったからスタック追加
      // 見やすくはないので、見ても意味がない行をフィルタするとか、行頭のAt を消すとか、そういうのは調整しながら。
      // trace以外については必要に応じて。少なくとも本番ではログ潰してるから気軽に有効にできるはず。
      console.trace(`[${this.name}]`, ...data);
    }
  };

  /**
   * LOG
   *
   * - DEBUG と同じ扱い。実装したアプリケーションコードの挙動調査するため。原則、ローカル等の開発環境でのみ利用する。
   * @see [ロギングガイドライン](https://www.notion.so/dc9818a99f65440bb88a9b7c3f82b2ad)
   *
   * @param message - `message` field に表示される文言
   * @param data - 任意のなにか
   */
  public log = (message: string, data?: any) => {
    if (Logger.logLevel <= LogLevel.Log) {
      console.log(`[${this.name}]`, message, data);
    }
    // TODO: log は、量が多いので、datadog へは連携しない
    // datadogLogs.logger.log(message, {logName: this.name, data});
  };

  /**
   * INFO
   *
   * - 開発者・運用者が、処理フローやライフサイクルイベント（e.g. start, end etc...）を追跡できるようにするために利用する。
   * - Datadog Logs で確認することが可能。
   * - e.g. starting to hoge, succeeded to hoge etc...
   * @see [ロギングガイドライン](https://www.notion.so/dc9818a99f65440bb88a9b7c3f82b2ad)
   * @see [Datadog Logs](https://app.datadoghq.com/logs?query=service%3Aworkhub-web%20status%3Ainfo)
   *
   * @param message - `message` field に表示される文言
   * @param data - 任意の Object。e.g. {organizationId: 'hoge', taskId: 1}
   */
  public info = (message: string, data?: Record<string, unknown>, err?: Error) => {
    if (Logger.logLevel <= LogLevel.Info) {
      console.info(`[${this.name}]`, message, data, err);
    }
    datadogLogs.logger.info(message, {logName: this.name, data}, err);
  };

  /**
   * WARN
   *
   * - 問題を引き起こす可能性がある情報を、INFOやERRORとは区別し、強調することで、調査・分析を容易にするために利用する。
   * - Datadog Logs で確認することが可能。
   * - Bad: /v1/hoge is deprecated
   * - Good: /v1/hoge is deprecated. please use /v2/hoge
   * @see [ロギングガイドライン](https://www.notion.so/dc9818a99f65440bb88a9b7c3f82b2ad)
   * @see [Datadog Logs](https://app.datadoghq.com/logs?query=service%3Aworkhub-web%20status%3Awarn)
   *
   * @param message - `message` field に表示される文言
   * @param data - 任意の Object。e.g. {organizationId: 'hoge', taskId: 1}
   * @param err - Error Object
   */
  public warn = (message: string, errOrData?: Error | Record<string, unknown>) => {
    if (Logger.logLevel <= LogLevel.Warn) {
      console.warn(`[${this.name}]`, message, errOrData);
    }
    if (errOrData instanceof Error) {
      datadogLogs.logger.warn(message, {logName: this.name}, errOrData);
    } else {
      datadogLogs.logger.warn(message, {logName: this.name, data: errOrData});
    }
  };

  /**
   * ERROR
   *
   * - 顧客影響を早期に検知して、問題の緩和・トリアージするために利用する。
   * - Datadog RUM の Error Tracking で管理・分析したい場合は、error で追加するようにする。
   * - Datadog Logs でも確認することが可能。
   * - e.g. failed to hoge
   * @see [ロギングガイドライン](https://www.notion.so/dc9818a99f65440bb88a9b7c3f82b2ad)
   * @see [Datadog Logs](https://app.datadoghq.com/logs?query=service%3Aworkhub-web%20status%3Aerror)
   *
   * @param message - `message` field に表示される文言
   * @param err - Error Object
   * @param data - 任意の Object。e.g. {organizationId: 'hoge', taskId: 1}
   */
  public error = (message: string, err: Error, data?: Record<string, unknown>) => {
    if (err.message === '') {
      // new Error() がきたら、message で上書きする
      err.message = message;
    }
    if (Logger.logLevel <= LogLevel.Error) {
      console.error(`[${this.name}] ${message}`, err, {cause: err?.cause, data});
    } else {
      const context = {logName: this.name, data, cause: err?.cause};
      // console.error が実行される場合、自動で、連携されるので、重複するため、連携しない場合のみ custom で転送する
      // https://app.datadoghq.com/logs?query=service%3Aworkhub-web%20status%3Aerror&cols=host%2Cservice&index=%2A&messageDisplay=inline&stream_sort=time%2Cdesc&viz=stream&from_ts=1672112255398&to_ts=1672113155398&live=true
      datadogLogs.logger.error(message, context, err);
      // console.error が出ていれば、RUM とも自動で紐付けしてくれるのですが、出力していない場合、個別で、err と RUM を紐づける必要があるため
      // https://app.datadoghq.com/rum/error-tracking?query=%40application.id%3Af4095471-4856-4681-9557-f70394ffaa82%20%40error.source%3Acustom&index=&start=1672109471705&end=1672113071705&paused=false
      datadogRum.addError(err, context);
    }
  };
}

export const DeCycleStringify = (val: any) => JSON.stringify(decycle(val));
