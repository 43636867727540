import type {StoreTypesOrgDropInSpaceSettings} from '@bitkey-service/v2_core-types/lib/store/organizations/drop-in-space-settings/storeTypesOrgDropInSpaceSettings';
import {MailTemplateType} from '@bitkey-service/v2_core-types/lib/store/organizations/workhub-reservation-settings/v2_storeTypesWHOrgReservationSetting';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {DropInSpaceSettingsState} from '@/common/redux/screens/state/dropInSpaceSettingsState';
import {ValidationUtil} from '@/common/utils/validationUtil';
import {SpaceReservationService} from '@/services/reservations/space-reservation/spaceReservationService';
import {ApiWorkhubCoreDropInSpaceSettings} from '@/v2_api/workhub-core/organizations/drop-in-space-settings/v2_ApiWorkhubCoreDropInSpaceSettings';
import {ThirdPlaceAreaReservationMailState} from '@/wscreens/spaces/areas/third-place/add/WAreaExternalUsageSettingsAdd';

import {RootState} from '../../store/rootStateType';

const initialState: DropInSpaceSettingsState = {
  isDetailScreen: false,
  initialLoading: true,
  dialog: {
    createDropInSpaceSettings: {
      registering: false,
      isOpenCreateDialog: false,
      isOpenConfirmDialog: false,
      targetId: '',
      inputBasicInfoStep: {
        publicSpaceName: {
          validType: 'initial',
        },
        publicSpaceDescription: '',
        locationImages: [],
      },
      inputEquipmentStep: {
        spaceEquipments: '',
      },
      inputPublishSettingStep: {
        isPublic: false,
        termsOfServiceUrl: {validType: 'initial'},
        emergencyPhoneNumber: {
          validType: 'initial',
        },
        publishExplanationOnHowToEnter: false,
        publishExplanationOnHowToExit: false,
        availableDuplicateCapacity: {enabled: false, capacity: 1},
      },
      inputReservableDateSettingStep: {
        baseReservableTimes: [],
        reservableDayOfWeeks: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        reservableTimesEachDayOfWeek: {
          Monday: [
            {
              startTime: '09:00',
              endTime: '18:00',
            },
          ],
          Tuesday: [
            {
              startTime: '09:00',
              endTime: '18:00',
            },
          ],
          Wednesday: [
            {
              startTime: '09:00',
              endTime: '18:00',
            },
          ],
          Thursday: [
            {
              startTime: '09:00',
              endTime: '18:00',
            },
          ],
          Friday: [
            {
              startTime: '09:00',
              endTime: '18:00',
            },
          ],
          Saturday: [],
          Sunday: [],
        },
      },
      inputKeyBundlesStep: {
        targetPatternIds: [],
        useTimebaseOneTimePasscode: false,
      },
      inputMailTemplateStep: {
        mailTemplates: {
          invitation: {
            sendable: false,
            id: '',
          },
          cancel: {
            sendable: false,
            id: '',
          },
          remind: {
            sendable: false,
            id: '',
          },
          finishRemind: {
            sendable: false,
            id: '',
          },
          update: {
            sendable: false,
            id: '',
          },
        },
        mailTemplateIds: [],
        sendableMailTemplateTypes: [],
        remindTimeSettings: {
          count: 10,
          unit: 'minute',
        },
        finishRemindTimeSettings: {
          count: 10,
          unit: 'minute',
        },
      },
      inputQrCodeStep: {
        useReception: false,
      },
    },
    editBasicInfoDialog: {
      isOpen: false,
      isProcessing: false,
      publicSpaceName: {
        validType: 'initial',
      },
    },
    editEquipmentDialog: {
      isOpen: false,
      isProcessing: false,
      spaceEquipments: '',
    },
    editPublishSettingDialog: {
      isOpen: false,
      isProcessing: false,
      isPublic: false,
      termsOfServiceUrl: {validType: 'initial'},
      emergencyPhoneNumber: {
        validType: 'initial',
      },
    },
    editReservableDateSettingDialog: {
      isOpen: false,
      isProcessing: false,
    },
    editKeyBundlesDialog: {
      isOpen: false,
      isProcessing: false,
      targetPatternIds: [],
      useTimebaseOneTimePasscode: false,
    },
    editMailTemplateDialog: {
      isOpen: false,
      isProcessing: false,
      mailTemplates: {
        invitation: {
          sendable: false,
          id: '',
        },
        cancel: {
          sendable: false,
          id: '',
        },
        remind: {
          sendable: false,
          id: '',
        },
        finishRemind: {
          sendable: false,
          id: '',
        },
        update: {
          sendable: false,
          id: '',
        },
      },
      remindTimeSettings: {
        count: 10,
        unit: 'minute',
      },
      finishRemindTimeSettings: {
        count: 10,
        unit: 'minute',
      },
    },
    editQrCodeDialog: {
      isOpen: false,
      isProcessing: false,
      useReception: false,
    },
  },
  persist: {
    dropInSpaceSetting: {
      id: '',
      targetId: '',
      publicSpaceName: '',
      publicSpaceDescription: '',
      locationImages: [],
      isPublic: false,
      termsOfServiceUrl: '',
      emergencyPhoneNumber: '',
      usageGuide: '',
      precautions: '',
      spaceEquipments: '',
      reservation: {
        targetPatternIds: [],
        availableBeforeMinutes: 0,
        availableAfterMinutes: 0,
        mailTemplateIds: [],
        sendableMailTemplateTypes: [],
      },
    },
    dropInSpaceSettings: {
      result: 'pending',
      data: [],
    },
  },
};

export const dropInSpaceSettingsSlice = createSlice({
  name: 'dropInSpaceSettings',
  initialState,
  reducers: {
    clear: () => initialState,
    setDropInSpaceSettingsId: (state, {payload}: PayloadAction<string>) => {
      state.dialog.createDropInSpaceSettings.targetId = payload;
    },
    resetCreateDialog: state => {
      state.dialog.createDropInSpaceSettings = initialState.dialog.createDropInSpaceSettings;
    },
    updateRegistering: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.createDropInSpaceSettings.registering = payload;
    },
    updateIsOpenCreateDialog: (
      state,
      {payload}: PayloadAction<DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['isOpenCreateDialog']>
    ) => {
      state.dialog.createDropInSpaceSettings.isOpenCreateDialog = payload;
    },
    openCreateConfirmDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.createDropInSpaceSettings.isOpenConfirmDialog = payload;
    },
    updateBasicInfoPublicSpaceName: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputBasicInfoStep']['publicSpaceName']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputBasicInfoStep;
      const edit = state.dialog.editBasicInfoDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.publicSpaceName = {
        value: payload?.value ?? updateTarget.publicSpaceName.value,
        validType: payload?.validType
          ? payload.validType
          : !payload?.value
            ? 'noInput'
            : ValidationUtil.isValidCode(payload.value)
              ? 'pending'
              : 'invalid',
      };
    },
    updateBasicInfoPublicSpaceNameEn: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputBasicInfoStep']['publicSpaceNameEn']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputBasicInfoStep;
      const edit = state.dialog.editBasicInfoDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.publicSpaceNameEn = payload ?? updateTarget.publicSpaceNameEn;
    },
    updateBasicInfoLocationImages: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputBasicInfoStep']['locationImages']>
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputBasicInfoStep;
      const edit = state.dialog.editBasicInfoDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.locationImages =
        payload !== undefined && payload.length ? (payload as string[]) : (updateTarget.locationImages ?? []);
    },
    updateBasicInfoPublicSpaceDescription: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputBasicInfoStep']['publicSpaceDescription']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputBasicInfoStep;
      const edit = state.dialog.editBasicInfoDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.publicSpaceDescription = payload ?? updateTarget.publicSpaceDescription;
    },
    updateBasicInfoPublicSpaceDescriptionEn: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputBasicInfoStep']['publicSpaceDescriptionEn']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputBasicInfoStep;
      const edit = state.dialog.editBasicInfoDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.publicSpaceDescriptionEn = payload ?? updateTarget.publicSpaceDescriptionEn;
    },
    updateSpaceEquipments: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputEquipmentStep']['spaceEquipments']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputEquipmentStep;
      const edit = state.dialog.editEquipmentDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.spaceEquipments = payload ?? updateTarget.spaceEquipments;
    },
    updateSpaceEquipmentsEn: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputEquipmentStep']['spaceEquipmentsEn']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputEquipmentStep;
      const edit = state.dialog.editEquipmentDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.spaceEquipmentsEn = payload ?? updateTarget.spaceEquipmentsEn;
    },
    updatePublishSpaceType: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['isPublic']>
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.isPublic = payload ?? updateTarget.isPublic;
    },
    updateAvailableDuplicateCapacity: (
      state,
      {
        payload,
      }: PayloadAction<
        DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['availableDuplicateCapacity']
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.availableDuplicateCapacity = {
        enabled: !!payload?.enabled,
        capacity: payload?.capacity ?? updateTarget.availableDuplicateCapacity?.capacity,
      };
    },
    updatePublishTermsOfServiceUrl: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['termsOfServiceUrl']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.termsOfServiceUrl = {
        value: payload?.value ?? updateTarget.termsOfServiceUrl.value,
        validType: payload?.validType
          ? payload.validType
          : !payload?.value
            ? 'noInput'
            : ValidationUtil.isValidUrl(payload.value)
              ? 'pending'
              : 'invalid',
      };
    },
    updatePublishEmergencyPhoneNumber: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['emergencyPhoneNumber']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.emergencyPhoneNumber = {
        value: payload?.value ?? updateTarget.emergencyPhoneNumber.value,
        validType: payload?.validType
          ? payload.validType
          : !payload?.value
            ? 'noInput'
            : ValidationUtil.isValidTelPhone(payload.value)
              ? 'pending'
              : 'invalid',
      };
    },
    updatePublishUsageGuide: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['usageGuide']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.usageGuide = payload ?? updateTarget.usageGuide;
    },
    updatePublishUsageGuideEn: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['usageGuideEn']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.usageGuideEn = payload ?? updateTarget.usageGuideEn;
    },
    updatePublishPrecaution: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['precautions']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.precautions = payload ?? updateTarget.precautions;
    },
    updatePublishPrecautionEn: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['precautionsEn']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.precautionsEn = payload ?? updateTarget.precautionsEn;
    },
    updatePublishExplanationOnHowToEnter: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['publishExplanationOnHowToEnter']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.publishExplanationOnHowToEnter = payload ?? updateTarget.publishExplanationOnHowToEnter;
    },
    updateExplanationOnHowToEnter: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['explanationOnHowToEnter']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.explanationOnHowToEnter = payload ?? updateTarget.explanationOnHowToEnter;
      updateTarget.publishExplanationOnHowToEnter = !!payload;
    },
    updateExplanationOnHowToEnterEn: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['explanationOnHowToEnterEn']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.explanationOnHowToEnterEn = payload ?? updateTarget.explanationOnHowToEnterEn;
      updateTarget.publishExplanationOnHowToEnter = !!payload;
    },
    updatePublishExplanationOnHowToExit: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['publishExplanationOnHowToExit']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.publishExplanationOnHowToExit = payload ?? updateTarget.publishExplanationOnHowToExit;
    },
    updateExplanationOnHowToExit: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['explanationOnHowToExit']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.explanationOnHowToExit = payload ?? updateTarget.explanationOnHowToExit;
      updateTarget.publishExplanationOnHowToExit = !!payload;
    },
    updateExplanationOnHowToExitEn: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputPublishSettingStep']['explanationOnHowToExitEn']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputPublishSettingStep;
      const edit = state.dialog.editPublishSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.explanationOnHowToExitEn = payload ?? updateTarget.explanationOnHowToExitEn;
    },
    updateReservableDateSetting: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputReservableDateSettingStep']>
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputReservableDateSettingStep;
      const edit = state.dialog.editReservableDateSettingDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.reservableDayOfWeeks = payload?.reservableDayOfWeeks ?? updateTarget.reservableDayOfWeeks;
      updateTarget.baseReservableTimes = payload?.baseReservableTimes ?? updateTarget.baseReservableTimes;
      updateTarget.reservableTimesEachDayOfWeek =
        payload?.reservableTimesEachDayOfWeek ?? updateTarget.reservableTimesEachDayOfWeek;
      updateTarget.holidayReservableTime = payload?.holidayReservableTime ?? updateTarget.holidayReservableTime;
    },
    updateTargetPatternIds: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputKeyBundlesStep']['targetPatternIds']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputKeyBundlesStep;
      const edit = state.dialog.editKeyBundlesDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.targetPatternIds =
        payload !== undefined && payload.length ? (payload as string[]) : (updateTarget.targetPatternIds ?? []);
    },
    updateAvailableBeforeMinutes: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputKeyBundlesStep']['availableBeforeMinutes']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputKeyBundlesStep;
      const edit = state.dialog.editKeyBundlesDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.availableBeforeMinutes = payload ?? updateTarget.availableBeforeMinutes;
    },
    updateAvailableAfterMinutes: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputKeyBundlesStep']['availableAfterMinutes']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputKeyBundlesStep;
      const edit = state.dialog.editKeyBundlesDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.availableAfterMinutes = payload ?? updateTarget.availableAfterMinutes;
    },
    updateUseTimebaseOneTimePasscode: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputKeyBundlesStep']['useTimebaseOneTimePasscode']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputKeyBundlesStep;
      const edit = state.dialog.editKeyBundlesDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.useTimebaseOneTimePasscode = payload ?? updateTarget.useTimebaseOneTimePasscode;
    },
    updateUseReception: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputQrCodeStep']['useReception']>
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputQrCodeStep;
      const edit = state.dialog.editQrCodeDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.useReception = payload ?? updateTarget.useReception;
    },
    updateEnableVisitorPassSent: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputQrCodeStep']['enableVisitorPassSent']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputQrCodeStep;
      const edit = state.dialog.editQrCodeDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.enableVisitorPassSent = payload ?? updateTarget.enableVisitorPassSent;
    },
    updateIsCreateACWithReception: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputQrCodeStep']['isCreateACWithReception']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputQrCodeStep;
      const edit = state.dialog.editQrCodeDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.isCreateACWithReception = payload ?? updateTarget.isCreateACWithReception;
    },
    updateSendQRForOnlyExternalGuest: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputQrCodeStep']['sendQRForOnlyExternalGuest']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputQrCodeStep;
      const edit = state.dialog.editQrCodeDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.sendQRForOnlyExternalGuest = payload ?? updateTarget.sendQRForOnlyExternalGuest;
    },
    updateMailTemplates: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputMailTemplateStep']['mailTemplates']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputMailTemplateStep;
      const edit = state.dialog.editMailTemplateDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.mailTemplates = (payload as ThirdPlaceAreaReservationMailState) ?? updateTarget.mailTemplates;
      updateTarget.mailTemplateIds = Object.values(updateTarget.mailTemplates)
        .filter(t => t.id)
        .map(t => t.id);
      updateTarget.sendableMailTemplateTypes = Object.entries(updateTarget.mailTemplates)
        .map(([k, v]) => {
          if (v.sendable) return k as MailTemplateType;
          return undefined;
        })
        .flatMap(d => d ?? []);
    },
    updateMailTemplatesRemindTimeSettings: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputMailTemplateStep']['remindTimeSettings']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputMailTemplateStep;
      const edit = state.dialog.editMailTemplateDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.remindTimeSettings = {
        count: payload?.count ?? updateTarget.remindTimeSettings?.count ?? 10,
        unit: payload?.unit ?? updateTarget.remindTimeSettings?.unit ?? 'minute',
      };
    },
    updateMailTemplatesFinishRemindTimeSettings: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputMailTemplateStep']['finishRemindTimeSettings']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputMailTemplateStep;
      const edit = state.dialog.editMailTemplateDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.finishRemindTimeSettings = {
        count: payload?.count ?? updateTarget.remindTimeSettings?.count ?? 10,
        unit: payload?.unit ?? updateTarget.remindTimeSettings?.unit ?? 'minute',
      };
    },
    updateMailTemplateSendBccMailAddress: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          DropInSpaceSettingsState['dialog']['createDropInSpaceSettings']['inputMailTemplateStep']['sendBccMailAddress']
        >
      >
    ) => {
      const create = state.dialog.createDropInSpaceSettings.inputMailTemplateStep;
      const edit = state.dialog.editMailTemplateDialog;
      const updateTarget = state.isDetailScreen ? edit : create;
      updateTarget.sendBccMailAddress =
        payload !== undefined && payload.length ? (payload as string[]) : (updateTarget.sendBccMailAddress ?? []);
    },
    initializeDropInSpaceSetting: (
      state,
      {payload}: PayloadAction<DropInSpaceSettingsState['persist']['dropInSpaceSetting']>
    ) => {
      state.persist.dropInSpaceSetting = payload;
      return state;
    },
  },
});

export const dropInSpaceSettingsScreenAsyncThunk = {
  register: createAsyncThunk<void>('dropInSpaceSettings/register', async (_, {dispatch, getState, rejectWithValue}) => {
    try {
      const dropInSpaceSettings = (getState() as RootState).screens.areasDetailScreen.tabs.dropInSpaceSettingsSlice
        .dialog.createDropInSpaceSettings;
      dispatch(dropInSpaceSettingsSlice.actions.updateRegistering(true));
      const spaceSetting: StoreTypesOrgDropInSpaceSettings = {
        id: dropInSpaceSettings.targetId,
        targetId: dropInSpaceSettings.targetId,
        publicSpaceName: dropInSpaceSettings.inputBasicInfoStep.publicSpaceName.value ?? '',
        publicSpaceNameEn: dropInSpaceSettings.inputBasicInfoStep.publicSpaceNameEn,
        publicSpaceDescription: dropInSpaceSettings.inputBasicInfoStep.publicSpaceDescription,
        publicSpaceDescriptionEn: dropInSpaceSettings.inputBasicInfoStep.publicSpaceDescriptionEn,
        locationImages: dropInSpaceSettings.inputBasicInfoStep.locationImages,
        isPublic: dropInSpaceSettings.inputPublishSettingStep.isPublic,
        termsOfServiceUrl: dropInSpaceSettings.inputPublishSettingStep.termsOfServiceUrl.value ?? '',
        emergencyPhoneNumber: dropInSpaceSettings.inputPublishSettingStep.emergencyPhoneNumber.value ?? '',
        usageGuide: dropInSpaceSettings.inputPublishSettingStep.usageGuide,
        usageGuideEn: dropInSpaceSettings.inputPublishSettingStep.usageGuideEn,
        precautions: dropInSpaceSettings.inputPublishSettingStep.precautions,
        precautionsEn: dropInSpaceSettings.inputPublishSettingStep.precautionsEn,
        spaceEquipments: dropInSpaceSettings.inputEquipmentStep.spaceEquipments,
        spaceEquipmentsEn: dropInSpaceSettings.inputEquipmentStep.spaceEquipmentsEn,
        reservation: {
          targetPatternIds: dropInSpaceSettings.inputKeyBundlesStep.targetPatternIds,
          availableBeforeMinutes: dropInSpaceSettings.inputKeyBundlesStep.availableBeforeMinutes,
          availableAfterMinutes: dropInSpaceSettings.inputKeyBundlesStep.availableAfterMinutes,
          mailTemplateIds: dropInSpaceSettings.inputMailTemplateStep.mailTemplateIds,
          sendableMailTemplateTypes: dropInSpaceSettings.inputMailTemplateStep.sendableMailTemplateTypes,
          remindMinutes: SpaceReservationService.convertUnitCountToMinute(
            dropInSpaceSettings.inputMailTemplateStep.remindTimeSettings ?? {count: 0, unit: 'minute'}
          ),

          reservableDayOfWeeks: dropInSpaceSettings.inputReservableDateSettingStep.reservableDayOfWeeks,
          baseReservableTimes: dropInSpaceSettings.inputReservableDateSettingStep.baseReservableTimes,
          availableDuplicateCapacity: dropInSpaceSettings.inputPublishSettingStep.availableDuplicateCapacity?.enabled
            ? dropInSpaceSettings.inputPublishSettingStep.availableDuplicateCapacity.capacity
            : undefined,
          reservableTimesEachDayOfWeek: dropInSpaceSettings.inputReservableDateSettingStep.reservableTimesEachDayOfWeek,
          holidayReservableTime: dropInSpaceSettings.inputReservableDateSettingStep.holidayReservableTime,
          sendBccMailAddress: dropInSpaceSettings.inputMailTemplateStep.sendBccMailAddress,
          enableVisitorPassSent: dropInSpaceSettings.inputQrCodeStep.enableVisitorPassSent,
          useTimebaseOneTimePasscode: dropInSpaceSettings.inputKeyBundlesStep.useTimebaseOneTimePasscode,
          isCreateACWithReception: dropInSpaceSettings.inputQrCodeStep.isCreateACWithReception,
          sendQRForOnlyExternalGuest: dropInSpaceSettings.inputQrCodeStep.sendQRForOnlyExternalGuest,
          publishExplanationOnHowToEnter: dropInSpaceSettings.inputPublishSettingStep.publishExplanationOnHowToEnter,
          explanationOnHowToEnter: dropInSpaceSettings.inputPublishSettingStep.explanationOnHowToEnter,
          explanationOnHowToEnterEn: dropInSpaceSettings.inputPublishSettingStep.explanationOnHowToEnterEn,
          publishExplanationOnHowToExit: dropInSpaceSettings.inputPublishSettingStep.publishExplanationOnHowToExit,
          explanationOnHowToExit: dropInSpaceSettings.inputPublishSettingStep.explanationOnHowToExit,
          explanationOnHowToExitEn: dropInSpaceSettings.inputPublishSettingStep.explanationOnHowToExitEn,
          howToUnlockMethods: ['qrCode', 'faceRecognition', 'oneTimePasscode', 'workhubApp'],
        },
        reception: {
          useReception: dropInSpaceSettings.inputQrCodeStep.useReception,
        },
      };
      await ApiWorkhubCoreDropInSpaceSettings.add(spaceSetting);
      dispatch(dropInSpaceSettingsSlice.actions.resetCreateDialog());
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(dropInSpaceSettingsSlice.actions.updateRegistering(false));
    }
  }),
  initializeDropInSpaceSettingsScreen: createAsyncThunk<void, {dropInSpaceSettingId: string}>(
    'dropInSpaceSettings/initializeDropInSpaceSettings',
    async ({dropInSpaceSettingId}, {dispatch, rejectWithValue}) => {
      try {
        const dropInSpaceSetting = await ApiWorkhubCoreDropInSpaceSettings.getById(dropInSpaceSettingId);
        if (!dropInSpaceSetting) {
          dispatch(
            dropInSpaceSettingsSlice.actions.initializeDropInSpaceSetting({
              ...initialState.persist.dropInSpaceSetting,
            })
          );
        } else {
          dispatch(
            dropInSpaceSettingsSlice.actions.initializeDropInSpaceSetting({
              ...dropInSpaceSetting,
            })
          );
        }
        return;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
};
