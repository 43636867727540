import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {FirestoreOrgChargeDetail} from '@/common/firebase/firestore/references/firestoreOrgChargeDetail';
import {
  DropInPricePlansDetailScreenState,
  DropInPricePlansDetailTabs,
} from '@/common/redux/screens/state/dropInPricePlansDetailScreenState';
import {RootState} from '@/common/redux/store/rootStateType';
import {EpochMillis} from '@/common/types/common-types';
import {ApiDropInPricePlans} from '@/v2_api/workhub-core/organizations/drop-in-price-plans/apiDropInPricePlans';

const initialState: DropInPricePlansDetailScreenState = {
  deleteLoading: false,
  displayTab: DropInPricePlansDetailTabs.OverView,
  editBasicInfoDialog: {isOpen: false},
  editValidPeriodDialog: {isOpen: false},
  editPricePlanDialog: {isOpen: false},
  editChargeDetailDialog: {isOpen: false},
  duplicatePricePlanDialog: {isOpen: false},
  deletePricePlanDialog: {isOpen: false},
  master: {},
};

export const dropInPricePlansDetailScreenSlice = createSlice({
  name: 'dropInPricePlansDetailScreenSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    initialize: (state, {payload}: PayloadAction<DropInPricePlansDetailScreenState['master']>) => {
      state.master.chargeDetails = payload.chargeDetails;
      state.master.dropInPricePlan = payload.dropInPricePlan;
      state.dropInPricePlanId = payload.dropInPricePlan?.id;
    },
    updateTabs: (state, {payload}: PayloadAction<DropInPricePlansDetailScreenState['displayTab']>) => {
      state.displayTab = payload;
    },
    updateEditBasicInfoDialog: (
      state,
      {payload}: PayloadAction<DropInPricePlansDetailScreenState['editBasicInfoDialog']['isOpen']>
    ) => {
      state.editBasicInfoDialog.isOpen = payload;
    },
    updateEditValidPeriodDialog: (
      state,
      {payload}: PayloadAction<DropInPricePlansDetailScreenState['editValidPeriodDialog']['isOpen']>
    ) => {
      state.editBasicInfoDialog.isOpen = payload;
    },
    updateEditPricePlanDialog: (
      state,
      {payload}: PayloadAction<DropInPricePlansDetailScreenState['editPricePlanDialog']['isOpen']>
    ) => {
      state.editPricePlanDialog.isOpen = payload;
    },
    updateEditChargeDetailDialog: (
      state,
      {payload}: PayloadAction<DropInPricePlansDetailScreenState['editChargeDetailDialog']['isOpen']>
    ) => {
      state.editPricePlanDialog.isOpen = payload;
    },
    updateDeleteLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.deleteLoading = payload;
    },
    updateDeletePricePlanDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.deletePricePlanDialog.isOpen = payload;
    },
    // db
    updateMasterDropInPlan: (
      state,
      {
        payload,
      }: PayloadAction<{
        code?: string;
        name?: string;
        publicPlanName?: string;
        publicPlanDescription?: string;
        validFrom?: EpochMillis;
        validTo?: EpochMillis;
      }>
    ) => {
      const current = state.master.dropInPricePlan;
      if (current) {
        state.master.dropInPricePlan = {
          id: current.id,
          code: payload?.code ?? current.code,
          name: payload.name ?? current.name,
          publicPlanName: payload.publicPlanName ?? current.publicPlanName,
          publicPlanDescription: payload.publicPlanDescription ?? current.publicPlanDescription,
          validFrom: payload.validFrom ?? current.validFrom,
          validTo: payload.validTo ?? current.validTo,
          pricePlanType: current.pricePlanType,
          pricePlanSetting: current.pricePlanSetting,
          price: current.price,
          taxDiv: current.taxDiv,
          taxRate: current.taxRate,
          reservablePeriod: current.reservablePeriod,
          cancellationDeadline: current.cancellationDeadline,
          cancelPolicy: current.cancelPolicy,
          chargeDetailIds: current.chargeDetailIds,
          registeredBy: current.registeredBy,
          lastUpdatedBy: current.lastUpdatedBy,
        };
      }
    },
  },
});

export const dropInPricePlanDetailScreenAsyncThunk = {
  initialLoad: createAsyncThunk<void, {dropInPricePlanId: string; tabId?: DropInPricePlansDetailTabs}>(
    'dropInPricePlansDetailScreenAsyncThunk/initialLoad',
    async ({dropInPricePlanId, tabId}, {getState, dispatch, rejectWithValue}) => {
      try {
        const state = getState() as RootState;
        const dropInPricePlan = await ApiDropInPricePlans.getDropInPricePlanById(dropInPricePlanId);
        const chargeDetails = await FirestoreOrgChargeDetail.getDataByIds(
          state.organization.id,
          dropInPricePlan.chargeDetailIds
        );
        dispatch(
          dropInPricePlansDetailScreenSlice.actions.initialize({
            dropInPricePlan: {
              id: dropInPricePlan.id,
              code: dropInPricePlan.code,
              name: dropInPricePlan.name,
              publicPlanName: dropInPricePlan.publicPlanName,
              publicPlanNameEn: dropInPricePlan.publicPlanNameEn,
              publicPlanDescription: dropInPricePlan.publicPlanDescription,
              publicPlanDescriptionEn: dropInPricePlan.publicPlanDescriptionEn,
              validFrom: dropInPricePlan.validFrom,
              validTo: dropInPricePlan.validTo,
              pricePlanType: dropInPricePlan.pricePlanType,
              pricePlanSetting: dropInPricePlan.pricePlanSetting,
              price: dropInPricePlan.price,
              taxDiv: dropInPricePlan.taxDiv,
              taxRate: dropInPricePlan.taxRate,
              reservablePeriod: dropInPricePlan.reservablePeriod,
              cancellationDeadline: dropInPricePlan.cancellationDeadline,
              cancelPolicy: dropInPricePlan.cancelPolicy,
              cancelPolicyEn: dropInPricePlan.cancelPolicyEn,
              chargeDetailIds: dropInPricePlan.chargeDetailIds,
              registeredBy: dropInPricePlan.registeredBy,
              lastUpdatedBy: dropInPricePlan.lastUpdatedBy,
            },
            chargeDetails: chargeDetails,
          })
        );
        // tabIdがある場合は、指定のタブに遷移する
        dispatch(dropInPricePlansDetailScreenSlice.actions.updateTabs(tabId ?? DropInPricePlansDetailTabs.OverView));
        return;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  ),
  deleteDropInPricePlan: createAsyncThunk<void>(
    'dropInPricePlansDetailScreenAsyncThunk/deleteDropInPricePlan',
    async (_, {getState, dispatch, rejectWithValue}) => {
      try {
        const state = getState() as RootState;
        const dropInPricePlanId = state.screens.dropInPricePlansDetailScreen.dropInPricePlanId;
        if (!dropInPricePlanId) return;
        dispatch(dropInPricePlansDetailScreenSlice.actions.updateDeleteLoading(true));
        await ApiDropInPricePlans.deletedDropInPricePlan(dropInPricePlanId);
        return;
      } catch (e) {
        return rejectWithValue(e);
      } finally {
        dispatch(dropInPricePlansDetailScreenSlice.actions.updateDeleteLoading(false));
      }
    }
  ),
};
