import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {WowtalkIntegrationState} from '@/common/redux/state-types/wowtalkIntegrationState';

const initialState: WowtalkIntegrationState = {};
export const wowtalkIntegrationSlice = createSlice({
  name: 'wowtalkIntegration',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<WowtalkIntegrationState>) => action.payload,
    clear: () => initialState,
  },
});
