import React, {SVGProps} from 'react';

import {WHColor} from '@/common/styles/whColor';
type Props = {
  color?: string;
  size?: number;
} & SVGProps<SVGSVGElement>;
const WIconAreaTypeSpace = React.memo<Props>(function WIconAreaTypeSpace({
  color = WHColor.object.brandPrimary,
  size = 24,
  ...rest
}) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24' fill='none' {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4.14163L6.0398 7.50841L12 10.8752L17.9602 7.50841L12 4.14163ZM5 9.22553L11 12.6148V19.3484L5 15.9591V9.22553ZM11.3607 21.8565C11.5681 21.9737 11.7894 22.0151 12 21.9952C12.2106 22.0151 12.4319 21.9737 12.6393 21.8565L20.4393 17.4505C20.7857 17.2548 21 16.8868 21 16.4877V7.68008C21 7.42751 20.919 7.20289 20.7859 7.02314C20.6937 6.83188 20.5435 6.66325 20.3353 6.54567L12.5393 2.14186C12.2044 1.95271 11.7956 1.95271 11.4607 2.14186L3.66469 6.54567C3.45654 6.66325 3.30635 6.83188 3.2141 7.02313C3.08097 7.20288 3 7.4275 3 7.68008V16.4877C3 16.8868 3.21435 17.2548 3.56071 17.4505L11.3607 21.8565ZM15.0124 18.2116L13 19.3484V12.6148L19 9.22553V15.9591L17 17.0889V14.0359C17 13.4813 16.5523 13.0317 16 13.0317C15.4477 13.0317 15 13.4813 15 14.0359V18.0528C15 18.1068 15.0042 18.1599 15.0124 18.2116ZM6.5 14.5457C6.5 14.9063 6.69417 15.239 7.00817 15.4164L8.75408 16.4026C9.0874 16.5909 9.5 16.3501 9.5 15.9673V14.1049C9.5 13.7443 9.30583 13.4116 8.99183 13.2342L7.24592 12.248C6.9126 12.0597 6.5 12.3005 6.5 12.6833V14.5457Z'
        fill={color}
      />
    </svg>
  );
});

export default WIconAreaTypeSpace;
