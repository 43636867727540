import dayjs from 'dayjs';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import dayOfYearPlugin from 'dayjs/plugin/dayOfYear';
import durationPlugin from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import objectSupportPlugin from 'dayjs/plugin/objectSupport';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import weekOfYearPlugin from 'dayjs/plugin/weekOfYear';

import 'dayjs/locale/en';
import 'dayjs/locale/ja';

dayjs.extend(customParseFormatPlugin);
dayjs.extend(dayOfYearPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(isoWeekPlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(objectSupportPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);
dayjs.extend(weekOfYearPlugin);
