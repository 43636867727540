import {FirebaseApp, initializeApp} from 'firebase/app';

let firebaseApp: FirebaseApp | undefined;
export const getFirebaseApp = (): FirebaseApp => {
  if (firebaseApp) {
    return firebaseApp;
  }
  if (import.meta.env.VITE_FIREBASE_PROJECT_ID === 'workhub-local') {
    firebaseApp = initializeApp({projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID, apiKey: 'a'});
  } else {
    firebaseApp = initializeApp({
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
    });
  }
  return firebaseApp;
};
