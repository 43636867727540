import {createFileRoute} from '@tanstack/react-router';

import WelcomeScreen from '@/wscreens/welcome/WelcomeScreen';

export const Route = createFileRoute('/sendResetPassword')({
  component: RouteComponent,
});

function RouteComponent() {
  return <WelcomeScreen isJumpResetPasswordScreen />;
}
