import {StoreTypesOrgContract} from '@bitkey-service/v2_core-types/lib/store/organizations/contract/storeTypesOrgContract';
import {StoreTypesOrgCustomer} from '@bitkey-service/v2_core-types/lib/store/organizations/customer/storeTypesOrgCustomer';
import {V2StoreTypesOrgPeople} from '@bitkey-service/v2_core-types/lib/store/organizations/people/v2_storeTypesOrgPeople';
import {StoreTypesOrgReservations} from '@bitkey-service/v2_core-types/lib/store/organizations/reservations/storeTypesOrgReservations';
import {V2StoreTypesOrgUserGroup} from '@bitkey-service/v2_core-types/lib/store/organizations/user-groups/v2_storeTypesOrgUserGroup';
import {createSlice} from '@reduxjs/toolkit';

import {OrgNfcCard} from '@/common/firebase/firestore/references/firestoreOrgNfcCards';
import {
  CustomerDetailControlDbState,
  CustomerDetailDialogFlag,
  CustomerDetailHeaderTabId,
  CustomerDetailLoadedDbState,
  CustomerDetailLoadedFlag,
  CustomerDetailState,
} from '@/common/redux/screens/state/customerDetailSlice';
import {ImageSelectorData} from '@/components/image/types/ImageSelectorData';
import {ThirdPlaceCustomerRolePresets} from '@/wscreens/coworking-guests/interactor/thirdPlaceCustomerRoleGetPresetsInteractor';

const InitialLoadedDBState: CustomerDetailLoadedDbState = {};

const InitialControlState: CustomerDetailControlDbState = {
  tabId: 'overview',
  selectedContractVersion: 1,
  dialogOpen: {},
  loaded: {},
};
const initialState: CustomerDetailState = {
  db: InitialLoadedDBState,
  control: InitialControlState,
};

const customerDetailSlice = createSlice({
  name: 'customerDetail',
  initialState,
  reducers: {
    addContracts: (
      state,
      action: {
        payload: {
          contracts?: StoreTypesOrgContract[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addActiveMemberContracts: (
      state,
      action: {
        payload: {
          activeMemberContracts?: (StoreTypesOrgContract & {thirdPlaceCustomerRoleIds: string[]})[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addPerson: (
      state,
      action: {
        payload: {
          person?: V2StoreTypesOrgPeople;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addFreeUserGroups: (
      state,
      action: {
        payload: {
          freeUserGroups?: V2StoreTypesOrgUserGroup[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addOtherUserGroups: (
      state,
      action: {
        payload: {
          otherUserGroups?: V2StoreTypesOrgUserGroup[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addCustomers: (
      state,
      action: {
        payload: {
          customers?: StoreTypesOrgCustomer[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addNfcCards: (
      state,
      action: {
        payload: {
          nfcCards?: OrgNfcCard[];
          lendNfcCards?: OrgNfcCard[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addPersonImageData: (
      state,
      action: {
        payload: ImageSelectorData;
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          personImage: action.payload,
        },
      };
    },
    addPeopleImageData: (
      state,
      action: {
        payload: ImageSelectorData[];
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          peopleImageData: action.payload,
        },
      };
    },
    addReservations: (
      state,
      action: {
        payload: {
          reservations: StoreTypesOrgReservations[];
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    addControlFlag: (
      state,
      action: {
        payload: CustomerDetailLoadedFlag;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          loaded: {
            ...state.control.loaded,
            contractLoad: action.payload.contractLoad ?? state.control.loaded?.contractLoad,
            personLoad: action.payload.personLoad ?? state.control.loaded?.personLoad,
            customerLoad: action.payload.customerLoad ?? state.control.loaded?.customerLoad,
            nfcCardsLoad: action.payload.nfcCardsLoad ?? state.control.loaded?.nfcCardsLoad,
            reservationsLoad: action.payload.reservationsLoad ?? state.control.loaded?.reservationsLoad,
            userGroupsLoad: action.payload.userGroupsLoad ?? state.control.loaded?.userGroupsLoad,
            rolePresetsLoad: action.payload.rolePresetsLoad ?? state.control.loaded?.rolePresetsLoad,
            activeMemberContractsLoad:
              action.payload.activeMemberContractsLoad ?? state.control.loaded?.activeMemberContractsLoad,
          },
        },
      };
    },
    addRolePresets: (
      state,
      action: {
        payload: {
          rolePresets: ThirdPlaceCustomerRolePresets;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload,
        },
      };
    },
    updateCustomerTabId: (
      state,
      action: {
        payload: CustomerDetailHeaderTabId;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          tabId: action.payload,
        },
      };
    },
    updateOpenDialogState: (
      state,
      action: {
        payload: CustomerDetailDialogFlag;
      }
    ) => {
      return {
        ...state,
        control: {
          ...state.control,
          dialogOpen: action.payload,
        },
      };
    },
    clear: () => initialState,
  },
});
export default customerDetailSlice;
