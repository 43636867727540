import {useSelector} from 'react-redux';

import {UserState} from '../redux/state-types/userStateType';
import {RootState} from '../redux/store/rootStateType';

const useLoginUser = () => {
  return useSelector<RootState, UserState>(s => s.user);
};

export {useLoginUser};
