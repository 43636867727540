import {StoreTypesOrgChargeDetail} from '@bitkey-service/v2_core-types/lib/store/organizations/charge-detail/storeTypesOrgChargeDetail';
import {
  ApplicationFormSetting,
  StoreTypesOrgContractPlans,
} from '@bitkey-service/v2_core-types/lib/store/organizations/contract-plans/storeTypesOrgContractPlans';
import {StoreTypesOrgOptionServices} from '@bitkey-service/v2_core-types/lib/store/organizations/option-services/storeTypesOrgOptionServices';
import {StoreTypesOrgPaymentMethod} from '@bitkey-service/v2_core-types/lib/store/organizations/payment-method/storeTypesOrgPaymentMethod';
import {V2StoreTypesOrgSpace} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';
import {StoreTypesOrgThirdPlaceAreaSettings} from '@bitkey-service/v2_core-types/lib/store/organizations/third-place-area-setting/storeTypesOrgThirdPlaceAreaSettings';

import {ImageSelectorData} from '@/components/image/types/ImageSelectorData';

export type CommonAvailableArea = {
  area: V2StoreTypesOrgSpace;
  floor?: V2StoreTypesOrgSpace;
  building?: V2StoreTypesOrgSpace;
  region?: V2StoreTypesOrgSpace;
  thirdPlaceAreaSetting?: StoreTypesOrgThirdPlaceAreaSettings;
};

/**
 * 契約プラン及び、オプションサービスを選択した時に持つ情報
 * 下記の情報を元に、データを取得していく。
 */
export type TPLoadedDbReleaseContractPlanState = {
  contractPlan?: StoreTypesOrgContractPlans; // 選択したプラン
  spaces?: V2StoreTypesOrgSpace[]; // 選択可能な空間
  optionServices?: StoreTypesOrgOptionServices[]; // 選択可能なオプションサービス
  availableAreas?: CommonAvailableArea[]; // 選択可能な空間のデータ集合体
  thirdPlaceAreaSettings?: StoreTypesOrgThirdPlaceAreaSettings[];
  paymentMethods?: StoreTypesOrgPaymentMethod[][];
  chargeDetails?: StoreTypesOrgChargeDetail[];
};

/**
 * 契約作成時に入力及び自動入力された項目の全て
 * これをそのままAPIに投げれる形に変換する
 */
export type TPInputReleaseContractPlanState = {
  id: string;
  public?: {
    /** 公開用名称 */
    name: string;
    nameJp?: string;
    nameEn?: string;
    /** 概要・説明 */
    description?: string;
    descriptionEn?: string;
    /** 注意事項 */
    precautions?: string;
    precautionsEn?: string;
    status?: 'public' | 'private';
    // プライバシーポリシー
    privacyPolicyUrl?: string;
    // 利用規約
    termOfServiceUrl?: string;
    // 申し込みフォームの設定
    applicationFormSettings?: ApplicationFormSetting[];
  };
  imageSelectors?: ImageSelectorData[];
  selectedSpaces?: V2StoreTypesOrgSpace[];
  selectedOptionServices?: StoreTypesOrgOptionServices[];
  availablePaymentMethods: ('invoice' | 'creditCard' | 'accountTransfer')[];
};

export type TPContractType = 'create' | 'renew' | 'draft' | 'add';

// 契約作成画面において、画面側の制御を行うための項目
export type TPReleaseContractPlanControl = {
  type: TPContractType;
  step: TPReleaseContractPlanStep;
  openConfirmDialog: boolean;
  // 画面の制御に必要な項目
  errorList: RELEASE_CONTRACT_PLAN_ERROR_TYPE[];
  isInviteMailSetting?: boolean;
  invalidPlanContractArea?: boolean;
  initialized?: boolean; // これ名前がよくない気もするな
  additionalLoaded?: boolean;
  readonly?: boolean;
  ownerSettingCompleted?: boolean;
};

export enum TPReleaseContractPlanStep {
  InitialMessage,
  Base,
  PrivacyPolicy,
  PublicArea,
  PublicOptionService,
  ApplicationFormSetting,
  Confirm,
  Complete,
}

export enum RELEASE_CONTRACT_PLAN_ERROR_TYPE {
  privacy_policy_required = 'privacy_policy_required',
  term_of_service_required = 'term_of_service_required',
}
