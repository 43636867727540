import {
  ApiTypesOrgContractPlansReq,
  ApiTypesOrgContractPlansRes,
  ApiTypesOrgContractPlansUpdateInvoiceSettingReq,
  ApiTypesOrgContractPlansUpdateReq,
  ApiTypesOrgPricePlansUpdateReq,
  ApiTypesOrgContractPlansGetQuery,
} from '@bitkey-service/v2_core-types/lib/api/organization/contract-plans/apiTypesOrgContractPlans';
import {AddPricePlan} from '@bitkey-service/v2_core-types/lib/api/organization/third-place/common/apiTypesOrgThirdPlaceCommon';
import {AxiosRequestConfig} from 'axios';

import {catchStack} from '@/common/error/stacktrace';
import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';
import {FsContractPlan} from '@/firestore/types/fsContractPlanType';
import {FsContract} from '@/firestore/types/fsContractType';

import {ApiWorkhubCore} from '../../apiWorkhubCore';

const basePath = () => {
  return '/organizations/contract-plans';
};
export class ApiWorkhubCoreContractPlan {
  private static get = <Response>(path: string) =>
    ApiWorkhubCore.get<Response>(FirebaseFunctions.makePath(basePath(), path)).catch(catchStack());
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.post<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.patch<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );
  private static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.delete<Response>(FirebaseFunctions.makePath(basePath(), path), config).catch(catchStack());

  public static add = (data: ApiTypesOrgContractPlansReq) =>
    ApiWorkhubCoreContractPlan.post<ApiTypesOrgContractPlansRes, ApiTypesOrgContractPlansReq>('/', data);
  public static deleteByIds = (data: {contractPlanIds: string[]}) =>
    ApiWorkhubCoreContractPlan.post('/bulk/delete', data);
  public static update = (contractPlanId: string, data: ApiTypesOrgContractPlansUpdateReq) =>
    ApiWorkhubCoreContractPlan.patch(`/${contractPlanId}`, data);
  public static addPricePlan = (contractPlanId: string, data: AddPricePlan) =>
    ApiWorkhubCoreContractPlan.post<ApiTypesOrgContractPlansRes, AddPricePlan>(
      `/${contractPlanId}/add-price-plan`,
      data
    );

  public static updatePricePlan = (
    contractPlanId: string,
    chargeDetailId: string,
    data: ApiTypesOrgPricePlansUpdateReq
  ) =>
    ApiWorkhubCoreContractPlan.patch<ApiTypesOrgContractPlansRes, ApiTypesOrgPricePlansUpdateReq>(
      `/${contractPlanId}/update-price-plan/${chargeDetailId}`,
      data
    );
  public static updateInvoiceSetting = (
    contractPlanId: string,
    data: ApiTypesOrgContractPlansUpdateInvoiceSettingReq
  ) => ApiWorkhubCoreContractPlan.patch(`/${contractPlanId}/update-invoice-setting`, data);
  public static getPlans = (query: ApiTypesOrgContractPlansGetQuery) =>
    ApiWorkhubCoreContractPlan.get<{plans: FsContractPlan[]}>(`/?type=${query.type}`);
  public static getPlan = (planId: string) =>
    ApiWorkhubCoreContractPlan.get<{contractPlan: FsContractPlan}>(`/${planId}`);
  public static getActivateContractsByPlan = (planId: string) =>
    ApiWorkhubCoreContractPlan.get<{contracts: FsContract[]}>(`/${planId}/contracts`);
}
