export enum BusinessType {
  None = 'None', // 読み込む前。基本的に何も表示できない状態。
  Core = 'CORE',
  // Coworking = 'CO_WORKING',
  Residence = 'RESIDENCE',

  // workhub用
  Office = 'office',
  // 上のやつとかぶるなー
  Coworking = 'coworking',
  // ただ悩む
  CoworkingGuest = 'coworking-guest',
}

export type BusinessTypeState = BusinessType;
