import {Locale} from '@/common/redux/state-types/localeStateType';

export const accessSecuritySectionMenuDef = {
  keyAndAuthenticationManagement: {
    default: {
      default: 'カギ・認証管理',
      [Locale.en_US]: 'Key and Authentication Management',
    },
  },
  wSecurityCardManagement: {
    default: {
      default: 'セキュリティカード管理',
      [Locale.en_US]: 'Security Card Management',
    },
  },
  signage: {default: {default: 'サイネージ', [Locale.en_US]: 'Signage'}},
  setting: {
    default: {
      default: '設定',
      [Locale.en_US]: 'Settings',
    },
  },
  productSupport: {
    default: {
      default: '製品サポート',
      [Locale.en_US]: 'Product support',
    },
  },
  aboutWorkhub: {
    default: {
      default: 'workhubについて',
      [Locale.en_US]: 'about workhub',
    },
  },
  authority: {default: {default: '権限設定', [Locale.en_US]: 'Authority Settings'}},
  mailTemplates: {
    default: {
      default: 'メールテンプレート設定',
      [Locale.en_US]: 'Mail Templates',
    },
  },
  linkageSetting: {
    default: {
      default: '外部サービス連携',
      [Locale.en_US]: 'External Service Linkage',
    },
  },
  ssoSetting: {
    default: {
      default: 'SSO連携設定',
      [Locale.en_US]: 'SSO Settings',
    },
  },
  organizationSetting: {
    default: {
      default: '組織設定',
      [Locale.en_US]: 'Organization Settings',
    },
  },
  notificationSetting: {
    default: {
      default: '通知設定',
      [Locale.en_US]: 'Notification Settings',
    },
  },
  invitationMailSetting: {
    default: {default: '会員招待メール設定', [Locale.en_US]: ' Member Invitation Mail Setting'},
  },

  reservationSetting: {default: {default: '予約設定', [Locale.en_US]: 'Reservation Settings'}},
  wReservationUnavailableTimeSettingScreen: {
    default: {
      default: '予約の一時停止スケジュール',
      [Locale.en_US]: 'Schedule for pausing reservations',
    },
  },
  receptionSetting: {default: {default: '受付設定', [Locale.en_US]: 'Reception Settings'}},
  receptionSettingTemplate: {default: {default: '受付設定テンプレート', [Locale.en_US]: 'Reception Setting Template'}},
  attendanceSetting: {
    default: {
      default: '勤怠設定',
      [Locale.en_US]: 'Attendance Setting',
    },
  },
  wCheckinInformation: {
    default: {
      default: 'チェックイン情報',
      [Locale.en_US]: 'Checkin Information',
    },
  },
  wSecurityCardInfo: {
    default: {
      default: 'セキュリティカード',
      [Locale.en_US]: 'Security Cards',
    },
  },
  wNfcCard: {
    default: {
      default: 'NFCカード',
      [Locale.en_US]: 'NFCカード',
    },
  },
  wSecurityCardGroup: {
    default: {
      default: 'セキュリティカードグループ',
      [Locale.en_US]: 'Security Card Groups',
    },
  },
  wTemporaryCardGroup: {
    default: {
      default: '一時利用カードグループ',
      [Locale.en_US]: 'Temporary Use Card Groups',
    },
  },
  bMmigrate: {
    default: {
      default: 'bM移行アシスタント',
      [Locale.en_US]: 'bM migrate assistant',
    },
  },
  wManualManagement: {
    default: {
      default: 'マニュアル管理',
      [Locale.en_US]: 'Manual Management',
    },
  },
  bMmigrateHelp: {
    default: {default: 'ヘルプ', [Locale.en_US]: 'Help'},
  },
  bMmigrateTourQA: {
    default: {default: 'Q&A', [Locale.en_US]: 'Q&A'},
  },
  wManual: {
    default: {
      default: 'マニュアル',
      [Locale.en_US]: 'Manual',
    },
  },
  wSupportSite: {
    default: {
      default: 'サポートサイト',
      [Locale.en_US]: 'Support Site',
    },
  },
  keyBundle: {
    default: {
      default: 'カギ束',
      [Locale.en_US]: 'Key Bundles',
    },
  },
  accessRights: {
    default: {
      default: 'アクセスコントロール',
      [Locale.en_US]: 'Access Controls',
    },
  },
  fixedPasscodes: {
    default: {
      default: '固定パスコード',
      [Locale.en_US]: 'Fixed Passcodes',
    },
  },
  bitkeyInfo: {
    default: {default: '会社概要', [Locale.en_US]: 'Company Overview'},
  },
  termOfUse: {
    default: {default: '利用規約', [Locale.en_US]: 'Term Of Use'},
  },
  privacyPolicy: {
    default: {default: 'プライバシーポリシー', [Locale.en_US]: 'Privacy Policy'},
  },
  whitePaper: {
    default: {default: 'ホワイトペーパー', [Locale.en_US]: 'White Paper'},
  },
  fmDashboardSetting: {
    default: {default: 'FMダッシュボード設定', [Locale.en_US]: 'FM Dashboard Settings'},
  },
};
