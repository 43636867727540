import React, {useCallback} from 'react';

import CsvUtil from '@/common/utils/csvUtil';
import {NameUtils} from '@/common/utils/nameUtil';
import WCsvDownloadDialog from '@/components/figma/others/dialog/csv/WCsvDownloadDialog';
import {routes} from '@/wscreens/routing/routing';

import {featureGroupExistBlackList, featureGroupToActivationGroup, menuDict} from '../debuggerService';

const FeatureGroupCsvDownloadDialog = React.memo<{
  open: boolean;
  onSuccess: () => any;
  onFail: () => any;
  onClose: () => any;
  disabled?: boolean;
}>(function FeatureGroupCsvDownloadDialog(props) {
  const {open, onSuccess, onFail, onClose, disabled} = props;

  const makeFeatureGroupList = useCallback(async () => {
    const header = ['親メニュー', '子メニュー', '組織作成依頼時の単位', 'activation'];
    const csvData: Array<string> = [];
    for (const route of routes) {
      const featureGroups = routes.flatMap(v =>
        v.type === 'withChildren' ? v.children.map(s => s.featureGroup) : v.featureGroup
      );

      if (
        route.type === 'withoutChildren' &&
        (featureGroupExistBlackList(route.featureGroup) || (route.hide && route.hide(featureGroups)))
      ) {
        continue;
      }

      const menuName = menuDict(route.dictKey);

      if (route.type === 'withChildren') {
        // なんでこんな面倒なことしているかっていうと資料の見た目を整えるため。
        let isParentAddFinish = false;
        for (const child of route.children) {
          const childFeatureGroup = child.featureGroup;
          if (featureGroupExistBlackList(childFeatureGroup) || (child.hide && child.hide(featureGroups))) {
            continue;
          }

          csvData.push(
            CsvUtil.makeCsvRow('', [
              isParentAddFinish ? '' : menuName,
              menuDict(child.dictKey),
              '',
              featureGroupToActivationGroup(childFeatureGroup) as string,
            ])
          );

          if (!isParentAddFinish) {
            isParentAddFinish = true;
          }
        }
      } else {
        // 子メニューがない場合
        csvData.push(
          CsvUtil.makeCsvRow('', [menuName, '', '', featureGroupToActivationGroup(route.featureGroup) as string])
        );
      }
    }

    return {header, csvData};
  }, []);

  return (
    <WCsvDownloadDialog
      open={open}
      title={'メニュー表示（activation）資料ダウンロード'}
      fileName={NameUtils.makeFileName('featureGroup.csv')}
      getData={makeFeatureGroupList}
      onSuccess={onSuccess}
      onFail={onFail}
      onClose={onClose}
      disabled={!!disabled}
    >
      <div>メニュー表示（activation）の資料をダウンロードします。（なおv1は対象外）</div>
    </WCsvDownloadDialog>
  );
});

export default FeatureGroupCsvDownloadDialog;
