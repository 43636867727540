import {useSelector} from 'react-redux';

import {LocaleState} from '../redux/state-types/localeStateType';
import {RootState} from '../redux/store/rootStateType';

const useLocale = () => {
  return useSelector<RootState, LocaleState>(s => s.locale);
};

export {useLocale};
