// --> TODO: 後でworkhubに移動する
import {StoreTypesOrgCurrentCheckIn} from '@bitkey-service/v2_core-types/lib/store/organizations/current-check-in/storeTypesOrgCurrentCheckIn';
import {
  CollectionOrgSpace,
  SpaceUsageReportShowType,
  V2SpaceAttribute,
  V2SpaceType,
  V2StoreTypesOrgSpace,
} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';
import {CollectionOrganizations} from '@bitkey-service/v2_core-types/lib/store/organizations/v2_storeTypesOrganization';
import {EpochMillis} from '@bitkey-service/workhub-functions-common-types/lib/common/commonTypes';
import {FirestoreCollectionPath} from '@bitkey-service/workhub-functions-common-types/lib/firestore/firestoreCollectionPath';
import {query, where} from 'firebase/firestore';

import {Firestore} from '../firebase-firestore';

const CollectionOrgSpaceCurrentCheckInPath = (organizationId: string, spaceId: string) =>
  FirestoreCollectionPath.createPath<StoreTypesOrgCurrentCheckIn>(
    CollectionOrganizations(),
    organizationId,
    `spaces/${spaceId}/currentCheckIn`
  );
// <--

const collection = (organizationId: string) =>
  Firestore.getInstance().getCollectionRef(CollectionOrgSpace(organizationId));

// 結局作っちゃった
export class FirestoreOrgSpaces {
  public static ref = (organizationId: string) => collection(organizationId);
  public static getAll = (organizationId: string) => Firestore.getInstance().getByQuery(collection(organizationId));
  public static getById = (orgId: string, spaceId: string) =>
    Firestore.getInstance().getById(collection(orgId), spaceId);
  public static getByIds = (orgId: string, spaceIds: string[]): Promise<V2StoreTypesOrgSpace[]> =>
    Firestore.getInstance().getByIds(collection(orgId), spaceIds);

  public static getByCodes = async (orgId: string, codes: (string | undefined)[]) => {
    return await Firestore.getByFieldValues(collection(orgId), 'code', codes, false);
  };

  public static getByParentId = (orgId: string, parentId: string): Promise<V2StoreTypesOrgSpace[]> =>
    Firestore.getInstance().getByQuery(query(collection(orgId), where('parentIds', 'array-contains', parentId)));

  public static getByParentIds = (orgId: string, parentIds: string[]) =>
    Firestore.getByFieldValues(collection(orgId), 'parentIds', parentIds, true);

  // 寝ぼけてたわ・・・でもコードだけは悪くないから悔しいけど残しちゃう
  // public static getByParentIds = async (orgId: string, parentIds: string[]): Promise<V2StoreTypesOrgSpace[]> => {
  // const instance = Firestore.getInstance();
  // return (await Promise.all(Array.from({length: Math.floor(parentIds.length / 10)})
  //   .map((_, idx) => parentIds.slice(idx * 10, (idx + 1) * 10)
  //     .map(ids => instance.getByQuery(collection(orgId).where('parentIds', 'array-contains-any', ids)))).flatMap(p => p))).flatMap(p => p);
  // }

  public static getByType = (orgId: string, type: V2SpaceType): Promise<V2StoreTypesOrgSpace[]> =>
    Firestore.getInstance().getByQuery(query(collection(orgId), where('type', '==', type)));

  public static getArea = (orgId: string): Promise<V2StoreTypesOrgSpace[]> =>
    Firestore.getInstance().getByQuery(query(collection(orgId), where('type', '==', 'Area')));

  public static getByTypes = (orgId: string, types: V2SpaceType[]) =>
    Firestore.getByFieldValues(collection(orgId), 'type', types, false);

  public static getByTypeAndParentIds = (orgId: string, type: V2SpaceType, parentIds: string[]) =>
    Firestore.getInstance().getByQuery(
      query(collection(orgId), where('type', '==', type), where('parentIds', 'array-contains-any', parentIds))
    );
  public static getByTypeAndParentId = (orgId: string, type: V2SpaceType, parentIds: string[]) =>
    Firestore.getInstance().getByQuery(
      query(collection(orgId), where('type', '==', type), where('parentId', 'in', parentIds))
    );

  public static getEmptyParentByType = (orgId: string, type: V2SpaceType) =>
    Firestore.getInstance().getByQuery(
      query(collection(orgId), where('type', '==', type), where('parentId', '==', ''))
    );

  public static getByAttributes = (orgId: string, attributes: V2SpaceAttribute[]) =>
    Firestore.getByFieldValues(collection(orgId), 'attributes', attributes, true);

  public static spaceCurrentCheckInRef = (organizationId: string, spaceId: string) =>
    Firestore.getInstance().getCollectionRef(CollectionOrgSpaceCurrentCheckInPath(organizationId, spaceId));
  public static getCurrentCheckIn = (organizationId: string, spaceId: string, recordedAt: EpochMillis) =>
    Firestore.getInstance().getByQuery(
      query(FirestoreOrgSpaces.spaceCurrentCheckInRef(organizationId, spaceId), where('recordedAt', '>', recordedAt))
    );

  public static getBySpaceUsageReportShowType = (organizationId: string, spaceUsageReport: SpaceUsageReportShowType) =>
    Firestore.getInstance().getByQuery(
      query(collection(organizationId), where('workspaceViewVisibleSettings.spaceUsageReport', '==', spaceUsageReport))
    );

  public static getCurrentCheckInByPeopleId = (
    organizationId: string,
    spaceId: string,
    peopleId: string,
    recordedAt: EpochMillis
  ) =>
    Firestore.getInstance().getByQuery(
      query(
        FirestoreOrgSpaces.spaceCurrentCheckInRef(organizationId, spaceId),
        where('peopleId', '==', peopleId),
        where('recordedAt', '>', recordedAt)
      )
    );
}
