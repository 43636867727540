export default class CsvUtil {
  /**
   * stringの配列の間に','を挿入します。
   * @param {string} noData - undefinedのデータに代わりに表示する文字列
   * @param {(string | undefined)[]} csvDatas - CSVの一行に表示する配列
   * @return {string[]}
   */
  public static makeCsvRow = (noData: string, csvDatas: (string | undefined)[]) => {
    return csvDatas
      .reduce((acc: string, val) => (acc += `"${val ? val.replace(/"/g, `""`) : noData}",`), '')
      .slice(0, -1);
  };
}
