import {
  ApiTypesOrgContractPlansReq,
  ApiTypesOrgContractPlansUpdateReq,
  ApiTypesOrgPricePlansUpdateReq,
} from '@bitkey-service/v2_core-types/lib/api/organization/contract-plans/apiTypesOrgContractPlans';
import {AddPricePlan} from '@bitkey-service/v2_core-types/lib/api/organization/third-place/common/apiTypesOrgThirdPlaceCommon';
import {InvoiceSetting} from '@bitkey-service/v2_core-types/lib/store/organizations/contract-plans/storeTypesOrgContractPlans';

import {ApiWorkhubCoreContractPlan} from '../../v2_api/workhub-core/organizations/contractPlan/v2_ApiWorkhubCoreCoworkingContractPlan';

export class ContractPlanService {
  public static add = (data: ApiTypesOrgContractPlansReq) => ApiWorkhubCoreContractPlan.add(data);
  public static deleteByIds = (data: {contractPlanIds: string[]}) => ApiWorkhubCoreContractPlan.deleteByIds(data);
  public static update = (contractPlanId: string, data: ApiTypesOrgContractPlansUpdateReq) =>
    ApiWorkhubCoreContractPlan.update(contractPlanId, data);
  public static addPricePlan = (contractPlanId: string, data: AddPricePlan) =>
    ApiWorkhubCoreContractPlan.addPricePlan(contractPlanId, data);
  public static updatePricePlan = (
    contractPlanId: string,
    chargeDetailId: string,
    data: ApiTypesOrgPricePlansUpdateReq
  ) => ApiWorkhubCoreContractPlan.updatePricePlan(contractPlanId, chargeDetailId, data);
  public static updateInvoiceSetting = (contractPlanId: string, data: InvoiceSetting) =>
    ApiWorkhubCoreContractPlan.updateInvoiceSetting(contractPlanId, data);
}
