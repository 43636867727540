import React, {useCallback} from 'react';

import CsvUtil from '@/common/utils/csvUtil';
import {NameUtils} from '@/common/utils/nameUtil';
import WCsvDownloadDialog from '@/components/figma/others/dialog/csv/WCsvDownloadDialog';
import {routes} from '@/wscreens/routing/routing';

import {authorityDict, featureGroupExistBlackList, menuDict} from '../debuggerService';

const FeatureCsvDownloadDialog = React.memo<{
  open: boolean;
  onSuccess: () => any;
  onFail: () => any;
  onClose: () => any;
  disabled?: boolean;
}>(function FeatureCsvDownloadDialog(props) {
  const {open, onSuccess, onFail, onClose, disabled} = props;

  const makeFeatureList = useCallback(async () => {
    const header = ['親メニュー', '子メニュー', '権限', '権限（開発向け）'];
    const csvData: Array<string> = [];
    for (const route of routes) {
      const featureGroups = routes.flatMap(v =>
        v.type === 'withChildren' ? v.children.map(s => s.featureGroup) : v.featureGroup
      );
      if (
        route.type === 'withoutChildren' &&
        (featureGroupExistBlackList(route.featureGroup) || (route.hide && route.hide(featureGroups)))
      ) {
        continue;
      }
      const menuName = menuDict(route.dictKey);

      if (route.type === 'withChildren') {
        // なんでこんな面倒なことしているかっていうと資料の見た目を整えるため。
        let isParentAddFinish = false;
        for (const child of route.children) {
          if (featureGroupExistBlackList(child.featureGroup) || (child.hide && child.hide(featureGroups))) {
            continue;
          }

          const feature = child.feature;
          csvData.push(
            CsvUtil.makeCsvRow('', [
              isParentAddFinish ? '' : menuName,
              menuDict(child.dictKey),
              authorityDict(feature),
              feature,
            ])
          );

          if (!isParentAddFinish) {
            isParentAddFinish = true;
          }
        }
      } else {
        // 子メニューがない場合
        // @ts-ignore
        const feature = route.feature;
        csvData.push(CsvUtil.makeCsvRow('', [menuName, '', authorityDict(feature), feature]));
      }
    }

    return {header, csvData};
  }, []);

  return (
    <WCsvDownloadDialog
      open={open}
      title={'権限とメニューの紐付き資料ダウンロード'}
      fileName={NameUtils.makeFileName('feature.csv')}
      getData={makeFeatureList}
      onSuccess={onSuccess}
      onFail={onFail}
      onClose={onClose}
      disabled={!!disabled}
    >
      <div>権限とメニューの紐付き資料をダウンロードします。（なおv1は対象外）</div>
    </WCsvDownloadDialog>
  );
});

export default FeatureCsvDownloadDialog;
