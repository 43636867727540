import styled from '@emotion/styled';
import {createFileRoute, useLocation} from '@tanstack/react-router';

import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHColor} from '@/common/styles/whColor';
import {WHFontCss} from '@/common/styles/whFont';
import WorkhubLogoText from '@/components/icon/WorkhubLogoText';

export const Route = createFileRoute('/_authorized/_mobile/form/satisfaction/complete')({
  component: RouteComponent,
});

const Main = styled.main`
  width: 100%;
  min-height: 100%;
  background-color: var(--surface-neutral-low);
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  flex: 0 0 130px;
  background-color: var(--surface-brand-primary);
  padding: 10px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Heading = styled.h1`
  ${WHFontCss.titleSmall}
  color: var(--text-neutral-primary);
  text-align: center;
  margin: 0 0 40px;
`;

const ContentWrapper = styled.section`
  padding: 40px 16px 50px;
  width: 100%;
  box-sizing: border-box;
`;

const Picture = styled.picture`
  display: block;
  margin: 40px;

  & > img,
  & > source {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const dictDef = {
  head: {
    default: {
      default: 'アンケートにご協力ありがとうございました',
      [Locale.en_US]: 'Thank you for your cooperation in the questionnaire.',
    },
  },
};

export function RouteComponent() {
  const location: {state: {score?: number}} = useLocation();
  const dict = useDict(dictDef);
  const isScoreUnderLow = location?.state?.score && location?.state?.score <= 4;

  return (
    <Main>
      <Header>
        <LogoWrapper>
          <WorkhubLogoText color={WHColor.text.neutralWhitePrimary} />
        </LogoWrapper>
      </Header>
      <ContentWrapper>
        <Heading>{dict.head}</Heading>
        <Picture>
          <source
            width='264'
            height='293'
            src={`/image/form/image-satisfaction-complete${isScoreUnderLow ? '-low' : ''}.png 1x`}
          />
          <source
            width='264'
            height='293'
            src={`/image/form/image-satisfaction-complete${isScoreUnderLow ? '-low' : ''}@2x.png 2x`}
          />
          <img
            width='264'
            height='293'
            src={`/image/form/image-satisfaction-complete${isScoreUnderLow ? '-low' : ''}.png`}
            aria-hidden='true'
            alt=''
          />
        </Picture>
      </ContentWrapper>
    </Main>
  );
}
