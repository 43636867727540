import styled from '@emotion/styled';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {Checkbox} from '@workhub/ui';
import {useCallback, useEffect, useMemo, useState} from 'react';
import * as R from 'remeda';

import useDict from '@/common/hooks/useDict';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {Locale} from '@/common/redux/state-types/localeStateType';
import WLoadingComponent from '@/components/figma/others/stepper/WLoadingComponent';
import WHeaderNavigation from '@/components/header/WHeaderNavigation';
import WHeaderSearch, {WHeaderSearchProps} from '@/components/header/WHeaderSearch';
import {WFacetSelectProps} from '@/components/search/WFacetSelect';
import V2PaginationHeader, {
  PAGE_SIZE,
  PaginationSettingKey,
} from '@/components/table/pagination/component/V2PaginationHeader';
import {AnnouncementTable} from '@/features/announcement/AnnouncementTable';
import {Authorize} from '@/routing/Authorize';
import {AnnouncementService} from '@/services/announcement/announcementService';

export const Route = createFileRoute('/_authorized/announcements/')({
  component: () => (
    <Authorize featureGroup='Announcement' feature='announcement'>
      <RouteComponent />
    </Authorize>
  ),
});

const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--surface-neutral-low);
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 0 var(--spacing-24);
  padding: var(--spacing-4) 0;
  border-bottom: 2px solid var(--border-on-light-default);
`;

const dictDef = {
  title: {
    default: {
      default: 'お知らせ',
      [Locale.en_US]: 'Announcements',
    },
  },
  navigation: {
    default: {
      default: 'お知らせ /',
      [Locale.en_US]: 'Announcement /',
    },
  },
  notExistAnnouncements: {
    default: {
      default: 'お知らせはありません',
      [Locale.en_US]: 'Not exist announcements.',
    },
  },
  unreadOnly: {
    default: {
      default: '未読のみ表示',
      [Locale.en_US]: 'Unread only',
    },
  },
  publisher: {
    default: {
      default: '送信元',
      [Locale.en_US]: 'Publisher',
    },
  },
};

function RouteComponent() {
  const dict = useDict(dictDef);
  const user = useLoginUser();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE[0]);
  const handleNextPage = useCallback((nextPage: number) => {
    setCurrentPage(nextPage);
  }, []);
  const handlePrevPage = useCallback((prevPage: number) => {
    setCurrentPage(prevPage);
  }, []);
  const handleChangePageSize = useCallback((_currentPageNumber: number, pageSize: number) => {
    setPageSize(pageSize);
  }, []);

  const offset = useMemo(() => (currentPage - 1) * pageSize, [currentPage, pageSize]);

  const {data, isLoading} = useQuery({
    queryKey: ['announcements', user.organizationId],
    queryFn: AnnouncementService.loadAnnouncements,
    placeholderData: keepPreviousData,
  });

  const announcements = useMemo(() => data ?? [], [data]);
  const unread = useMemo(() => announcements.filter(a => !a.read), [announcements]);
  const maxPageNumber = useMemo(
    () => (announcements.length ? Math.ceil(announcements.length / pageSize) : 1),
    [announcements, pageSize]
  );

  useEffect(() => {
    if (currentPage > maxPageNumber && currentPage > 1) {
      setCurrentPage(maxPageNumber || 1);
      handlePrevPage(maxPageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPageNumber]);

  // 絞り込み - 条件
  const [unreadOnly, setUnreadOnly] = useState<boolean>(false);
  const [filteredPublisherNames, setFilteredPublisherNames] = useState<string[]>([]);
  const publisherNames = useMemo(() => {
    return R.unique(announcements.map(a => a.publishedBy.name));
  }, [announcements]);
  const facets: WHeaderSearchProps['facets'] = useMemo(() => {
    if (publisherNames.length <= 1) {
      return [
        <Checkbox onChange={setUnreadOnly} isSelected={unreadOnly}>
          {dict.unreadOnly}
        </Checkbox>,
      ];
    }
    const publisherFacet: WFacetSelectProps = {
      label: dict.publisher,
      checkType: 'multi',
      choices: publisherNames.map(name => ({id: name, label: name})),
      onCheckStateChanged: selectedIds => {
        setFilteredPublisherNames(selectedIds);
      },
    };
    return [
      publisherFacet,
      <Checkbox onChange={setUnreadOnly} isSelected={unreadOnly}>
        {dict.unreadOnly}
      </Checkbox>,
    ];
  }, [dict.publisher, dict.unreadOnly, publisherNames, unreadOnly]);
  // 絞り込み - リセット
  const onReset = useCallback(() => {
    setFilteredPublisherNames([]);
    setUnreadOnly(false);
  }, []);
  // 絞り込み - 結果
  const filteredAnnouncements = useMemo(() => {
    if (unreadOnly) {
      if (filteredPublisherNames.length) {
        return unread.filter(a => filteredPublisherNames.includes(a.publishedBy.name));
      }
      return unread;
    }
    if (filteredPublisherNames.length) {
      return announcements.filter(a => filteredPublisherNames.includes(a.publishedBy.name));
    }
    return announcements;
  }, [announcements, filteredPublisherNames, unread, unreadOnly]);
  // ページング結果
  const pagedAnnouncements = useMemo(() => {
    return filteredAnnouncements.slice(offset, offset + pageSize);
  }, [filteredAnnouncements, pageSize, offset]);

  return (
    <Root>
      <div>
        <WHeaderNavigation title={dict.title} navigation={dict.navigation} />
        <WHeaderSearch
          facets={facets}
          searchableLabels={[]}
          updateSearchWord={() => {}}
          onReset={onReset}
          searchBoxWidth={0}
        />
        <PaginationWrapper>
          <V2PaginationHeader
            allLength={filteredAnnouncements.length}
            paginationSettingKey={PaginationSettingKey.AnnouncementTable}
            onChangePageSize={handleChangePageSize}
            onNext={handleNextPage}
            onPrev={handlePrevPage}
          />
        </PaginationWrapper>
      </div>
      {isLoading ? (
        <WLoadingComponent message={'Loading...'} notTransparent={true} />
      ) : (
        <AnnouncementTable announcements={pagedAnnouncements} />
      )}
    </Root>
  );
}
