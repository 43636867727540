import React from 'react';
import {createRoot} from 'react-dom/client';

import Env from '@/common/env/env';
import {addPreloadErrorListener} from '@/common/vite/preloadErrorListener';
import {Datadog} from '@/monitoring/datadog';

import App from './App';

import '@workhub/ui/styles.css';

// 初回アクセスで以下の事象が発生する
// https://stackoverflow.com/questions/75433591/failed-to-load-module-script-expected-a-javascript-module-script-but-the-server
// のでその対応
// https://ja.vitejs.dev/guide/build#%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%82%A8%E3%83%A9%E3%83%BC%E3%81%AE%E3%83%8F%E3%83%B3%E3%83%88%E3%82%99%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%99
addPreloadErrorListener();

Datadog.startMonitoring();
Env.init();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
