import {StoreTypesOrgChargeDetail} from '@bitkey-service/v2_core-types/lib/store/organizations/charge-detail/storeTypesOrgChargeDetail';
import {
  DayOfWeek,
  StoreTypesOrgClaimCycle,
} from '@bitkey-service/v2_core-types/lib/store/organizations/claim-cycle/storeTypesOrgClaimCycle';
import {
  CommonContractCorporateInfo,
  ContractCustomer,
  StoreTypesOrgCustomer,
} from '@bitkey-service/v2_core-types/lib/store/organizations/customer/storeTypesOrgCustomer';
import {StoreTypesOrgOptionServices} from '@bitkey-service/v2_core-types/lib/store/organizations/option-services/storeTypesOrgOptionServices';
import {PaymentMethodType} from '@bitkey-service/v2_core-types/lib/store/organizations/payment-method/storeTypesOrgPaymentMethod';
import {V2StoreTypesOrgPeople} from '@bitkey-service/v2_core-types/lib/store/organizations/people/v2_storeTypesOrgPeople';
import {V2StoreTypesOrgSpace} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';
import {createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {AddressUtil} from '@/common/utils/addressUtils';
import ArrayUtil from '@/common/utils/arrayUtil';
import {CalculatedChargeDetailTypes} from '@/wscreens/third-place/common/interactors/calcChargeDetailInteractor';
import {TPCustomer} from '@/wscreens/third-place/contract-add/types/TPCustomer';
import {CONTRACT_CREATE_ERROR_TYPE} from '@/wscreens/third-place/types/common/WContractCreateContext';
import {BankAccountInput, InputRepresentativeType} from '@/wscreens/third-place/types/common/WContractCreateTypes';
import {
  TPContractControl,
  TPContractCreateSteps,
  TPContractType,
  TPInputContractState,
  TPInputContractStateArg,
  TPLoadedDbState,
} from '@/wscreens/third-place/types/ContractCreateState';

import {CreateContractState} from '../state/createContractState';

const InitialInputContractState: TPInputContractState = {
  contractCode: '',
  conclusionDate: dayjs().startOf('d').valueOf(),
  contractTerm: {
    startAt: dayjs().startOf('d').valueOf(),
  },
  selectedOptionServices: [],
  selectedAllClaimCycles: [],
  employeeContractors: [],
  inChargeContractor: [],
  billingNotifications: [],
  members: [],
  selectedSpaces: [],
  corporateInfo: {
    name: '',
    firstNameJp: '',
    familyNameJp: '',
  },
  representative: {
    name: '',
    firstNameJp: '',
    familyNameJp: '',
    email: '',
    identificationImageData: [],
    thirdPlaceCustomerRoleCodes: [],
  },
  billTo: {
    name: '',
    firstNameJp: '',
    familyNameJp: '',
    email: '',
  },
  customerType: 'business',
  manualAddedChargeDetails: [],
};

const InitialLoadedDBState: TPLoadedDbState = {
  selectableOptionServices: [],
  chargeDetails: [],
  claimCycles: [],
  paymentMethods: [],
  selectableSpaces: [],
  allRelationSpaces: [],
  thirdPlaceAreaSettings: [],
  loadAllClaimCycles: [],
  loadAllCustomer: [],
  loadAllPeople: [],
  loadAllEmployee: [],
  initialPayMethodTypes: [],
  basePayMethodTypes: [],
};

const InitialControlState: TPContractControl = {
  errorList: [],
  step: 0,
  isInviteMailSetting: false,
  type: 'create',
};
const initialState: CreateContractState = {
  db: InitialLoadedDBState,
  input: InitialInputContractState,
  control: InitialControlState,
};

// FIXME
// ここの書き方ちょっとぐらついている、、、
// 基本的に、既存のstateを見てなんかする処理はこっちでやったほうがいいと思ってるんだけど、
// actionsに依存しているところもある。
// 量的にはそんな多くないので、後で整理する。
// あとsetInputとかいらんのでは、っていう気持ちが強くなってきた。
const createContractSlice = createSlice({
  name: 'createContract',
  initialState,
  reducers: {
    // 基本形
    setInput: (state, action: {payload: Partial<TPInputContractStateArg>}) => ({
      ...state,
      input: {
        ...state.input,
        ...action.payload,
      },
    }),
    setControl: (state, action: {payload: Partial<TPContractControl>}) => ({
      ...state,
      control: {
        ...state.control,
        ...action.payload,
      },
    }),
    setNext: state => ({
      ...state,
      control: {
        ...state.control,
        step: state.control.step + 1,
        readonly: state.control.step + 1 === TPContractCreateSteps.Confirm,
      },
    }),
    setBack: state => ({
      ...state,
      control: {
        ...state.control,
        step: state.control.step - 1,
        errorList: [],
        readonly: state.control.step - 1 === TPContractCreateSteps.Confirm,
      },
    }),
    setStep: (state, action: {payload: number}) => ({
      ...state,
      control: {
        ...state.control,
        step: action.payload,
        readonly: action.payload === TPContractCreateSteps.Confirm,
      },
    }),
    // 特殊系
    // 引数をそのまま使うのではなく、計算したり変換したりするやつら
    setStartAt: (
      state,
      action: {
        payload: {
          startAt: number;
          calculated: CalculatedChargeDetailTypes;
        };
      }
    ) => ({
      ...state,
      input: {
        ...state.input,
        calcChargeDetailAll: action.payload.calculated,
        contractTerm: {
          ...state.input.contractTerm,
          startAt: action.payload.startAt,
        },
      },
    }),
    setEndAt: (state, action: {payload: number | undefined}) => ({
      ...state,
      input: {
        ...state.input,
        contractTerm: {
          ...state.input.contractTerm,
          endAt: action.payload,
        },
      },
    }),
    setMemo: (state, action: {payload: string | undefined}) => ({
      ...state,
      input: {
        ...state.input,
        memo: action.payload,
      },
    }),
    setPlan: (
      state,
      action: {
        payload: {
          db: Partial<TPLoadedDbState>;
          input: Partial<TPInputContractState>;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload.db,
        },
        input: {
          ...state.input,
          ...action.payload.input,
        },
      };
    },
    removePlan: state => ({
      ...state,
      db: {
        ...state.db,
        claimCycles: [],
        chargeDetails: [],
        paymentMethods: [],
        contractPlan: undefined,
        thirdPlaceAreaSettings: [],
        allRelationSpaces: [],
        selectableOptionServices: [],
        selectableSpaces: [],
      },
      input: {
        ...state.input,
        calcChargeDetailAll: undefined,
        calcChargeDetails: [],
        selectedContractPlan: undefined,
        selectedOptionServices: [],
        initialClaimCycle: undefined,
        baseCycle: undefined,
        selectedAllClaimCycles: [],
        selectedSpaces: [],
      },
    }),
    setOptionServices: (
      state,
      action: {
        payload: {
          optionServices: StoreTypesOrgOptionServices[];
          chargeDetailsAll: CalculatedChargeDetailTypes;
          optionChargeDetails: StoreTypesOrgChargeDetail[];
          optionClaimCycles: StoreTypesOrgClaimCycle[];
        };
      }
    ) => {
      // FIXME これは処理を共通化できる
      const initialDetails = action.payload.chargeDetailsAll.details.filter(detail => detail.billingType === 'initial');
      const baseDetails = action.payload.chargeDetailsAll.details.filter(detail => detail.billingType !== 'initial');

      const baseCycleIds = baseDetails.map(base => base.claimCycleId);
      const existBaseCycle = baseCycleIds.some(baseCycleId => state.input.baseCycle?.id === baseCycleId);
      const newBaseCycle = action.payload.optionClaimCycles.find(cycle =>
        baseCycleIds.some(cycleId => cycleId === cycle.id)
      );

      const initialCycleIds = initialDetails.map(initial => initial.claimCycleId);
      const existInitialCycle = initialCycleIds.some(
        initialCycleId => state.input.initialClaimCycle?.id === initialCycleId
      );
      const newInitialCycle = action.payload.optionClaimCycles.find(cycle =>
        initialCycleIds.some(cycleId => cycleId === cycle.id)
      );
      return {
        ...state,
        db: {
          ...state.db,
          claimCycles: [...state.db.claimCycles, ...action.payload.optionClaimCycles],
          chargeDetails: [...state.db.chargeDetails, ...action.payload.optionChargeDetails],
        },
        input: {
          ...state.input,
          selectedOptionServices: [...state.input.selectedOptionServices, ...action.payload.optionServices],
          calcChargeDetailAll: action.payload.chargeDetailsAll,
          selectedAllClaimCycles: [
            ...state.input.selectedAllClaimCycles.filter(claimCycle =>
              action.payload.optionClaimCycles.some(cycle => cycle.id !== claimCycle.id)
            ),
            ...action.payload.optionClaimCycles,
          ],
          baseCycle: existBaseCycle ? state.input.baseCycle : newBaseCycle,
          initialClaimCycle: existInitialCycle ? state.input.initialClaimCycle : newInitialCycle,
        },
      };
    },
    removeOptionService: (
      state,
      action: {
        payload: {
          removeOptionServiceId: string;
          updateCalculatedDetail: CalculatedChargeDetailTypes;
        };
      }
    ) => {
      if (!action.payload.removeOptionServiceId) {
        return state;
      }
      const initialDetails = action.payload.updateCalculatedDetail.details.filter(
        detail => detail.billingType === 'initial'
      );
      const baseDetails = action.payload.updateCalculatedDetail.details.filter(
        detail => detail.billingType !== 'initial'
      );
      const baseCycleIds = baseDetails.map(base => base.claimCycleId);
      const existBaseCycle = baseCycleIds.some(baseCycleId => state.input.baseCycle?.id === baseCycleId);
      const newBaseCycle = state.input.selectedAllClaimCycles.find(cycle =>
        baseCycleIds.some(cycleId => cycleId === cycle.id)
      );

      const initialCycleIds = initialDetails.map(initial => initial.claimCycleId);
      const existInitialCycle = initialCycleIds.some(
        initialCycleId => state.input.initialClaimCycle?.id === initialCycleId
      );
      const newInitialCycle = state.input.selectedAllClaimCycles.find(cycle =>
        initialCycleIds.some(cycleId => cycleId === cycle.id)
      );
      return {
        ...state,
        input: {
          ...state.input,
          selectedOptionServices: state.input.selectedOptionServices.filter(
            option => option.id !== action.payload.removeOptionServiceId
          ),
          calcChargeDetailAll: action.payload.updateCalculatedDetail,
          baseCycle: existBaseCycle ? state.input.baseCycle : newBaseCycle,
          initialClaimCycle: existInitialCycle ? state.input.initialClaimCycle : newInitialCycle,
        },
      };
    },
    setInitialClaimCycle: (state, action: {payload: StoreTypesOrgClaimCycle}) => {
      if (!action.payload) {
        return state;
      }
      if (!state.input.calcChargeDetailAll) {
        return;
      }
      return {
        ...state,
        input: {
          ...state.input,
          initialClaimCycle: action.payload,
          selectedAllClaimCycles: [
            ...state.input.selectedAllClaimCycles.filter(cycle => cycle.id !== action.payload?.id),
            action.payload,
          ],
          calcChargeDetailAll: {
            ...state.input.calcChargeDetailAll,
            details:
              state.input.calcChargeDetailAll?.details.map(detail => {
                if (detail.billingType === 'initial') {
                  return {
                    ...detail,
                    claimCycleId: action.payload.id ?? '',
                  };
                } else {
                  return detail;
                }
              }) ?? [],
          },
          manualAddedChargeDetails: state.input.manualAddedChargeDetails.map(detail => ({
            ...detail,
            claimCycleId: action.payload.id,
          })),
        },
      };
    },
    setSelectSpace: (state, action: {payload: V2StoreTypesOrgSpace}) => ({
      ...state,
      input: {
        ...state.input,
        selectedSpaces: [...state.input.selectedSpaces.filter(space => space.id !== action.payload.id), action.payload],
      },
    }),
    removeSelectSpace: (
      state,
      action: {
        payload: {
          spaceId: string;
          calculatedDetailsAll?: CalculatedChargeDetailTypes;
        };
      }
    ) => ({
      ...state,
      input: {
        ...state.input,
        selectedSpaces: state.input.selectedSpaces.filter(space => space.id !== action.payload.spaceId),
        calcChargeDetailAll: action.payload.calculatedDetailsAll,
      },
    }),
    addError: (state, action: {payload: CONTRACT_CREATE_ERROR_TYPE}) => {
      if (state.control.errorList.some(error => error === action.payload)) {
        return;
      }
      return {
        ...state,
        control: {
          ...state.control,
          errorList: ArrayUtil.removeDuplicateBySet([...state.control.errorList, action.payload]),
        },
      };
    },

    removeError: (state, action: {payload: CONTRACT_CREATE_ERROR_TYPE}) => {
      if (!state.control.errorList.some(error => error === action.payload)) {
        return;
      }
      return {
        ...state,
        control: {
          ...state.control,
          errorList: ArrayUtil.removeDuplicateBySet(state.control.errorList.filter(error => error !== action.payload)),
        },
      };
    },

    setUseAddressFromSpace: state => {
      const areaAddresses = state.input.selectedSpaces.map(space => space.v2address);
      const parentIds = state.input.selectedSpaces.flatMap(space => space.parentIds);
      const buildingAddress = state.db.allRelationSpaces
        .filter(space => space.type === 'Building')
        .find(space => parentIds.some(id => space.id === id))?.v2address;
      const adress =
        areaAddresses.find(
          address =>
            address?.postalCode ||
            address?.prefecture ||
            address?.city ||
            address?.addressLine1 ||
            address?.addressLine2
        ) ?? buildingAddress;
      return {
        ...state,
        input: {
          ...state.input,
          corporateInfo: {
            ...state.input.corporateInfo,
            postcode: adress?.postalCode ?? state.input.corporateInfo.postcode,
            address: adress ? AddressUtil.toString(adress) : state.input.corporateInfo.address,
            useRentalSpaceAddress: true,
          },
        },
      };
    },
    removeUseAddressFromSpace: state => {
      return {
        ...state,
        input: {
          ...state.input,
          corporateInfo: {
            ...state.input.corporateInfo,
            postcode: '',
            address: '',
            useRentalSpaceAddress: false,
          },
        },
      };
    },
    setCorporate: (
      state,
      action: {
        payload: Partial<
          CommonContractCorporateInfo & {
            useRentalSpaceAddress?: boolean;
            isRegistered?: boolean;
            corporateRegistrationNumber?: string;
            corporateRegistrationFiles?: File[];
          }
        >;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          corporateInfo: {
            ...state.input.corporateInfo,
            ...action.payload,
          },
          billTo: state.input.billAccount?.isBillingAddressSame
            ? {
                ...state.input.billTo,
                ...state.input.corporateInfo,
                ...action.payload,
                useRentalSpaceAddress: undefined,
                isRegistered: undefined,
                corporateRegistrationNumber: undefined,
                corporateRegistrationFiles: undefined,
                managedCode: undefined,
              }
            : state.input.billTo,
        },
      };
    },

    removeInChargeContractor: (
      state,
      action: {
        payload: {
          removeIndex: number;
        };
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          inChargeContractor: state.input.inChargeContractor.filter((_, idx) => idx !== action.payload.removeIndex),
        },
      };
    },

    setNewInChargeContractor: (
      state,
      action: {
        payload: (ContractCustomer & {
          peopleId?: string;
        })[];
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          inChargeContractor: [...state.input.inChargeContractor, ...action.payload],
        },
      };
    },
    setInChargeContractor: (
      state,
      action: {
        payload: ContractCustomer[];
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          inChargeContractor: action.payload,
        },
      };
    },
    setRepresentative: (
      state,
      action: {
        payload: Partial<InputRepresentativeType>;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          representative: {
            ...state.input.representative,
            ...action.payload,
          },
          billTo: state.input.billAccount?.isBillingAddressSame
            ? {
                ...state.input.billTo,
                email: action.payload.email ?? '',
              }
            : state.input.billTo,
        },
      };
    },
    setConsumerContractor: (
      state,
      action: {
        payload: Partial<InputRepresentativeType>;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          representative: {
            ...state.input.representative,
            ...action.payload,
          },
          corporateInfo: {
            ...state.input.corporateInfo,
            name: action.payload?.name ?? state.input.corporateInfo.name,
            familyNameJp: action.payload?.familyNameJp ?? state.input.corporateInfo.familyNameJp,
            firstNameJp: action.payload?.firstNameJp ?? state.input.corporateInfo.firstNameJp,
            familyNameKana: action.payload?.familyNameKana ?? state.input.corporateInfo.familyNameKana,
            firstNameKana: action.payload?.firstNameKana ?? state.input.corporateInfo.firstNameKana,
            nameKana: action.payload?.nameKana ?? state.input.corporateInfo.nameKana,
            telePhone: action.payload?.telePhone ?? state.input.corporateInfo.telePhone,
            address: action.payload?.address ?? state.input.corporateInfo.address,
          },
          billTo: state.input.billAccount?.isBillingAddressSame
            ? {
                ...state.input.billTo,
                ...state.input.corporateInfo,
                ...action.payload,
                useRentalSpaceAddress: undefined,
                isRegistered: undefined,
                corporateRegistrationNumber: undefined,
                corporateRegistrationFiles: undefined,
                managedCode: undefined,
              }
            : state.input.billTo,
        },
      };
    },
    setChargeContractor: (
      state,
      action: {
        payload: ContractCustomer;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          inChargeContractor: [...state.input.inChargeContractor, action.payload],
        },
      };
    },
    setBillTo: (
      state,
      action: {
        payload: Partial<ContractCustomer>;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          billTo: {
            ...state.input.billTo,
            ...action.payload,
          },
        },
      };
    },
    addNewNotification: (
      state,
      action: {
        payload: ContractCustomer;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          billingNotifications: [...state.input.billingNotifications, action.payload],
        },
      };
    },

    setNotification: (
      state,
      action: {
        payload: {idx: number; notification: Partial<ContractCustomer>};
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          billingNotifications: state.input.billingNotifications.map((notification, idx) => {
            if (action.payload.idx === idx) {
              return {
                ...notification,
                ...action.payload.notification,
              };
            } else {
              return notification;
            }
          }),
        },
      };
    },
    deleteNotification: (
      state,
      action: {
        payload: {idx: number};
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          billingNotifications: state.input.billingNotifications.filter((_, idx) => action.payload.idx !== idx),
        },
      };
    },
    setSyncBillAddressCorporate: state => {
      return {
        ...state,
        input: {
          ...state.input,
          billTo: {
            ...state.input.billTo,
            name: state.input.corporateInfo.name,
            familyNameJp: state.input.corporateInfo.familyNameJp,
            firstNameJp: state.input.corporateInfo.firstNameJp,
            nameKana: state.input.corporateInfo.nameKana,
            familyNameKana: state.input.corporateInfo.familyNameKana,
            firstNameKana: state.input.corporateInfo.firstNameKana,
            telePhone: state.input.corporateInfo.telePhone,
            email: state.input.representative.email,
            postcode: state.input.corporateInfo.postcode,
            address: state.input.corporateInfo.address,
          },
          billAccount: {
            ...state.input.billAccount,
            isBillingAddressSame: true,
          },
        },
      };
    },
    removeSyncBillAddress: state => {
      return {
        ...state,
        input: {
          ...state.input,
          billTo: {
            ...state.input.billTo,
            name: '',
            familyNameJp: '',
            firstNameJp: '',
            nameKana: '',
            familyNameKanaJp: '',
            firstNameKanaJp: '',
            familyNameKana: '',
            firstNameKana: '',
            telePhone: undefined,
            email: '',
            postcode: undefined,
            address: undefined,
          },
          billAccount: {
            ...state.input.billAccount,
            isBillingAddressSame: false,
          },
        },
      };
    },

    setSyncBillAddressCorporateInfo: state => {
      return {
        ...state,
        input: {
          ...state.input,
          billTo: {
            ...state.input.billTo,
            name: state.input.corporateInfo.name,
            familyNameJp: state.input.corporateInfo.familyNameJp,
            firstNameJp: state.input.corporateInfo.firstNameJp,
            nameKana: state.input.corporateInfo.nameKana,
            familyNameKana: state.input.corporateInfo.familyNameKana,
            firstNameKana: state.input.corporateInfo.firstNameKana,
            telePhone: state.input.corporateInfo.telePhone,
            email: state.input.representative.email,
            postcode: state.input.corporateInfo.postcode,
            address: state.input.corporateInfo.address,
          },
          billAccount: {
            ...state.input.billAccount,
            isBillingAddressSame: true,
          },
        },
        control: {
          ...state.control,
          errorList: state.control.errorList.filter(
            list => list !== CONTRACT_CREATE_ERROR_TYPE.contract_billing_customer_input_invalid
          ),
        },
      };
    },
    setSyncBankRefundsFromBill: (
      state,
      action: {
        payload: {refundBankAccountSame: boolean};
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          refundBankAccount: {
            isRefundAddressSame: action.payload.refundBankAccountSame,
            bankName: state.input.billAccount?.bankName,
            bankCode: state.input.billAccount?.bankCode,
            branchCode: state.input.billAccount?.branchCode,
            branchName: state.input.billAccount?.branchName,
            accountType: state.input.billAccount?.accountType,
            accountNumber: state.input.billAccount?.accountNumber,
            jpBankSymbol: state.input.billAccount?.jpBankSymbol,
            jpBankNumber: state.input.billAccount?.jpBankNumber,
            accountHolder: state.input.billAccount?.accountHolder,
          },
        },
      };
    },
    removeSyncBankRefundsFromBill: state => {
      return {
        ...state,
        input: {
          ...state.input,
          refundBankAccount: undefined,
        },
      };
    },
    setRefundBank: (
      state,
      action: {
        payload: {bank: BankAccountInput};
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          refundBankAccount: {
            ...state.input.refundBankAccount,
            ...action.payload.bank,
          },
        },
      };
    },
    clearRefundBank: state => {
      return {
        ...state,
        input: {
          ...state.input,
          refundBankAccount: undefined,
        },
      };
    },

    setBillBank: (
      state,
      action: {
        payload: {bank: BankAccountInput};
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          billAccount: {
            ...state.input.billAccount,
            ...action.payload.bank,
          },
        },
      };
    },
    setBillBankCustomerNo: (
      state,
      action: {
        payload: string;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          billAccount: {
            ...state.input.billAccount,
            customerNo: action.payload,
          },
        },
      };
    },
    setLoaded: (
      state,
      action: {
        payload: {
          initialized: boolean;
          isInviteMailSetting: boolean;
          contractor: {id: string; name: string; email: string; iconImage?: string};
          allClaimCycles: StoreTypesOrgClaimCycle[];
          ownerSettingCompleted?: boolean;
          allCustomer: StoreTypesOrgCustomer[];
          allPeople: V2StoreTypesOrgPeople[];
          allEmployee: V2StoreTypesOrgPeople[];
        };
      }
    ) => ({
      ...state,
      control: {
        ...state.control,
        initialized: action.payload.initialized,
        isInviteMailSetting: action.payload.isInviteMailSetting,
        ownerSettingCompleted: action.payload.ownerSettingCompleted,
      },
      db: {
        ...state.db,
        loadAllClaimCycles: action.payload.allClaimCycles,
        loadAllCustomer: action.payload.allCustomer,
        loadAllPeople: action.payload.allPeople,
        loadAllEmployee: action.payload.allEmployee,
      },
      input: {
        ...state.input,
        employeeContractors: [action.payload.contractor],
      },
    }),
    setInitialBillDate: (
      state,
      action: {
        payload: Partial<{
          day?: DayOfWeek;
          baseUnit?: 'd' | 'w' | 'M' | 'y';
          baseQty?: number;
          date?: number;
          month?: number;
          holidayTransfer?: 'prev' | 'next' | 'ignore';
          monthDateMethod?: 'end' | 'begin';
        }>;
      }
    ) => {
      if (!state.input.initialClaimCycle) {
        return;
      }
      const newInitialClaimCycle = {
        ...state.input.initialClaimCycle,
        billDate: {
          ...state.input.initialClaimCycle?.billDate,
          ...action.payload,
        },
      };
      return {
        ...state,
        input: {
          ...state.input,
          selectedAllClaimCycles: [
            ...state.input.selectedAllClaimCycles.filter(cycle => cycle.id !== newInitialClaimCycle?.id),
            newInitialClaimCycle,
          ],
          initialClaimCycle: newInitialClaimCycle,
        },
      };
    },
    setInitialBillDateDate: (
      state,
      action: {
        payload: number;
      }
    ) => {
      if (!state.input.initialClaimCycle) {
        return;
      }
      const now = dayjs().startOf('d');
      const valueDate = dayjs(action.payload).startOf('d');
      const diffDate = valueDate.diff(now, 'days');
      const newInitialClaimCycle = {
        ...state.input.initialClaimCycle,
        billDate: {
          ...state.input.initialClaimCycle?.billDate,
          baseQty: diffDate,
          baseUnit: 'd' as const, // 日付固定
        },
      };
      return {
        ...state,
        input: {
          ...state.input,
          selectedAllClaimCycles: [
            ...state.input.selectedAllClaimCycles.filter(cycle => cycle.id !== newInitialClaimCycle?.id),
            newInitialClaimCycle,
          ],
          initialClaimCycle: newInitialClaimCycle,
        },
      };
    },
    setInitialPaymentDeadline: (
      state,
      action: {
        payload: Partial<{
          day?: DayOfWeek;
          baseUnit?: 'd' | 'w' | 'M' | 'y';
          baseQty?: number;
          date?: number;
          month?: number;
          holidayTransfer?: 'prev' | 'next' | 'ignore';
          monthDateMethod?: 'end' | 'begin';
          referenceDate?: 'closing_date' | 'bill_date';
        }>;
      }
    ) => {
      if (!state.input.initialClaimCycle) {
        return;
      }
      const newInitialClaimCycle = {
        ...state.input.initialClaimCycle,
        paymentDeadLine: {
          ...state.input.initialClaimCycle.paymentDeadLine,
          ...action.payload,
        },
      };
      return {
        ...state,
        input: {
          ...state.input,
          selectedAllClaimCycles: [
            ...state.input.selectedAllClaimCycles.filter(cycle => cycle.id !== newInitialClaimCycle?.id),
            newInitialClaimCycle,
          ],
          initialClaimCycle: newInitialClaimCycle,
        },
      };
    },

    setPayForAgree: (
      state,
      action: {
        payload: boolean;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          payForAgree: action.payload,
        },
      };
    },
    setSyncBillType: state => {
      return {
        ...state,
        input: {
          ...state.input,
          initialBillSyncBaseCycle: true,
          initialPaymentMethodType: state.input.basePaymentMethodType,
          initialClaimCycle: state.input.baseCycle,
        },
      };
    },
    setUnSyncBillType: state => {
      return {
        ...state,
        input: {
          ...state.input,
          initialBillSyncBaseCycle: false,
          initialPaymentMethodType: PaymentMethodType.Invoice,
          initialClaimCycle: state.input.selectedAllClaimCycles.find(cycle => cycle.cycleType === 'oneShot'),
        },
      };
    },
    setBasePayMethod: (
      state,
      action: {
        payload: PaymentMethodType;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          basePaymentMethodType: action.payload,
          initialPaymentMethodType: state.input.initialBillSyncBaseCycle
            ? action.payload
            : state.input.initialPaymentMethodType,
        },
      };
    },
    setFinishedAddContract: (
      state,
      action: {
        payload: {
          id: string;
          contractCode: string;
        };
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          id: action.payload.id,
          contractCode: action.payload.contractCode,
        },
        control: {
          ...state.control,
          step: state.control.step + 1,
        },
      };
    },
    setCustomerType: (
      state,
      action: {
        payload: TPCustomer;
      }
    ) => {
      return {
        ...state,
        input: {
          ...state.input,
          customerType: action.payload,
        },
        control: {
          ...state.control,
          errorList: [],
        },
      };
    },
    setContractType: (
      state,
      action: {
        payload: {
          type: TPContractType;
          beforeContractId?: string;
          customerId?: string;
        };
      }
    ) => ({
      ...state,
      control: {
        ...state.control,
        type: action.payload.type,
      },
      db: {
        ...state.db,
        beforeContractId: action.payload.beforeContractId,
        existingData: {
          ...state.db.existingData,
          id: state.db.existingData?.id ?? '',
          selectedContractPlans: state.db.existingData?.selectedContractPlans ?? [],
          chargeDetails: state.db.existingData?.chargeDetails ?? [],
          claimCycles: state.db.existingData?.claimCycles ?? [],
          allClaimCycles: state.db.existingData?.allClaimCycles ?? [],
          customerId: action.payload.customerId,
        },
      },
    }),
    setFromDraft: (
      state,
      action: {
        payload: {
          db: Partial<TPLoadedDbState>;
          input: Partial<TPInputContractState>;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload.db,
        },
        input: {
          ...state.input,
          ...action.payload.input,
        },
      };
    },
    setFromAddContract: (
      state,
      action: {
        payload: {
          db: Partial<TPLoadedDbState>;
          input: Partial<TPInputContractState>;
        };
      }
    ) => {
      return {
        ...state,
        db: {
          ...state.db,
          ...action.payload.db,
        },
        input: {
          ...state.input,
          ...action.payload.input,
        },
      };
    },
    removeChargeDetail: (
      state,
      action: {
        payload: {
          updatedCalculatedDetail: CalculatedChargeDetailTypes;
          removeDetailId: string;
        };
      }
    ) => {
      const initialDetails = action.payload.updatedCalculatedDetail.details.filter(
        detail => detail.billingType === 'initial'
      );
      const baseDetails = action.payload.updatedCalculatedDetail.details.filter(
        detail => detail.billingType !== 'initial'
      );
      const baseCycleIds = baseDetails.map(base => base.claimCycleId);
      const existBaseCycle = baseCycleIds.some(baseCycleId => state.input.baseCycle?.id === baseCycleId);
      const newBaseCycle = state.input.selectedAllClaimCycles.find(cycle =>
        baseCycleIds.some(cycleId => cycleId === cycle.id)
      );

      const initialCycleIds = initialDetails.map(initial => initial.claimCycleId);
      const existInitialCycle = initialCycleIds.some(
        initialCycleId => state.input.initialClaimCycle?.id === initialCycleId
      );
      const newInitialCycle = state.input.selectedAllClaimCycles.find(cycle =>
        initialCycleIds.some(cycleId => cycleId === cycle.id)
      );
      // 入居料金を参照する品目の場合、契約プランに存在するオリジナルの品目をもとに選択された入居エリアの分だけ品目を複製している。
      // 契約作成画面上で手動で品目を追加した場合、追加時の情報をオリジナルとしてinput.manualAddedChargeDetailsに保持し、入居エリア分だけ複製する。
      // 複製された品目を削除した場合、品目そのものを削除したいのか、ある一部の入居エリア分だけ削除したいのかわからないので、他に複製された品目が残っている場合はinput.manualAddedChargeDetailsはそのまま保持する。
      // 複製された品目が1つしかない状態で削除した場合は、品目そのものを削除するとみなしてinput.manualAddedChargeDetailsからも削除する。
      // これをやらない場合、間違った内容で追加→一度削除して再作成のようにした場合に間違った内容の品目も残り続けてしまうため。
      //
      // idをsliceで先頭20文字に切っているのは、入居エリアの分だけ品目が複製された際に識別できるように品目のidとエリアのidをつなげたものをchargeDetailIdとして保持しているため
      const trimChargeDetailId = action.payload.removeDetailId.slice(0, 20);
      const isManualAddCharge = state.input.manualAddedChargeDetails.some(
        detail => detail.id.slice(0, 20) === trimChargeDetailId
      );
      const isAllDeletedReplica =
        isManualAddCharge &&
        action.payload.updatedCalculatedDetail.details.every(
          detail => detail.id.slice(0, 20) !== action.payload.removeDetailId.slice(0, 20)
        );
      const removedManualAddedChargeDetails = state.input.manualAddedChargeDetails.filter(
        detail => detail.id !== trimChargeDetailId
      );

      return {
        ...state,
        input: {
          ...state.input,
          calcChargeDetailAll: action.payload.updatedCalculatedDetail,
          baseCycle: existBaseCycle ? state.input.baseCycle : newBaseCycle,
          initialClaimCycle: existInitialCycle ? state.input.initialClaimCycle : newInitialCycle,
          manualAddedChargeDetails: isAllDeletedReplica
            ? removedManualAddedChargeDetails
            : state.input.manualAddedChargeDetails,
        },
      };
    },
    editChargeDetail: (
      state,
      action: {
        payload: {
          updatedCalculatedDetail: CalculatedChargeDetailTypes;
          selectedAllClaimCycles: StoreTypesOrgClaimCycle[];
        };
      }
    ) => {
      const initialDetails = action.payload.updatedCalculatedDetail.details.filter(
        detail => detail.billingType === 'initial'
      );
      const baseDetails = action.payload.updatedCalculatedDetail.details.filter(
        detail => detail.billingType !== 'initial'
      );

      const baseCycleIds = baseDetails.map(base => base.claimCycleId);
      const existBaseCycle = baseCycleIds.some(baseCycleId => state.input.baseCycle?.id === baseCycleId);
      const newBaseCycle = action.payload.selectedAllClaimCycles.find(cycle =>
        baseCycleIds.some(cycleId => cycleId === cycle.id)
      );

      const initialCycleIds = initialDetails.map(initial => initial.claimCycleId);
      const existInitialCycle = initialCycleIds.some(
        initialCycleId => state.input.initialClaimCycle?.id === initialCycleId
      );
      const newInitialCycle = action.payload.selectedAllClaimCycles.find(cycle =>
        initialCycleIds.some(cycleId => cycleId === cycle.id)
      );
      return {
        ...state,
        input: {
          ...state.input,
          calcChargeDetailAll: action.payload.updatedCalculatedDetail,
          selectedAllClaimCycles: action.payload.selectedAllClaimCycles,
          baseCycle: existBaseCycle ? state.input.baseCycle : newBaseCycle,
          initialClaimCycle: existInitialCycle ? state.input.initialClaimCycle : newInitialCycle,
        },
      };
    },
    clear: () => initialState,
  },
});
export default createContractSlice;
