import type {AnyRoute} from '@tanstack/react-router';
import {useCallback} from 'react';
import * as z from 'zod';

interface Options<T extends z.AnyZodObject> {
  schema: T;
}

interface SetSearchState<T extends z.AnyZodObject> {
  (input: z.input<T> | ((prev: z.infer<T>) => z.input<T>)): void;
}

type UseSearchState<T extends z.AnyZodObject> = [z.infer<T>, SetSearchState<T>];

interface WithSearchState<T extends z.AnyZodObject> {
  validateSearch(input: Record<string, unknown>): z.input<T>;
  useSearchState<R extends AnyRoute>(route: R): UseSearchState<T>;
}

/**
 * クエリパラメータをステートとしてスキーマフルに扱うための HOC.
 */
export function withSearchState<T extends z.AnyZodObject>({schema}: Options<T>): WithSearchState<T> {
  function validateSearch(input: Record<string, unknown>): z.input<T> {
    return schema.parse(input);
  }

  function useSearchState<R extends AnyRoute>(route: R): UseSearchState<T> {
    const searchState = route.useSearch();
    const navigate = route.useNavigate();

    const setSearchState: SetSearchState<T> = useCallback(
      (input): void => {
        void navigate({
          search: input as any,
        });
      },
      [navigate]
    );

    return [searchState, setSearchState];
  }

  return {
    validateSearch,
    useSearchState,
  };
}
