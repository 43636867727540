import styled from '@emotion/styled';
import {useMutation} from '@tanstack/react-query';
import {createFileRoute, useNavigate} from '@tanstack/react-router';
import * as Icon from '@workhub/icons';
import {IconButton, TextButton} from '@workhub/ui';
import {isAxiosError} from 'axios';
import * as React from 'react';
import {useCallback, useState, useMemo} from 'react';
import {isMobile} from 'react-device-detect';

import {accountResetPasswordApi} from '@/api-call/workhub-core/accountResetPasswordApi';
import useDict from '@/common/hooks/useDict';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {CookieKey, getCookieValueByKey, removeCookieValueByKey} from '@/common/storage/cookie';
import {WHFont} from '@/common/styles/whFont';
import WFormDialogTextField from '@/components/figma/others/dialog/form/WFormDialogTextField';
import WLabelContent from '@/components/figma/others/label/WLabelContent';
import LanguageToggler from '@/components/languageToggler/LanguageToggler';
import {simpleSnackbarContainer} from '@/components/layouts/simpleSnackbarContainer';
import SimpleSnackbar from '@/components/snackbar/SimpleSnackbar';
import {
  BKP_MIN_PASSWORD_LENGTH,
  BKP_MAX_PASSWORD_LENGTH,
  useValidatePassword,
} from '@/wcustomhooks/auth/useValidatePassword';

import {RESET_PASSWORD_QUERY_KEY} from './-queryKeys';

export const Route = createFileRoute('/resetPassword')({
  component: RouteComponent,
  validateSearch: search => search as {passwordResetToken: string}, // TODO: バリデーション
});

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: var(--surface-neutral-low);
`;

const FormInner = styled.div`
  margin-top: 80px;
`;

const PasswordHint = styled.div`
  ${WHFont.bodySmall}
  color: var(--text-neutral-secondary);
  margin-top: var(--spacing-8);
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 360px;
  height: 540px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-shadow: ${!isMobile ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : 'none'};
  border-radius: var(--radius-l);
`;

const ErrorField = styled.div`
  margin-top: var(--spacing-16);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 88%;
  padding: var(--spacing-16);
  box-sizing: border-box;
`;

const ErrorMessage = styled.div`
  ${WHFont.bodySmall}
  color: var(--text-semantic-error);
`;

const InputFieldWrapper = styled.div`
  position: relative;
`;

const VisibilityIconButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
`;

const WhIcon = styled(Icon.WhIconDisplay)`
  font-size: 48px;
  color: var(--text-brand-primary);
`;

const VisibilityIcon = styled(Icon.Visibility)`
  color: var(--text-neutral-secondary);
`;

const VisibilityOffIcon = styled(Icon.VisibilityOff)`
  color: var(--text-neutral-secondary);
`;

const LogoWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
`;

const FullWidthTextButton = styled(TextButton)`
  width: 100%;
  margin-top: 60px;
  padding: var(--spacing-20) 0;
`;

const PasswordResetCompletedMessage = styled.div`
  margin-top: 80px;
  text-align: center;
  white-space: pre-wrap;
`;

const dictDef = {
  invalidUrl: {
    default: {
      default: '無効なURLです',
      [Locale.en_US]: 'The URL is invalid.',
    },
  },
  failedReset: {
    default: {
      default:
        'URLの使用期限が超過、もしくは使用済みの可能性があります。\nお手数ですが、もう一度パスワード再設定用のメールを発行してください。',
      [Locale.en_US]:
        'The URL may have exceeded its expiration date or may have already been used.\nPlease issue an email to reset your password again.',
    },
  },
  reset: {
    default: {
      default: 'リセット',
      [Locale.en_US]: 'Reset',
    },
  },
  newPassword: {
    default: {
      default: '新しいパスワード',
      [Locale.en_US]: 'New Password',
    },
  },
  togglePasswordVisibility: {
    default: {
      default: 'パスワードの表示/非表示',
      [Locale.en_US]: 'Toggle password visibility',
    },
  },
  passwordLengthError: {
    default: {
      default: `パスワードは半角${BKP_MIN_PASSWORD_LENGTH}文字以上${BKP_MAX_PASSWORD_LENGTH}文字以下で入力してください`,
      [Locale.en_US]: `Please enter ${BKP_MIN_PASSWORD_LENGTH} characters at least and no more than ${BKP_MAX_PASSWORD_LENGTH} characters`,
    },
  },
  passwordResetCompleted: {
    default: {
      default: `パスワードリセットが完了しました\nまだ組織に参加していない場合は、招待リンクから再度参加してください`,
      [Locale.en_US]: `Password reset completed.\nIf you have not yet joined the organization, please join again from the invitation link.`,
    },
  },
  thisPasswordIsNotSecure: {
    default: {
      default: '安全性の低いパスワードです。別のパスワードを設定してください',
      [Locale.en_US]: 'This password is not secure. Please set a different password.',
    },
  },
  login: {
    default: {
      default: 'ログイン',
      [Locale.en_US]: 'Login',
    },
  },
};

function RouteComponent() {
  const dict = useDict(dictDef);
  const {passwordResetToken: bulkResetToken} = Route.useSearch();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const snackbarState = simpleSnackbarContainer.useValue();
  const snackbar = useSnackbar();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const {passwordErrorMessage} = useValidatePassword(password);
  const navigate = useNavigate();

  const {
    mutate: onSubmitResetPassword,
    isPending,
    isSuccess,
  } = useMutation({
    mutationKey: [RESET_PASSWORD_QUERY_KEY.BULK_RESET_PASSWORD, bulkResetToken],
    mutationFn: async () => {
      if (passwordErrorMessage) {
        throw new Error('validate-password-error');
      }
      if (!bulkResetToken) {
        throw new Error('bulk-reset-token-not-found');
      }
      const emailAddress = getCookieValueByKey(CookieKey.emailAddress);
      return await accountResetPasswordApi({
        body: {
          password,
          bulkResetToken,
          emailAddress: emailAddress ? decodeURIComponent(emailAddress) : undefined,
        },
      });
    },
    onSuccess: () => {
      // Cookieのメールアドレスを使ってパスワード再設定が完了したら、Cookieを削除する
      removeCookieValueByKey(CookieKey.emailAddress);
    },
    onError: e => {
      if (isAxiosError(e)) {
        // 現時点でAPIから400が返されるのはPasswordRulesetを満たしていない場合のみ
        // それ以外はBKPから500が返される場合のみなので、 snackbar.fail(dict.failedReset) で吸収する
        if (e.response?.status === 400) {
          setErrorMessage(dict.thisPasswordIsNotSecure);
          return;
        }
      }
      if (e instanceof Error) {
        switch (e.message) {
          case 'validate-password-error':
            setErrorMessage(passwordErrorMessage);
            return;
          case 'invitation-id-not-found':
            setErrorMessage(dict.invalidUrl);
            return;
        }
      }

      snackbar.fail(dict.failedReset);
    },
  });

  const onChangePassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    [setPassword]
  );

  const toggleShowPassword = useCallback(() => {
    setShowPassword(value => !value);
  }, []);

  const shouldDisableResetButton = useMemo(() => {
    return password.length < BKP_MIN_PASSWORD_LENGTH || !bulkResetToken;
  }, [password, bulkResetToken]);

  const onKeyDownPassword = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter') {
        onSubmitResetPassword();
      }
    },
    [onSubmitResetPassword]
  );

  return (
    <Frame>
      <LanguageToggler />
      <Box theme={{isMobile}}>
        <Wrapper>
          <LogoWrapper>
            <WhIcon />
          </LogoWrapper>
          {!isSuccess ? (
            <>
              <FormInner>
                <WLabelContent
                  label={dict.newPassword}
                  width={'100%'}
                  content={
                    <InputFieldWrapper>
                      <WFormDialogTextField
                        id={'password'}
                        name={'password'}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={onChangePassword}
                        onKeyDown={onKeyDownPassword}
                      />
                      <VisibilityIconButtonWrapper>
                        <IconButton title={dict.togglePasswordVisibility} onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </VisibilityIconButtonWrapper>
                    </InputFieldWrapper>
                  }
                />
              </FormInner>
              <PasswordHint>{dict.passwordLengthError}</PasswordHint>
              <FullWidthTextButton
                onClick={() => onSubmitResetPassword()}
                disabled={shouldDisableResetButton}
                processing={isPending}
              >
                {dict.reset}
              </FullWidthTextButton>
              {!!errorMessage && (
                <ErrorField>
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </ErrorField>
              )}
            </>
          ) : (
            <>
              <PasswordResetCompletedMessage>{dict.passwordResetCompleted}</PasswordResetCompletedMessage>
              <FullWidthTextButton onClick={() => navigate({to: '/'})}>{dict.login}</FullWidthTextButton>
            </>
          )}
        </Wrapper>
      </Box>
      <SimpleSnackbar snackbarState={snackbarState.state} setSnackbarState={snackbarState.setState} />
    </Frame>
  );
}
