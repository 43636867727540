import styled from '@emotion/styled';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {createFileRoute} from '@tanstack/react-router';

import {WHColor} from '@/common/styles/whColor';

export const Route = createFileRoute('/_authorized/_mobile/reservation/')({
  component: RouteComponent,
});

const Root = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--surface-neutral-low);
`;

const HeaderTitle = styled.div`
  height: 90px;
  background-color: var(--surface-brand-primary);
  color: var(--text-neutral-white-primary);
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  height: 80%;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 20px;
`;

const Message = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: var(--text-brand-primary);
`;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
`;

const adornStyle = createTheme({
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: WHColor.surface.neutralHigh,
        },
        input: {
          padding: '15px 12px 10px',
        },
        underline: {
          '&:after': {
            borderBottom: `1px solid ${WHColor.surface.neutralHigh} !important`,
          },
          '&:before': {
            borderBottom: `1px solid ${WHColor.surface.neutralHigh} !important`,
          },
          '&:hover': {
            '&:before': {
              borderBottom: `1px solid ${WHColor.surface.neutralHigh} !important`,
            },
          },
          '&:hover&:before': {
            borderBottom: `1px solid ${WHColor.surface.neutralHigh} !important`,
          },
        },
      },
    },
  },
});

function RouteComponent() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={adornStyle}>
        <Root>
          <HeaderTitle>会議室予約</HeaderTitle>
          <Wrapper>
            <Content>
              <Message>本機能の提供は終了しました</Message>
            </Content>
          </Wrapper>
        </Root>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
