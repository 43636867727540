import {createFileRoute} from '@tanstack/react-router';
import {isMobileOnly} from 'react-device-detect';

import {NoPage} from '@/mobile/screens/NoPage';
import Router from '@/routing/Router';

export const Route = createFileRoute('/_authorized/$')({
  component: RouteComponent,
});

function RouteComponent() {
  if (isMobileOnly) {
    return <NoPage />;
  }

  return <Router />;
}
