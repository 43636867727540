import {createSlice} from '@reduxjs/toolkit';

import {NestedReadonly} from '../../types/common-types';
import {BusinessType} from '../state-types/businessTypeStateType';

const initialState: NestedReadonly<BusinessType> = BusinessType.None;

const businessTypeSlice = createSlice({
  name: 'businessType',
  initialState,
  reducers: {
    set: (state, action) => action.payload,
    clear: () => initialState,
  },
});

export default businessTypeSlice;
