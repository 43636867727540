import dayjs, {type Dayjs, isDayjs} from 'dayjs';

import './useDayjs';

/**
 * dayjsインスタンスの生成と同時に日本時間のoffsetを設定する。
 * dayjsはmomentと異なり日時操作が非破壊なので破壊的な動作を利用しようとすると意図しない結果になる点に注意。
 * @param init
 */
export const jpDate = (...init: Parameters<typeof dayjs>): Dayjs => {
  const [date, ...rest] = init;
  // utcOffsetをかけたDayjsインスタンスに二重にかけてhour(n)などの演算結果がバグるので1度Dateインスタンスに変換して入力
  if (date && isDayjs(date)) {
    return dayjs(date.toDate(), ...rest).utcOffset(9);
  }
  return dayjs(...(init ?? [])).utcOffset(9);
};
