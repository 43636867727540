import {OperationLogEventTriggers} from '@bitkey-service/v2_core-types/lib/enum/operation-logs/operationLogEventTypes';
import {AxiosRequestConfig} from 'axios';

import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';

import {getAccessToken} from '../../common/accessToken';
import {coreCurrentApi} from '../../common/axios/coreCurrentApi';
import {getFirebaseAuth} from '../../common/firebase/firebase-auth';

/**
 * 操作ログを保存するためのパラメータ
 */
export const csvLogOption = {trigger: OperationLogEventTriggers.Csv, disable: false};
export const manualLogOption = {trigger: OperationLogEventTriggers.Manual, disable: false};
export const coreLogOption = (csv: boolean) => {
  return csv ? csvLogOption : manualLogOption;
};

const getIdToken = () => getFirebaseAuth().currentUser?.getIdToken();
const getConfig = async (config?: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  // idTokenとaccessTokenはどっちかでいいんだけど元々両方詰めてたから踏襲
  // まぁ2つあるとバックエンドで変更入れやすいからアリ。
  const idToken = await getIdToken();
  const authorizationHeader = idToken ? {authorization: `bearer ${idToken}`} : undefined;
  const accessToken = getAccessToken();
  const xApiKeyHeader = accessToken ? {'x-api-key': accessToken} : undefined;
  return {...config, headers: {...config?.headers, ...authorizationHeader, ...xApiKeyHeader}};
};

export class ApiWorkhubCore {
  static post = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await coreCurrentApi.post(path, data, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static put = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await coreCurrentApi.put(path, data, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static patch = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await coreCurrentApi.patch(path, data, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static get = async <Response>(path: string, config?: AxiosRequestConfig): Promise<Response> => {
    const res = await coreCurrentApi.get(path, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static delete = async <Response>(path: string, config?: AxiosRequestConfig): Promise<Response> => {
    const res = await coreCurrentApi.delete(path, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
}
