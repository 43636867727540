import {Locale} from '@/common/redux/state-types/localeStateType';

export const buildingOwnerSectionMenuDef = {
  buildingOwnerContractManagement: {default: {default: '契約管理', [Locale.en_US]: 'Contract Management'}},
  buildingOwnerPlanAndOptions: {default: {default: 'プラン・オプション', [Locale.en_US]: 'Plan Options'}},
  buildingOwnerMember: {default: {default: '会員・顧客管理', [Locale.en_US]: 'Member and Customer Management'}},
  buildingOwnerContract: {default: {default: 'ビルテナント契約', [Locale.en_US]: 'Building Tenant Contracts'}},
  usageHistory: {default: {default: '利用履歴', [Locale.en_US]: 'Usage history'}},
  buildingOwnerContractPlans: {
    default: {default: 'ビルテナント契約プラン', [Locale.en_US]: 'Building Tenant Contract Plans'},
  },
  buildingOwnerOptionService: {default: {default: 'オプションサービス', [Locale.en_US]: 'Option Service Management'}},
};
