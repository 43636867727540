import {Auth, connectAuthEmulator, getAuth, signInWithCustomToken} from 'firebase/auth';

import {getFirebaseApp} from './firebase';

let auth: Auth | undefined;
export const getFirebaseAuth = (): Auth => {
  if (auth) {
    return auth;
  }
  auth = getAuth(getFirebaseApp());
  if (import.meta.env.VITE_FIREBASE_PROJECT_ID === 'workhub-local') {
    connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST as string);
  }
  return auth;
};

export default class FirebaseAuth {
  public static createInstance = () => new FirebaseAuth();
  signIn = async (customToken: string) => {
    await signInWithCustomToken(getFirebaseAuth(), customToken);
  };
  getIdToken = () => getFirebaseAuth().currentUser?.getIdToken();
}

export const getFirebaseCurrentUser = () => getFirebaseAuth().currentUser;
export const getFirebaseCurrentUid = (): string | undefined => getFirebaseCurrentUser()?.uid;
export const getFirebaseIdToken = async (): Promise<string | undefined> => getFirebaseCurrentUser()?.getIdToken();
