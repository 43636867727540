import styled from '@emotion/styled';

import {WHColor} from '@/common/styles/whColor';

export const LoadingSkeltonAnimation = styled.div`
  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation: loading 1s linear infinite;
  }
  background: ${WHColor.surface.neutralHigh};
  overflow: hidden;
  position: relative;
`;

export const LoadingSkeltonAnimationGray = styled.div`
  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, ${WHColor.background.default}, transparent);
    animation: loading 1s linear infinite;
  }
  background: white;
  overflow: hidden;
  position: relative;
`;

export const LoadingAreaBase = styled.div`
  overflow: hidden;
  background: ${WHColor.surface.neutralLow};
  display: flex;
  align-items: center;
`;
