// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import Logger from '@/common/logger/logger';

const logger = Logger.create('updateSatisfactionApi');

export const updateSatisfactionApi = async (
  arg: {body: UpdateSatisfactionBody; paths: UpdateSatisfactionPathParam},
  opts?: {region?: 'default' | 'asia'}
) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .patch<UpdateSatisfactionResponse>(`/organizations/satisfactions/${arg.paths.satisfactionId}`, arg.body, {
      headers: {
        authorization: `bearer ${idToken}`,
      },
    })
    .catch(axiosCatch(getStackTrace()));
};

export type UpdateSatisfactionResponse = {
  id: string;
  spaceId: string;
  peopleId: string;
  satisfactionSettingId: string;
  reservationId?: string;
  score?: number;
  comment?: string;
  questionnaires?: {
    /**
     * 回答するアンケートのidを設定してください。
     */
    questionnaireId: string;
    /**
     * ラジオタイプ形式など選択肢が存在する設問の場合、回答する選択肢のidを設定してください
     */
    optionIds?: string[];
    /**
     * テキスト形式のアンケートの場合、テキスト回答を入力してください。
     */
    answerText?: string;
  }[];
  sentAt: ISOString;
  answeredAt?: ISOString;
  registeredByPersonaId?: string;
  modifiedByPersonaId?: string;
  createdAt: ISOString;
  updatedAt: ISOString;
};
export type UpdateSatisfactionBody = {
  score?: number;
  comment?: string;
  questionnaires?: {
    /**
     * 回答するアンケートのidを設定してください。
     */
    questionnaireId: string;
    /**
     * ラジオタイプ形式など選択肢が存在する設問の場合、回答する選択肢のidを設定してください
     */
    optionIds?: string[];
    /**
     * テキスト形式のアンケートの場合、テキスト回答を入力してください。
     */
    answerText?: string;
  }[];
};
export type UpdateSatisfactionPathParam = {
  satisfactionId: string;
};
