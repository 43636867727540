import {EpochMillis} from '@bitkey-service/workhub-functions-common-types/lib/common/commonTypes';
import Big from 'big.js';
import dayjs, {type Dayjs} from 'dayjs';

import {Timestamp} from '../firebase/firestore/firebase-firestore';

type YYYYMMDD = number;
type hhmmss = number;
// undefinedも許容すると影響範囲広くなるので、一旦入れない
type UtilTimestamp = number | string | Date | Dayjs | EpochMillis | Timestamp;

const EMPTY_STRING = '-';

export class TimeUtils {
  private static timestampToDayjs = (timestamp: UtilTimestamp) => {
    let _timestamp: Exclude<UtilTimestamp, Timestamp>;
    if (typeof timestamp !== 'string' && typeof timestamp !== 'number' && 'toMillis' in timestamp) {
      _timestamp = timestamp.toMillis();
    } else {
      _timestamp = timestamp;
    }

    const _dayjs = dayjs(_timestamp);
    if (!_dayjs.isValid()) {
      return '';
    }

    return _dayjs;
  };

  // TODO: 地域によって表示すべき年月日の並びが違うのでその分岐を入れる
  private static timestampToFormat = (timestamp: UtilTimestamp, format: string) => {
    const _dayjs = TimeUtils.timestampToDayjs(timestamp);
    return _dayjs && _dayjs.format(format);
  };

  public static timestampToFormatString = (timestamp: UtilTimestamp) => {
    return TimeUtils.timestampToFormat(timestamp, 'YYYY/MM/DD HH:mm');
  };

  public static timestampToStringYYYYMMDD = (timestamp: UtilTimestamp) => {
    return TimeUtils.timestampToFormat(timestamp, 'YYYY/MM/DD');
  };

  public static timestampToStringYYYYMMDDStartToEnd = ({start, end}: {start?: UtilTimestamp; end?: UtilTimestamp}) => {
    const startTime = start ? TimeUtils.timestampToStringYYYYMMDD(start) : '';
    const endTime = end ? TimeUtils.timestampToStringYYYYMMDD(end) : '';
    return `${startTime} - ${endTime}`;
  };

  public static timestampToStringYYYYMMDDwithoutSlash = (timestamp: UtilTimestamp) => {
    return TimeUtils.timestampToFormat(timestamp, 'YYYYMMDD');
  };

  public static timestampToStringHHmm = (timestamp: UtilTimestamp) => {
    return TimeUtils.timestampToFormat(timestamp, 'HH:mm');
  };

  public static toString = (timestamp?: UtilTimestamp): string => {
    if (!timestamp) return EMPTY_STRING;
    return TimeUtils.timestampToFormat(timestamp, 'YYYY/MM/DD HH:mm');
  };

  public static toStringYYYYMMDD = (timestamp?: UtilTimestamp): string => {
    if (!timestamp) return EMPTY_STRING;
    return TimeUtils.timestampToFormat(timestamp, 'YYYY/MM/DD');
  };

  public static toStringSecond = (timestamp: UtilTimestamp): string => {
    if (!timestamp) return EMPTY_STRING;
    return TimeUtils.timestampToFormat(timestamp, 'YYYY/MM/DD HH:mm:ss');
  };

  public static toStringMMDDddWareki = (timestamp: UtilTimestamp): string => {
    if (!timestamp) return EMPTY_STRING;
    return TimeUtils.timestampToFormat(timestamp, 'MM月DD日（dd）');
  };

  public static edgeHistoryToDayjs = ({date, time}: {date: YYYYMMDD; time: hhmmss}) => {
    const dateStr = date.toString();
    // 0埋めされてこない時間の対応
    const timeStr = time.toString().padStart(6, '0');

    return dayjs({
      year: Number(dateStr.slice(0, 4)),
      month: Number(dateStr.slice(4, 6)) - 1,
      day: Number(dateStr.slice(6, 8)),
      hour: Number(timeStr.slice(0, 2)),
      minute: Number(timeStr.slice(2, 4)),
      second: Number(timeStr.slice(4, 6)),
    });
  };

  public static EdgeHistoryToString = ({date, time}: {date: YYYYMMDD; time: hhmmss}) => {
    return TimeUtils.edgeHistoryToDayjs({date, time}).format('YYYY/MM/DD HH:mm:ss');
  };

  public static durationFromTo = ({from, to}: {from: number; to: number}) => {
    if (!from || !to) {
      return;
    }
    const fromDate = dayjs(from);
    const toDate = dayjs(to);
    const diff = toDate.diff(fromDate);
    return dayjs.duration(diff);
  };

  public static durationFromWithFormat = ({from, to}: {from: number; to: number}) => {
    if (!from || !to) {
      return;
    }
    return TimeUtils.durationFromTo({from, to})?.humanize();
  };

  /**
   * valueが有効期限内かどうかを判定する
   * @param validFromTo
   * @param value
   */
  public static validFromTo = ({
    validFromTo,
    value,
  }: {
    validFromTo: {
      from?: number;
      to?: number;
    };
    value: {
      from?: number;
      to?: number;
    };
  }) => {
    // 無期限だったら判定する意味なし
    if ((!value.to && !value.from) || (!validFromTo.from && !validFromTo.to)) {
      return true;
    }
    const validFrom = !validFromTo.from || validFromTo.from <= (value?.from ?? 0);
    const validTo = !validFromTo.to || (value?.to && validFromTo.to >= value?.to);
    return validFrom && validTo;
  };

  public static minuteToTimeHourMinute = (minutes?: number) => {
    if (!minutes) return `${0}分`;
    const MINUTES_IN_AN_HOUR = 60;

    const hours = Math.floor(minutes / MINUTES_IN_AN_HOUR);
    const remainingMinutes = minutes % MINUTES_IN_AN_HOUR;

    return `${hours}時間${Big(remainingMinutes).round(1).toNumber()}分`;
  };

  public static isEndDateValid = ({
    startDate,
    endDate,
  }: {
    startDate?: UtilTimestamp | null;
    endDate?: UtilTimestamp | null;
  }): boolean => {
    if (!startDate || !endDate) return true;
    return dayjs(startDate).isSameOrBefore(dayjs(endDate), 'day');
  };
}
