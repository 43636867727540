import {StoreTypesOrgDropInPricePlans} from '@bitkey-service/v2_core-types/lib/store/organizations/drop-in-price-plans/storeTypesOrgDropInPricePlans';
import {PaymentMethodType} from '@bitkey-service/v2_core-types/lib/store/organizations/payment-method/storeTypesOrgPaymentMethod';
import {V2SpaceAttribute} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';
import {StoreTypesOrgThirdPlaceSettingsOrgPaymentMethod} from '@bitkey-service/v2_core-types/lib/store/organizations/third-place-settings/storeTypesOrgThirdPlaceSettingsOrgPaymentMethod';

import {PayeeType} from '@/wscreens/third-place/invoice-setting/edit/InvoiceSettingPayeeEdit';
import {SenderType} from '@/wscreens/third-place/invoice-setting/edit/InvoiceSettingSenderEdit';

export enum CreateDropInPlanStep {
  Start = 0,
  BasicInfo = 1,
  SelectArea = 2,
  SelectPricePlan = 3,
  SelectPayment = 4,
}

export enum DropInPlanDetailTabs {
  OverView = 'overView',
  ApplicableArea = 'applicableArea',
}

type LoadResult = 'pending' | 'fulfilled' | 'reject';

type InputValue = {
  value?: string;
  validType: 'initial' | 'noInput' | 'invalid' | 'duplicate' | 'pending' | 'valid';
};

type SpaceData = {
  id: string;
  name: string;
  attribute: Extract<V2SpaceAttribute, 'WorkBooth' | 'MeetingRoom'>;
  regionName: string | undefined;
  regionId: string | undefined;
  buildingId: string | undefined;
  floorId: string | undefined;
  location: string;
  capacity: string;
  /**定期利用設定の公開設定 */
  isPublicSubscriptionArea: boolean;
  /**ドロップイン設定の公開設定 */
  isPublicDropInArea: boolean;
};

type PricePlanType = {
  id: string;
  code: string;
  name: string;
  publicName: string;
  price: number;
  pricePlanSetting: StoreTypesOrgDropInPricePlans['pricePlanSetting'];
  validFrom: EpochMillis;
  validTo?: EpochMillis;
};

type InputBasicInfoType = {
  code: InputValue;
  managementName: InputValue;
};

type SelectPricePlanType = {
  searchWord?: string;
  viewPricePlans: PricePlanType[];
  allCheckRow: boolean;
  checkedPricePlanIds: string[];
  validFrom?: number;
  validTo?: number;
};

type SelectPaymentMethodType = {
  selectedPaymentMethods: PaymentMethodType[];
  sender?: SenderType;
  payee?: PayeeType;
};

type SelectSpaceAreaType = {
  searchWord?: string;
  displayAreaType: SpaceData['attribute'][];
  viewSpaces: SpaceData[];
  allCheckRow: boolean;
  checkRowIds: string[];
  selectSpaceStructures: {
    regionIds: string[];
    buildingIds: string[];
    floorIds: string[];
  };
};

type EditCommonType = {isOpen: boolean; isProcessing: boolean};
type DropInPlanListType = {id: string; code: string; name: string; isPublished: boolean; lastUpdatedAt: number};

export type DropInPlansScreenState = {
  isDetailScreen: boolean;
  tabs: {
    createDropInPlanDialog: (typeof CreateDropInPlanStep)[keyof typeof CreateDropInPlanStep];
    dropInPlanDetailTab: (typeof DropInPlanDetailTabs)[keyof typeof DropInPlanDetailTabs];
  };
  loading: {
    /**削除する時 */
    deleteLoading: boolean;
    /**非公開にする時のローディング */
    publishLoading: boolean;
  };
  dropInPlanListUI: {
    allCheck: boolean;
    checkedPlanIds: string[];
    viewPlans: DropInPlanListType[];
    searchWord: string;
    lastUpdateFilter: {from?: number; to?: number};
    publishFilter: ('publish' | 'private')[];
    sortBy: {
      key: 'code';
      orderBy: 'asc' | 'desc';
    };
  };
  dialog: {
    createDropInPlan: {
      registering: boolean;
      isOpenCreateDialog: boolean;
      isOpenConfirmDialog: boolean;
      inputBasicInfoStep: InputBasicInfoType;
      selectAreaStep: SelectSpaceAreaType;
      selectPricePlanStep: SelectPricePlanType;
      selectPaymentMethodStep: SelectPaymentMethodType;
    };
    editBasicInfoDialog: EditCommonType & InputBasicInfoType;
    editPricePlanDialog: EditCommonType & SelectPricePlanType;
    editPaymentMethodDialog: EditCommonType & SelectPaymentMethodType;
    editApplicableAreaDialog: EditCommonType & SelectSpaceAreaType;
    editPublishDialog: EditCommonType & {isOpenConfirmDialog: boolean};
    bulkDeleteConfirmDialog: EditCommonType;
    deleteDropInPlanDialog: EditCommonType;
  };
  persist: {
    /**詳細ページで最終更新者を変更するために必要 */
    loginUser: {
      name: string;
      iconUrl: string;
    };
    /**詳細ページ用 */
    dropInPlan?: {
      id: string;
      code: string;
      name: string;
      isPublished: boolean;
      registeredBy?: {peopleId: string; name: string; imageUrl: string; timestamp: EpochMillis};
      lastUpdatedBy?: {peopleId: string; name: string; imageUrl: string; timestamp: EpochMillis};
      paymentMethodTypes: PaymentMethodType[];
      sender?: SenderType;
      payee?: PayeeType;
      pricePlans: PricePlanType[];
      spaces: {
        ids: string[];
        result: LoadResult;
        /**一覧で表示するため */
        data?: SpaceData[];
      };
    };
    /**一覧ページ用 */
    dropInPlans: {
      result: LoadResult;
      data: DropInPlanListType[];
    };
    spaceStructures: {
      regionId: string;
      label: string;
      buildings: {
        buildingId: string;
        label: string;
        floors: {
          floorId: string;
          label: string;
        }[];
      }[];
    }[];
    spaces: {
      result: LoadResult;
      data: SpaceData[];
    };
    pricePlans: {
      result: LoadResult;
      data: PricePlanType[];
    };
    activePaymentMethod: StoreTypesOrgThirdPlaceSettingsOrgPaymentMethod['activePaymentMethod'];
  };
};
