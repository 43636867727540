import axios, {AxiosError, AxiosResponse} from 'axios';

import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import Logger from '../logger/logger';

const logger = Logger.create('coreAxiosApi');

/**
 * cloud runのcore APIクライアントとなるaxiosインスタンスを取得する
 * IDトークンは利用するところで取得して渡す
 */
export const getCoreAxios = (opts?: {region?: 'default' | 'asia'}) => {
  const instance = axios.create({
    baseURL: getBaseUrl(opts?.region),
    headers: {
      'content-type': 'application/json',
    },
  });
  instance.interceptors.response.use(
    (res: AxiosResponse) => {
      logger.trace(res.request?.responseURL, res);
      return res;
    },
    (e: AxiosError) => {
      const errRes = (e as AxiosError<{code: number}>)?.response;
      if (errRes?.data && errRes.data.code === 401 && FirebaseFunctions.logoutByExpired) {
        logger.info(`failed to authenticate. logouting...`, {res: errRes}, e);
        FirebaseFunctions.logoutByExpired?.();
      } else {
        logger.error(`failed to ${e.response?.config.method} ${e.response?.config.url}`, e);
      }
      throw e;
    }
  );

  return instance;
};
const getBaseUrl = (region?: 'default' | 'asia') => {
  if (import.meta.env.VITE_LOCAL_API === 'core') {
    return import.meta.env.VITE_CORE_LOCAL_API_URL;
  }
  if (region === 'asia') {
    return import.meta.env.VITE_CORE_ASIA_API_URL;
  }
  return import.meta.env.VITE_CORE_DEFAULT_API_URL;
};
