import {createFileRoute} from '@tanstack/react-router';
import {useEffect} from 'react';

export const Route = createFileRoute('/sso/$code')({
  component: RouteComponent,
});

const host = import.meta.env.VITE_SSO_HOST as string;

function RouteComponent() {
  const {code} = Route.useParams();

  useEffect(() => {
    window.location.replace(`${host}/sso/${code}`);
  }, [code]);

  return null;
}
