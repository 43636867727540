import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {MultiTenantContractPlanScreenSliceState} from '@/common/redux/screens/state/multiTenantContractPlanScreenSliceState';
import {ValidationUtil} from '@/common/utils/validationUtil';

const initialState: MultiTenantContractPlanScreenSliceState = {
  dialog: {
    confirmRegister: {isOpen: false, loading: false},
    delete: {isOpen: false, loading: false},
    upsert: {
      isOpen: false,
      loading: false,
      name: {value: '', status: 'initial'},
      code: {value: '', status: 'initial'},
      selectSpaces: [],
    },
  },
  list: {
    plans: {data: [], status: 'loading'},
    viewPlanIds: [],
    searchFilter: {
      lastUpdateFilter: {},
      searchWord: '',
      sortBy: {key: 'code', orderBy: 'asc'},
    },
  },
  detail: {plan: {status: 'loading'}},
};

const multiTenantContractPlanScreenSlice = createSlice({
  name: 'multiTenantContractPlanScreenSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    setPersistencePlans: (
      state,
      {payload}: PayloadAction<MultiTenantContractPlanScreenSliceState['list']['plans']>
    ) => {
      state.list.plans = payload;
      state.list.viewPlanIds = payload.data.map(v => v.id);
    },
    addPersistencePlansData: (
      state,
      {payload}: PayloadAction<MultiTenantContractPlanScreenSliceState['list']['plans']['data'][number]>
    ) => {
      state.list.plans.data = [...state.list.plans.data, payload];
    },
    setOpenUpsertDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.upsert.isOpen = payload;
      if (!payload) {
        state.dialog.upsert = initialState.dialog.upsert;
      }
    },
    openEditDialog: state => {
      state.dialog.upsert = {
        isOpen: true,
        name: {value: state.detail.plan.data?.name, status: 'valid'},
        code: {value: state.detail.plan.data?.code, status: 'valid'},
        selectSpaces: state.detail.plan.data?.spaces ?? [],
        loading: false,
      };
    },
    updateUpsertDialogName: (state, {payload}: PayloadAction<string>) => {
      state.dialog.upsert.name = {
        value: payload,
        status: payload ? 'valid' : 'noInput',
      };
    },
    updateUpsertDialogCode: (
      state,
      {payload}: PayloadAction<Partial<MultiTenantContractPlanScreenSliceState['dialog']['upsert']['code']>>
    ) => {
      state.dialog.upsert.code = {
        value: payload?.value ?? state.dialog.upsert.code.value,
        status: payload?.status
          ? payload.status
          : !payload?.value
            ? 'noInput'
            : ValidationUtil.isValidCode(payload.value)
              ? 'pending'
              : 'invalid',
      };
    },
    updateOpenConfirmRegisterDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.confirmRegister.isOpen = payload;
    },
    updateOpenConfirmRegisterDialogLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.confirmRegister.loading = payload;
    },
    updateOpenDeleteDialog: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.delete.isOpen = payload;
    },
    updateOpenDeleteDialogLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.delete.loading = payload;
    },
    deleteSelectArea: (state, {payload}: PayloadAction<{spaceId: string}>) => {
      state.dialog.upsert.selectSpaces = state.dialog.upsert.selectSpaces.filter(v => v.id !== payload.spaceId);
    },
    updateSelectArea: (
      state,
      {payload}: PayloadAction<{spaces: MultiTenantContractPlanScreenSliceState['dialog']['upsert']['selectSpaces']}>
    ) => {
      state.dialog.upsert.selectSpaces = payload.spaces;
    },
    updateSearchFilterLastUpdatedAt: (
      state,
      {payload}: PayloadAction<MultiTenantContractPlanScreenSliceState['list']['searchFilter']['lastUpdateFilter']>
    ) => {
      state.list.searchFilter.lastUpdateFilter = {
        from: payload.from ?? state.list.searchFilter.lastUpdateFilter.from,
        to: payload.to ?? state.list.searchFilter.lastUpdateFilter.to,
      };
    },
    filterContractPlans: (
      state,
      {
        payload,
      }: PayloadAction<{
        searchWord?: string;
        allClear?: boolean;
        lastUpdateFilterType?: 'clear' | 'search';
        orderBy?: 'asc' | 'desc';
      }>
    ) => {
      const currentValue = state.list.searchFilter;

      if (payload.allClear) {
        state.list.viewPlanIds = state.list.plans.data.map(v => v.id);
        state.list.searchFilter = initialState.list.searchFilter;
      } else {
        const targetOrderBy = payload.orderBy ?? currentValue.sortBy.orderBy;
        const targetSearchWord = payload.searchWord ?? currentValue.searchWord;

        const targetLastUpdatedAt =
          payload.lastUpdateFilterType === 'clear' ? {to: undefined, from: undefined} : currentValue.lastUpdateFilter;

        const newViewPlans = state.list.plans.data.filter(v => {
          const isMatchSearchWord =
            !targetSearchWord ||
            v.code.toLowerCase().includes(targetSearchWord.toLocaleLowerCase()) ||
            v.name.toLowerCase().includes(targetSearchWord.toLocaleLowerCase());
          if (!isMatchSearchWord) return false;

          const isMatchLastUpdatedAt =
            !targetLastUpdatedAt.from && !targetLastUpdatedAt.to
              ? true
              : (!targetLastUpdatedAt.from || v.lastUpdatedBy.timestamp >= targetLastUpdatedAt.from) &&
                (!targetLastUpdatedAt.to || v.lastUpdatedBy.timestamp <= targetLastUpdatedAt.to);

          if (!isMatchLastUpdatedAt) return false;

          return true;
        });
        const isAsc = targetOrderBy === 'asc';
        state.list.viewPlanIds = newViewPlans
          .sort((a, b) => (a.code > b.code ? (isAsc ? 1 : -1) : isAsc ? -1 : 1))
          .map(v => v.id);
        state.list.searchFilter.sortBy.orderBy = targetOrderBy;
        state.list.searchFilter.searchWord = targetSearchWord;
        state.list.searchFilter.lastUpdateFilter = targetLastUpdatedAt;
      }
    },
    setDetailPlan: (state, {payload}: PayloadAction<MultiTenantContractPlanScreenSliceState['detail']['plan']>) => {
      state.detail.plan = payload;
    },
    updateDetailPlan: (state, {payload}: PayloadAction<MultiTenantContractPlanScreenSliceState['detail']['plan']>) => {
      state.detail.plan = payload;
    },
    updateHasActiveContracts: (state, {payload}: PayloadAction<boolean>) => {
      state.detail.hasActiveContracts = payload;
    },
  },
});
export default multiTenantContractPlanScreenSlice;
